/**
 * @template {(...args: any) => any} T
 * @param {T} fn
 * @returns {T}
 */
export function cached(fn) {
    let cachedValue;
    let cached;
    let cachedArgs;
    return function(...args) {
        if (cached) {
            if (
                cachedArgs && 
                args.length == cachedArgs.length &&
                args.every((v, i) => cachedArgs[i] === v)
            ) {
                return cachedValue;
            }
        }
        cachedArgs = args;
        cachedValue = fn.apply(this, args);
        cached = true;
        if (typeof cachedValue.then === 'function') {
            cachedValue = cachedValue.then(null, err => {
                cached = false;
                cachedValue = undefined;
                throw err;
            });
        }
        return cachedValue;
    }
}