import { Button, CircularProgress, LinearProgress, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useApiState } from '../../api/common';
import { commitShortableCNTable, createShortableCNTable, uploadShortableCNTableRow } from '../../api/shortable-cn';
import { getStockCodesWithExchanges } from '../../api/stock';
import { bn } from '../../lib/bn';
import { readSheetFromFile } from '../../lib/excel';
import { makeParser } from '../../lib/importer';
import { urlParent } from '../../lib/url';
import { useCoCallback } from '../../lib/useCo';

/** @typedef {{
 *    CodeAndType: string
 *    gics: string
 *    name: string
 *    total_Shares: string
 *    total_USD: string
 *    weighted_Rate: string
 *    baml_Rate: string
 *    baml_USD: string
 *    baml_Shares: string
 *    citi_Rate: string
 *    citi_USD: string
 *    citi_Shares: string
 *    clsa_Rate: string
 *    clsa_USD: string
 *    clsa_Shares: string
 *    cs_Rate: string
 *    cs_USD: string
 *    cs_Shares: string
 *    hsbc_Rate: string
 *    hsbc_USD: string
 *    hsbc_Shares: string
 *    jpm_Rate: string
 *    jpm_USD: string
 *    jpm_Shares: string
 *    ubs_Rate: string
 *    ubs_USD: string
 *    ubs_Shares: string
 *    cicc_Rate: string
 *    cicc_USD: string
 *    cicc_Shares: string
 * }} ParsedRow
 */

const headerMap = {
    'ticker': 'CodeAndType',
}

const requiredHeaders = [
    'CodeAndType', 'gics', 'total_Shares', 'total_USD', 'weighted_Rate',
];

const ignoreBigNumber = ['-'];

const exchanges = ['SSE', 'SZSE', "BJEX"];

const createParser = (/** @type {string[]} */codes) => makeParser(builder => {
    return {
        CodeAndType: val => {
            if (!val) {
                return [false, 'ticker是必填字段'];
            }
            const match = /(\d\d\d\d\d\d)\s+(C1|C2|CH)/.exec(val);
            if (!match) {
                return [false, 'ticker格式错误，必须为 "000000 CH/C1/C2"'];
            }
            if (match[2] !== 'CH' && match[2] !== 'C1' && match[2] !== 'C2') {
                return [false, 'ticker格式错误，必须为 "000000 CH/C1/C2"'];
            }
            const code = match[1];
            if (!codes.find(c => c.substring(0, 6) === code)) {
                return [false, `股票代码无效或不存在：${match[1]}`];
            }
            return [true, `${match[1]} ${match[2]}`];
        },
        gics: builder.optionalString(),
        name: builder.optionalString(),
        total_Shares: builder.optionalBigNumber(ignoreBigNumber),
        total_USD: builder.optionalBigNumber(ignoreBigNumber),
        weighted_Rate: builder.optionalBigNumber(ignoreBigNumber),
        baml_Rate: builder.optionalBigNumber(ignoreBigNumber),
        baml_USD: builder.optionalBigNumber(ignoreBigNumber),
        baml_Shares: builder.optionalBigNumber(ignoreBigNumber),
        citi_Rate: builder.optionalBigNumber(ignoreBigNumber),
        citi_USD: builder.optionalBigNumber(ignoreBigNumber),
        citi_Shares: builder.optionalBigNumber(ignoreBigNumber),
        clsa_Rate: builder.optionalBigNumber(ignoreBigNumber),
        clsa_USD: builder.optionalBigNumber(ignoreBigNumber),
        clsa_Shares: builder.optionalBigNumber(ignoreBigNumber),
        cs_Rate: builder.optionalBigNumber(ignoreBigNumber),
        cs_USD: builder.optionalBigNumber(ignoreBigNumber),
        cs_Shares: builder.optionalBigNumber(ignoreBigNumber),
        hsbc_Rate: builder.optionalBigNumber(ignoreBigNumber),
        hsbc_USD: builder.optionalBigNumber(ignoreBigNumber),
        hsbc_Shares: builder.optionalBigNumber(ignoreBigNumber),
        jpm_Rate: builder.optionalBigNumber(ignoreBigNumber),
        jpm_USD: builder.optionalBigNumber(ignoreBigNumber),
        jpm_Shares: builder.optionalBigNumber(ignoreBigNumber),
        ubs_Rate: builder.optionalBigNumber(ignoreBigNumber),
        ubs_USD: builder.optionalBigNumber(ignoreBigNumber),
        ubs_Shares: builder.optionalBigNumber(ignoreBigNumber),
        cicc_Rate: builder.optionalBigNumber(ignoreBigNumber),
        cicc_USD: builder.optionalBigNumber(ignoreBigNumber),
        cicc_Shares: builder.optionalBigNumber(ignoreBigNumber),
    };
}, /** @type { ParsedRow } */(null));

export default function ShortableCNTableImport() {
    const [codes] = useApiState(getStockCodesWithExchanges, exchanges);
    const [warnings, setWarnings] = useState(/** @type {import('../../lib/importer').ErrorMessage[]} */([]));
    const [errors, setErrors] = useState(/** @type {import('../../lib/importer').ErrorMessage[]} */([]));
    const [parseErrors, setParseErrors] = useState(/** @type {string[]} */([]));
    const [data, setData] = useState(/** @type {ParsedRow[]} */(null));
    const [originalRows, setOriginalRows] = useState(0);
    const [importSuccessCount, setImportSuccessCount] = useState(0);
    const [importErrorCount, setImportErrorCount] = useState(0);
    const [importErrors, setImportErrors] = useState(/** @type {string[]} */([]));
    const [fileSelectKey, setFileSelectKey] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [importResult, setImportResult] = useState(0);
    const { url } = useRouteMatch();
    const history = useHistory();

    const parser = useMemo(() => createParser(codes), [codes]);

    function reset() {
        setWarnings([]);
        setErrors([]);
        setParseErrors([]);
        setData(null);
        setOriginalRows(0);
        setImportSuccessCount(0);
        setImportErrorCount(0);
        setImportErrors([]);
        setFileSelectKey(k => k + 1);
        setSubmitting(false);
        setImportResult(0);
    }

    const onSelect = useCoCallback(function*(_isCancelled, event) {
        try {
            setWarnings([]);
            setErrors([]);
            setParseErrors([]);
            setData(null);
            const sheet = /** @type {string[][]} */(yield readSheetFromFile(event.target.files[0]));
            const len = Math.max(sheet[0].length, sheet[1].length);
            for (let i = 0; i < len; i++) {
                const a = (sheet[0][i]?.trim()||'');
                const b = (sheet[1][i]?.trim()||'');
                sheet[1][i] = (a + (a&&b ? '_' : '') + b).toLowerCase();
            }
            const parseResult = parser(sheet, 2, 1, headerMap, requiredHeaders);
            setData(parseResult.data);
            console.log(parseResult.data);
            setWarnings(parseResult.warnings);
            setErrors(parseResult.errors);
            setParseErrors(parseResult.parseErrors);
            setOriginalRows(sheet.length - 2);
        } catch (e) {
            console.warn(e);
            setErrors([{ message: e.message }]);
        }
    }, [parser]);

    const onSubmit = useCoCallback(function*(isCancelled, /** @type {ParsedRow[]} */ data){
        try {
            setSubmitting(true);
            let anyError = false;
            const version = /** @type { number } */(yield createShortableCNTable());
            for (const item of data) {
                const [codePrefix, typeName] = item.CodeAndType.split(' ');
                const type = { 'CH': 1, 'C2': 2, 'C1': 3 }[typeName];
                try {
                    yield uploadShortableCNTableRow(
                        version, codePrefix,
                        {
                            type,
                            gics: item.gics,
                            total_Shares: bn(item.total_Shares),
                            total_USD: bn(item.total_USD),
                            weighted_Rate: bn(item.weighted_Rate),
                            baml_Rate: bn(item.baml_Rate),
                            baml_USD: bn(item.baml_USD),
                            baml_Shares: bn(item.baml_Shares),
                            citi_Rate: bn(item.citi_Rate),
                            citi_USD: bn(item.citi_USD),
                            citi_Shares: bn(item.citi_Shares),
                            clsa_Rate: bn(item.clsa_Rate),
                            clsa_USD: bn(item.clsa_USD),
                            clsa_Shares: bn(item.clsa_Shares),
                            cs_Rate: bn(item.cs_Rate),
                            cs_USD: bn(item.cs_USD),
                            cs_Shares: bn(item.cs_Shares),
                            hsbc_Rate: bn(item.hsbc_Rate),
                            hsbc_USD: bn(item.hsbc_USD),
                            hsbc_Shares: bn(item.hsbc_Shares),
                            jpm_Rate: bn(item.jpm_Rate),
                            jpm_USD: bn(item.jpm_USD),
                            jpm_Shares: bn(item.jpm_Shares),
                            ubs_Rate: bn(item.ubs_Rate),
                            ubs_USD: bn(item.ubs_USD),
                            ubs_Shares: bn(item.ubs_Shares),
                            cicc_Rate: bn(item.cicc_Rate),
                            cicc_USD: bn(item.cicc_USD),
                            cicc_Shares: bn(item.cicc_Shares),
                        }
                    );
                    setImportSuccessCount(c => c + 1);
                } catch (e) {
                    console.warn(e);
                    setImportErrorCount(c => c + 1);
                    setImportErrors(errs => [...errs, e.message]);
                    anyError = true;
                }
            }
            if (!anyError) {
                yield commitShortableCNTable(version);
                setImportResult(1);
                history.replace(`${urlParent(url)}/${version}`);
            } else {
                setImportResult(2);
            }
            setSubmitting(false);
        } catch (e) {
            console.warn(e);
            setImportErrors(es => [...es, e.message]);
            setImportResult(2);
            setSubmitting(false);
        }
    }, []);
    if (!codes) {
        return (
            <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
                <CircularProgress color="primary" />
            </div>
        );
    }
    return (
        <Paper style={{flex:1, marginBottom: 20, padding: 20}}>
            <div style={{ marginBottom: 16}}>
                <input key={fileSelectKey} disabled={data?.length > 0} type="file" style={{fontSize: 16}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv" onChange={onSelect} />
            </div>
            <div>
                { parseErrors?.length ? ( parseErrors.map((e, i) => (
                    <Alert severity="error" key={i}>
                        { e }
                    </Alert>
                ))) : null}
                { data ? (<div>
                    { data.length === 0 && originalRows === 0 ? (
                        <Alert severity="error">
                            导入的表格中没有数据
                        </Alert>
                    ) : null}
                    { data.length === 0 && originalRows !== 0 ? (
                        <Alert severity="error">
                            原始数据量：{ originalRows }<br/>
                            没有可导入的数据，错误原因如下：
                            {errors?.length ? errors.map((e,i) => (
                                <span key={i}>
                                    <br/>{ e.ref }：{ e.message }
                                </span>
                            )) : null}
                        </Alert>
                    ) : null}
                    { data.length !== 0 && data.length < originalRows ? (
                        <Alert severity="warning">
                            原始数据量：{ originalRows }<br/>
                            可导入数据量：{ data.length }<br/>
                            以下单元格的数据解析出错：
                            {errors?.length ? errors.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e.ref }：{ e.message }
                                    </span>
                                </span>
                            )) : null}
                        </Alert>
                    ) : null}
                    { data.length !== 0 && data.length === originalRows ? (
                        <Alert severity="success">
                            原始数据量：{ originalRows }<br/>
                            可导入数据量：{ data.length }
                        </Alert>
                    ) : null}
                    { warnings.length ? (
                        <Alert severity="warning">
                            以下单元格可能有问题，但不影响导入：
                            {warnings.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e.ref }：{ e.message }
                                    </span>
                                </span>
                            ))}
                        </Alert>
                    ) : null }
                </div>) : null}
            </div>
            { data?.length > 0 ? (<div style={{marginTop: 20}}>
                <div>
                    <LinearProgress
                        style={{marginTop: 16, marginBottom: 16}}
                        color="primary"
                        variant="determinate"
                        value={(importSuccessCount + importErrorCount) * 100 / data.length}
                    />
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{flex:1}}>
                        导入进度：{importSuccessCount + importErrorCount}/{data.length}
                    </div>
                    <Button disabled={submitting} variant="contained" onClick={reset}>
                        重置
                    </Button>
                    <Button
                        disabled={submitting || importResult > 0 }
                        style={{marginLeft: 16}} 
                        variant="contained"
                        color="primary" 
                        onClick={()=>onSubmit(data)}
                    >
                        开始导入
                    </Button>
                </div>
                <div>
                    {importResult === 1 ? (
                        <Alert severity="success">导入完成</Alert>
                    ):null}
                    {importResult === 2 ? (
                        <Alert severity="warning">导入失败，下方是详细错误信息：</Alert>
                    ):null}
                </div>
                <div>
                    { importErrors.length > 0 ? (
                        <Alert severity="error">
                            导入数据过程中发生以下错误：
                            {importErrors.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e }
                                    </span>
                                </span>
                            ))}
                        </Alert>
                    ) : null}
                </div>
            </div>) : null}
        </Paper>
    )
}