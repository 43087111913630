import React, { createContext, useState, useMemo, useContext, useCallback, useEffect } from 'react';

const Context = createContext(null);

/** @type {{[key:string]:any}} */
const initialGlobal = {};

const driver = {
    /**
     * @param {string} key 
     */
    async getItem(key) {
        return window.localStorage.getItem(key)
    },
    /**
     * @param {string} key 
     * @param {string} value 
     */
    async setItem(key, value) {
        window.localStorage.setItem(key, value);
    }
}

/**
 * @param {{ children: React.ReactElement, renderAfterLoaded?: boolean }} param0 
 */
export function GlobalContainer({ children, renderAfterLoaded }) {
    
    const [global, setGlobal] = useState(initialGlobal);
    useEffect(() => { //load initial globals from async storage
        driver.getItem("global").then(r => {
            const data = JSON.parse(r);
            setGlobal({
                ...data,
                __loaded: true
            });
        }).catch(e => {
            setGlobal({
                __loaded: true
            });
            console.warn(e);
        });
    }, []);
    useEffect(() => { //save globals to async storage
        const { __loaded, ...rest } = global;
        if (__loaded) {//only save globals after loaded
            driver.setItem("global", JSON.stringify(rest)).catch(e => {
                console.warn(e);
            });
        }
    }, [global]);
    const value = useMemo(() => [global, setGlobal], [global, setGlobal]);
    if (renderAfterLoaded && !global?.__loaded) {
        return null;
    }
    return <Context.Provider value={value}>
        {children}
    </Context.Provider>
}

/**
 * @returns {[
 *   Readonly<{[key:string]: any}>, 
 *   (name: string, val: React.SetStateAction<any>) => void,
 *   (val:{[key:string]: any}) => void
 * ]}
 */
export function useAllGlobals() {
    const [global, setGlobal] = useContext(Context);
    const setter = useCallback((name, newVal) => {
        if (typeof newVal === 'function') {
            setGlobal(prevState => {
                const actualNewVal = newVal(prevState[name]);
                if (actualNewVal === prevState[name]) {
                    return prevState;
                }
                return {
                    ...prevState,
                    [name]: actualNewVal
                }
            });
        } else {
            setGlobal(prevState => {
                if (newVal === prevState[name]) {
                    return prevState;
                }
                return {
                    ...prevState,
                    [name]: newVal
                }
            });
        }
    }, [setGlobal]);
    const globalSetter = useCallback((val) => {
        setGlobal({
            ...val,
            __loaded: true,
        });
    }, [setGlobal]);
    return [global, setter, globalSetter];
}

/**
 * @template T
 * @param {string} name 全局变量名
 * @param {T} defaultValue 变量默认取值
 * @returns {[Readonly<T>, React.Dispatch<React.SetStateAction<T>>]}
 */
export function useGlobal(name, defaultValue = undefined) {
    const [global, setGlobal] = useContext(Context);
    const { [name]: val = defaultValue } = global;
    const setter = useCallback(newVal => {
        if (typeof newVal === 'function') {
            setGlobal(prevState => {
                const actualNewVal = newVal(prevState[name]);
                if (actualNewVal === prevState[name]) {
                    return prevState;
                }
                return {
                    ...prevState,
                    [name]: actualNewVal
                }
            });
        } else {
            setGlobal(prevState => {
                if (newVal === prevState[name]) {
                    return prevState;
                }
                return {
                    ...prevState,
                    [name]: newVal
                }
            });
        }
    }, [name, setGlobal]);
    return [val, setter];
}