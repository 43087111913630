import { useCallback, useEffect, useState } from "react";
import { useApiState } from "../api/common";

/**
 * @template T
 * @template C
 * @param {C[]} collection 
 * @param {(elem:C)=>T} mapper 
 */
export default function useCollector(collection, mapper) {
    const [result, setResult] = useState(/** @type {T[]} */([]));
    useEffect(() => {
        setResult(result => {
            /** @type {T[]} */
            let newResult;
            const mapped = (collection?.map(mapper) || []).filter(v => v!== null && v !== undefined);
            for (const r of mapped) {
                if ((newResult || result).indexOf(r) < 0) {
                    if (!newResult) {
                        newResult = [...result];
                    }
                    newResult.push(r);
                }
            }
            if (newResult) {
                newResult = newResult.filter(r => mapped.indexOf(r) >= 0);
            }
            return newResult || result;
        });
    }, [collection]);
    return result;
}

/**
 * @template T
 * @template C
 * @template R
 * @param {C[]} collection 
 * @param {(elem:C)=>T|T[]} mapper
 * @param {(ids: T[]) => Promise<R[]>} fetcher
 * @returns {[data: R[], loading: boolean, error: Error, reload: () => void]}
 */
export function useApiCollectionFetcher(collection, mapper, fetcher) {
    const [[allIds, newIds], setIds] = useState(/** @type {[T[], T[]]} */([[], []]));
    useEffect(() => {
        setIds(ids => {
            const [allIds, newIds] = ids;
            /** @type {T[]} */
            let thisNewIds;
            const collectionIds = /** @type {T[]} */(collection?.map(mapper).flat() || []).filter(v => v!== null && v !== undefined);
            for (const id of collectionIds) {
                if ((thisNewIds || newIds).indexOf(id) < 0 && allIds.indexOf(id) < 0) {
                    if (!thisNewIds) {
                        thisNewIds = [...newIds];
                    }
                    thisNewIds.push(id);
                }
            }
            if (!thisNewIds) {
                return ids;
            } else {
                return [allIds, thisNewIds];
            }
        });
    }, [collection]);
    const [items, setItems] = useState(/** @type {R[]} */([]));
    const [newItems, loading, error] = useApiState(
        async (ids) => {
            if (!ids || !ids.length) {
                return [];
            }
            return fetcher(ids);
        },
        newIds
    );
    useEffect(() => {
        if (!newItems) return;
        setItems(items => [...items, ...newItems]);
        setIds(([allIds, newIds]) => [[...allIds, ...newIds], newIds.length > 0 ? [] : newIds]);
    }, [newItems]);
    const reset = useCallback(() => {
        setIds(([allIds, newIds]) => [allIds, allIds]);
        setItems([]);
    }, []);
    return [items, loading, error, reset];
}
