import React from 'react';
import useAuth from '../api/auth';

/**
 * 
 * @param {{
 *  required?: import('../api/common').PermissionItem | import('../api/common').PermissionItem[],
 *  requiredAny?: import('../api/common').PermissionItem[],
 *  children: React.ReactElement
 * }} param0
 */
function RequirePermission({ required, requiredAny, children }, ref) {

    const [auth] = useAuth();

    if (!required) {
        required = [];
    } else if (!Array.isArray(required)) {
        required = [required];
    }
    if (required.some(p => auth.permissions.indexOf(p) < 0)) {
        return null;
    }
    if (requiredAny) {
        if (requiredAny.every(p => auth.permissions.indexOf(p) < 0)) {
            return null;
        }
    }
    return React.cloneElement(children, { ref });
}

export default React.forwardRef(RequirePermission);