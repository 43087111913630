import useAuth from '../api/auth';

/**
 * @param {{ coverer: number[] }} stock 
 */
export function useStockIsEditable(stock) {
    const [auth] = useAuth();
    if (auth.permissions.indexOf('editNonCoveredStock') >= 0) {
        return true;
    }
    if (auth.permissions.indexOf('editCoveredStock') >= 0 && stock?.coverer?.indexOf(auth.userId) >= 0) {
        return true;
    }
    return false;
}

/**
 * @param {{
 *   stock: { coverer: number[] },
 *   children: React.ReactElement
 * }} param0 
 */
export default function StockIsEditable({ stock, children }) {
    if (useStockIsEditable(stock)) {
        return children;
    }
    return null;
}