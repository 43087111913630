import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import StockReportUploadHKTable from './StockReportUploadHKTable';
import StockReportUploadHKTableImport from './StockReportUploadHKTableImport';

export default function StockReportUploadHKTableRouter() {
    return <Switch>
        <Route path="/user-table/stock-report-upload-hk" exact component={StockReportUploadHKTable} />
        <Route path="/user-table/stock-report-upload-hk/add" exact component={StockReportUploadHKTableImport} />
        <Route path="/user-table/stock-report-upload-hk/:version" exact component={StockReportUploadHKTable} />
        <Redirect to="/user-table/stock-report-upload-hk" />
    </Switch>
}