import React, { useMemo } from 'react';
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import OverflowTooltip from '../components/OverflowTooltip';

const useStockStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        fontSize: 12
    },
    plain: {
        fontSize: 12,
        borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
        height: 27,
        lineHeight: '27px',
    },
    plainNoUnderline: {
        fontSize: 12,
        borderBottom: '1px solid transparent',
        height: 27,
        lineHeight: '27px',
    },
    normal: {
        fontSize: 12,
        borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
        height: 27,
        lineHeight: '27px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    tooltip: {
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 'normal',
        padding: '12px 8px'
    },
}));

/**
 * 
 * @param {{
 *   edit: boolean,
 *   value: number,
 *   defaultValue?: number,
 *   onChange: (newValue: number) => void,
 *   options: { [key: number]: string } | string[],
 *   optionOrders?: number[],
 *   underline?: boolean,
 *   disableDefault?: boolean,
 *   hideDefault?: boolean,
 * } & Omit<import('@material-ui/core').SelectProps, 'value' | 'onChange' | 'options'> } param0 
 */
export default function SelectField({ edit, value, defaultValue, onChange, options, optionOrders, underline = true, disableDefault = false, hideDefault = false, ...selectProps }) {
    const classes = useStockStyles();
    const children = useMemo(() => {
        /** @type {JSX.Element[]} */
        let items;
        if (Array.isArray(options)) {
            items = options.map((name, id) => {
                if (id === 0) return null;
                return <MenuItem key={id} value={id}>{name}</MenuItem>
            }).filter(Boolean);
        } else {
            items = Object.keys(options).map(key => {
                const id = Number(key);
                if (id === 0) return null;
                return <MenuItem key={id} value={id}>{options[key]}</MenuItem>
            }).filter(Boolean);
        }
        if (optionOrders) {
            items = optionOrders.map(id => {
                return items.find(item => item.props.value === id);
            }).filter(Boolean);
        }
        return items;
    }, [options, optionOrders]);
    if (!edit) {
        return <div className={underline ? classes.plain : classes.plainNoUnderline}>
            <OverflowTooltip classes={classes}>
                {options[value ?? defaultValue]||'-'}
            </OverflowTooltip>
        </div>
    }
    let defaultOption;
    if (hideDefault) {
        defaultOption = null;
    } else if (defaultValue) {
        defaultOption = (
            <MenuItem key={0} value={0}>{options[defaultValue]}(默认)</MenuItem>
        );
    } else if (!disableDefault) {
        defaultOption = (
            <MenuItem key={0} value={0}>{options[0] || '-'}</MenuItem>
        );
    } else if (!value) {
        defaultOption = (
            <MenuItem key={0} value={0} disabled>{options[0] || '-'}</MenuItem>
        );
    }
    return <Select
        {...selectProps}
        className={`${selectProps.className||''} ${classes.select}`}
        value={value}
        onChange={e => onChange?.(e.target.value)}
        displayEmpty
        renderValue={value => options[value]}
    >
        {defaultOption}
        {children}
    </Select>
}