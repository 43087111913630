import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import useAuth from '../api/auth';

/**
 * @typedef {{
 *   label: string,
 *   path: string,
 *   exact?: boolean,
 *   permission: keyof import('../api/common').PermissionMap,
 *   component: React.ComponentType<any>
 * }} TabDef
 */

/**
 * @param {{
 *   tabs: TabDef[]
 * }} param0
 */
export default function Tabs({ tabs }) {
    const [auth] = useAuth();
    const visibleTabs = useMemo(() => {
        return tabs.filter(tab => auth?.permissions?.indexOf(tab.permission) >= 0);
    }, [tabs, auth]);
    const [value, setValue] = useState(0);
    if (visibleTabs.length == 0) {
        return null;
    }
    let defaultPath = visibleTabs[0].path;
    return <>
        <MuiTabs value={value} indicatorColor='primary'>
            { visibleTabs.map((tab, index) => <Tab key={index} value={index} tab={tab} onSelected={setValue} />) }
        </MuiTabs>
        <Switch>
            { visibleTabs.map((tab, index) => <Route key={index} path={tab.path} exact={tab.exact} component={tab.component} />)}
            <Redirect to={defaultPath} />
        </Switch>
    </>
}

/**
 * @param {{
 *   tab: TabDef,
 *   value: number,
 *   onSelected: (value: number) => void
 * }} param0 
 */
function Tab({ tab, value, onSelected }) {
    const selected = Boolean(useRouteMatch({
        path: tab.path,
        exact: tab.exact,
    }));
    const history = useHistory();
    useEffect(() => {
        if (selected) {
            onSelected(value);
        }
    }, [selected]);
    return <MuiTab value={value} selected={selected} onClick={() => history.push(tab.path)} label={tab.label}/>
}
