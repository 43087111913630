import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import bigDecimal from 'js-big-decimal';
import { bn, bns } from '../lib/bn';

const useStyle = makeStyles((theme) => ({
    text: {
        fontSize: 12,
        width: '100%',
    },
    input: {
        fontSize: 12,
    },
    plain: {
        fontSize: 12,
        borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
        height: 27,
        lineHeight: '27px',
    }
}));

/**
 * 
 * @param {{ 
 *   value: bigDecimal,
 *   edit?: boolean,
 *   onSubmit: (v: bigDecimal) => void,
 *   suffixIcon?: React.ComponentType<any>,
 *   onSuffixIconClick?: (text:string) => void,
 *   placeholder?: string,
 * } & import('@material-ui/core').TextFieldProps } param0 
 */
export default function BigNumberInput({ edit = false, placeholder, value, onSubmit, suffixIcon, onSuffixIconClick, ...rest }) {
    const classes = useStyle();
    const [text, setText] = useState("");
    useEffect(() => {
        setText(bns(value));
    }, [value]);
    function validateAndSetText(e) {
        const match = /^[0-9,]*(\.\d*)?/.exec(e.target.value);
        if (!match) {
            setText("");
        } else {
            setText(match[0]);
        }
    }

    function onBlur() {
        if (text !== bns(value)) {
            onSubmit(bn(text));
        }
    }

    if (!edit) {
        return <div className={classes.plain}>{bns(value) || placeholder || '-'}</div>
    }

    const SuffixIcon = suffixIcon;

    const endAdornment = suffixIcon ? (
        <SuffixIcon fontSize="small" style={{cursor:'pointer'}} onClick={() => onSuffixIconClick(text)} />
    ) : null;

    return <TextField className={classes.text} InputProps={{ classes: {root: classes.input}, endAdornment }} {...rest} value={text} placeholder={placeholder} onChange={validateAndSetText} onBlur={onBlur} />;
}