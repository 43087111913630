import { CircularProgress } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { FullHeightVirtualScrollTable } from './Table';
import { FieldNames, StatusLabels } from './lib/enums';
import { useApiState } from './api/common';
import useErrorMessage from './lib/useErrorMessage';
import { getEnums } from './api/enum';
import { Link } from 'react-router-dom';
import { date2str } from './lib/date';
import { getCoverTable } from './api/call';
import { bns, bnsPercent, div } from './lib/bn';
import querystring from 'querystring';

/** 
 * @typedef {{
 *   code: string,
 *   name: string,
 *   price_Close: string,
 *   price_Change: string,
 *   marketValue: string,
 *   rsi: string,
 *   pt_Score: string,
 *   w52_Score: string,
 *   section: string,
 *   industrial_Chain: string,
 *   status: string,
 *   call_Watch_Date: string,
 *   pe_t0: string,
 *   pe_t1: string,
 *   pb_t0: string,
 *   div_Pct: string,
 * }} CoverTableRow 
 */

/**
 * @param {import('./api/stock').StockInfo} r 
 * @param {import('./api/enum').Enums} enums
 * @returns { CoverTableRow }
 */
 function renderRow(r, enums) {
    return {
        code: r.code,
        name: r.name,
        price_Close: bns(r.price_Close),
        price_Change: bns(r.price_Chg),
        marketValue: bns(r.marketValue),
        rsi: bns(r.last_RSI),
        pt_Score: bns(r.pt_Score, 0),
        w52_Score: '',
        section: enums.sectionList.find(item => item.section_ID === r.section_ID)?.section_Name,
        industrial_Chain: enums.industrialChainList.find(item => item.industrial_Chain_ID === r.industrial_Chain_ID)?.industrial_Chain_Name,
        status: StatusLabels[r.status_Or_Default],
        call_Watch_Date: date2str(r.call_Watch_Date),
        pe_t0: bns(div(r.price_Close, r.eps_Consensus_T0)),
        pe_t1: bns(div(r.price_Close, r.eps_Consensus_T1)),
        pb_t0: bns(div(r.price_Close, r.bps_Consensus_T0)),
        div_Pct: bnsPercent(
            div(
                r.avg_Cash_Dividend_PS,
                r.price_Close,
            )
        )
    };
}

/** @type { (keyof import('./api/stock').StockInfo)[]} */
const keys = ['code', 'name', 'price_Close', 'price_Chg', 'marketValue', 'last_RSI', 'pt_Score', 'section_ID', 'industrial_Chain_ID', 'status_Or_Default', 'call_Watch_Date', 'eps_Consensus_T0', 'eps_Consensus_T1', 'bps_Consensus_T0', 'avg_Cash_Dividend_PS'];

/** @type {import('./Table').ColumnOption<CoverTableRow>[]} */
const columns = [
    {
        field: 'code',
        label: FieldNames.code,
        width: 150,
        model: 'none',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code}</Link>
    },
    {
        field: 'name',
        label: FieldNames.name,
        width: 150,
        model: 'none',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.name}</Link>
    },
    {
        field: 'price_Close',
        label: 'Price',
        width: 100,
        model: 'none',
    },
    {
        field: 'price_Change',
        label: '涨跌幅',
        width: 100,
        model: 'none'
    },
    {
        field: 'marketValue',
        label: '市值',
        width: 100,
        model: 'none',
    },
    {
        field: 'rsi',
        label: 'RSI',
        width: 100,
        model: 'none'
    },
    {
        field: 'pt_Score',
        label: 'Pt',
        width: 60,
        model: 'none'
    },
    // {
    //     field: 'w52_Score',
    //     label: '52W分',
    //     width: 60,
    //     model: 'none'
    // },
    {
        field: 'section',
        label: '板块',
        width: 100,
        model: 'none',
    },
    {
        field: 'industrial_Chain',
        label: '产业链',
        width: 100,
        model: 'none',
    },
    {
        field: 'status',
        label: 'Call表状态',
        width: 100,
        model: 'none'
    },
    {
        field: 'call_Watch_Date',
        label: 'Call Date',
        width: 100,
        model: 'none'
    },
    {
        field: 'pe_t0',
        label: 'PE-T0',
        width: 100,
        model: 'none'
    },
    {
        field: 'pe_t1',
        label: 'PE-T1',
        width: 100,
        model: 'none'
    },
    {
        field: 'pb_t0',
        label: 'PB-T0',
        width: 100,
        model: 'none'
    },
    {
        field: 'div_Pct',
        label: '股息率',
        width: 100,
        model: 'none'
    }
];

/** 
 * @type {import('./Table').SorterAndFilters<CoverTableRow>} */
const initSorterAndFilters = null;

/** @type {import('./Table').Fields<CoverTableRow>[]} */
const visibleColumns = columns.map(col => col.field);

/** @type {import('./Table').FullHeightVirtualScrollTableComponent<CoverTableRow>} */
// @ts-ignore
const Table = FullHeightVirtualScrollTable;

/**
 * 
 * @param {{ location: { search: string }}} param0 
 * @returns 
 */
export default function CoverTable({ location }) {
    let search = location?.search || '';
    if (search.startsWith('?')) {
        search = search.substr(1);
    }
    const more = querystring.parse(search)?.more;
    const [enums, enumsLoading, enumsError] = useApiState(getEnums);
    const [stocks=[], stocksLoading, stocksError] = useApiState(getCoverTable, keys, more);
    const data = useMemo(() => {
        if (!stocks || !enums) return [];
        return stocks.map(s => renderRow(s, enums));
    }, [stocks, enums]);
    const [sorterAndFilters, setSorterAndFilters] = useState(initSorterAndFilters);
    useErrorMessage(enumsError || stocksError);
    if (enumsLoading || stocksLoading) {
        return <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
            <CircularProgress color="primary" />
        </div>;
    }
    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, position: 'relative' }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, padding: 20, display: 'flex' }}>
                <Table
                    data={data}
                    columns={columns}
                    sorterAndFilters={sorterAndFilters}
                    onSorterAndFiltersChange={setSorterAndFilters}
                    variant="full"
                    visibleColumnOrder={visibleColumns}
                />
            </div>
        </div>
    </div>
}
