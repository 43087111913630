import React, { useMemo } from 'react';
import { makeStyles, MenuItem, Select } from "@material-ui/core";

const useStockStyles = makeStyles(theme => ({
    select: {
        width: '100%',
        fontSize: 12
    },
    plain: {
        fontSize: 12,
        borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
        height: 27,
        lineHeight: '27px',
    },
    plainNoUnderline: {
        fontSize: 12,
        borderBottom: '1px solid transparent',
        height: 27,
        lineHeight: '27px',
    }
}));

/**
 * 
 * @param {{
 *   edit: boolean,
 *   value: string,
 *   defaultValue?: string,
 *   onChange: (newValue: string) => void,
 *   options: {id:string, name:string }[] ,
 *   optionOrders?: number[],
 *   underline?: boolean,
 *   disableDefault?: boolean,
 *   hideDefault?: boolean,
 * } & Omit<import('@material-ui/core').SelectProps, 'value' | 'onChange' | 'options'> } param0 
 */
export default function SelectOptionField({ edit, value, defaultValue, onChange, options, optionOrders, underline = true, disableDefault = false, hideDefault = false, ...selectProps }) {
    const classes = useStockStyles();
    const children = useMemo(() => {
        /** @type {JSX.Element[]} */
        let items = options.map(({ name, id }) => {
            return <MenuItem key={id} value={id}>{name}</MenuItem>
        }).filter(Boolean);

        if (optionOrders) {
            items = optionOrders.map(id => {
                return items.find(item => item.props.value === id);
            }).filter(Boolean);
        }
        return items;
    }, [options, optionOrders]);
    if (!edit) {
        return <div className={underline ? classes.plain : classes.plainNoUnderline}>{(options.find(opt => opt.id === (value || defaultValue))?.name || value || defaultValue) ?? "-"}</div>
    }
    let defaultOption;
    if (hideDefault) {
        defaultOption = null;
    } else if (defaultValue) {
        defaultOption = { id: "-", name: `${options.find(opt => opt.id === defaultValue)?.name}(默认)`, value: "", disabled: false };
    } else if (!disableDefault) {
        defaultOption = { id: "-", name: '-', value: "", disabled: false };
    } else {
        defaultOption = { id: "-", name: '-', value: "", disabled: true };
    }
    return <Select
        {...selectProps}
        className={`${selectProps.className||''} ${classes.select}`}
        value={value}
        renderValue={value => value ? options.find(opt => opt.id === value)?.name : defaultOption?.name || '-' }
        displayEmpty
        onChange={e => {
            if (!onChange) return;
            const value = e.target.value;
            const opt = options.find(opt => opt.id === value) || defaultOption;
            onChange(opt.value || opt.id);
        }}
    >
        { defaultOption ? 
            <MenuItem value={defaultOption.id} disabled={defaultOption.disabled}>{defaultOption.name}</MenuItem>
        : null }
        {children}
    </Select>
}