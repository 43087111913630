import React from 'react';
import { Radio, withStyles } from "@material-ui/core";




const SmallRadio = withStyles({
    root: {
        padding: 0,
    },
    checked: {},
})((props) => <Radio color="primary" {...props} />);

export default function SmallRadioBox({
    checked,
    onChange,
    ...restProps
}) {
    return <SmallRadio
        {...restProps}
        checked={checked}
        onClick={e => {
            const value = !checked;
            onChange({ target: { checked: value, value } });
        }}
    />
}