import EventEmitter from 'events';
import { useEffect, useState } from 'react';
import { getToken, setToken, idle, busy } from './common';
import { getCurrentUser } from './user';
import querystring from 'querystring';

/**
 * @typedef {{
 *   login: (username: string, password: string) => Promise<void>,
 *   validate: () => Promise<void>,
 *   logout: () => void
 * }} TokenActions
 */

/** @type {import('./common').PermissionItem[]} */
const PermissionLiterals = [
    'viewCoveredStock',
    'editCoveredStock',
    'viewNonCoveredStock',
    'editNonCoveredStock',
    'viewUsers',
    'editUsers',
    'viewStockList',
    'editStockList',
    'viewAggrTable',
    'viewCallTable',
    'viewShortTable',
    'viewEventTable',
    'viewWorkTable',
    'importStock',
    'reviewTicket',
];

(() => {
    const nologin = { authenticated: false, validated: false, permissions: [] };
    /** @type { import('./common').Token } */
    let token;
    let s = localStorage.getItem('auth');
    if (s) {
        try {
            token = JSON.parse(s) || nologin;
        } catch (e) {
            token = nologin;
        }
    } else {
        token = nologin;
    }
    if (token) {
        if (token.expiresAt < Date.now()) {
            token = nologin;
        }
    }
    token = {
        ...token,
        validated: false,
    };
    setToken(token);
})();

const globalChannel = new EventEmitter();
globalChannel.setMaxListeners(32);

const actions = {
    async login(username, password) {
        try {
            busy();
            const form = {
                grant_type: 'password',
                username,
                password
            };
            const resp = await fetch('/oauth/token', {
                method: 'POST',
                body: querystring.stringify(form),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic ' + btoa('client' + ":" + '123456')
                }
            });
            if (resp.status === 400 || resp.status === 401) {
                let m = '网络错误';
                try {
                    const d = await resp.json();
                    if (d?.error_description) {
                        m = d.error_description;
                    }
                } catch (e) {
                    console.warn(e);
                }
                throw new Error(m);
            } else if (resp.status === 200) {
                const t = await resp.json();
                const newTempToken = {
                    authenticated: true,
                    validated: true,
                    accessToken: t.access_token,
                    username: username,
                    expiresAt: Date.now() + (t.expires_in - 60) * 1000,
                    permissions: [],
                }
                setToken(newTempToken);
                const user = await getCurrentUser();
                /** @type { import('./common').Token } */
                const newToken = {
                    authenticated: true,
                    validated: true,
                    accessToken: t.access_token,
                    userId: user.user_ID,
                    username: user.user_Name,
                    displayName: user.display_Name,
                    expiresAt: Date.now() + (t.expires_in - 60) * 1000,
                    permissions: PermissionLiterals.filter(p => user[p]),
                };
                setToken(newToken);
                localStorage.setItem('auth', JSON.stringify(newToken));
                globalChannel.emit('authChange', newToken);
            } else {
                throw new Error('网络错误');
            }
        } finally {
            idle();
        }
    },
    async validate() {
        const oldToken = getToken();
        const user = await getCurrentUser();
        const newToken = {
            ...oldToken,
            validated: true,
            displayName: user.display_Name,
            permissions: PermissionLiterals.filter(p => user[p]),
        };
        setToken(newToken);
        localStorage.setItem('auth', JSON.stringify(newToken));
        globalChannel.emit('authChange', newToken);
    },
    logout() {
        const newToken = { authenticated: false };
        setToken(newToken);
        localStorage.setItem('auth', JSON.stringify(newToken));
        globalChannel.emit('authChange', newToken);
    }
}

/**
 * @returns {[ import('./common').Token, TokenActions ]}
 */
export default function useAuth() {
    const [t, setToken] = useState(getToken());
    useEffect(() => {
        globalChannel.addListener("authChange", setToken);
        return () => globalChannel.removeListener("authChange", setToken);
    }, []);
    return [t, actions];
}