import urllib from 'url';

/**
 * @param {string} from 
 * @param {string} to 
 */
export function urlResolve(from, to) {
    return urllib.resolve(from, to);
}

/**
 * @param {string} url 
 */
export function urlParent(url) {
    if (url.endsWith('/')) {
        url = url.substring(0, url.length - 1);
    }
    let r = urlResolve(url, '.');
    if (r.endsWith('/')) {
        r = r.substring(0, r.length - 1);
    }
    return r;
}

