
/**
 * @typedef {typeof import("xlsx")} XLSX
 */

export async function loadXlsx() {
    const { default: XLSX } = await import('xlsx');
    return XLSX;
}

/**
 * @param {Blob} file 
 * @returns {Promise<ArrayBuffer>}
 */
export function readFile(file) {
    return new Promise((fulfill, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
            fulfill(e.target.result);
        };
        reader.onerror = e => {
            reject(e.target.error);
        };
        reader.readAsArrayBuffer(file);
    });
}


/**
 * @param {Blob} file 
 * @returns {Promise<string[][]>}
 */
export async function readSheetFromFile(file) {
    const xlsx = await loadXlsx();
    const buffer = await readFile(file);
    const wb = xlsx.read(buffer, { type: 'array' });
    const sheet = wb.Sheets[wb.SheetNames[0]];
    const data = /** @type {unknown[][]} */(xlsx.utils.sheet_to_json(sheet, { 
        header: 1, 
        raw: true, 
        rawNumbers: true, 
        blankrows: false,
    }));
    return data.map(row => row.map(item => (item === null || item === undefined) ? "" : `${item}`))
}


/**
 * @param {string[][]} aoa 
 * @param {number} headerLine
 * @returns {{[key:string]:string}[]}
 */
export function aoa2json(aoa, headerLine) {
    const header = aoa[headerLine];
    /** @type{{[key:string]:string}[]} */  
    const r = [];
    for (let i = headerLine + 1; i < aoa.length; i++) {
        /** @type{{[key:string]:string}} */
        const o = {};
        for (let j = 0; j < header.length; j++) {
            o[header[j]] = aoa[i][j];
        }
        r.push(o);
    }
    return r;
}
