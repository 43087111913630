import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import StockDailyExport from './StockDailyExport';
import StockDailyImport from './StockDailyImport';

export default function StockDailyRouter() {
    const { path, url } = useRouteMatch();
    return <Switch>
        <Route path={path} exact render={() => <StockDailyExport urlOfImport={`${path}/import`}/>}/>
        <Route path={`${path}/import`} exact component={StockDailyImport} />
        <Redirect to={url} />
    </Switch>
}