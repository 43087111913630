import { get, post } from "./common";

/**
 * @typedef {{
 *   id: number,
 *   code: string,
 *   version: number,
 *   currency: string
 * }} ShortableHK
 */

/**
 * @returns { Promise<number> }
 */
export async function createShortableHKTable() {
    return await post('/api/user-table/shortable-hk');
}

/**
 * @returns { Promise<{valid: number, uploaded: number }> }
 */
export async function getShortableHKTableVersions() {
    return await get('/api/user-table/shortable-hk/version')
}

/**
 * @param { number } version
 * @returns { Promise<ShortableHK[]> }
 */
export async function getShortableHKTable(version) {
    const list = /** @type{any[]} */(await get('/api/user-table/shortable-hk', { version }));
    return list.map(s => ({
        id: s.id,
        code: s.code,
        version: s.version,
        currency: s.currency
    }));
}

/**
 * @param { number } version
 * @param { string } code
 * @param { string } currency
 * @returns { Promise<number> }
 */
export async function uploadShortableHKTableRow(version, code, currency) {
    const data = {
        code, currency
    };
    return await post(`/api/user-table/shortable-hk/${version}/row`, data);
}

/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function commitShortableHKTable(version) {
    return await post(`/api/user-table/shortable-hk/${version}/commit`);
}

/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function validShortableHKTable(version) {
    return await post(`/api/user-table/shortable-hk/${version}/valid`);
}


/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function invalidShortableHKTable(version) {
    return await post(`/api/user-table/shortable-hk/${version}/invalid`);
}
