import BigDecimal from 'js-big-decimal';
import { get } from './common';

/**
 * @typedef {{
 *     id: number,
 *     code: string,
 *     date_First: number,
 *     date_Last: number,
 *     stock_Report_Quarterly_ID: number[],
 *     report_Quarterly: number,
 *     rsi_50_Up: number,
 *     rsi_50_Down: number,
 *     rsi_75: number,
 *     rsi_25: number,
 *     weeks_52_Hi: number,
 *     weeks_52_Lo: number,
 *     macd_Up: number,
 *     macd_Down: number,
 *     name: string,
 *     coverer: number[],
 *     industrial_Chain_ID: number,
 *     sw1_ID: number,
 *     sw2_ID: number,
 *     sw3_ID: number,
 *     status_Or_Default: number,
 *     price_Close: BigDecimal,
 *     last_RSI: BigDecimal,
 *     pt_Score: BigDecimal,
 *     quality_Score_User: BigDecimal,
 *     trend_Score: BigDecimal,
 *     pricing_Score_User: BigDecimal,
 *     external_Power: BigDecimal,
 *     stock_Power: BigDecimal,
 *     short_Wishlist_Or_Default: number,
 *     shortable_Or_Default: number,
 *     marketValue_USD: BigDecimal,
 *     adv_1M: BigDecimal,
 *     latest_Comment_ID: number,
 * }} SignalEvent
 */

/**
 * @param {string} s 
 * @returns { BigDecimal }
 */
function bn(s) {
    if (!s) {
        return null;
    }
    return new BigDecimal(s);
}

/** @returns {SignalEvent} */
function makeEvent(resp) {
    return {
        id: Number(resp[0]),
        code: `${resp[1]}`,
        date_First: Number(resp[2]),
        date_Last: Number(resp[3]),
        stock_Report_Quarterly_ID: resp[4] ? resp[4].split(',').map(s => Number(s)) : [],
        
        report_Quarterly: Number(resp[5]),
        rsi_50_Up: Number(resp[6]),
        rsi_50_Down: Number(resp[7]),
        rsi_75: Number(resp[8]),
        rsi_25: Number(resp[9]),
        weeks_52_Hi: Number(resp[10]),
        weeks_52_Lo: Number(resp[11]),
        macd_Up: Number(resp[12]),
        macd_Down: Number(resp[13]),
        
        name: `${resp[14]}`,
        coverer: resp[15],
        industrial_Chain_ID: Number(resp[16]),
        sw1_ID: Number(resp[17]),
        sw2_ID: Number(resp[18]),
        sw3_ID: Number(resp[19]),
        status_Or_Default: Number(resp[20]),
        price_Close: bn(resp[21]),
        last_RSI: bn(resp[22]),
        pt_Score: bn(resp[23]),
        quality_Score_User: bn(resp[24]),
        trend_Score: bn(resp[25]),
        pricing_Score_User: bn(resp[26]),
        external_Power: bn(resp[27]),
        stock_Power: bn(resp[28]),
        short_Wishlist_Or_Default: Number(resp[29]),
        shortable_Or_Default: Number(resp[30]),
        marketValue_USD: bn(resp[31]),
        adv_1M: bn(resp[32]),
        latest_Comment_ID: Number(resp[33]),
    };
}

export async function getEvents(
    code,
    startDateIncluded,
    endDateNotIncluded,
    Report_Quarterly,
    RSI_50_Up,
    RSI_50_Down,
    RSI_75,
    RSI_25,
    Weeks_52_Hi,
    Weeks_52_Lo,
    MACD_Up,
    MACD_Down,
    cny_gte,
    hkd_gte,
    usd_gte,
) {
    /** @type {any[]} */
    const r = await get(`/api/event`, {
        code,
        startDate: startDateIncluded,
        endDate: endDateNotIncluded,
        Report_Quarterly,
        RSI_50_Up,
        RSI_50_Down,
        RSI_75,
        RSI_25,
        Weeks_52_Hi,
        Weeks_52_Lo,
        MACD_Up,
        MACD_Down,
        cny_gte,
        hkd_gte,
        usd_gte,
    });
    return r.map(makeEvent);
}
