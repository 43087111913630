import { Button, CircularProgress, Dialog, DialogActions, FormControl, InputLabel, makeStyles, MenuItem, Paper, Select } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { FullHeightVirtualScrollTable } from './Table';
import { useApiState } from './api/common';
import useErrorMessage from './lib/useErrorMessage';
import { EventNames as _EventNames, FieldNames, ShortableLabels, ShortWishlistLabels, StatusLabels } from './lib/enums';
import { bns, bnsMax } from './lib/bn';
import { date2str, fromDays2000, toDate } from './lib/date';
import { getEvents } from './api/event';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { getEnums } from './api/enum';
import { Link } from 'react-router-dom';
import { getUsersByIds } from './api/user';
import useQuery from './lib/useQuery';
import OverflowTooltip from './components/OverflowTooltip';
import { useCoCallback } from './lib/useCo';
import { createExcelSheets } from './Table/excel';
import { getCommentsByIds } from './api/comment';
import { CommentDialog } from './components/CommentDialog';

/** @typedef {import('./api/event').SignalEvent} SignalEvent */

/** @typedef {'rsi_50_Up' | 'rsi_50_Down' | 'rsi_75' | 'rsi_25' | 'weeks_52_Hi' | 'weeks_52_Lo' | 'macd_Up' | 'macd_Down' | 'report_Quarterly'} EventKey */

const EventNames = /** @type {{[key in EventKey]:string}} */(_EventNames);

/** 
 * @typedef {{
 *   id: string,
 *   key: EventKey,
 *   type: string,
 *   code: string,
 *   date_First: string,
 *   name: string,
 *   status: string,
 *   coverer: string[],
 *   industrial_Chain_Name: string,
 *   sw1_Name: string,
 *   sw2_Name: string,
 *   sw3_Name: string,
 *   price_Close: string,
 *   last_RSI: string,
 *   pt_Score: string,
 *   repeat_count_90: string,
 *   quality_Score_User: string,
 *   trend_Score: string,
 *   pricing_Score_User: string,
 *   external_Power: string,
 *   short_Wishlist: string,
 *   shortable: string,
 *   marketValue_USD: string,
 *   adv_1M: string,
 *   latest_Comment_ID: number,
 * }} EventRow 
 */

const todayMoment = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
const defaultDate = moment(todayMoment).subtract(1, 'day');

const useStyles = makeStyles({
    left: {
        // @ts-ignore
        textAlign: 'left !important',
    },
    clickable: {
        cursor: 'pointer'
    },
    paper: {
        width: '80%',
        maxWidth: '80%',
    },
});

/**
 * @param {number} id
 */
 export async function getCommentByIdOpt(id) {
    if (!id) return undefined;
    const s = await getCommentsByIds([id]);
    return s[0];
}

export default function EventTable() {
    const classes = useStyles();
    const [commentDialog, setCommentDialog] = useState({
        commentId: 0,
        comment: /** @type {import("./api/comment").Comment} */(null),
        show: false
    });
    const [commentLoaded,, commentError] = useApiState(getCommentByIdOpt, commentDialog.commentId);
    useEffect(() => {
        if (commentLoaded?.id == commentDialog.commentId && !commentDialog.comment) {
            setCommentDialog(cd => ({ ...cd, comment: commentLoaded }));
        }
    }, [commentLoaded, commentDialog]);
    useErrorMessage(commentError);
    const columns = useMemo(() => {
        /** @type {import('./Table').ColumnOption<EventRow>[]} */
        const columns = [
            {
                field: 'date_First',
                label: '日期',
                model: 'enum',
            },
            {
                field: 'type',
                label: '类型',
                model: 'enum',
                width: 80,
            },
            {
                field: 'code',
                label: 'Code',
                model: 'string',
                width: 100,
                headerClassName: classes.left,
                cellClassName: classes.left,
                render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code}</Link>
            },
            {
                field: 'name',
                label: FieldNames.name,
                model: 'string',
                headerClassName: classes.left,
                cellClassName: classes.left,
                width: 150,
                render: row => (
                    <OverflowTooltip 
                        style={{width: 100,overflow:'hidden',textOverflow:'ellipsis'}}
                        title={row.name}
                        placement='right'
                    >
                        <Link to={`/stock/${row.code}`} target="_blank">{row.name}</Link>
                    </OverflowTooltip>
                )
            },
            {
                field: 'status',
                label: FieldNames.status,
                model: 'enum',
                width: 80,
                render: (row) => {
                    if (!row.latest_Comment_ID) {
                        return row.status;
                    }
                    return (
                        <a 
                            className={classes.clickable} 
                            onClick={() => setCommentDialog({ commentId: row.latest_Comment_ID, show: true, comment: null })}
                        >
                            {row.status}
                        </a>
                    )
                }
            },
            {
                field: 'coverer',
                label: FieldNames.coverer,
                model: 'enum',
                width: 100,
                render: row => row.coverer.join("/")
            },
            {
                field: 'industrial_Chain_Name',
                label: FieldNames.industrial_Chain,
                model: 'string',
            },
            {
                field: 'sw1_Name',
                label: FieldNames.sw1,
                model: 'string',
            },
            {
                field: 'sw2_Name',
                label: FieldNames.sw2,
                model: 'string',
            },
            {
                field: 'sw3_Name',
                label: FieldNames.sw3,
                model: 'string',
                sortable: true,
            },
            {
                field: 'price_Close',
                label: '现价',
                model: 'number',
            },
            {
                field: 'last_RSI',
                label: 'RSI',
                model: 'number',
            },
            {
                field: 'pt_Score',
                label: 'Pt',
                model: 'number',
            },
            {
                field: 'repeat_count_90',
                label: '90日频',
                model: 'number',
            },
            {
                field: 'quality_Score_User',
                label: FieldNames.quality_Score,
                model: 'number',
            }, 
            {
                field: 'trend_Score',
                label: '动能',
                model: 'number',
            },
            {
                field: 'pricing_Score_User',
                label: '估值',
                model: 'number',
            },
            {
                field: 'external_Power',
                label: '外',
                model: 'number',
            },
            {
                field: 'short_Wishlist',
                label: FieldNames.short_Wishlist,
                model: 'enum',
            },
            {
                field: 'shortable',
                label: FieldNames.shortable,
                model: 'enum',
                sortable: true,
            },
            {
                field: 'marketValue_USD',
                label: "市值US$",
                model: 'number',
                sortable: true
            },
            {
                field: 'adv_1M',
                label: "平均ADV",
                model: 'number',
                sortable: true
            },
        ];
        return columns;
    }, [classes]);
    const [allMarketValue, setAllMarketValue] = useQuery('am', false, Boolean, v => v ? '1' : '');
    const cny_gte = allMarketValue ? null : 2000;
    const hkd_gte = allMarketValue ? null : 2000;
    const usd_gte = allMarketValue ? null : 300;
    const [rsi_50_Up, setRsi_50_Up] = useQuery('u50', true, Boolean, v => v ? '1' : '');
    const [rsi_50_Down, setRsi_50_Down] = useQuery('d50', false, Boolean, v => v ? '1' : '');
    const [rsi_75, setRsi_75] = useQuery('u75', false, Boolean, v => v ? '1' : '');
    const [rsi_25, setRsi_25] = useQuery('d25', false, Boolean, v => v ? '1' : '');
    const [weeks_52_Hi, setWeeks_52_Hi] = useQuery('w52h', false, Boolean, v => v ? '1' : '');
    const [weeks_52_Lo, setWeeks_52_Lo] = useQuery('w52l', false, Boolean, v => v ? '1' : '');
    const [macd_Up, setMacd_Up] = useQuery('mu', false, Boolean, v => v ? '1' : '');
    const [macd_Down, setMacd_Down] = useQuery('md', false, Boolean, v => v ? '1' : '');
    const [report_Quarterly, setReport_Quarterly] = useQuery('r', false, Boolean, v => v ? '1' : '');
    const types = {
        rsi_50_Up,
        rsi_50_Down,
        rsi_75,
        rsi_25,
        weeks_52_Hi,
        weeks_52_Lo,
        macd_Up,
        macd_Down,
        report_Quarterly,
    };
    const setTypes = {
        rsi_50_Up: setRsi_50_Up,
        rsi_50_Down: setRsi_50_Down,
        rsi_75: setRsi_75,
        rsi_25: setRsi_25,
        weeks_52_Hi: setWeeks_52_Hi,
        weeks_52_Lo: setWeeks_52_Lo,
        macd_Up: setMacd_Up,
        macd_Down: setMacd_Down,
        report_Quarterly: setReport_Quarterly,
    };

    const allTypesSelected = rsi_50_Up && rsi_50_Down && rsi_75 && rsi_25 && weeks_52_Hi && weeks_52_Lo && macd_Up && macd_Down && report_Quarterly;

    const [date, setDate] = useQuery('d', defaultDate, s => Number.isSafeInteger(Number(s)) ? fromDays2000(Number(s)) : null, m => `${toDate(m)}`);
    const [origData,,error] = useApiState(getEvents, 
        null, 
        toDate(date),
        toDate(date) + 1,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        cny_gte,
        hkd_gte,
        usd_gte
    );
    const [enums,] = useApiState(getEnums);
    const userIds = useMemo(() => {
        if (!origData) return [];
        const set = /** @type {Set<number>} */(new Set());
        for (const row of origData) {
            for (const userId of row.coverer) {
                set.add(userId);
            }
        }
        return Array.from(set);
    }, [origData]);
    const [users] = useApiState(getUsersByIds, userIds);
    const rows = useMemo(() => {
        if (!origData) return [];
        const r = /** @type {EventRow[]} */([]);
        origData.forEach(event => {
            /** @type {EventKey[]}*/(Object.keys(EventNames)).forEach(eventKey => {
                if (!event[eventKey]) return;
                r.push({
                    id: `${eventKey}-${event.id}`,
                    key: eventKey,
                    type: EventNames[eventKey],
                    code: event.code,
                    date_First: date2str(event.date_First),
                    coverer: event.coverer?.map(id => users?.find(u => u.user_ID === id)?.display_Name).filter(Boolean),
                    name: event.name,
                    status: StatusLabels[event.status_Or_Default],
                    industrial_Chain_Name: enums?.industrialChainList?.find(ic => ic.industrial_Chain_ID === event.industrial_Chain_ID)?.industrial_Chain_Name || '',
                    sw1_Name: enums?.sw1List?.find(e => e.sw1_ID === event.sw1_ID)?.sw1_Name || '',
                    sw2_Name: enums?.sw2List?.find(e => e.sw2_ID === event.sw2_ID)?.sw2_Name || '',
                    sw3_Name: enums?.sw3List?.find(e => e.sw3_ID === event.sw3_ID)?.sw3_Name || '',
                    price_Close: bns(event.price_Close),
                    last_RSI: bns(event.last_RSI),
                    pt_Score: bns(event.pt_Score, 0),
                    repeat_count_90: `${event[eventKey]}`,
                    quality_Score_User: bnsMax(event.quality_Score, 1),
                    trend_Score: bnsMax(event.trend_Score_Or_Default, 1),
                    pricing_Score_User: bnsMax(event.pricing_Score, 1),
                    external_Power: bnsMax(event.external_Power, 1),
                    shortable: ShortableLabels[event.shortable_Or_Default], 
                    short_Wishlist: ShortWishlistLabels[event.short_Wishlist_Or_Default],
                    marketValue_USD: bns(event.marketValue_USD),
                    adv_1M: bns(event.adv_1M),
                    latest_Comment_ID: Number(event.latest_Comment_ID),
                })
            });
        });
        return r;
    }, [origData, enums, users]);

    const filteredRows = useMemo(() => {
        if (!rows) return [];
        return rows.filter(row => types[row.key]);
    }, [rows, rsi_50_Up, rsi_50_Down, rsi_75, rsi_25, weeks_52_Hi, weeks_52_Lo, macd_Up, macd_Down, report_Quarterly]);
    
    useErrorMessage(error); 

    const { enqueueSnackbar } = useSnackbar();
    const [exportInProgress, setExportInProgress] = useState(false);
    
    const exportExcel = useCoCallback(function*(){
        if (!rows) {
            enqueueSnackbar('请先加载数据');
            return;
        }
        try {
            setExportInProgress(true);
            const sheets = /** @type {{[key in keyof EventNames]?: (import('./Table/excel').ExcelSheetData<EventRow> & { sheetName: string })}} */({});
            for (const row of rows) {
                let sheet = sheets[row.key];
                if (!sheet) {
                    sheet = {
                        sheetName: EventNames[row.key],
                        data: [],
                        columns,
                        visibleColumnOrder: columns.map(c => c.field),
                        sorterAndFilters: null,
                        types: {
                            type: 'string',
                            code: 'string',
                            date_First: 'date',
                            name: 'string',
                            status: 'string',
                            coverer: 'string',
                            industrial_Chain_Name: 'string',
                            sw1_Name: 'string',
                            sw2_Name: 'string',
                            sw3_Name: 'string',
                            price_Close: 'number',
                            last_RSI: 'number',
                            pt_Score: 'number',
                            repeat_count_90: 'number',
                            quality_Score_User: 'number',
                            trend_Score: 'number',
                            pricing_Score_User: 'number',
                            external_Power: 'number',
                            short_Wishlist: 'string',
                            shortable: 'string',
                            marketValue_USD: 'number',
                            adv_1M: 'number',
                        }
                    };
                    sheets[row.key] = sheet;
                }
                /** @type {EventRow[]} */(sheet.data).push(row);
            }
            const sheetsArray = Object.keys(EventNames).map(key => sheets[key]).filter(Boolean);
            yield createExcelSheets(sheetsArray, "信号表" + date?.format('YYYY-MM-DD'));
        } catch (e) {
            console.warn(e);
            enqueueSnackbar('生成Excel文件出错');
        } finally {
            setExportInProgress(false);
        }
    }, [rows, columns]);

    const Table = /** @type {import('./Table').FullHeightVirtualScrollTableComponent<EventRow>} */(FullHeightVirtualScrollTable);
    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Paper style={{padding:20,  marginBottom: 10, display: 'flex', flexDirection: 'row'}}>
            <DatePicker
                style={{marginRight: 16, width: 200}}
                autoOk
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="none"
                label="日期"
                value={date}
                maxDate={todayMoment}
                onChange={setDate}
            />
            <FormControl>
                <InputLabel htmlFor="coverer">市值</InputLabel>
                <Select
                    style={{marginRight: 16, width:200}}
                    value={allMarketValue ? 1 : 0}
                    onChange={e => {
                        setAllMarketValue(Boolean(e.target.value));
                    }}
                >
                    <MenuItem value={0}>
                        大市值
                    </MenuItem>
                    <MenuItem value={1}>
                        全部市值
                    </MenuItem>
                </Select>
            </FormControl>
            { Object.keys(EventNames).map(key => (
                <Button 
                    key={key}
                    style={{marginTop: 12, marginRight: 8}}
                    color='primary' 
                    variant={ types[key] ? 'contained' : 'outlined' }
                    onClick={() => setTypes[key](!types[key])}    
                >
                    {EventNames[key]}
                </Button>
            ))}
            <Button
                style={{marginTop: 12, marginRight: 8}}
                color='primary'
                variant={allTypesSelected ? 'contained' : 'outlined'}
                onClick={() => {
                    Object.keys(EventNames).forEach(key => setTypes[key](!allTypesSelected))
                }}
            >
                全选
            </Button>
            <div style={{flex:1}}></div>
            <Button
                disabled={exportInProgress}
                style={{marginTop: 12}}
                color='primary'
                variant={'contained'}
                onClick={() => exportExcel()}
            >
                导出
            </Button>
        </Paper>
        <div style={{ flex: 1, position: 'relative', marginBottom: 20 }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 20, display: 'flex' }}>
                <Table
                    dataKey='id'
                    data={filteredRows}
                    columns={columns}
                    variant="compat"
                />
            </div>
        </div>
        {commentDialog.commentId != 0 && !commentDialog.comment ? (
            <Dialog 
                open={commentDialog.show} 
                onClose={() => setCommentDialog(cd => ({...cd, show: false}))}
                classes={{ paper: classes.paper }}>
                <div style={{margin:"20px auto"}}>
                    <CircularProgress color="primary" />
                </div>
                <DialogActions style={{position: 'sticky', left: 0, right: 0, bottom: 0, background: '#424242', zIndex: 1 }}>
                    <div style={{flex:1}}></div>
                    <Button onClick={() => setCommentDialog(cd => ({...cd, show: false}))}>
                        关闭
                    </Button>
                </DialogActions>
            </Dialog>
        ) : null}
        {commentDialog.commentId != 0 && commentDialog.comment ? (
            <CommentDialog
                key={commentDialog.commentId}
                show={commentDialog.show}
                onClose={() => setCommentDialog(cd => ({...cd, show: false}))}
                comment={commentDialog.comment}
                changeStatusDisabled
                editDisabled
                closeWithBackdropClickAndEscapeKeyEnabled
            />
        ) : null}
    </div>
}

function setExportInProgress(arg0) {
    throw new Error('Function not implemented.');
}
