import { get, post, put } from "./common";

/**
 * @typedef {{
 *   id: number,
 *   code: string,
 *   version: number,
 *   report_Date: number,
 *   title: string,
 *   theme: string,
 *   release_Time: number,
 *   parsed_Type: 1 | 2 | 3 | 4,
 *   parsed_Quarter: number,
 *   parsed_Quarter_Or_Semi: 1 | 2,
 *   conflict: 0 | 1 | 2,
 * }} StockReportUploadHK
 */

/**
 * @returns { Promise<number> }
 */
export async function createStockReportUploadHKTable() {
    return await post('/api/user-table/stock-report-upload-hk');
}

/**
 * @returns { Promise<{valid: number, uploaded: number }> }
 */
export async function getStockReportUploadHKTableVersions() {
    return await get('/api/user-table/stock-report-upload-hk/version')
}

/**
 * @param { number } version
 * @returns { Promise<StockReportUploadHK[]> }
 */
export async function getStockReportUploadHKTable(version) {
    const list = /** @type{any[]} */(await get('/api/user-table/stock-report-upload-hk', { version }));
    return list.map(s => ({
        id: s.id,
        code: s.code,
        version: s.version,
        report_Date: s.report_Date,
        title: s.title,
        theme: s.theme,
        release_Time: s.release_Time,
        parsed_Type: s.parsed_Type,
        parsed_Quarter: s.parsed_Quarter,
        parsed_Quarter_Or_Semi: s.parsed_Quarter_Or_Semi,
        conflict: s.conflict,
    }));
}

/**
 * @param { number } version
 * @param { string } code
 * @param { Omit<StockReportUploadHK, 'id'|'code'|'version'> } row
 * @returns { Promise<number> }
 */
export async function uploadStockReportUploadHKTableRow(version, code, row) {
    const {
        report_Date,
        title,
        theme,
        release_Time,
        parsed_Type,
        parsed_Quarter,
        parsed_Quarter_Or_Semi,
        conflict,
    } = row;
    const data = {
        code,
        report_Date,
        title,
        theme,
        release_Time,
        parsed_Type,
        parsed_Quarter,
        parsed_Quarter_Or_Semi,
        conflict,
    };
    return await post(`/api/user-table/stock-report-upload-hk/${version}/row`, data);
}

/**
 * @param { number } id
 * @param { Partial<Pick<StockReportUploadHK, 'parsed_Quarter'|'parsed_Type'|'parsed_Quarter_Or_Semi'|'conflict'>> } row
 * @returns { Promise<void> }
 */
 export async function updateStockReportUploadHKTableRow(id, row) {
    const {
        parsed_Type,
        parsed_Quarter,
        parsed_Quarter_Or_Semi,
        conflict
    } = row;
    const data = {
        parsed_Type,
        parsed_Quarter,
        parsed_Quarter_Or_Semi,
        conflict
    };
    return await put(`/api/user-table/stock-report-upload-hk/${id}`, data);
}

/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function commitStockReportUploadHKTable(version) {
    return await post(`/api/user-table/stock-report-upload-hk/${version}/commit`);
}

/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function validStockReportUploadHKTable(version) {
    return await post(`/api/user-table/stock-report-upload-hk/${version}/valid`);
}


/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function invalidStockReportUploadHKTable(version) {
    return await post(`/api/user-table/stock-report-upload-hk/${version}/invalid`);
}
