import React, { useEffect, useMemo, useState } from 'react';
import { FullHeightVirtualScrollTable } from './Table';
import { useGlobal } from './lib/useGlobal';
import { BoolLabels, FieldNames, StatusLabels, TrendStabilityLabels, TrendStageLabels, TrendStrengthLabels, TrendSustainabilityLabels } from './lib/enums';
import { bns } from './lib/bn';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, Fab, makeStyles } from '@material-ui/core';
import { fromDays2000, quarterFromDays2000, quarterNumOfToday, quarterNumToDayNum } from './lib/date';
import createExcel from './Table/excel';
import { useCoCallback } from './lib/useCo';
import { useSnackbar } from 'notistack';
import { GetAppRounded } from '@material-ui/icons';
import moment from 'moment';
import { usePreload } from './lib/preload';
import { useApiState } from './api/common';
import { getCommentsByIds } from './api/comment';
import { CommentDialog } from './components/CommentDialog';
import useErrorMessage from './lib/useErrorMessage';

/**
 * @typedef { {
 *  'code': string,
 *  'name': string,
 *  'call_Watch_Date': string,
 *  'coverer_name': string[],
 *  'status_Or_Default': string,
 *  'price_Close': string,
 *  '_cost': string,
 *  'ctd_Pct': string,
 *  '_ctd_Pct_Exchange': string,
 *  'total_Score': string,
 *  'quality_Score': string,
 *  'trend_Score': string,
 *  'pricing_Score_User': string,
 *  'ft_Score': string,
 *  'pt_Score': string,
 *  'external_power': string,
 *  'stock_power': string,
 *  'trend_Stage': string,
 *  'trend_Strength': string,
 *  'trend_Sustainability': string,
 *  'trend_Stability': string,
 *  'alert_Before_Next_FS_Or_Default': string,
 *  'last_RSI': string,
 *  'risk_Line_Or_Default_Value': string,
 *  'reward_Line_Or_Default_Value': string,
 *  'reward_Risk_Ratio': string,
 *  'latest_Comment_ID': number,
 * } } CallTableRow
 */

/** 
 * @type {import('./Table').SorterAndFilters<CallTableRow>} */
const initSorterAndFilters = {
    sorters: [
        { field: 'call_Watch_Date', order: -1 }
    ],
    filters: {}
};

/** @type {import('./Table').Fields<CallTableRow>[]} */
const initVisibleColumns = null;

const useStyles = makeStyles({
    left: {
        // @ts-ignore
        textAlign: 'left !important',
    },
    nobr: {
        whiteSpace: 'nowrap',
    },
    clickable: {
        cursor: 'pointer'
    },
    paper: {
        width: '80%',
        maxWidth: '80%',
    },
});

export function FullCallTable(props) {
    return <CallTable {...props} full />
}

/** @type {import('./Table').FullHeightVirtualScrollTableComponent<CallTableRow>} */
// @ts-ignore
const Table = FullHeightVirtualScrollTable;

/**
 * @param {number} id
 */
export async function getCommentByIdOpt(id) {
    if (!id) return undefined;
    const s = await getCommentsByIds([id]);
    return s[0];
}

export default function CallTable() {
    const full = true;
    const classes = useStyles();
    const [commentDialog, setCommentDialog] = useState({
        commentId: 0,
        comment: /** @type {import("./api/comment").Comment} */(null),
        show: false
    });
    const [commentLoaded,, commentError] = useApiState(getCommentByIdOpt, commentDialog.commentId);
    useEffect(() => {
        if (commentLoaded?.id == commentDialog.commentId && !commentDialog.comment) {
            setCommentDialog(cd => ({ ...cd, comment: commentLoaded }));
        }
    }, [commentLoaded, commentDialog]);
    useErrorMessage(commentError);
    const columns = useMemo(() => {
        /** @type {import('./Table').ColumnOption<CallTableRow>[]} */
        const columns = [
            {
                field: 'code',
                label: 'Code',
                model: full ? 'string' : 'none',
                headerClassName: classes.left,
                cellClassName: classes.left,
                render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code}</Link>,
                width: 80
            }, 
            {
                field: 'name',
                label: 'Name',
                model: full ? 'string' : 'none',
                headerClassName: classes.left,
                cellClassName: classes.left,
                render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.name}</Link>,
                width: 80
            },
            {
                field: 'call_Watch_Date',
                label: '首Call日',
                model: full ? 'string' : 'none',
                width: 80,
                render: row => <a className={classes.clickable} onClick={() => setCommentDialog({ commentId: row.latest_Comment_ID, show: true, comment: null })}>{row.call_Watch_Date}</a>,
            },
            {
                field: 'coverer_name',
                label: '研究员',
                model: full ? 'enum' : 'none',
                render: row => row.coverer_name.join("/")
            },
            {
                field: 'status_Or_Default',
                label: '状态',
                model: full ? 'enum' : 'none',
                render: row => <a className={classes.clickable} onClick={() => setCommentDialog({ commentId: row.latest_Comment_ID, show: true, comment: null })}>{row.status_Or_Default}</a>,
            },
            {
                field: 'price_Close',
                label: '现价',
                model: full ? 'number' : 'none',
            },
            {
                field: '_cost',
                label: 'Cost',
                model: full ? 'number' : 'none',
            },
            {
                field: 'ctd_Pct',
                label: 'CTD绝对',
                model: full ? 'number' : 'none',
            }, 
            {
                field: '_ctd_Pct_Exchange',
                label: 'CTD相对',
                model: full ? 'number' : 'none',
            }, 
            {
                field: 'total_Score',
                label: '总分',
                model: full ? 'number' : 'none',
            }, 
            {
                field: 'quality_Score',
                label: FieldNames.quality_Score,
                model: full ? 'number' : 'none',
            }, 
            {
                field: 'trend_Score',
                label: '动能',
                model: full ? 'number' : 'none',
            },
            {
                field: 'pricing_Score_User',
                label: '估值',
                model: full ? 'number' : 'none',
            }, 
            {
                field: 'ft_Score',
                label: 'Ft',
                model: full ? 'number' : 'none',
            }, 
            {
                field: 'pt_Score',
                label: 'Pt',
                model: full ? 'number' : 'none',
            }, 
            {
                field: 'external_power',
                label: '外',
                model: full ? 'number' : 'none',
            },
            {
                field: 'stock_power',
                label: '票',
                model: full ? 'number' : 'none',
            },
            {
                field: 'trend_Stage',
                label: '行情阶段',
                model: full ? 'enum' : 'none',
            }, 
            {
                field: 'trend_Strength',
                label: '行情强弱',
                model: full ? 'enum' : 'none',
            }, 
            {
                field: 'trend_Sustainability',
                label: '可持续性',
                model: full ? 'enum' : 'none',
            },
            {
                field: 'trend_Stability',
                label: '稳定性',
                model: full ? 'enum' : 'none',
            },
            {
                field: 'alert_Before_Next_FS_Or_Default',
                label: '看业绩',
                model: full ? 'enum' : 'none',
            }, 
            {
                field: 'last_RSI',
                label: 'RSI',
                model: full ? 'number' : 'none',
            },
            {
                field: 'risk_Line_Or_Default_Value',
                label: '风险',
                model: full ? 'number' : 'none',
            }, 
            {
                field: 'reward_Line_Or_Default_Value',
                label: '回报',
                model: full ? 'number' : 'none',
            }, 
            {
                field: 'reward_Risk_Ratio',
                label: <span><span className={classes.nobr}>风险</span><span className={classes.nobr}>回报比</span></span>,
                model: full ? 'number' : 'none',
            }, 
        ];
        return columns;
    }, [classes]);
    const { 
        users, 
        callTable: origData,
        requestUsersReload,
        requestCallTableReload
    } = usePreload();
    useEffect(() => {
        requestUsersReload();
        requestCallTableReload();
    }, []);
    /** @type {CallTableRow[]} */
    const data = useMemo(() => {
        if (!origData) return [];
        if (!users) return [];
        return origData.map(d => ({
            code: d.code,
            name: d.name,
            call_Watch_Date: fromDays2000(d.call_Watch_Date).format('YYYY-MM-DD'),
            coverer_name: d.coverer?.map(c => users?.find(u => u.user_ID === c)?.display_Name),
            status_Or_Default: StatusLabels[d.status_Or_Default],
            price_Close: bns(d.price_Close),
            _cost: '',
            ctd_Pct: bns(d.ctd_Pct),
            _ctd_Pct_Exchange: '',
            total_Score: bns(d.total_Score, 1),
            quality_Score: bns(d.quality_Score, 1),
            trend_Score: bns(d.trend_Score, 1),
            pricing_Score_User: bns(d.pricing_Score_User, 1),
            ft_Score: bns(d.ft_Score, 0),
            pt_Score: bns(d.pt_Score, 0),
            external_power: bns(d.external_Power, 1),
            stock_power: bns(d.stock_Power, 1),
            trend_Stage: TrendStageLabels[d.trend_Stage],
            trend_Strength: TrendStrengthLabels[d.trend_Strength],
            trend_Sustainability: TrendSustainabilityLabels[d.trend_Sustainability],
            trend_Stability: TrendStabilityLabels[d.trend_Stability],
            alert_Before_Next_FS_Or_Default: BoolLabels[d.alert_Before_Next_FS_Or_Default],
            last_RSI: bns(d.last_RSI),
            risk_Line_Or_Default_Value: bns(d.risk_Line_Or_Default_Value, 2),
            reward_Line_Or_Default_Value: bns(d.reward_Line_Or_Default_Value, 2),
            reward_Risk_Ratio: bns(d.reward_Risk_Ratio),
            latest_Comment_ID: d.latest_Comment_ID,
        }));
    }, [origData, users]);
    // useErrorMessage(error); 
    // const { data } = getCallData();
    const [sorterAndFilters, setSorterAndFilters] = useState(initSorterAndFilters);
    const [visibleColOrder, setVisibleColOrder] = useGlobal('CallTable_VisibleColOrder', initVisibleColumns);
    const { enqueueSnackbar } = useSnackbar();
    const [exportInProgress, setExportInProgress] = useState(false);
    const exportExcel = useCoCallback(function*(){
        try {
            setExportInProgress(true);
            const q = quarterNumOfToday() - 1;
            console.log("q", q);
            const qs = quarterFromDays2000(quarterNumToDayNum(q));
            console.log("quarterNumToDayNum(q)", quarterNumToDayNum(q), "qs", qs);
            const filename = `Call表_${moment().format("DDMMMYY")}`;
            yield createExcel(data, columns, visibleColOrder, sorterAndFilters, {
                call_Watch_Date: 'date',
                price_Close: 'number',
                ctd_Pct: 'number',
                total_Score: 'number',
                quality_Score: 'number',
                trend_Score: 'number',
                pricing_Score_User: 'number',
                ft_Score: 'number',
                pt_Score: 'number',
                external_power: 'number',
                stock_power: 'number',
                last_RSI: 'number',
                risk_Line_Or_Default_Value: 'number',
                reward_Line_Or_Default_Value: 'number',
                reward_Risk_Ratio: 'number'
            }, filename, "Call表");
        } catch (e) {
            console.warn(e);
            enqueueSnackbar('生成Excel文件出错');
        } finally {
            setExportInProgress(false);
        }
    }, [data, columns, visibleColOrder, sorterAndFilters]);

    if (!data) return null;

    return <div style={{padding: 20, paddingBottom: 10, flex: 1, alignSelf: 'stretch', display: 'flex', flexDirection: 'column'}}>
        <Table
            dataKey='code'
            variant='full'
            data={data}
            columns={columns}
            sorterAndFilters={sorterAndFilters}
            onSorterAndFiltersChange={setSorterAndFilters}
            visibleColumnOrder={visibleColOrder}
            onVisibleColumnOrderChange={setVisibleColOrder}
        />
        <div style={{height:50}}>
            <Fab
                size="small" 
                color="primary" 
                style={{ float:'right', marginTop: 10 }}
                disabled={exportInProgress}
                onClick={exportExcel}
            >
                { exportInProgress ? <CircularProgress color="inherit" size={20} /> : <GetAppRounded /> }
            </Fab>
        </div>
        {commentDialog.commentId != 0 && !commentDialog.comment ? (
            <Dialog 
                open={commentDialog.show} 
                onClose={() => setCommentDialog(cd => ({...cd, show: false}))}
                classes={{ paper: classes.paper }}>
                <div style={{margin:"20px auto"}}>
                    <CircularProgress color="primary" />
                </div>
                <DialogActions style={{position: 'sticky', left: 0, right: 0, bottom: 0, background: '#424242', zIndex: 1 }}>
                    <div style={{flex:1}}></div>
                    <Button onClick={() => setCommentDialog(cd => ({...cd, show: false}))}>
                        关闭
                    </Button>
                </DialogActions>
            </Dialog>
        ) : null}
        {commentDialog.commentId != 0 && commentDialog.comment ? (
            <CommentDialog
                key={commentDialog.commentId}
                show={commentDialog.show}
                onClose={() => setCommentDialog(cd => ({...cd, show: false}))}
                comment={commentDialog.comment}
                changeStatusDisabled
                editDisabled
                closeWithBackdropClickAndEscapeKeyEnabled
            />
        ) : null}
        
    </div>
}