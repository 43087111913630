import React, { useMemo, useState } from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import AddReport from "./AddReport";
import ImportReport from "./ImportReport";

/**
 * @typedef {{
 *   open: boolean,
 *   code: string,
 *   variant: 'quarter' | 'semi',
 *   onShow: (code: string, variant: 'quarter' | 'semi') => void,
 *   onClose: () => void,
 * }} AddReportDialogState
 */

/** 
 * @type {{
 *   open: boolean,
 *   code: string,
 *   variant: 'quarter' | 'semi',
 * }} 
 */
const initialState = {
    open: false,
    code: null,
    variant: null,
};

/** @returns { AddReportDialogState } */
export function useAddReportDialogState() {
    const [state, setState] = useState(initialState);
    return useMemo(() => {
        return {
            ...state,
            onShow (code, variant) {
                setState({
                    open: true,
                    code,
                    variant,
                });
            },
            onClose() {
                setState(s => {
                    if (!s.open) return s;
                    return {
                        ...s,
                        open: false,
                    }
                });
            }
        };
    }, [state]);
}

/**
 * @param {{
 *   state: AddReportDialogState,
 *   onReloadReport: () => void
 * }} param0 
 */
export default function AddReportDialog({ state, onReloadReport }) {
    const { open, onClose } = state;
    const [tab, setTab] = useState(1);
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <span style={tab === 1 ? {} : { cursor: 'pointer', opacity: 0.5 }} onClick={() => setTab(1)}>添加报表</span>
                <span style={{marginLeft: 16, marginRight: 16}}>|</span>
                <span style={tab === 2 ? {} : { cursor: 'pointer', opacity: 0.5 }} onClick={() => setTab(2)}>导入报表</span>
            </DialogTitle>
            { tab === 1 ? <AddReport state={state} onReloadReport={onReloadReport}/> : null }
            { tab === 2 ? <ImportReport state={state} onReloadReport={onReloadReport}/> : null }
        </Dialog>
    )
}