import naturalCompare from 'natural-compare';
import bigDecimal from 'js-big-decimal';

/**
 * @template T
 * @param {T[]} arr
 * @param {(a: T, b: T) => number} compareFn
 * @returns 
 */
export function sorted(arr, compareFn = undefined) {
    arr.sort(compareFn);
    return arr;
}

/**
 * @param {string} field Field name
 * @param {boolean} desc Descending order
 * @returns {(a:unknown, b:unknown) => -1 | 0 | 1}
 */
function comparerSingle(field = '', desc = false) {
    return (a, b) => {
        a = field ? a[field] : a;
        b = field ? b[field] : b;
        if (a === null || a === undefined) {
            if (b === null || b === undefined) {
                return 0;
            } else {
                return 1; //NULL is always larger than other values
            }
        } else {
            if (b === null || b === undefined) {
                return -1;
            } else {
                if (a instanceof bigDecimal) {
                    return /** @type {0|1|-1} */(a.compareTo(/** @type {bigDecimal} */(b)) * (desc ? -1 : 1));
                }
                const stra = `${a}`.toLowerCase();
                const strb = `${b}`.toLowerCase();
                const r = naturalCompare(stra, strb);
                return /** @type {0|1|-1} */(r * (desc ? -1 : 1));
            }
        }
    }
}

/**
 * @param {{
 *     field: string,
 *     order: -1 | 0 | 1,
 * }[]} fields 
 * @returns {(a:unknown, b:unknown) => -1 | 0 | 1}
 */
export function comparer(fields) {
    const comparers = fields.filter(f => f.order !== 0).map(f => comparerSingle(f.field, f.order === -1));
    return (a, b) => {
        for (const c of comparers) {
            const r = c(a, b);
            if (r !== 0) {
                return r;
            }
        }
        return 0;
    };
}
