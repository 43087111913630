import React from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { GlobalContainer } from './lib/useGlobal';
import SingleStock from './SingleStock';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CallTable, { FullCallTable } from './CallTable';
import Home from './Home';
import NavBar from './NavBar';
import useAuth from './api/auth';
import Login from './Login';
import Bootstrap from './Bootstrap';
import EventTable from './EventTable';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AggrTable from './AggrTable';
import WorkTable from './WorkTable';
import ChangePassword from './ChangePassword';
import ShortTable from './ShortTable';
import { PreloadProvider } from './lib/preload';
import Manage from './Manage';
import CoverTable from './CoverTable';
import TicketTabs from './TicketTabs';

const theme = createTheme({
    palette: {
        type: 'dark',
        primary: blue,
        secondary: red,
    },
    overrides: {
        MuiTypography: {
            h1: {
                fontSize: 32,
            },
            h2: {
                fontSize: 28,
            },
            h3: {
                fontSize: 24,
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        }
    }
});

function Routes() {
    return <Switch>
        <Route path="/stock/:code" exact render={(props) => <SingleStock key={props.match.params.code} code={props.match.params.code}/>} />
        <Route path="/manage" component={Manage} />
        <Route path="/call" exact component={CallTable} />
        <Route path="/call/full" exact component={FullCallTable} />
        <Route path="/short" exact component={ShortTable} />
        <Route path="/cover" exact component={CoverTable} />
        <Route path="/event" exact component={EventTable} />
        <Route path="/aggr" exact component={AggrTable} />
        <Route path="/work" exact component={WorkTable} />
        <Route path="/user/change-password" component={ChangePassword} />
        <Route path="/" exact component={Home} />
        <Route path="/user-table" component={TicketTabs} />
        <Redirect to="/" />
    </Switch>
}

function AuthState() {
    const [auth, actions] = useAuth();
    if (auth.authenticated) {
        if (auth.validated) {
            return <Layout />
        } else {
            return <Bootstrap />
        }
    } else {
        return <Login />
    }
}

function Layout() {
    return <div style={{display: 'flex', flex: 1}}>
        <NavBar />
        <div style={{marginTop:64, display: 'flex', flex: 1}}>
            <Routes />
        </div>
    </div>
}

export default function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <BrowserRouter basename="/view">
                    <GlobalContainer renderAfterLoaded>
                        <PreloadProvider>
                            <SnackbarProvider anchorOrigin={{horizontal: 'center', vertical: 'top'}} variant="error">
                                <CssBaseline />
                                <AuthState />
                            </SnackbarProvider>
                        </PreloadProvider>
                    </GlobalContainer>
                </BrowserRouter>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
}
