import React, { useState } from 'react';
import { Button, Menu, MenuItem } from "@material-ui/core";

/**
 * @template { number | string } T
 * @param {{
 *   options: { id: T, name: string }[],
 *   value: T,
 *   onChange: (value: T) => void,
 *   placeholder?: React.ReactNode,
 *   text?: string,
 *   disabled?: boolean,
 *   className?: string,
 *   style?: React.CSSProperties
 * }} param0 
 */
export default function PopupMenuSelect({ options = [], value, onChange, placeholder, text, disabled = false, className, style }) {
    const [anchorEl, setAnchorEl] = useState();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleSubmit = (opt) => {
        if (onChange) {
            onChange(opt.id);
        }
        setAnchorEl(null);
    };

    const content = text ? text : (options.find(opt => opt.id === value)?.name || placeholder);

    return <>
        <Button onClick={handleClick} disabled={disabled} className={className} style={style}>
            <span style={{textTransform: 'none'}}>{ content }</span>
        </Button>
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            { options.map(opt => (
                <MenuItem key={opt.id} onClick={() => handleSubmit(opt)}>{opt.name}</MenuItem>
            )) }
        </Menu>
    </>
}