import bigDecimal from 'js-big-decimal';
import querystring from 'querystring';
import { useState } from 'react';
import { useCoEffect } from '../lib/useCo';

const baseUrl = '';

/**
 * @template {{[key:string]: unknown}} T
 * @typedef {{
 *   [K in keyof T]: T[K] extends bigDecimal ? string : T[K];
 * }} ApiSerialized
 */

/**
 * @typedef {{
 *   'viewCoveredStock': boolean,
 *   'editCoveredStock': boolean,
 *   'viewNonCoveredStock': boolean,
 *   'editNonCoveredStock': boolean,
 *   'viewUsers': boolean,
 *   'editUsers': boolean,
 *   'viewStockList': boolean,
 *   'editStockList': boolean,
 *   'viewAggrTable': boolean,
 *   'viewCallTable': boolean,
 *   'viewShortTable': boolean,
 *   'viewEventTable': boolean,
 *   'viewWorkTable': boolean,
 *   'importStock': boolean,
 *   'reviewTicket': boolean,
 *  }} PermissionMap
 */

/**
 * @typedef { keyof PermissionMap } PermissionItem
 */

/** 
 * @typedef {{
 *   authenticated: boolean,
 *   validated: boolean,
 *   accessToken?: string,
 *   userId? : number,
 *   username?: string,
 *   displayName?: string,
 *   expiresAt?: number,
 *   permissions: PermissionItem[]
 * }} Token
 */

/**
 * @type { Token }
 */
let token = null;

export function getToken() {
    return token;
}

/**
 * @param {Token} t 
 */
export function setToken(t) {
    token = t;
}

function checkResponse(data) {
    if (data.code !== 0) {
        throw new Error(data.message);
    }
    return data.data;
}

function checkStatus(status) {
    if (status == 401) {
        throw new Error('登录已过期，请刷新页面重新登录');
    } if (status == 403) {
        throw new Error('你没有此操作的权限');
    } else if (status !== 200) {
        throw new Error('Bad status ' + status);
    }
}

let connectionCount = 0;
let idleTimeout;
/** @type { (() => void)[] } */
let idleQueue = [];

export function busy() {
    connectionCount ++;
    if (idleTimeout) {
        clearTimeout(idleTimeout);
        idleTimeout = null;
    }
}

export function idle() {
    connectionCount --;
    checkIdle();
}

function checkIdle() {
    if (connectionCount === 0 && !idleTimeout) {
        idleTimeout = setTimeout(function onIdle() {
            idleTimeout = null;
            const t = idleQueue.shift();
            if (t) {
                t();
                idleTimeout = setTimeout(onIdle, 100);
            }
        }, 100);
    }
}

export function requestNetworkIdleCallback(cb, priority = false) {
    if (priority) {
        idleQueue.unshift(cb);
    } else {
        idleQueue.push(cb);
    }
    checkIdle();
}

export function cancelNetworkIdleCallback(cb) {
    const i = idleQueue.indexOf(cb);
    if (i >= 0) {
        idleQueue.splice(i, 1);
    }
}

export async function get(api, query) {
    try {
        busy();
        const qs = query ? '?' + querystring.stringify(query) : '';
        const resp = await fetch(baseUrl + api + qs, {
            method: 'GET',
            headers: {
                'Authorization': token && token.authenticated ? ('Bearer ' + token.accessToken) : ''
            }
        });
        checkStatus(resp.status);
        return checkResponse(await resp.json());
    } finally {
        idle();
    }
}

export async function del(api, query) {
    try {
        busy();
        const qs = query ? '?' + querystring.stringify(query) : '';
        const resp = await fetch(baseUrl + api + qs, {
            method: 'DELETE',
            headers: {
                'Authorization': token && token.authenticated ? ('Bearer ' + token.accessToken) : ''
            }
        });
        checkStatus(resp.status);
        return checkResponse(await resp.json());
    } finally {
        idle();
    }
}

export async function post(api, data) {
    try {
        busy();
        const resp = await fetch(baseUrl + api, {
            method: 'POST',
            body: querystring.stringify(data),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token && token.authenticated ? ('Bearer ' + token.accessToken) : ''
            }
        });
        checkStatus(resp.status);
        return checkResponse(await resp.json());
    } finally {
        idle();
    }
}

export async function put(api, data) {
    try {
        busy();
        const resp = await fetch(baseUrl + api, {
            method: 'PUT',
            body: querystring.stringify(data),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token && token.authenticated ? ('Bearer ' + token.accessToken) : ''
            }
        });
        checkStatus(resp.status);
        return checkResponse(await resp.json());
    } finally {
        idle();
    }
}

export async function postJson(api, data) {
    try {
        busy();
        const resp = await fetch(baseUrl + api, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token && token.authenticated ? ('Bearer ' + token.accessToken) : ''
            }
        });
        checkStatus(resp.status);
        return checkResponse(await resp.json());
    } finally {
        idle();
    }
}

export async function putJson(api, data) {
    try {
        busy();
        const resp = await fetch(baseUrl + api, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token && token.authenticated ? ('Bearer ' + token.accessToken) : ''
            }
        });
        checkStatus(resp.status);
        return checkResponse(await resp.json());
    } finally {
        idle();
    }
}

/**
 * @param {number} time 
 * @return {Promise<void>}
 */
export function timeout(time) {
    return new Promise((fulfill) => {
        setTimeout(fulfill, time);
    });
}


/**
 * @template { (...args: any) => any } T
 * @param { T } api 
 * @param  { Parameters<T> } params 
 * @returns { [(ReturnType<T> extends Promise<infer R> ? R : T)| undefined, boolean, Error] }
 */
export function useApiState(api, ...params) {
    /** @type {[(ReturnType<T> extends Promise<infer R> ? R : T)| undefined, boolean, Error]} */
    const initialState = [undefined, true, undefined];
    const [data, setData] = useState(initialState)
    useCoEffect(function*(){
        try {
            // 在重载时不再loading，避免页面闪烁，
            // 这个行为原来是bug，现在已经变成feature了
            // setData(d => [d[0], true, undefined]);
            const r = yield api(...params);
            setData([r, false, undefined]);
        } catch (e) {
            setData([undefined, false, e]);
        }
    }, params);
    return data;
}

/**
 * @template { (...args: any) => any } T
 * @param { T } func
 * @returns { (...args: [...Parameters<T>, reload: unknown]) => ReturnType<T> } 
 */
export function withReload(func) {
    return (...args) => {
        const newArgs = args.slice(0, args.length - 1);
        return func(...newArgs);
    };
}
