import { Button, LinearProgress, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { commitRelationCNHKTable, createRelationCNHKTable, uploadRelationCNHKTableRow } from '../../api/relation-cn-hk';
import { bn } from '../../lib/bn';
import { readSheetFromFile } from '../../lib/excel';
import { makeParser } from '../../lib/importer';
import { urlParent, urlResolve } from '../../lib/url';
import { useCoCallback } from '../../lib/useCo';

/** @typedef {{
 *    code_CN: string,
 *    code_HK: string,
 *    rate: string,
 *    unused: string,
 * }} ParsedRow
 */

const headerMap = {
    'A股代码': 'code_CN',
    'H股代码': 'code_HK',
    'A股/H股': 'rate',
    '名称': 'unused',
    '现价': 'unused',
    '涨跌': 'unused',
    '涨跌幅': 'unused',
    '陆股通持股占比': 'unused',
    '成交额': 'unused',
    '港股通持股占比': 'unused',
    'H股/A股': 'unused',
}

const ignoreBigNumber = ['-'];

const parser = makeParser(builder => {
    return {
        code_CN: builder.requiredStringWithPattern(/\d\d\d\d\d\d\.(SH|SZ)/, '单元格不是正确的A股代码'),
        code_HK: builder.requiredStringWithPattern(/\d\d\d\d\.HK/, '单元格不是有效的H股代码'),
        rate: builder.optionalBigNumber(),
        unused: builder.any(),
    };
}, /** @type { ParsedRow } */(null));

export default function RelationCNHKTableImport() {
    const [warnings, setWarnings] = useState(/** @type {import('../../lib/importer').ErrorMessage[]} */([]));
    const [errors, setErrors] = useState(/** @type {import('../../lib/importer').ErrorMessage[]} */([]));
    const [parseErrors, setParseErrors] = useState(/** @type {string[]} */([]));
    const [data, setData] = useState(/** @type {ParsedRow[]} */(null));
    const [originalRows, setOriginalRows] = useState(0);
    const [importSuccessCount, setImportSuccessCount] = useState(0);
    const [importErrorCount, setImportErrorCount] = useState(0);
    const [importErrors, setImportErrors] = useState(/** @type {string[]} */([]));
    const [fileSelectKey, setFileSelectKey] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [importResult, setImportResult] = useState(0);
    const history = useHistory();
    const { url } = useRouteMatch();
    const parent = urlParent(url);

    function reset() {
        setWarnings([]);
        setErrors([]);
        setParseErrors([]);
        setData(null);
        setOriginalRows(0);
        setImportSuccessCount(0);
        setImportErrorCount(0);
        setImportErrors([]);
        setFileSelectKey(k => k + 1);
        setSubmitting(false);
        setImportResult(0);
    }

    const onSelect = useCoCallback(function*(_isCancelled, event) {
        try {
            setWarnings([]);
            setErrors([]);
            setParseErrors([]);
            setData(null);
            const sheet = /** @type {string[][]} */(yield readSheetFromFile(event.target.files[0]));
            const parseResult = parser(sheet, 1, 0, headerMap, ['code_CN', 'code_HK', 'rate']);
            console.log(parseResult.data);
            setData(parseResult.data);
            setWarnings(parseResult.warnings);
            setErrors(parseResult.errors);
            setParseErrors(parseResult.parseErrors);
            setOriginalRows(sheet.length - 1);
        } catch (e) {
            console.warn(e);
            setErrors([{ message: e.message }]);
        }
    }, []);

    const onSubmit = useCoCallback(function*(isCancelled, /** @type {ParsedRow[]} */ data){
        try {
            setSubmitting(true);
            let anyError = false;
            const version = /** @type { number } */(yield createRelationCNHKTable());
            for (const item of data) {
                try {
                    yield uploadRelationCNHKTableRow(
                        version,
                        {
                            code_CN: item.code_CN,
                            code_HK: item.code_HK,
                            rate: bn(item.rate)
                        }
                    );
                    setImportSuccessCount(c => c + 1);
                } catch (e) {
                    console.warn(e);
                    setImportErrorCount(c => c + 1);
                    setImportErrors(errs => [...errs, e.message]);
                    anyError = true;
                }
            }
            if (!anyError) {
                yield commitRelationCNHKTable(version);
                setImportResult(1);
                history.replace(parent);
            } else {
                setImportResult(2);
            }
            setSubmitting(false);
        } catch (e) {
            console.warn(e);
            setImportErrors(es => [...es, e.message]);
            setImportResult(2);
            setSubmitting(false);
        }
    }, []);
    return (
        <Paper style={{flex:1, marginBottom: 20, padding: 20}}>
            <div style={{marginBottom: 16}}>
                <input key={fileSelectKey} disabled={data?.length > 0} type="file" style={{fontSize: 16}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv" onChange={onSelect} />
            </div>
            <div>
                { parseErrors?.length ? ( parseErrors.map((e, i) => (
                    <Alert severity="error" key={i}>
                        { e }
                    </Alert>
                ))) : null}
                { data ? (<div>
                    { data.length === 0 && originalRows === 0 ? (
                        <Alert severity="error">
                            导入的表格中没有数据
                        </Alert>
                    ) : null}
                    { data.length === 0 && originalRows !== 0 ? (
                        <Alert severity="error">
                            原始数据量：{ originalRows }<br/>
                            没有可导入的数据，错误原因如下：
                            {errors?.length ? errors.map((e,i) => (
                                <span key={i}>
                                    <br/>{ e.ref }：{ e.message }
                                </span>
                            )) : null}
                        </Alert>
                    ) : null}
                    { data.length !== 0 && data.length < originalRows ? (
                        <Alert severity="warning">
                            原始数据量：{ originalRows }<br/>
                            可导入数据量：{ data.length }<br/>
                            以下单元格的数据解析出错：
                            {errors?.length ? errors.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e.ref }：{ e.message }
                                    </span>
                                </span>
                            )) : null}
                        </Alert>
                    ) : null}
                    { data.length !== 0 && data.length === originalRows ? (
                        <Alert severity="success">
                            原始数据量：{ originalRows }<br/>
                            可导入数据量：{ data.length }
                        </Alert>
                    ) : null}
                    { warnings.length ? (
                        <Alert severity="warning">
                            以下单元格可能有问题，但不影响导入：
                            {warnings.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e.ref }：{ e.message }
                                    </span>
                                </span>
                            ))}
                        </Alert>
                    ) : null }
                </div>) : null}
            </div>
            { data?.length > 0 ? (<div style={{marginTop: 20}}>
                <div>
                    <LinearProgress
                        style={{marginTop: 16, marginBottom: 16}}
                        color="primary"
                        variant="determinate"
                        value={(importSuccessCount + importErrorCount) * 100 / data.length}
                    />
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{flex:1}}>
                        导入进度：{importSuccessCount + importErrorCount}/{data.length}
                    </div>
                    <Button disabled={submitting} variant="contained" onClick={reset}>
                        重置
                    </Button>
                    <Button
                        disabled={submitting || importResult > 0 }
                        style={{marginLeft: 16}} 
                        variant="contained"
                        color="primary" 
                        onClick={()=>onSubmit(data)}
                    >
                        开始导入
                    </Button>
                </div>
                <div>
                    {importResult === 1 ? (
                        <Alert severity="success">导入完成</Alert>
                    ):null}
                    {importResult === 2 ? (
                        <Alert severity="warning">导入失败，下方是详细错误信息：</Alert>
                    ):null}
                </div>
                <div>
                    { importErrors.length > 0 ? (
                        <Alert severity="error">
                            导入数据过程中发生以下错误：
                            {importErrors.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e }
                                    </span>
                                </span>
                            ))}
                        </Alert>
                    ) : null}
                </div>
            </div>) : null}
        </Paper>
    )
}