import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, InputLabel, ListItemText, makeStyles, MenuItem, Paper, Select } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { FixedTable, FullHeightVirtualScrollTable, VirtualScrollTable } from './Table';
import { useApiState, withReload } from './api/common';
import useErrorMessage from './lib/useErrorMessage';
import { useSnackbar } from 'notistack';
import { createUser, getAllRoles, getUsers, getUsersByIds, updateUser } from './api/user';
import { Button } from '@material-ui/core';
import SmallCheckbox from './components/SmallCheckbox';
import { AddRounded } from '@material-ui/icons';
import { TextField } from '@material-ui/core';
import { useCoCallback } from './lib/useCo';
import SearchableSelect from './components/SearchableSelect';

/** @typedef {import('./api/user').User} User */
/** @typedef {import('./api/user').Role} Role */
/** @typedef {User & Role} UserWithRole */

/** 
 * @type {import('./Table').SorterAndFilters<UserWithRole>} */
const initSorterAndFilters = null;

/** @type {import('./Table').Fields<UserWithRole>[]} */
const initVisibleColumns = null;

const useStyles = makeStyles({
    left: {
        // @ts-ignore
        textAlign: 'left !important',
    },
    hoverUnderline: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    pt16: {
        paddingTop: 16
    }
});

/**
 * @param {{
 *   id: number,
 *   open: boolean,
 *   onClose: () => void,
 *   onReloadData: () => void,
 *   initialValue: string
 * }} param0
 */
function SetDisplayNameDialog({ id, open, onClose, onReloadData, initialValue }) {
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        if (open) {
            setValue(initialValue);
        }
    }, [open]);
    const [submitting, setSubmitting] = useState(false);
    const onSubmit = useCoCallback(function*(isCancelled, value){
        try {
            setSubmitting(true);
            yield updateUser({ user_ID: id, user_Name: value.toLowerCase(), display_Name: value });
            setSubmitting(false);
            enqueueSnackbar("修改成功", { variant: 'success' });
            onReloadData();
            onClose();
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, [id]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>修改显示名</DialogTitle>
            <DialogContent>
                <TextField
                    disabled={submitting}
                    style={{width:300}}
                    label="显示名"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={submitting}>
                    取消
                </Button>
                <Button onClick={()=>onSubmit(value)} disabled={submitting} color="primary">
                    保存
                </Button>
            </DialogActions>
        </Dialog>
    )
}

/**
 * @param {{
 *   id: number,
 *   open: boolean,
 *   onClose: () => void,
 *   onReloadData: () => void
 * }} param0
 */
function SetPasswordDialog({ id, open, onClose, onReloadData }) {
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState('');
    useEffect(() => {
        if (open) {
            setValue('');
        }
    }, [open]);
    const [submitting, setSubmitting] = useState(false);
    const onSubmit = useCoCallback(function*(isCancelled, value){
        try {
            setSubmitting(true);
            yield updateUser({ user_ID: id, password: value });
            setSubmitting(false);
            enqueueSnackbar("修改成功", { variant: 'success' });
            onReloadData();
            onClose();
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, [id]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>修改密码</DialogTitle>
            <DialogContent>
                <TextField
                    disabled={submitting}
                    style={{width:300}}
                    label="密码"
                    type="password"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={submitting}>
                    取消
                </Button>
                <Button onClick={()=>onSubmit(value)} disabled={submitting} color="primary">
                    保存
                </Button>
            </DialogActions>
        </Dialog>
    )
}

/**
 * @param {{ 
 *   roles: Role[],
 *   id: number,
 *   open: boolean,
 *   onClose: () => void,
 *   onReloadData: () => void,
 *   initialValue: number
 * }} param0
 */
function SetUserRoleDialog({ roles, id, open, onClose, onReloadData, initialValue, }) {
    const roleOpts = useMemo(() => {
        return roles.map(r => ({
            id: r.role_ID,
            name: r.role_Name
        }));
    }, [roles]);
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState(() => roleOpts.find(opt => opt.id === initialValue));
    useEffect(() => {
        if (open) {
            setValue(roleOpts.find(opt => opt.id === initialValue));
        }
    }, [open]);
    const [submitting, setSubmitting] = useState(false);
    const onSubmit = useCoCallback(function*(isCancelled, role_ID){
        try {
            if (!role_ID) {
                throw new Error('必须选择一个角色');
            }
            setSubmitting(true);
            yield updateUser({ user_ID: id, role_ID });
            setSubmitting(false);
            enqueueSnackbar("修改成功", { variant: 'success' });
            onReloadData();
            onClose();
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, [id]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>修改角色</DialogTitle>
            <DialogContent>
                <SearchableSelect
                    disabled={submitting}
                    options={roleOpts} 
                    label="角色" 
                    style={{width:300}} 
                    value={value}
                    onChange={setValue}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={submitting}>
                    取消
                </Button>
                <Button onClick={()=>onSubmit(value?.id)} disabled={submitting} color="primary">
                    保存
                </Button>
            </DialogActions>
        </Dialog>
    )
}

/**
 * @param {{
 *   roles: Role[],
 *   open: boolean,
 *   onClose: () => void,
 *   onReloadData: () => void,
 * }} param0 
 */
function CreateUserDialog({ roles, open, onClose, onReloadData }) {
    const classes = useStyles();
    const roleOpts = useMemo(() => {
        return roles.map(r => ({
            id: r.role_ID,
            name: r.role_Name
        }));
    }, [roles]);
    const { enqueueSnackbar } = useSnackbar();
    const [displayName, setDisplayName] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState(null);
    useEffect(() => {
        if (open) {
            setDisplayName("");
            setPassword("");
            setRole(null);
        }
    }, [open]);
    const [submitting, setSubmitting] = useState(false);
    const onSubmit = useCoCallback(function*(isCancelled, displayName, password, roleId){
        try {
            if (!displayName) {
                throw new Error('显示名为空');
            }
            if (!password) {
                throw new Error('密码为空');
            }
            if (password.length < 6) {
                throw new Error('密码至少为6位');
            }
            if (!roleId) {
                throw new Error('必须选择一个角色');
            }
            setSubmitting(true);
            yield createUser({
                user_Name: displayName.toLowerCase(),
                display_Name: displayName,
                password: password,
                role_ID: roleId,
                enabled: true,
                visible: true
            });
            setSubmitting(false);
            enqueueSnackbar("创建成功", { variant: 'success' });
            onReloadData();
            onClose();
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, []);
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>创建用户</DialogTitle>
            <DialogContent>
                <div>
                    <TextField
                        disabled={submitting}
                        style={{width:300}}
                        label="显示名"
                        value={displayName}
                        onChange={e => setDisplayName(e.target.value)}
                    />
                </div>
                <div className={classes.pt16}>
                    <TextField
                        disabled={submitting}
                        style={{width:300}}
                        label="密码"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <div className={classes.pt16}>
                    <SearchableSelect
                        disabled={submitting}
                        options={roleOpts} 
                        label="角色" 
                        style={{width:300}} 
                        value={role}
                        onChange={setRole}
                    />
                </div>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={onClose} disabled={submitting}>
                    取消
                </Button>
                <Button onClick={()=>onSubmit(displayName, password, role?.id)} disabled={submitting} color="primary">
                    创建用户
                </Button>
            </DialogActions>
        </Dialog>
    )
}


/**
 * @param {{
 *   id: number,
 *   username: string,
 *   displayName: string,
 *   open: boolean,
 *   onClose: () => void,
 *   onReloadData: () => void
 * }} param0
 */
function DeleteUserDialog({ id, username, displayName, open, onClose, onReloadData }) {
    const { enqueueSnackbar } = useSnackbar();
    const [submitting, setSubmitting] = useState(false);
    const onSubmit = useCoCallback(function*(isCancelled){
        try {
            setSubmitting(true);
            let n = `_d${Math.random().toString(36).substr(-4)}_${username}`;
            if (n.length > 31) {
                n = n.substr(0, 31);
            }
            yield updateUser({ user_ID: id, display_Name: '-', user_Name: n, visible: false, enabled: false, deleted: true });
            setSubmitting(false);
            enqueueSnackbar("删除成功", { variant: 'success' });
            onReloadData();
            onClose();
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, [id, username]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>删除用户</DialogTitle>
            <DialogContent>
                <div style={{width: 300}}>
                    是否删除用户“{displayName}”？
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={submitting}>
                    取消
                </Button>
                <Button onClick={()=>onSubmit()} disabled={submitting} color="secondary">
                    删除
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const getUsersReloadable = withReload(getUsers);

export default function UserTable() {
    const classes = useStyles();
    const [showSetDisplayNameDialog, setShowSetDisplayNameDialog] = useState({
        id: 0,
        open: false,
        initialValue: ''
    });
    const [showSetPasswordDialog, setShowSetPasswordDialog] = useState({
        id: 0,
        open: false
    });
    const [showCreateUser, setShowCreateUser] = useState(false);
    const [showSetRoleDialog, setShowSetRoleDialog] = useState({
        id: 0,
        open: false,
        initialValue: 0
    });
    const [showDeleteUserDialog, setShowDeleteUserDialog] = useState({
        id: 0,
        open: false,
        username: '',
        displayName: ''
    });
    const [reload, setReload] = useState(0);
    const [users = [], loading, error] = useApiState(getUsersReloadable, true, reload);
    const [roles = [], rolesLoading, rolesError] = useApiState(getAllRoles);
    const data = useMemo(() => {
        /** @type {{[key:number]: Role}} */
        const roleMap = {};
        roles.forEach(r => {
            roleMap[r.role_ID] = r;
        });
        const usersWithRole = users.map(u => {
            const r = roleMap[u.role_ID];
            return { ...u, ...r };
        });
        return {
            users: usersWithRole,
            roles
        }
    }, [users, roles]);
    const { enqueueSnackbar } = useSnackbar();

    const onReloadData = () => setReload(r => r + 1);
    const onSetEnabled = useCoCallback(function*(isCancelled, /** @type { number } */ id, /** @type { boolean } */enabled){
        try {
            yield updateUser({ user_ID: id, enabled });
            enqueueSnackbar("修改成功", { variant: 'success' });
            onReloadData();
        } catch (e) {
            enqueueSnackbar(e.message);
        }
    }, []);

    const onSetVisible = useCoCallback(function*(isCancelled, /** @type { number } */ id, /** @type { boolean } */visible){
        try {
            yield updateUser({ user_ID: id, visible });
            enqueueSnackbar("修改成功", { variant: 'success' });
            onReloadData();
        } catch (e) {
            enqueueSnackbar(e.message);
        }
    }, []);

    const columns = useMemo(() => {
        /** @type {import('./Table').ColumnOption<UserWithRole>[]} */
        const columns = [
            {
                field: 'user_ID',
                label: 'ID',
                model: 'none',
                sortable: false,
                width: 100,
            },
            {
                field: 'display_Name',
                label: '显示名',
                model: 'none',
                sortable: false,
                width: 150,
                render: row => <span className={classes.hoverUnderline} onClick={() => setShowSetDisplayNameDialog({
                    id: row.user_ID,
                    open: true,
                    initialValue: row.display_Name,
                })}>{row.display_Name}</span>,
            },
            {
                field: 'role_Name',
                label: '角色',
                model: 'none',
                sortable: false,
                width: 150,
                render: row => <Button size="small" onClick={()=>setShowSetRoleDialog({
                    id: row.user_ID,
                    open: true,
                    initialValue: row.role_ID
                })}>{row.role_Name}</Button>,
            },
            {
                field: 'enabled',
                label: '允许登录',
                model: 'none',
                sortable: false,
                width: 100,
                render: row => <SmallCheckbox 
                    checked={row.enabled}
                    onChange={e => onSetEnabled(row.user_ID, !row.enabled)}
                />,
            },
            {
                field: 'visible',
                label: '可见',
                model: 'none',
                sortable: false,
                width: 100,
                render: row => <SmallCheckbox 
                    checked={row.visible}
                    onChange={e => onSetVisible(row.user_ID, !row.visible)}
                />,
            },
            {
                field: 'user_ID',
                label: '动作',
                model: 'none',
                sortable: false,
                width: 200,
                render: row => <>
                    <Button size="small" onClick={() => setShowSetPasswordDialog({
                        id: row.user_ID,
                        open: true
                    })}>重置密码</Button>
                    <Button size="small" onClick={() => setShowDeleteUserDialog({
                        id: row.user_ID,
                        open: true,
                        username: row.user_Name,
                        displayName: row.display_Name
                    })}>删除</Button>
                </>,
            },
            {
                field: 'empty',
                label: '',
                model: 'none',
                sortable: false,
                render: row => null
            }
        ];
        return columns;
    }, [classes, onSetEnabled]);

    useErrorMessage(error || rolesError);
    if (!data) return null;
    return <div style={{width:'100%', display: 'flex', flexDirection: 'column'}}>
        <div style={{flex:1}}>
            <FixedTable
                height={1000}
                data={data.users}
                columns={columns}
                sorterAndFilters={initSorterAndFilters}
                onSorterAndFiltersChange={()=>{}}
                visibleColumnOrder={initVisibleColumns}
                onVisibleColumnOrderChange={()=>{}}
                variant="compat"
            />
        </div>
        <div style={{height:50}}>
            <Fab
                size="small" 
                color="primary" 
                style={{ float:'right', marginTop: 10 }}
            >
                <AddRounded onClick={()=>setShowCreateUser(true)}/>
            </Fab>
        </div>
        <div>
            <SetDisplayNameDialog
                key={showSetDisplayNameDialog.id}
                {...showSetDisplayNameDialog}
                onClose={() => setShowSetDisplayNameDialog(v => ({ ...v, open: false }))}
                onReloadData={onReloadData}
            />
        </div>
        <div>
            <SetPasswordDialog
                key={showSetPasswordDialog.id}
                {...showSetPasswordDialog}
                onClose={() => setShowSetPasswordDialog(v => ({ ...v, open: false }))}
                onReloadData={onReloadData}
            />
        </div>
        <div>
            <CreateUserDialog
                roles={roles}
                open={showCreateUser}
                onClose={() => setShowCreateUser(false)}
                onReloadData={onReloadData}
            />
        </div>
        <div>
            <SetUserRoleDialog
                key={showSetRoleDialog.id}
                roles={roles}
                {...showSetRoleDialog}
                onClose={() => setShowSetRoleDialog(v => ({ ...v, open: false }))}
                onReloadData={onReloadData}
            />
        </div>
        <div>
            <DeleteUserDialog
                key={showDeleteUserDialog.id}
                {...showDeleteUserDialog}
                onClose={() => setShowDeleteUserDialog(v => ({ ...v, open: false }))}
                onReloadData={onReloadData}
            />
        </div>
    </div>
}