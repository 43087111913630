import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, makeStyles, Paper, Tab, Tabs } from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { FullHeightVirtualScrollTable } from '../../Table';
import { ConflictLabels, FieldNames } from '../../lib/enums';
import { useApiState } from '../../api/common';
import useErrorMessage from '../../lib/useErrorMessage';
import { useSnackbar } from 'notistack';
import { AddRounded, CheckRounded, CloseRounded, EditRounded } from '@material-ui/icons';
import { getStockReportUploadCNTable, getStockReportUploadCNTableVersions, invalidStockReportUploadCNTable, updateStockReportUploadCNTableRow, validStockReportUploadCNTable } from '../../api/stock-report-upload-cn';
import { useCoCallback } from '../../lib/useCo';
import { date2quarterOrSemi, dateToDayNum, dayNumToQuarterNum, fromDays2000, quarterFromDayNum, quarterFromDays2000, quarterNumToDayNum, semiFromDayNum } from '../../lib/date';
import moment from 'moment';
import SearchableSelect from '../../components/SearchableSelect';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import RadioButtonCheckedRoundedIcon from '@material-ui/icons/RadioButtonCheckedRounded';

const currentYear = moment().year();
/** @type {{ id:number, name: string, year: number, quarter: number }[] } */
const quarterOptions = [];

/** @type {{ id:number, name: string, year: number, quarter: number }[] } */
const semiOptions = [];

for (let y = currentYear - 1; y <= currentYear + 3; y++) {
    for (let q = 0; q < 4; q++) {
        const date = moment();
        let isValidSemi;
        switch(q) {
            case 0:
                date.set({ year: y, month: 2, date: 31, hour: 0, minute: 0, second: 0, millisecond: 0 });
                isValidSemi = false;
                break;
            case 1:
                date.set({ year: y, month: 5, date: 30, hour: 0, minute: 0, second: 0, millisecond: 0 });
                isValidSemi = true;
                break;
            case 2:
                date.set({ year: y, month: 8, date: 30, hour: 0, minute: 0, second: 0, millisecond: 0 });
                isValidSemi = false;
                break;
            case 3:
                date.set({ year: y, month: 11, date: 31, hour: 0, minute: 0, second: 0, millisecond: 0 });
                isValidSemi = true;
                break;
        }
        const dayNum = dateToDayNum(date);
        quarterOptions.push({
            id: dayNumToQuarterNum(dayNum),
            name: quarterFromDayNum(dayNum),
            year: y,
            quarter: q,
        });
        if (isValidSemi) {
            semiOptions.push({
                id: dayNumToQuarterNum(dayNum),
                name: semiFromDayNum(dayNum),
                year: y,
                quarter: q,
            })
        }
    }
}

const typeOptions = [
{
    id: 1,
    name: '预告'
}, {
    id: 2,
    name: '快报'
}, {
    id: 3,
    name: '财报'
},{
    id: 4,
    name: '其他'
},
];

const quarter_or_semi_options = [
    {
        id: 1,
        name: '季报'
    },
    {
        id: 2,
        name: '半年报'
    }
];

/** 
 * @typedef {{
 *   id: number,
 *   code: string,
 *   report_Date: string,
 *   title: string,
 *   theme: string,
 *   release_Time: string,
 *   parsed_Type: number,
 *   parsed_Quarter: number,
 *   parsed_Quarter_Or_Semi: number,
 *   parsed: string,
 *   conflict: string,
 *   origin: import('../../api/stock-report-upload-cn').StockReportUploadCN,
 * }} StockReportUploadCNRenderedRow 
 */

/**
 * @param {import('../../api/stock-report-upload-cn').StockReportUploadCN} r
 * @returns { StockReportUploadCNRenderedRow }
 */
function renderRow(r) {
    const parsed = r.parsed_Type === 4 ? '其他' :
        (date2quarterOrSemi(r.parsed_Quarter_Or_Semi, quarterNumToDayNum(r.parsed_Quarter)) + (['-', '预', '快', '财'])[r.parsed_Type]);

    return {
        id: r.id,
        code: r.code,
        report_Date: fromDays2000(r.report_Date).format('YYYY-MM-DD'),
        title: r.title,
        theme: r.theme,
        release_Time: moment(r.release_Time).format('YYYY-MM-DD HH:mm'),
        parsed,
        parsed_Type: r.parsed_Type,
        parsed_Quarter: r.parsed_Quarter,
        parsed_Quarter_Or_Semi: r.parsed_Quarter_Or_Semi,
        conflict: r.conflict ? `${r.conflict}` : '',
        origin: r,
    };
}

/** 
 * @type {import('../../Table').SorterAndFilters<StockReportUploadCNRenderedRow>} */
const initSorterAndFilters = null;

/** @type {import('../../Table').Fields<StockReportUploadCNRenderedRow>[]} */
const visibleColumns = ['code', 'report_Date', 'title', 'theme', 'release_Time', 'parsed', 'conflict'];

/** @type {import('../../Table').FullHeightVirtualScrollTableComponent<StockReportUploadCNRenderedRow>} */
// @ts-ignore
const Table = FullHeightVirtualScrollTable;

const useStyles = makeStyles({
    clickable: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
});

export default function StockReportUploadCNTable({  }) {
    // @ts-ignore
    const version = useParams()?.version;
    const history = useHistory();
    const [reload, setReload] = useState(0);
    const [versions] = useApiState(getStockReportUploadCNTableVersionsReloadable, reload);

    useEffect(() => {
        if (version && versions && Number(version) !== versions.uploaded) {
            history.replace("/user-table/stock-report-upload-cn");
        }
    }, [version, versions]);

    const [table, loading, error] = useApiState(getStockReportUploadCNTableReloadable, version, reload);
    const data = useMemo(() => (table||[]).map(renderRow), [table]);
    useErrorMessage(error);

    const { enqueueSnackbar } = useSnackbar();
    const [showImport, setShowImport] = useState(false);
    
    const [sorterAndFilters, setSorterAndFilters] = useState(initSorterAndFilters);
    
    const [submitting, setSubmitting] = useState(false);

    const onMakeValid = useCoCallback(function*(isCancelled, version){
        try {
            setSubmitting(true);
            yield validStockReportUploadCNTable(version);
            setSubmitting(false);
            enqueueSnackbar('上传数据已生效', { variant: 'success' });
            history.replace("/user-table/stock-report-upload-cn");
            setReload(r => r+1);
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, []);

    const onMakeInvalid = useCoCallback(function*(isCancelled, version){
        try {
            setSubmitting(true);
            yield invalidStockReportUploadCNTable(version);
            setSubmitting(false);
            enqueueSnackbar('上传数据已取消');
            history.replace("/user-table/stock-report-upload-cn");
            setReload(r => r+1);
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, []);

    const [editParsed, setEditParsed] = useState({
        open: false,
        code: '',
        id: 0,
        quarter_or_semi: /** @type {{ id: number, name: string }} */(null),
        type: /** @type {{ id: number, name: string }} */(null),
        quarter: /** @type {{ id: number, name: string }} */(null)
    });
    
    const [editSubmitting, setEditSubmitting] = useState(false);
    
    const onSubmit = useCoCallback(function*(isCancelled, id, parsed_Quarter, parsed_Type, parsed_Quarter_Or_Semi){
        try {
            setEditSubmitting(true);
            yield updateStockReportUploadCNTableRow(
                id, { parsed_Quarter, parsed_Type, parsed_Quarter_Or_Semi }
            );
            enqueueSnackbar('修改成功', { variant: 'success' });
            setReload(r => r+1);
            setEditSubmitting(false);
            setEditParsed(e => ({ ...e, open: false }));
        } catch (e) {
            console.warn(e);
            setEditSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, []);

    const onSetConflict = useCoCallback(function*(isCancelled, /** @type {number} */ id, /** @type {1|2} */ conflict) {
        try {
            yield updateStockReportUploadCNTableRow(
                id, { conflict }
            );
            enqueueSnackbar('修改成功', { variant: 'success' });
            setReload(r => r+1);
        } catch (e) {
            console.warn(e);
            enqueueSnackbar(e.message);
        }
    }, []);

    const classes = useStyles();

    const columns = useMemo(() => {
        /** @type {import('../../Table').ColumnOption<StockReportUploadCNRenderedRow>[]} */
        const columns = [
            {
                field: 'code',
                label: FieldNames.code,
                width: 100,
                model: 'string',
                render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code}</Link>
            },
            {
                field: 'report_Date',
                label: '公告日期',
                width: 100,
                model: 'string',
            },
            {
                field: 'title',
                label: '公告标题',
                width: 400,
                model: 'string',
                cellStyle: { textAlign: 'left' }
            },
            {
                field: 'theme',
                label: '主题',
                width: 100,
                model: 'enum'
            },
            {
                field: 'release_Time',
                label: '发布时间',
                width: 150,
                model: 'string'
            },
        ];
        if (version && versions?.uploaded) {
            columns.push({
                field: 'parsed',
                label: '业绩类型',
                width: 100,
                model: 'enum',
                render: row => (
                    <span 
                        className={classes.clickable}
                        onClick={()=>{
                            setEditParsed({
                                open: true,
                                code: row.code,
                                id: row.id,
                                type: typeOptions.find(opt => opt.id === row.parsed_Type),
                                quarter: row.parsed_Quarter_Or_Semi === 2 ?
                                    semiOptions.find(opt => opt.id === row.parsed_Quarter) :
                                    quarterOptions.find(opt => opt.id === row.parsed_Quarter),
                                quarter_or_semi: quarter_or_semi_options.find(opt => opt.id === row.parsed_Quarter_Or_Semi),
                            });
                        }}
                    >
                        {row.parsed}
                    </span>
                )
            });
            columns.push({
                field: 'conflict',
                label: '重复',
                width: 50,
                model: 'none',
                render: row => (row.conflict ?
                    <span 
                        className={classes.clickable}
                        onClick={() => {
                            if (row.origin.conflict === 1) {
                                onSetConflict(row.id, 2);    
                            } else {
                                onSetConflict(row.id, 1);
                            }
                        }}
                    >
                        {row.conflict==='1' ? <RadioButtonCheckedRoundedIcon style={{verticalAlign:'middle',display:'inline-block'}} fontSize='inherit' /> : <RadioButtonUncheckedRoundedIcon style={{verticalAlign:'middle',display:'inline-block'}} fontSize='inherit'/>}
                        <span style={{marginLeft:4}}>{ConflictLabels[row.conflict]}</span>
                    </span>
                : null)
            });
        } else {
            columns.push({
                field: 'parsed',
                label: '业绩类型',
                width: 100,
                model: 'enum',
            });
        }
        return columns;
    }, [classes, table, version, versions]);

    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Paper>
            <Tabs value={(version && versions?.uploaded) ? 'uploaded' : 'valid' } indicatorColor="primary">
                <Tab value='valid' label="已导入" onClick={() => history.push("/user-table/stock-report-upload-cn")} />
                {versions?.uploaded ? (
                    <Tab value='uploaded' label="等待导入" onClick={() => history.push(`/user-table/stock-report-upload-cn/${versions?.uploaded}`)} />
                ):null}
            </Tabs>
        </Paper>
        { loading ? (
            <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
                <CircularProgress color="primary" />
            </div>
        ) : null}
        { !loading ? (
            <div style={{ flex: 1, position: 'relative' }}>
                <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, display: 'flex' }}>
                    <Table
                        data={data}
                        columns={columns}
                        sorterAndFilters={sorterAndFilters}
                        onSorterAndFiltersChange={setSorterAndFilters}
                        variant="full"
                        visibleColumnOrder={visibleColumns}
                    />
                </div>
            </div>
        ) : null}
        <div style={{height:60}}>
            <Link to="/user-table/stock-report-upload-cn/add">
                <Fab
                    size="small" 
                    color="primary" 
                    style={{ position: 'fixed', bottom: 10, left: 20 }} 
                    onClick={() => setShowImport(true)}
                    disabled={submitting}
                >
                    <AddRounded />
                </Fab>
            </Link>
            { version ? (
                <Fab
                    size="small" 
                    style={{ position: 'fixed', bottom: 10, right: 80 }} 
                    onClick={() => onMakeInvalid(version)}
                    disabled={submitting}
                >
                    <CloseRounded />
                </Fab>
            ) : null }
            { version ? (
                <Fab
                    size="small" 
                    color="primary" 
                    style={{ position: 'fixed', bottom: 10, right: 20 }} 
                    onClick={() => onMakeValid(version)}
                    disabled={submitting}
                >
                    <CheckRounded />
                </Fab>
            ) : null }
        </div>
        <Dialog
            open={editParsed.open}
            onClose={() => setEditParsed(e => ({ ...e, open: false }))}
        >
            <DialogTitle>
                修改{editParsed.code}的业绩类型    
            </DialogTitle>
            <DialogContent>
                <SearchableSelect
                    disabled={submitting}
                    options={typeOptions} 
                    label="类型" 
                    style={{width:300}} 
                    value={editParsed.type}
                    onChange={type => setEditParsed(e => ({ ...e, type }))}
                />
                <SearchableSelect
                    disabled={submitting}
                    options={quarter_or_semi_options}
                    label="季报/半年报"
                    style={{marginTop: 16, width:300}}
                    value={editParsed.quarter_or_semi}
                    onChange={quarter_or_semi => setEditParsed(e => ({ ...e, quarter_or_semi }))}
                />
                <SearchableSelect
                    style={{marginTop: 16, width:300}}
                    disabled={submitting}
                    options={editParsed.quarter_or_semi?.id === 2 ? semiOptions : quarterOptions} 
                    label="季度/年度"
                    value={editParsed.quarter}
                    onChange={quarter => setEditParsed(e => ({ ...e, quarter }))}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setEditParsed(e => ({ ...e, open: false }))} disabled={editSubmitting}>
                    取消
                </Button>
                <Button onClick={()=>{
                    if (!editParsed.quarter?.id) {
                        enqueueSnackbar("请选择一个季度");
                        return;
                    }
                    if (!editParsed.type?.id) {
                        enqueueSnackbar("请选择一个类型");
                        return;
                    }
                    onSubmit(editParsed.id, editParsed.quarter.id, editParsed.type.id, editParsed.quarter_or_semi.id);
                }} disabled={editSubmitting} color="primary">
                    修改
                </Button>
            </DialogActions>
            
        </Dialog>
    </div>
}

function getStockReportUploadCNTableReloadable(version, ignored) {
    return getStockReportUploadCNTable(version || 0);
}

function getStockReportUploadCNTableVersionsReloadable(ignored) {
    return getStockReportUploadCNTableVersions();
}
