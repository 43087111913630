import { get } from "./common";

/** @typedef {import('./comment').Comment} Comment */

/**
 * @typedef {{
 *   code: string,
 *   date: number,
 *   name: string,
 *   user_Display_Name_List: string[],
 *   stock_Info_Update_ID_List: number[],
 *   comment_Create_ID_List: number[],
 *   comment_Update_ID_List: number[],
 *   event: {
 *     report_Quarterly: boolean,
 *     rsi_50_Up: boolean,
 *     rsi_50_Down: boolean,
 *     rsi_75: boolean,
 *     rsi_25: boolean,
 *     weeks_52_Hi: boolean,
 *     weeks_52_Lo: boolean,
 *     macd_Up: boolean,
 *     macd_Down: boolean,
 *   }
 * }} WorkDaily
 */

/**
 * @param {number} dayNum 
 * @returns { Promise<WorkDaily[]> }
 */
export async function getDailyWork(dayNum) {
    return await get('/api/work', {
        date: dayNum
    });
}