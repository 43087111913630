import { Button, CircularProgress, LinearProgress, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useApiState } from '../../api/common';
import { commitShortableHKTable, createShortableHKTable, uploadShortableHKTableRow } from '../../api/shortable-hk';
import { getStockCodesWithExchanges } from '../../api/stock';
import { readSheetFromFile } from '../../lib/excel';
import { makeParser } from '../../lib/importer';
import { urlParent } from '../../lib/url';
import { useCoCallback } from '../../lib/useCo';

/** @typedef {{
 *    code: string,
 *    currency: string,
 *    type: 'stock' | 'fund',
 *    unused: string,
 * }} ParsedRow
 */

const headerMap = {
    '股份代號': 'code',
    '交易貨幣': 'currency',
    '種類': 'type',
    '數目': 'unused',
    '股份簡稱': 'unused',
    '豁免賣空價規例': 'unused',
    '備註': 'unused',
}

const parser = makeParser(builder => {
    return {
        code: val => {
            if (!val) {
                return [false, '“股份代號”列是必填字段'];
            }
            const n = Number(val);
            if (!n) {
                return [false, '“股份代號”列不是有效的数字：' + val];
            }
            let s = `${n}`;
            while (s.length < 4) {
                s = '0' + s;
            }
            s += ".HK";
            return [true, s];
        },
        type: val => {
            if (val === '股本證券') {
                return [true, 'stock'];
            } else if (val === '基金') {
                return [true, 'fund'];
            } else {
                return [false, `无法识别的“種類”列：${val}`];
            }
        },
        currency: val => {
            if (val === 'HKD') {
                return [true, 'HKD'];
            } else if (val === 'CNY') {
                return [true, 'CNY'];
            } else if (val === 'USD') {
                return [true, 'USD'];
            } else {
                return [false, `无法识别的“交易貨幣”列：${val}`];
            }
        },
        unused: builder.any()
    };
}, /** @type { ParsedRow } */(null));

const exchanges = ["HKEX"]

export default function ShortableHKTableImport() {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [codes] = useApiState(getStockCodesWithExchanges, exchanges);
    const [warnings, setWarnings] = useState(/** @type {import('../../lib/importer').ErrorMessage[]} */([]));
    const [errors, setErrors] = useState(/** @type {import('../../lib/importer').ErrorMessage[]} */([]));
    const [parseErrors, setParseErrors] = useState(/** @type {string[]} */([]));
    const [data, setData] = useState(/** @type {ParsedRow[]|null} */(null));
    const [ignoreByTypeCount, setIgnoreByTypeCount] = useState(0);
    const [ignoredCodes, setIgnoredCodes] = useState(/** @type {string[]} */([]));
    const [originalRows, setOriginalRows] = useState(0);
    const [importSuccessCount, setImportSuccessCount] = useState(0);
    const [importErrorCount, setImportErrorCount] = useState(0);
    const [importErrors, setImportErrors] = useState(/** @type {string[]} */([]));
    const [fileSelectKey, setFileSelectKey] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [importResult, setImportResult] = useState(0);
    const { url } = useRouteMatch();

    function reset() {
        setWarnings([]);
        setErrors([]);
        setParseErrors([]);
        setData(null);
        setIgnoreByTypeCount(0);
        setIgnoredCodes([]);
        setOriginalRows(0);
        setImportSuccessCount(0);
        setImportErrorCount(0);
        setImportErrors([]);
        setFileSelectKey(k => k + 1);
        setSubmitting(false);
        setImportResult(0);
    }

    const onSelect = useCoCallback(function*(_isCancelled, event) {
        try {
            setWarnings([]);
            setErrors([]);
            setParseErrors([]);
            setData(null);
            const sheet = /** @type {string[][]} */(yield readSheetFromFile(event.target.files[0]));
            const parseResult = parser(sheet, 4, 3, headerMap);
            setData(parseResult.data.filter(item => item.type === 'stock' && codes?.indexOf(item.code) >= 0));

            setIgnoreByTypeCount(parseResult.data.filter(item => item.type !== 'stock').length);
            setIgnoredCodes(
                parseResult.data
                    .filter(item => item.type === 'stock' && codes?.indexOf(item.code) < 0)
                    .map(item => item.code)
            );
            setWarnings(parseResult.warnings);
            setErrors(parseResult.errors);
            setParseErrors(parseResult.parseErrors);
            setOriginalRows(sheet.length - 4);
        } catch (e) {
            console.warn(e);
            setErrors([{ message: e.message }]);
        }
    }, [codes]);

    const onSubmit = useCoCallback(function*(isCancelled, /** @type {ParsedRow[]} */ data){
        try {
            setSubmitting(true);
            let anyError = false;
            const version = /** @type { number } */(yield createShortableHKTable());
            for (const item of data) {
                try {
                    yield uploadShortableHKTableRow(
                        version, item.code, item.currency
                    );
                    setImportSuccessCount(c => c + 1);
                } catch (e) {
                    console.warn(e);
                    setImportErrorCount(c => c + 1);
                    setImportErrors(errs => [...errs, e.message]);
                    anyError = true;
                    break;
                }
            }
            if (!anyError) {
                yield commitShortableHKTable(version);
                setImportResult(1);
                enqueueSnackbar("上传成功", { variant: 'success' });
                history.replace(`${urlParent(url)}/${version}`);
            } else {
                setImportResult(2);
            }
            setSubmitting(false);
        } catch (e) {
            console.warn(e);
            setImportErrors(es => [...es, e.message]);
            setImportResult(2);
            setSubmitting(false);
        }
    }, []);
    if (!codes) {
        return (
            <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
                <CircularProgress color="primary" />
            </div>
        );
    }
    return (
        <Paper style={{flex:1, marginBottom: 20, padding: 20}}>
            <div style={{marginBottom: 16}}>
                <input key={fileSelectKey} disabled={data?.length > 0} type="file" style={{fontSize: 16}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv" onChange={onSelect} />
            </div>
            <div>
                { parseErrors?.length ? ( parseErrors.map((e, i) => (
                    <Alert severity="error" key={i}>
                        { e }
                    </Alert>
                ))) : null}
                { data ? (<div>
                    { data.length === 0 && originalRows === 0 ? (
                        <Alert severity="error">
                            导入的表格中没有数据
                        </Alert>
                    ) : null}
                    { data.length === 0 && originalRows !== 0 ? (
                        <Alert severity="error">
                            原始数据量：{ originalRows }<br/>
                            没有可导入的数据，原因如下：
                            {ignoreByTypeCount ? <span>
                                <br/>{ignoreByTypeCount}条数据因种类不是“股本證券”而被忽略
                            </span> : null}
                            {ignoredCodes?.length ? <span>
                                <br/>{ignoredCodes.length}条数据因系统中不存在对应的代码而被忽略：${ignoredCodes.join(", ")}
                            </span> : null}
                            {errors?.length ? <span><br/>以下单元格的数据解析出错：</span> : null }
                            {errors?.length ? errors.map((e,i) => (
                                <span key={i}>
                                    <br/>{ e.ref }：{ e.message }
                                </span>
                            )) : null}
                        </Alert>
                    ) : null}
                    { data.length !== 0 && data.length < originalRows ? (
                        <Alert severity="warning">
                            原始数据量：{ originalRows }<br/>
                            可导入数据量：{ data.length }<br/>
                            {ignoreByTypeCount ? <span>
                                {ignoreByTypeCount}条数据因种类不是“股本證券”而被忽略<br/>
                            </span> : null}
                            {ignoredCodes?.length ? <span>
                                {ignoredCodes.length}条数据因系统中不存在对应的代码而被忽略：{ignoredCodes.join(", ")}<br/>
                            </span> : null}
                            {errors?.length ? '以下单元格的数据解析出错：' : null }
                            {errors?.length ? errors.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e.ref }：{ e.message }
                                    </span>
                                </span>
                            )) : null}
                        </Alert>
                    ) : null}
                    { data.length !== 0 && data.length === originalRows ? (
                        <Alert severity="success">
                            原始数据量：{ originalRows }<br/>
                            可导入数据量：{ data.length }
                            {ignoreByTypeCount ? <span>
                                <br/>{ignoreByTypeCount}条数据因种类不是“股本證券”而被忽略
                            </span> : null}
                            {ignoredCodes?.length ? <span>
                                <br/>{ignoredCodes.length}条数据因系统中不存在对应的代码而被忽略：${ignoredCodes.join(", ")}
                            </span> : null}
                        </Alert>
                    ) : null}
                    { warnings.length ? (
                        <Alert severity="warning">
                            以下单元格可能有问题，但不影响导入：
                            {warnings.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e.ref }：{ e.message }
                                    </span>
                                </span>
                            ))}
                        </Alert>
                    ) : null }
                </div>) : null}
            </div>
            { data?.length > 0 ? (<div style={{marginTop: 20}}>
                <div>
                    <LinearProgress
                        style={{marginTop: 16, marginBottom: 16}}
                        color="primary"
                        variant="determinate"
                        value={(importSuccessCount + importErrorCount) * 100 / data.length}
                    />
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{flex:1}}>
                        导入进度：{importSuccessCount + importErrorCount}/{data.length}
                    </div>
                    <Button disabled={submitting} variant="contained" onClick={reset}>
                        重置
                    </Button>
                    <Button
                        disabled={submitting || importResult > 0 }
                        style={{marginLeft: 16}} 
                        variant="contained"
                        color="primary" 
                        onClick={()=>onSubmit(data)}
                    >
                        开始导入
                    </Button>
                </div>
                <div>
                    {importResult === 1 ? (
                        <Alert severity="success">导入完成</Alert>
                    ):null}
                    {importResult === 2 ? (
                        <Alert severity="warning">导入失败，下方是详细错误信息：</Alert>
                    ):null}
                </div>
                <div>
                    { importErrors.length > 0 ? (
                        <Alert severity="error">
                            导入数据过程中发生以下错误：
                            {importErrors.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e }
                                    </span>
                                </span>
                            ))}
                        </Alert>
                    ) : null}
                </div>
            </div>) : null}
        </Paper>
    )
}