import { Button, FormControl, InputLabel, MenuItem, Paper, Select } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useApiState } from '../api/common';
import { bns } from '../lib/bn';
import { dayNumOfToday, dayNumOfYesterday, fromDays2000, toDate } from '../lib/date';
import { useCoCallback } from '../lib/useCo';
import useQuery from '../lib/useQuery';
import { FullHeightVirtualScrollTable } from '../Table';
import createExcel from '../Table/excel';
import { getDailyDataNotExistedWithReload, StockDailyCommonColumns, StockDailyFullColumns, StockDailySimpleColumns } from './common';
import useErrorMessage from '../lib/useErrorMessage';

/** @typedef {import('./common').StockInfoDailyImportRow} StockInfoDailyImportRow */
/** @typedef {import('./common').StockInfoDailyResponse} StockInfoDailyResponse */
/** @typedef {import('./common').Models} Models */

const ntoday = dayNumOfToday();
const nyesterday = ntoday - 1;
const today = fromDays2000(dayNumOfToday());
const yesterday = fromDays2000(dayNumOfYesterday());


const Table = /** @type {import('../Table').FullHeightVirtualScrollTableComponent<StockInfoDailyImportRow>} */(
    /** @type {unknown} */(FullHeightVirtualScrollTable)
);

export default function StockDailyExport({ urlOfImport }) {
    const [model, setModel] = useQuery('m', /** @type {Models} */('bbg'));
    const [startDate, setStartDate] = useQuery('sd', today, s => Number.isSafeInteger(Number(s)) ? fromDays2000(Number(s)) : null, m => `${toDate(m)}`);
    const [endDate, setEndDate] = useQuery('ed', today, s => Number.isSafeInteger(Number(s)) ? fromDays2000(Number(s)) : null, m => `${toDate(m)}`);
    const [includePartial, setIncludePartial] = useQuery('p', '0');
    const [cols, setCols] = useQuery('c', 'simple');
    const [load, setLoad] = useState(0);

    const [data, loading, err] = useApiState(getDailyDataNotExistedWithReload,
        model,
        toDate(startDate),
        toDate(endDate),
        includePartial === '1',
        load
    );

    useErrorMessage(err);

    const columns = useMemo(() => {
        if (cols === 'full') {
            return [
                ...StockDailyCommonColumns,
                ...StockDailySimpleColumns,
                ...StockDailyFullColumns,
            ];
        } else {
            return [
                ...StockDailyCommonColumns,
                ...StockDailySimpleColumns,
            ];
        }
    }, [cols]);

    const rows = useMemo(() => {
        if (!data) return [];
        /** @type {StockInfoDailyImportRow[]} */
        const r = data.map(d => ({
            code: d.code,
            code_BBG: d.code_BBG,
            name: d.name,
            date: fromDays2000(d.date).format('M/D/YYYY'),
            price_Open: bns(d.price_Open),
            price_Close: bns(d.price_Close),
            price_Lo: bns(d.price_Lo),
            price_Hi: bns(d.price_Hi),
            last_RSI: bns(d.last_RSI),
            macd_DEA: bns(d.macd_DEA),
            macd_DIFF: bns(d.macd_DIFF),
            macd_MACD: bns(d.macd_MACD),
            price_Chg: bns(d.price_Chg),
            price_Chg_Pct: bns(d.price_Chg_Pct),
            pe_T0: bns(d.pe_T0),
            pb_TTM: bns(d.pb_TTM),
            marketValue: bns(d.marketValue),
            marketValue_USD: bns(d.marketValue_USD),
            price_52w_Hi: bns(d.price_52w_Hi),
            price_52w_Lo: bns(d.price_52w_Lo),
            ev: bns(d.ev),
            book_Value: bns(d.book_Value),
            price_Vol_10D: bns(d.price_Vol_10D),
            price_Vol_6M: bns(d.price_Vol_6M),
            price_Vol_1Y: bns(d.price_Vol_1Y),
            adv_1M: bns(d.adv_1M),
            adv_10D: bns(d.adv_10D),
            adv_3M: bns(d.adv_3M),
            num_Shares_T0_10K: bns(d.num_Shares_T0_10K),
            eps_Consensus_T0: bns(d.eps_Consensus_T0),
            eps_Consensus_T1: bns(d.eps_Consensus_T1),
            eps_YOY_Consensus_T0: bns(d.eps_YOY_Consensus_T0),
            eps_YOY_Consensus_T1: bns(d.eps_YOY_Consensus_T1),
            eps_PE_Consensus_T0: bns(d.eps_PE_Consensus_T0),
            eps_PE_Consensus_T1: bns(d.eps_PE_Consensus_T1),
            bps_Consensus_T0: bns(d.bps_Consensus_T0),
            bps_Consensus_T1: bns(d.bps_Consensus_T1),
            bps_YOY_Consensus_T0: bns(d.bps_YOY_Consensus_T0),
            bps_YOY_Consensus_T1: bns(d.bps_YOY_Consensus_T1),
            bps_PB_Consensus_T0: bns(d.bps_PB_Consensus_T0),
            bps_PB_Consensus_T1: bns(d.bps_PB_Consensus_T1),
            ebitda_Consensus_T0: bns(d.ebitda_Consensus_T0),
            ebitda_Consensus_T1: bns(d.ebitda_Consensus_T1),
            ebitda_YOY_Consensus_T0: bns(d.ebitda_YOY_Consensus_T0),
            ebitda_YOY_Consensus_T1: bns(d.ebitda_YOY_Consensus_T1),
            ebitda_EVX_Consensus_T0: bns(d.ebitda_EVX_Consensus_T0),
            ebitda_EVX_Consensus_T1: bns(d.ebitda_EVX_Consensus_T1),
            rev_Consensus_T0: bns(d.rev_Consensus_T0),
            rev_Consensus_T1: bns(d.rev_Consensus_T1),
            rev_YOY_Consensus_T0: bns(d.rev_YOY_Consensus_T0),
            rev_YOY_Consensus_T1: bns(d.rev_YOY_Consensus_T1),
            rev_PS_Consensus_T0: bns(d.rev_PS_Consensus_T0),
            rev_PS_Consensus_T1: bns(d.rev_PS_Consensus_T1),
        }));
        return r;
    }, [data]);

    const [exportInProgress, setExportInProgress] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const exportExcel = useCoCallback(function*(){
        if (!rows.length) {
            enqueueSnackbar('请先加载数据');
            return;
        }
        try {
            setExportInProgress(true);
            yield createExcel(rows, columns, columns.map(c => c.field), null, {
                code_BBG: 'string',
                code: 'string',
                name: 'string',
                date: 'date',
                price_Open: 'number',
                price_Close: 'number',
                price_Lo: 'number', 
                price_Hi: 'number',
                last_RSI: 'number',
                macd_DIFF: 'number',
                macd_DEA: 'number',
                macd_MACD: 'number',
                price_Chg: 'number',
                price_Chg_Pct: 'number',
                pe_T0: 'number',
                pb_TTM: 'number',
                marketValue: 'number',
                marketValue_USD: 'number',
                price_52w_Hi: 'number',
                price_52w_Lo: 'number',
                ev: 'number',
                book_Value: 'number',
                price_Vol_10D: 'number',
                price_Vol_6M: 'number',
                price_Vol_1Y: 'number',
                adv_1M: 'number',
                adv_10D: 'number',
                adv_3M: 'number',
                num_Shares_T0_10K: 'number',
                eps_Consensus_T0: 'number',
                eps_Consensus_T1: 'number',
                eps_YOY_Consensus_T0: 'number',
                eps_YOY_Consensus_T1: 'number',
                eps_PE_Consensus_T0: 'number',
                eps_PE_Consensus_T1: 'number',
                bps_Consensus_T0: 'number',
                bps_Consensus_T1: 'number',
                bps_YOY_Consensus_T0: 'number',
                bps_YOY_Consensus_T1: 'number',
                bps_PB_Consensus_T0: 'number',
                bps_PB_Consensus_T1: 'number',
                ebitda_Consensus_T0: 'number',
                ebitda_Consensus_T1: 'number',
                ebitda_YOY_Consensus_T0: 'number',
                ebitda_YOY_Consensus_T1: 'number',
                ebitda_EVX_Consensus_T0: 'number',
                ebitda_EVX_Consensus_T1: 'number',
                rev_Consensus_T0: 'number',
                rev_Consensus_T1: 'number',
                rev_YOY_Consensus_T0: 'number',
                rev_YOY_Consensus_T1: 'number',
                rev_PS_Consensus_T0: 'number',
                rev_PS_Consensus_T1: 'number',
            }, "日更数据表", "日更数据表");
        } catch (e) {
            console.warn(e);
            enqueueSnackbar('生成Excel文件出错');
        } finally {
            setExportInProgress(false);
        }
    }, [rows, columns]);

    const warns = [];
    const hour = moment().hour();
    if (hour < 16 && toDate(endDate) >= ntoday) {
        warns.push(
            <Alert key="1" severity='warning' style={{marginBottom: 10}}>
                {today.format('YYYY-MM-DD')}的A股尚未收盘，导出结果中将不包含该日期以及之后的A股。
            </Alert>
        );
    }
    if (model === 'bbg') {
        if (hour < 6 && toDate(endDate) === nyesterday) {
            warns.push(
                <Alert key="2" severity='warning' style={{marginBottom: 10}}>
                    {yesterday.format('YYYY-MM-DD')}的美股尚未收盘，导出结果中将不包含该日期以及之后的美股。
                </Alert>
            )
        } else if (toDate(endDate) >= ntoday) {
            warns.push(
                <Alert key="2" severity='warning' style={{marginBottom: 10}}>
                    {today.format('YYYY-MM-DD')}的美股尚未收盘，导出结果中将不包含该日期以及之后的美股。
                </Alert>
            )
        }
    }

    const history = useHistory();

    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Paper style={{padding:20,  marginBottom: 10}}>
            <FormControl>
                <InputLabel htmlFor="model">数据源</InputLabel>
                <Select
                    id="model"
                    style={{marginRight: 16, width:200}}
                    value={model}
                    onChange={e => {
                        setModel(/** @type {Models} */(e.target.value));
                        setLoad(0);
                    }}
                >
                    <MenuItem value='bbg'>
                        BBG
                    </MenuItem>
                    <MenuItem value='wind'>
                        Wind
                    </MenuItem>
                </Select>
            </FormControl>
            <DatePicker
                style={{marginRight: 16, width: 200}}
                autoOk
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="none"
                label="开始日期"
                value={startDate}
                maxDate={today}
                onChange={v => {
                    setStartDate(v);
                    setLoad(0);
                }}
            />
            <DatePicker
                style={{marginRight: 16, width: 200}}
                autoOk
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="none"
                label="结束日期"
                value={endDate}
                maxDate={today}
                onChange={v => {
                    setEndDate(v);
                    if (toDate(startDate) > toDate(endDate)) {
                        setStartDate(moment(v));
                    }
                    setLoad(0);
                }}
            />
            <FormControl>
                <InputLabel htmlFor="cols">数据字段</InputLabel>
                <Select
                    id="cols"
                    style={{marginRight: 16, width:200}}
                    value={cols}
                    onChange={e => {
                        setCols(/** @type {string} */(e.target.value));
                        setLoad(0);
                    }}
                >
                    <MenuItem value='simple'>
                        仅日更
                    </MenuItem>
                    <MenuItem value='full'>
                        日更与月更
                    </MenuItem>
                </Select>
            </FormControl>
            <div style={{display: 'inline-block', paddingTop: 12}}>
                <Button
                    disabled={loading}
                    onClick={() => setLoad(r => r + 1)}
                    variant='contained' color='primary'
                >
                    加载数据
                </Button>
                <Button
                    disabled={loading || exportInProgress}
                    onClick={() => exportExcel()}
                    variant='contained' color='primary'
                    style={{marginLeft: 10}}
                >
                    导出表格({rows.length}条)
                </Button>
                <Button
                    disabled={loading || exportInProgress}
                    onClick={() => {
                        history.push(urlOfImport);
                    }}
                    variant='contained' color='primary'
                    style={{marginLeft: 10}}
                >
                    开始导入
                </Button>
            </div>
        </Paper>
        {warns}
        <div style={{ flex: 1, position: 'relative', marginBottom: 20 }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 20, display: 'flex' }}>
                <Table
                    columns={columns}
                    data={rows}
                    variant="compat"
                />
            </div>
        </div>
        
    </div>
}