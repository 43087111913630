import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FullHeightVirtualScrollTable } from './Table';
import { useGlobal } from './lib/useGlobal';
import { useApiState } from './api/common';
import { getShortTable } from './api/call';
import useErrorMessage from './lib/useErrorMessage';
import { BoolLabels, CyclePositionLabels, FieldNames, MedTermTrendLabels, OptionsFromLabels, ShortWishlistLabels, StatusLabels, TrendStabilityLabels, TrendStageLabels, TrendStrengthLabels, TrendSustainabilityLabels } from './lib/enums';
import { bns, bnsMax, div } from './lib/bn';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, makeStyles, Typography } from '@material-ui/core';
import { fromDays2000, quarterFromDays2000, quarterNumOfToday, quarterNumToDayNum } from './lib/date';
import { getUsers } from './api/user';
import { updateStockInfo } from './api/stock';
import createExcel from './Table/excel';
import { useCoCallback, useCoEffect } from './lib/useCo';
import { useSnackbar } from 'notistack';
import { GetAppRounded } from '@material-ui/icons';
import moment from 'moment';
import { grey } from '@material-ui/core/colors';
import SearchableSelect from './components/SearchableSelect';
import { getCommentsByIds } from './api/comment';
import { CommentDialog } from './components/CommentDialog';
import { getEnums } from './api/enum';

/**
 * @typedef { {
 *  'code': string,
 *  'name': string,
 *  'shortable': string,
 *  'coverer_name': string[],
 *  'price_Close': string,
 *  'industrial_Chain': string,
 *  'sw1': string,
 *  'sw2': string,
 *  'sw3': string,
 *  'quality_Score': string,
 *  'trend_Score': string,
 *  'pricing_Score': string,
 *  'external_Power': string,
 *  'pt_Score': string,
 *  'last_RSI': string,
 *  'ft_Score': string,
 *  'cycle_Causation': string[],
 *  'cycle_Position': string,
 *  'latest_Comment_ID': number,
 * } } ShortTableRow
 */
/** 
 * @type {import('./Table').SorterAndFilters<ShortTableRow>} */
const initSorterAndFilters = null;

/** @type {import('./Table').Fields<ShortTableRow>[]} */
const initVisibleColumns = null;

const useStyles = makeStyles({
    left: {
        // @ts-ignore
        textAlign: 'left !important',
    },
    nobr: {
        whiteSpace: 'nowrap',
    },
    shortWishlist: {
        position: 'relative',
        padding: 0,
    },
    shortWishlistContent: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        padding: '3px 6px',
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: grey[700]
        }
    },
    clickable: {
        cursor: 'pointer'
    },
    paper: {
        width: '80%',
        maxWidth: '80%',
    },
});

/** @type { import('./api/stock').StockInfo[] } */
const emptyStockInfoList = [];

/** @type {import('./Table').FullHeightVirtualScrollTableComponent<ShortTableRow>} */
// @ts-ignore
const Table = FullHeightVirtualScrollTable;

/**
 * @param {number} id
 */
 export async function getCommentByIdOpt(id) {
    if (!id) return undefined;
    const s = await getCommentsByIds([id]);
    return s[0];
}

export default function ShortTable() {
    const classes = useStyles();
    const [origData, setOrigData] = useState(emptyStockInfoList);
    const [{ 
        open: setWishlishDialogOpen,
        code: setWishlishDialogCode,
        name: setWishlishDialogName,
        shortWishlist 
    }, setSetWishlistDialogState] = useState({ open: false, code: '', name: '', shortWishlist: 0 });
    const { enqueueSnackbar } = useSnackbar();
    useCoEffect(function*(){
        try {
            const data = yield getShortTable([
                'code',
                'name',
                'shortable_Total_Shares',
                'shortable_Total_USD',
                'shortable_Weighted_Rate',
                'shortable_Or_Default',
                'coverer',
                'price_Close',
                'industrial_Chain_ID',
                'sw1_ID',
                'sw2_ID',
                'sw3_ID',
                'quality_Score',
                'trend_Score',
                'pricing_Score_User',
                'external_Power',
                'pt_Score',
                'last_RSI',
                'ft_Score',
                'cycle_Causation',
                'cycle_Position',
                'latest_Comment_ID',
            ]);
            setOrigData(data);
        } catch (e) {
            console.warn(e);
            enqueueSnackbar(e.message);
        }
    }, []);
    const [enums] = useApiState(getEnums);
    const onStockUpdated = useCallback((/** @type {import('./api/stock').StockInfo} */stock) => {
        setOrigData(d => {
            const idx = d.findIndex(item => item.code === stock.code);
            if (idx < 0) return d;
            const n = [...d];
            n[idx] = stock;
            return n;
        });
    }, []);
    const [commentDialog, setCommentDialog] = useState({
        commentId: 0,
        comment: /** @type {import("./api/comment").Comment} */(null),
        show: false
    });
    const [commentLoaded,, commentError] = useApiState(getCommentByIdOpt, commentDialog.commentId);
    useEffect(() => {
        if (commentLoaded?.id == commentDialog.commentId && !commentDialog.comment) {
            setCommentDialog(cd => ({ ...cd, comment: commentLoaded }));
        }
    }, [commentLoaded, commentDialog]);
    useErrorMessage(commentError);
    const columns = useMemo(() => {
        /** @type {import('./Table').ColumnOption<ShortTableRow>[]} */
        const columns = [{
                field: 'code',
                label: 'Code',
                model: 'string',
                headerClassName: classes.left,
                cellClassName: classes.left,
                render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code}</Link>,
                width: 80
            }, 
            {
                field: 'name',
                label: 'Name',
                model: 'string',
                headerClassName: classes.left,
                cellClassName: classes.left,
                render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.name}</Link>,
                width: 80
            },
            {
                field: 'shortable',
                label: 'Shortable',
                model: 'string',
                width: 100,
                render: row => (
                    <a 
                        className={classes.clickable} 
                        onClick={() => setCommentDialog({ commentId: row.latest_Comment_ID, show: true, comment: null })}
                    >
                        {row.shortable}
                    </a>
                ),
            },
            {
                field: 'coverer_name',
                label: '研究员',
                model: 'enum',
                width: 80,
                render: row => row.coverer_name.join("/")
            },
            {
                field: 'price_Close',
                label: '现价',
                model: 'number',
                width: 80,
            },
            {
                field: 'industrial_Chain',
                label: FieldNames.industrial_Chain,
                model: 'enum',
            },
            {
                field: 'sw1',
                label: FieldNames.sw1,
                model: 'enum',
            },
            {
                field: 'sw2',
                label: FieldNames.sw2,
                model: 'enum',
            }, 
            {
                field: 'sw3',
                label: FieldNames.sw3,
                model: 'enum',
            },
            {
                field: 'quality_Score',
                label: FieldNames.quality_Score,
                model: 'number',
                width: 80,
            }, 
            {
                field: 'trend_Score',
                label: '动能',
                model: 'number',
                width: 80,
            },
            {
                field: 'pricing_Score',
                label: '估值',
                model: 'number',
                width: 80,
            },
            {
                field: 'external_Power',
                label: '外',
                model: 'number',
                width: 80,
            },
            {
                field: 'pt_Score',
                label: 'Pt',
                model: 'number',
                width: 80,
            },
            {
                field: 'last_RSI',
                label: 'RSI',
                model: 'number',
                width: 80,
            },
            {
                field: 'ft_Score',
                label: 'Ft',
                model: 'number',
                width: 80,
            },
            {
                field: 'cycle_Causation',
                label: FieldNames.cycle_Causation,
                model: 'enum',
                render: row => row.cycle_Causation.join("/")
            }, 
            {
                field: 'cycle_Position',
                label: FieldNames.cycle_Position,
                model: 'enum',
                width: 100,
            },
        ];
        return columns;
    }, [classes]);
    // const [data, setData] = useState(exampleData);
    const [users] = useApiState(getUsers);
    
    const data = useMemo(() => {
        if (!origData) return [];
        return origData.map(row => {
            /** @type {ShortTableRow} */
            const r = {
                code: row.code,
                name: row.name,
                shortable: row.shortable_Total_Shares ? `$${bns(div(row.shortable_Total_USD, '1000000'),1)}mn/${bns(row.shortable_Weighted_Rate)}` : ['否', '是', '否'][row.shortable_Or_Default],
                coverer_name: row.coverer?.map(c => users?.find(u => u.user_ID === c)?.display_Name).filter(Boolean),
                price_Close: bns(row.price_Close, 2),
                industrial_Chain: enums.industrialChainList?.find(ic => ic.industrial_Chain_ID === row.industrial_Chain_ID)?.industrial_Chain_Name,
                sw1: enums.sw1List?.find(i => i.sw1_ID === row.sw1_ID)?.sw1_Name,
                sw2: enums.sw2List?.find(i => i.sw2_ID === row.sw2_ID)?.sw2_Name,
                sw3: enums.sw3List?.find(i => i.sw3_ID === row.sw3_ID)?.sw3_Name,
                quality_Score: bnsMax(row.quality_Score, 1),
                trend_Score: bnsMax(row.trend_Score, 1),
                pricing_Score: bnsMax(row.pricing_Score_User, 1),
                external_Power: bns(row.external_Power, 1),
                pt_Score: bns(row.pt_Score, 0),
                last_RSI: bns(row.last_RSI),
                ft_Score: bns(row.ft_Score, 0),
                cycle_Causation: enums.cycleCausationFlagList.filter(i => (row.cycle_Causation & i.cycle_Causation_Flag) != 0)
                                      .map(i => i.cycle_Causation_Name),
                cycle_Position: CyclePositionLabels[row.cycle_Position],
                latest_Comment_ID: row.latest_Comment_ID,                
            };
            return r;
        });
    }, [origData, users, enums]);
    // const { data } = getCallData();
    const [sorterAndFilters, setSorterAndFilters] = useState(initSorterAndFilters);
    const [visibleColOrder, setVisibleColOrder] = useGlobal('CallTable_VisibleColOrder', initVisibleColumns);
    const [exportInProgress, setExportInProgress] = useState(false);
    const exportExcel = useCoCallback(function*(){
        try {
            setExportInProgress(true);
            const q = quarterNumOfToday() - 1;
            const qs = quarterFromDays2000(quarterNumToDayNum(q));
            console.log("quarterNumToDayNum(q)", quarterNumToDayNum(q), "qs", qs);
            const filename = `Short表_${moment().format("DDMMMYY")}`;
            yield createExcel(data, columns, visibleColOrder, sorterAndFilters, {
                code: 'string',
                name: 'string',
                shortable: 'string',
                coverer_name: 'string',
                price_Close: 'string',
                industrial_Chain: 'string',
                sw1: 'string',
                sw2: 'string',
                sw3: 'string',
                quality_Score: 'string',
                trend_Score: 'string',
                pricing_Score: 'string',
                external_Power: 'string',
                pt_Score: 'string',
                last_RSI: 'string',
                ft_Score: 'string',
                cycle_Causation: 'string',
                cycle_Position: 'string',
                latest_Comment_ID: 'number',
            }, filename, "Short表");
        } catch (e) {
            console.warn(e);
            enqueueSnackbar('生成Excel文件出错');
        } finally {
            setExportInProgress(false);
        }
    }, [data, columns, visibleColOrder, sorterAndFilters]);

    if (!data) return null;
    return <div style={{padding: 20, paddingBottom: 10, flex: 1, alignSelf: 'stretch', display: 'flex', flexDirection: 'column'}}>
        <Table
            dataKey='code'
            variant='full'
            data={data}
            columns={columns}
            sorterAndFilters={sorterAndFilters}
            onSorterAndFiltersChange={setSorterAndFilters}
            visibleColumnOrder={visibleColOrder}
            onVisibleColumnOrderChange={setVisibleColOrder}
        />
        <div style={{height:50}}>
            <Fab
                size="small" 
                color="primary" 
                style={{ float:'right', marginTop: 10 }}
                disabled={exportInProgress}
                onClick={exportExcel}
            >
                { exportInProgress ? <CircularProgress color="inherit" size={20} /> : <GetAppRounded /> }
            </Fab>
        </div>
        <SetShortWishlistDialog
            key={setWishlishDialogCode}
            open={setWishlishDialogOpen}
            onClose={() => setSetWishlistDialogState(s => ({ ...s, open: false }))}
            code={setWishlishDialogCode}
            name={setWishlishDialogName}
            initialShortWishlist={shortWishlist}
            onStockUpdated={onStockUpdated}
        />
        {commentDialog.commentId != 0 && !commentDialog.comment ? (
            <Dialog 
                open={commentDialog.show} 
                onClose={() => setCommentDialog(cd => ({...cd, show: false}))}
                classes={{ paper: classes.paper }}>
                <div style={{margin:"20px auto"}}>
                    <CircularProgress color="primary" />
                </div>
                <DialogActions style={{position: 'sticky', left: 0, right: 0, bottom: 0, background: '#424242', zIndex: 1 }}>
                    <div style={{flex:1}}></div>
                    <Button onClick={() => setCommentDialog(cd => ({...cd, show: false}))}>
                        关闭
                    </Button>
                </DialogActions>
            </Dialog>
        ) : null}
        {commentDialog.commentId != 0 && commentDialog.comment ? (
            <CommentDialog
                key={commentDialog.commentId}
                show={commentDialog.show}
                onClose={() => setCommentDialog(cd => ({...cd, show: false}))}
                comment={commentDialog.comment}
                changeStatusDisabled
                editDisabled
                closeWithBackdropClickAndEscapeKeyEnabled
            />
        ) : null}
    </div>
}

const ShortWishlistOptions = OptionsFromLabels(ShortWishlistLabels, false);

/**
 * 
 * @param {{
 *  open: boolean,
 *  code: string,
 *  name: string,
 *  initialShortWishlist: number,
 *  onClose: () => void,
 *  onStockUpdated: (stock: import('./api/stock').StockInfo) => void,
 * }} param0 
 * @returns 
 */
function SetShortWishlistDialog({ open, code, name, initialShortWishlist, onClose, onStockUpdated }) {
    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState(() => ShortWishlistOptions.find(opt => opt.id === initialShortWishlist));
    const { enqueueSnackbar } = useSnackbar();
    const onSubmit = useCoCallback(function*(){
        try {
            setSubmitting(true);
            const r = yield updateStockInfo(code, {
                short_Wishlist: `${value.id}`
            });
            enqueueSnackbar("更新Short类型成功", { variant: 'success' });
            setSubmitting(false);
            onStockUpdated(r);
            onClose();
        } catch (e) {
            console.warn(e);
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, [code, value]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle style={{paddingBottom: 0}}>设置Short类型</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle2" style={{marginBottom: 16}}>{name}({code})</Typography>
                <SearchableSelect
                    disabled={submitting}
                    options={ShortWishlistOptions} 
                    label="Short类型" 
                    style={{width:300}} 
                    value={value}
                    onChange={setValue}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={submitting}>
                    取消
                </Button>
                <Button onClick={onSubmit} disabled={submitting} color="primary">
                    保存
                </Button>
            </DialogActions>
        </Dialog>
    )
}