import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { compare } from 'pinyin';
import React, { useEffect, useMemo, useState } from 'react';
import { useApiState } from '../api/common';
import { updateStockCoverer } from '../api/stock';
import { getUsers } from '../api/user';
import { useCoCallback } from '../lib/useCo';
import useErrorMessage from '../lib/useErrorMessage';
import SearchableSelectMulti from './SearchableSelectMulti';

/**
 * @param {{
 *   code: string,
 *   open: boolean,
 *   onClose: () => void,
 *   onReloadStock: () => void,
 *   initialCovererIds: number[],
 *   initialCovererNames: string[],
 * }} param0 
 * @returns 
 */
export default function CovererDialog({ code, open, onClose, onReloadStock, initialCovererIds, initialCovererNames }) {
    const [users = [], loading, error] = useApiState(getAllUsersIfOpened, open);
    useErrorMessage(error);
    const [selectedUsers, setSelectedUsers] = useState(/** @type {{ id:number, name:string }[]} */([]));
    useEffect(()=>{
        if (!open) return;
        if (initialCovererIds?.length) {
            setSelectedUsers(initialCovererIds.map((id, i) => ({ id, name: initialCovererNames[i] })));
        } else {
            setSelectedUsers([]);
        }
    }, [open]);
    const options = useMemo(() => {
        if (!open) return [];
        if (loading) {
            return [{
                id: 0,
                name: '正在加载',
                disabled: true,
            }];
        }
        if (error) {
            return [{
                id: 0,
                name: '加载用户列表出错',
                disabled: true,
            }];
        }
        const list = users.map(u => ({
            id: u.user_ID,
            name: u.display_Name,
            disabled: false
        }));
        list.sort((a,b) => compare(`${a.name}`.toLowerCase(), `${b.name}`.toLowerCase()));
        return list;
    }, [open, users, loading, error]);
    const { enqueueSnackbar } = useSnackbar();
    const [submitting, setSubmitting] = useState(false);
    const onSubmit = useCoCallback(function*(isCancelled, /** @type {string} */code, /** @type {number[]} */userIds){
        if (!userIds?.length) {
            userIds = [0];
        }
        try {
            setSubmitting(true);
            yield updateStockCoverer(code, userIds);
            setSubmitting(false);
            enqueueSnackbar('更新负责人成功', { variant: 'success' });
            onReloadStock();
            onClose();
        } catch (e) {
            console.warn(e);
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, []);
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>修改负责人</DialogTitle>
            <DialogContent>
                <SearchableSelectMulti 
                    options={options} label="负责人" style={{width:300}} 
                    value={selectedUsers}
                    onChange={setSelectedUsers}
                    renderTags={(value, getTagProps) => {
                        const r = [];
                        const visibleUsers = value.filter(user => users.find(u => u.user_ID === user.id));
                        visibleUsers.forEach((option, index) => {
                            const { onDelete } = getTagProps({ index });
                            r.push(
                                <span key={`v-${index}`} className={classes.covererTag} onClick={onDelete}>
                                    {option.name}
                                </span>
                            );
                            r.push(
                                <span key={`s-${index}`}>/</span>
                            );
                        });
                        return r;
                        // value.map((option, index) => (
                        //     <Chip size="small" label={option.name} {...getTagProps({ index })} style={{background:'transparent'}} />
                        // ))
                    }}
                />
                <Typography variant="body2" style={{marginTop: 8}}>点击负责人名字可以移除此人</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={submitting}>
                    取消
                </Button>
                <Button onClick={() => onSubmit(code, selectedUsers.map(s => s.id))} color="primary"  disabled={submitting}>
                    确认
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function getAllUsersIfOpened(open) {
    if (!open) return;
    return getUsers();
}

const useStyles = makeStyles(theme => ({
    covererTag: {
        padding: '2px 4px',
        borderRadius: 4,
        cursor: 'pointer',
        '&:hover': {
            background: '#777'
        }
    }
}));
