import { Grid, makeStyles, Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Dot from './Dot';
import OverflowTooltip from './OverflowTooltip';

const useStyles = makeStyles(theme => ({
    container: {
        alignItems: 'center',
    },
    labelCol: {
        display: 'flex',
    },
    label: {
        fontSize: 12,
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    labelText: {
        whiteSpace: 'nowrap',
    },
    normal: {
        fontSize: 12,
        borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
        height: 27,
        lineHeight: '27px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    tooltip: {
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 'normal',
        padding: '12px 8px'
    },
}));

/**
 * @param {{
 *   label?: React.ReactNode,
 *   col?: number,
 *   row?: number,
 *   align?: boolean,
 *   dot?: 'none' | 'red' | 'yellow' | 'green' | 'blue' | 'grey',
 *   children?: React.ReactNode,
 *   plain?: boolean,
 *   suffix?: React.ReactNode,
 *   tooltip?: React.ReactNode
 * }} param0 
 */
export default function FieldLabel({ label = "", col = 6, row = 1, align = false, dot = "none", plain = false, children, suffix, tooltip }) {
    const classes = useStyles();
    if (align && typeof label === 'string') {
        switch (label.length) {
            case 2:
                label = `${label[0]}\u2003\u2003${label[1]}`;
                break;
            case 3:
                label = `${label[0]}\u2002${label[1]}\u2002${label[2]}`;
                break;
            default:
        }
    }
    let content = suffix ? (
        <div style={{display:'flex', flexDirection: 'row', alignItems: 'stretch'}}>
            <div style={{flex:1}}>
                { children }                
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                { suffix }
            </div>
        </div>
    ) : children;
    if (plain) {
        content = <OverflowTooltip classes={classes}>{ content }</OverflowTooltip>;
    }
    return <Grid container spacing={1} className={classes.container} style={{height:row*32}}>
        {col ? <Grid item md={col} className={classes.labelCol}>
            { tooltip ? (
                <Tooltip title={<div className={classes.tooltip}>{tooltip}</div>} placement="top-end">
                    <div className={classes.label}>
                        <div className={classes.labelText}>{label}</div>
                        <Dot color={dot} />
                    </div>
                </Tooltip>
            ) : (
                <div className={classes.label}>
                    <div className={classes.labelText}>{label}</div>
                    <Dot color={dot} />
                </div>
            )}
            
        </Grid> : null}
        <Grid item md={12-col}>
            { content }
        </Grid>
    </Grid>
}