import './theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './icon.css';
import { makeStyles, lighten, alpha, darken } from '@material-ui/core';

export default makeStyles(theme => {
    const borderColor = theme.palette.type === 'light'
    ? lighten(alpha(theme.palette.divider, 1), 0.88)
    : darken(alpha(theme.palette.divider, 1), 0.68);
    return {
        compat: {
            '& .p-datatable': {
                borderRadius: theme.shape.borderRadius,
                overflow: 'hidden',
                ['& .p-datatable-thead']: {
                    '& > tr > th': {
                        borderBottom: `1px solid ${borderColor}`,
                        background: theme.palette.grey[800],
                        textAlign: 'center',
                    },
                    '& > tr > td': {
                        borderBottom: `1px solid ${borderColor}`,
                        background: theme.palette.grey[800],
                        textAlign: 'center',
                    }
                },
                ['& .p-datatable-tbody']: {
                    '& > tr > th': {
                        borderBottom: `1px solid ${borderColor}`,
                        background: theme.palette.grey[800],
                        textAlign: 'center',
                    },
                    '& > tr > td': {
                        borderBottom: `1px solid ${borderColor}`,
                        background: theme.palette.grey[800],
                        textAlign: 'center',
                    }
                },
                ['& .p-datatable-tfoot']: {
                    '& > tr > th': {
                        background: theme.palette.grey[800],
                        textAlign: 'center',
                    },
                    '& > tr > td': {
                        background: theme.palette.grey[800],
                        textAlign: 'center',
                    }
                },
                ['&.p-datatable-sm .p-datatable-tbody']: {
                    '& > tr > th': {
                        padding: '3px 6px',
                        lineHeight: '26px',
                        whiteSpace: 'nowrap',
                        height: 35,
                        boxSizing: 'border-box'
                    },
                    '& > tr > td': {
                        padding: '3px 6px',
                        lineHeight: '26px',
                        whiteSpace: 'nowrap',
                        height: 35,
                        boxSizing: 'border-box'
                    }
                },
                ['& .p-sortable-column.p-highlight']: {
                    background: theme.palette.grey[700]
                },
                ['& .p-sortable-column:not(.p-highlight):hover']: {
                    background: theme.palette.grey[700]
                },
                ['& .p-datatable-scrollable-body']: {
                    ['&::-webkit-scrollbar']: {
                        width: 17,
                    },
                    ['&::-webkit-scrollbar-track']: {
                        backgroundColor: darken(theme.palette.grey[800], 0.1),
                    },
                    ['&::-webkit-scrollbar-button']: {
                        width: 0,
                        height: 0
                    },
                    ['&::-webkit-scrollbar-thumb']: {
                        backgroundColor: theme.palette.grey[700],
                        border: '0px none #ffffff',
                        borderRadius: theme.shape.borderRadius,
                    },
                    ['&::-webkit-scrollbar-thumb:hover']: {
                        backgroundColor: theme.palette.primary[800],
                    },
                    ['&::-webkit-scrollbar-corner']: {
                        backgroundColor: 'rgba(0,0,0,0)',
                    },
                },
                ['& .p-datatable-scrollable-header']: {
                    background: darken(theme.palette.grey[800], 0.1),
                },
                ['& .p-datatable-scrollable-footer']: {
                    background: darken(theme.palette.grey[800], 0.1),
                },
                ['& .p-datatable-thead > tr > th.p-filter-column']: {
                    padding: 0,
                },
                ['& .p-filter-column .p-chips']: {
                    background: theme.palette.grey[800],
                    width: '100%',
                    position: 'relative',
                    overflow: 'visible'
                },
                ['& .p-filter-column .p-chips .p-inputtext']: {
                    background: theme.palette.grey[800],
                    padding: '3px 1px',
                    width: '100%',
                    // borderColor: theme.palette.grey[600],
                    borderRadius: 0,
                    border: 'none'
                },
                ['& .p-filter-column .p-chips .p-button']: {
                    borderRadius: 0,
                    border: 'none',
                    padding: 3,
                },
                ['& .p-filter-column .p-chips .p-chips-token']: {
                    padding: '3px 2px'
                },
                ['& .p-filter-column .p-chips .p-chips-multiple-container .p-chips-input-token']: {
                    padding: '5px 5px'
                },
                ['& .p-filter-column .p-chips .p-chips-multiple-container .p-chips-token']: {
                    color: theme.palette.primary.light
                },
                ['& .p-filter-column .p-chips .p-button.p-button-outlined']: {
                    color: theme.palette.primary.light
                },
                ['& .p-filter-column .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus']: {
                    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.light}, inset 0 0 0 1px ${theme.palette.primary.light}, inset 0 0 0 1px ${theme.palette.primary.light}, inset 0 0 0 1px ${theme.palette.primary.light}`
                },
                '& a': {
                    color: 'inherit',
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    }
                },
            }
        },
        full: {
            '& .p-datatable': {
                '&.p-datatable-sm': {
                    borderRadius: theme.shape.borderRadius,
                    overflow: 'hidden',
                    '& .p-datatable-thead': {
                        '& > tr > th': {
                            borderBottom: `1px solid ${borderColor}`,
                            background: theme.palette.grey[800],
                            padding: '30px 0 0',
                            position: 'relative',
                            textAlign: 'center',
                            '& .p-column-title': {
                                display: 'block',
                                padding: '3px 6px',
                            },
                            '& .p-sortable-column-icon': {
                                top: 0,
                                left: 0,
                                right: 0,
                                width: '100%',
                                height: 30,
                                padding: '7px 3px',
                                borderBottom: `1px solid ${borderColor}`,
                                display: 'block',
                                position: 'absolute',
                                textAlign: 'inherit',
                            }
                        },
                        '& > tr > td': {
                            borderBottom: `1px solid ${borderColor}`,
                            background: theme.palette.grey[800],
                            textAlign: 'center',
                        }
                    },
                    '& .p-datatable-tbody': {
                        '& > tr > th': {
                            borderBottom: `1px solid ${borderColor}`,
                            background: theme.palette.grey[800],
                            textAlign: 'center',
                        },
                        '& > tr > td': {
                            borderBottom: `1px solid ${borderColor}`,
                            background: theme.palette.grey[800],
                            textAlign: 'center',
                        }
                    },
                    '& .p-datatable-tfoot': {
                        '& > tr > th': {
                            background: theme.palette.grey[800],
                            textAlign: 'center',
                        },
                        '& > tr > td': {
                            background: theme.palette.grey[800],
                            textAlign: 'center',
                        }
                    },
                    '&.p-datatable-sm .p-datatable-tbody': {
                        '& > tr > th': {
                            padding: '3px 6px',
                            lineHeight: '26px',
                            whiteSpace: 'nowrap',
                            height: 35,
                            boxSizing: 'border-box'
                        },
                        '& > tr > td': {
                            padding: '3px 6px',
                            lineHeight: '26px',
                            whiteSpace: 'nowrap',
                            height: 35,
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }
                    },
                    '& .p-sortable-column.p-highlight': {
                        background: theme.palette.grey[700]
                    },
                    '& .p-sortable-column:not(.p-highlight):hover': {
                        background: theme.palette.grey[700]
                    },
                    '& .p-datatable-scrollable-body': {
                        ['&::-webkit-scrollbar']: {
                            width: 17,
                        },
                        ['&::-webkit-scrollbar-track']: {
                            background: darken(theme.palette.grey[800], 0.1),
                        },
                        ['&::-webkit-scrollbar-button']: {
                            width: 0,
                            height: 0
                        },
                        ['&::-webkit-scrollbar-thumb']: {
                            background: theme.palette.grey[700],
                            border: '0px none #ffffff',
                            borderRadius: theme.shape.borderRadius,
                        },
                        ['&::-webkit-scrollbar-thumb:hover']: {
                            background: theme.palette.primary[800],
                        }
                    },
                    ['& .p-datatable-scrollable-header']: {
                        background: darken(theme.palette.grey[800], 0.1),
                    },
                    ['& .p-datatable-scrollable-footer']: {
                        background: darken(theme.palette.grey[800], 0.1),
                    },
                    ['& .p-datatable-thead > tr > th.p-filter-column']: {
                        padding: 0,
                    },
                    ['& .p-filter-column .p-chips']: {
                        background: theme.palette.grey[800],
                        width: '100%',
                        position: 'relative',
                        overflow: 'visible'
                    },
                    ['& .p-filter-column .p-chips .p-inputtext']: {
                        background: theme.palette.grey[800],
                        padding: '3px 1px',
                        width: '100%',
                        // borderColor: theme.palette.grey[600],
                        borderRadius: 0,
                        border: 'none'
                    },
                    ['& .p-filter-column .p-chips .p-button']: {
                        borderRadius: 0,
                        border: 'none',
                        padding: 3,
                    },
                    ['& .p-filter-column .p-chips .p-chips-token']: {
                        padding: '3px 2px'
                    },
                    ['& .p-filter-column .p-chips .p-chips-multiple-container .p-chips-input-token']: {
                        padding: '5px 5px'
                    },
                    ['& .p-filter-column .p-chips .p-chips-multiple-container .p-chips-token']: {
                        color: theme.palette.primary.light
                    },
                    ['& .p-filter-column .p-chips .p-button.p-button-outlined']: {
                        color: theme.palette.primary.light
                    },
                    ['& .p-filter-column .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus']: {
                        boxShadow: `inset 0 0 0 1px ${theme.palette.primary.light}, inset 0 0 0 1px ${theme.palette.primary.light}, inset 0 0 0 1px ${theme.palette.primary.light}, inset 0 0 0 1px ${theme.palette.primary.light}`
                    },
                    '& a': {
                        color: 'inherit',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline',
                        }
                    },
                }
            }
        }
        
    }
});