import { bn } from '../lib/bn';
import { get, post } from './common';

/** @typedef {import('js-big-decimal')} BigDecimal */

/**
 * @template {{}} T
 * @typedef {{
 *  id: number,
 *  date: number,
 *  total: number,
 *  count: number,
 *  avg_Risk_Pct: BigDecimal,
 *  avg_Reward_Pct: BigDecimal,
 *  avg_Chg_Pct_1D: BigDecimal,
 *  avg_Chg_Pct_1W: BigDecimal,
 *  avg_Chg_Pct_1M: BigDecimal,
 *  cnt_FT_Score_Pos: number,
 *  cnt_FT_Score_Neg: number,
 *  cnt_PT_Score_Pos: number,
 *  cnt_PT_Score_Neg: number,
 *  cnt_Price_Up: number,
 *  cnt_Price_Down: number,
 *  avg_Expected_CAGR: BigDecimal,
 *  avg_EPS_YOY_Consensus_T0: BigDecimal,
 *  avg_EPS_YOY_Consensus_T1: BigDecimal,
 *  avg_EPS_PE_Consensus_T0: BigDecimal,
 *  avg_EPS_PE_Consensus_T1: BigDecimal,
 * } & T} AggrFields
 */

/** 
 * @typedef { AggrFields<{ cluster_Layer_Value: number, cluster_Layer: number }>} AggrClusterLayer
 */

/** 
 * @typedef { AggrFields<{ quality_Score: BigDecimal }>} AggrQualityScore
 */


/**
 * @template {{}} T
 * @param {any[]} data 
 * @param {(key:any) => T} keyParser 
 */
function parseAggrFields(data, keyParser) {
    const key = keyParser(data[2]);
    return {
        id: Number(data[0]),
        date: Number(data[1]),
        total: Number(data[3]),
        count: Number(data[4]),
        avg_Risk_Pct: bn(data[5]),
        avg_Reward_Pct: bn(data[6]),
        avg_Chg_Pct_1D: bn(data[7]),
        avg_Chg_Pct_1W: bn(data[8]),
        avg_Chg_Pct_1M: bn(data[9]),
        cnt_FT_Score_Pos: Number(data[10]),
        cnt_FT_Score_Neg: Number(data[11]),
        cnt_PT_Score_Pos: Number(data[12]),
        cnt_PT_Score_Neg: Number(data[13]),
        cnt_Price_Up: Number(data[14]),
        cnt_Price_Down: Number(data[15]),
        avg_Expected_CAGR: bn(data[16]),
        avg_EPS_YOY_Consensus_T0: bn(data[17]),
        avg_EPS_YOY_Consensus_T1: bn(data[18]),
        avg_EPS_PE_Consensus_T0: bn(data[19]),
        avg_EPS_PE_Consensus_T1: bn(data[20]),
        ...key
    };
}

/** 
 * @returns { AggrClusterLayer }
 */
function parseAggrClusterLayer(data) {
    return parseAggrFields(data, k => ({
        cluster_Layer_Value: Number(k.cluster_Layer_Value),
        cluster_Layer: Number(k.cluster_Layer)
    }));
}

/** 
 * @returns { AggrQualityScore }
 */
function parseAggrQualityScore(data) {
    return parseAggrFields(data, k => ({
        quality_Score: bn(k.quality_Score)
    }));
}

export async function getAggrClusterLayer(date) {
    /** @type { any[] } */
    const data = await get('/api/aggr/cluster-layer', { date });
    return data.map(parseAggrClusterLayer);
}

export async function getAggrQualityScore(date) {
    /** @type { any[] } */
    const data = await get('/api/aggr/quality-score', { date });
    return data.map(parseAggrQualityScore);
}

export async function updateDateAggr(date) {
    return await post("/api/aggr/update", { date });
}
