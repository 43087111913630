import { CircularProgress, Fab, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useApiState, withReload } from '../api/common';
import { commitTicketFill, getTicketById, getTicketFill, updateTicketFill } from '../api/ticket';
import { getUserInfoById } from '../api/user';
import { bn, bns, div, isValid, lt, mul } from '../lib/bn';
import { date2quarterOrSemi, fromDays2000 } from '../lib/date';
import useErrorMessage from '../lib/useErrorMessage';
import useAuth from '../api/auth';
import { ExternalLabels, FieldNames, PowerLabels, ShortableLabels, ShortWishlistLabels, StatusLabels, TrendStageLabels, TrendSustainabilityLabels } from '../lib/enums';
import bigDecimal from 'js-big-decimal';
import { CheckRounded, SaveRounded } from '@material-ui/icons';
import { useCoCallback } from '../lib/useCo';
import { useSnackbar } from 'notistack';
import FieldLabel from '../components/FieldLabel';
import SelectField from '../SingleStock/SelectField';
import SmallCheckbox from '../components/SmallCheckbox';
import BigNumberInput from '../SingleStock/BigNumberInput';
import IntegerInput from '../SingleStock/IntegerInput';
import RichText, { toHtml } from '../components/RichText';
import SelectOptionField from '../SingleStock/SelectOptionField';
import RequirePermission from '../components/RequirePermission';
import SmallRadioBox from '../components/SmallRadioBox';


const cols = 14;

const useStyle = makeStyles({
    paper: {
        marginBottom: 10,
        padding: 10,
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    td: {
        width: `${100/cols}%`
    },
    td2: {
        width: `${2*100/cols}%`
    },
    td10: {
        width: `${10*100/cols}%`
    },
    td12: {
        width: `${12*100/cols}%`
    },
    td14: {
        width: `${14*100/cols}%`
    },
    link: {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

const DelayOptions = ['0','1','2','3','4','5','6','7','8','9','10'];
const TrendWidthLabels = ['-', '行业', '公司', '产业'];

const QualityScoreOptions = [
    // { id: "-", name: "-" },
    { id: "1.5", name: "1.5" },
    { id: "1.0", name: "1.0" },
    { id: "0.5", name: "0.5" },
    { id: "0.0", name: "0.0" },
    { id: "-0.5", name: "-0.5" },
    { id: "-1.0", name: "-1.0" },
];

const TrendScoreOptions = [
    // { id: "-", name: "-" },
    { id: "1.0", name: "1.0" },
    { id: "0.5", name: "0.5" },
    { id: "0.0", name: "0.0" },
    { id: "-0.5", name: "-0.5" },
    { id: "-1.0", name: "-1.0" },
];

const PricingScoreOptions = [
    // { id: "-", name: "-" },
    { id: "1.0", name: "1.0" },
    { id: "0.5", name: "0.5" },
    { id: "0.0", name: "0.0" },
    { id: "-0.5", name: "-0.5" },
    { id: "-1.0", name: "-1.0" },
];

const getTicketFillReloadable = withReload(getTicketFill);

export default function TicketView() {
    const params = /** @type {{ ticketId: string, userId: string }} */(useParams());
    const ticketId = Number(params.ticketId);
    const userId = Number(params.userId);
    
    const [loginUser] = useAuth();
    const [reload, setReload] = useState(0);
    const [ticketFill, loading, error] = useApiState(getTicketFillReloadable, ticketId, userId, reload);
    const [ticket, ticketLoading, ticketError] = useApiState(getTicketById, ticketFill?.ticket_ID);
    const [user] = useApiState(getUserInfoById, ticketFill?.user_ID);

    const [editFields, setEditFields] = useState(/** @type {Partial<import('../api/ticket').TicketFill>} */({}));
    
    /**
     * @template { keyof import('../api/ticket').TicketFill } T
     * @param { T } field 
     * @param { import('../api/ticket').TicketFill[T] } value 
     */
    function edit(field, value) {
        setEditFields(fields => ({ ...fields, [field]: value }));
    }

    const merged = { ...ticketFill, ...editFields };

    const [saving, setSaving] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const save = useCoCallback(
        function*(isCancelled,
            /** @type {import('../api/ticket').TicketFill} */ ticketFill,
            /** @type {Partial<import('../api/ticket').TicketFill>} */ editFields,
            /** 
             * @type {{
             *   valuation_Price_Multiple_Low: bigDecimal;
             *   valuation_Price_Multiple_High: bigDecimal;
             *   expected_Number: bigDecimal;
             *   valuation_Price_Low: bigDecimal;
             *   valuation_Price_High: bigDecimal;
             *   pricing_Score: string 
             * }} 
             */ defaults
        ){
            try {
                const merged = { ...ticketFill, ...editFields };
                const requiredMissing = [];
                if (!merged.status) { requiredMissing.push('多'); }
                if (merged.logic == 1 && !merged.trend_Width) { requiredMissing.push('广度'); }
                if (merged.logic == 1 && !merged.trend_Sustainability) { requiredMissing.push('持续性'); }
                if (merged.logic == 1 && !merged.trend_Stability) { requiredMissing.push('稳定性'); }
                if (merged.logic == 1 && !merged.trend_Stage) { requiredMissing.push('阶段'); }
                if (merged.logic == 1 && !merged.num_Q) { requiredMissing.push('持续'); }

                if (requiredMissing.length > 0) {
                    throw new Error(`缺少必填字段：${requiredMissing.join("、")}`);
                }
                setSaving(true);
                yield updateTicketFill(ticketFill.ticket_ID, ticketFill.user_ID, editFields);
                enqueueSnackbar('保存成功', { variant: 'success' });
                setSaving(false);
                setReload(r => r+1);
                setEditFields({});
            } catch (e) {
                setSaving(false);
                console.warn(e);
                enqueueSnackbar(e.message);
            }
        }, 
        []
    );

    const commit = useCoCallback(function*(isCancelled, ticketFillId, editFields) {
        try {
            if (Object.keys(editFields).length > 0) {
                throw new Error('当前Ticket尚未保存');
            }
            if (!ticketFillId) {
                throw new Error('当前Ticket尚未保存');
            }
            setSaving(true);
            const ticketCommentId = yield commitTicketFill(ticketFillId);
            enqueueSnackbar('保存成功', { variant: 'success' });
            setSaving(false);
            history.replace(`/user-table/ticket/comment/${ticketCommentId}`);
        } catch (e) {
            setSaving(false);
            console.warn(e);
            enqueueSnackbar(e.message);
        }
    }, []);

    useErrorMessage(error || ticketError);

    const classes = useStyle();

    const disabled = loginUser.userId != ticketFill?.user_ID;
    const delayVisible = loginUser.userId == ticketFill?.user_ID || loginUser.permissions.indexOf('editNonCoveredStock') >= 0;
    const delay = (merged?.public_Date - ticket?.report_Date) || 0;
    const logic = merged.logic === 1;

    const defaults = useMemo(() => {
        const merged = { ...ticketFill, ...editFields };
        const valuation_Price_Multiple_Low = merged.expected_CAGR_Bottomline || ticket?.expected_CAGR_Bottomline;
        const valuation_Price_Multiple_High = merged.expected_CAGR_Topline || ticket?.expected_CAGR_Topline;
        const expected_Number = ticket?.eps_Consensus_T1;
        const valuation_Price_Low = mul(
            merged.valuation_Price_Multiple_Low || valuation_Price_Multiple_Low,
            merged.expected_Number || expected_Number
        );
        const valuation_Price_High = mul(
            merged.valuation_Price_Multiple_High || valuation_Price_Multiple_High,
            merged.expected_Number || expected_Number
        );
    
        let pricing_Score;
        if (
            !isValid(merged.valuation_Price_Low || valuation_Price_Low) ||
            !isValid(merged.valuation_Price_High || valuation_Price_High)
        ) {
            pricing_Score = undefined;
        } else if (lt(ticket?.price_Close, merged.valuation_Price_Low || valuation_Price_Low)) {
            pricing_Score = "1.0";
        } else if (lt(ticket?.price_Close, merged.valuation_Price_High || valuation_Price_High)) {
            pricing_Score = "0.5";
        } else {
            pricing_Score = '0.0';
        }
    
        return {
            valuation_Price_Multiple_Low,
            valuation_Price_Multiple_High,
            expected_Number,
            valuation_Price_Low,
            valuation_Price_High,
            pricing_Score,
        }
    }, [ticket, ticketFill, editFields]);

    const read_Next_FS_Or_Default = merged.read_Next_FS || ticket?.read_Next_FS;
    const alert_Before_Next_FS_Or_Default = merged.alert_Before_Next_FS || ticket?.alert_Before_Next_FS;
        
    const FtTypeLabels = useMemo(() => {
        if (!ticket || !isValid(ticket.ft_Score)) {
            return ['- (非首尾)', '1 (首日向上)', '-1 (首日向下)', '0 (尾日)'];
        } else {
            return [bns(ticket.ft_Score, 0)+' (非首尾)', '1 (首日向上)', '-1 (首日向下)', '0 (尾日)'];
        }
    }, [ticket]);

    if (loading || ticketLoading || !ticket) {
        return <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
            <CircularProgress color="primary" />
        </div>;
    }
    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Paper className={classes.paper}>
            <table className={classes.table}>
                <tbody>
                    <tr>
                        <td className={classes.td}>
                            <Link to={`/stock/${ticket?.code}`} target="_blank" className={classes.link}>
                                {ticket?.code}
                            </Link>
                        </td>
                        <td className={classes.td}>
                            <Link to={`/stock/${ticket?.code}`} target="_blank" className={classes.link}>
                                {ticket?.name}
                            </Link>
                        </td>
                        <td className={classes.td}>
                            {date2quarterOrSemi(ticket?.quarter_Or_Semi, ticket?.rpt_Period)}
                            {['', '预', '快', '财'][ticket?.type]}
                        </td>
                        <td className={classes.td}>{fromDays2000(ticket?.report_Date)?.format('YYYY-MM-DD')}</td>
                        <td className={classes.td}>{user?.display_Name}</td>
                        <td className={classes.td}></td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="收盘价"
                                dot="yellow"
                                plain
                            >
                                {bns(ticket?.price_Close)}
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="市值"
                                dot="yellow"
                                plain
                            >
                                {ticket?.currency}{bns(ticket?.marketValue?.divide(new bigDecimal(100), 2))}亿
                            </FieldLabel>
                        </td>
                        <td className={classes.td}></td>
                        <td className={classes.td}></td>
                        <td className={classes.td2} colSpan={2}>
                            { delayVisible ? (
                                <FieldLabel label="延迟" dot="green">
                                    <SelectField
                                        edit={!disabled}
                                        value={delay}
                                        onChange={(delay) => ticket && edit('public_Date', ticket.report_Date + delay )}
                                        options={DelayOptions}
                                    />
                                </FieldLabel>
                            ) : null}
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="当季营收"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="同"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="环"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="单季归母"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="同"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="环"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="累计营收"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="同"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="环"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="累计归母"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="同"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="环"
                                dot="yellow"
                                plain
                            >
                                
                            </FieldLabel>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Paper>
        <Paper className={classes.paper} style={{marginBottom: 60}}>
            <table className={classes.table}>
                <tbody>
                    <tr>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="逻辑" dot="green">
                                <SmallCheckbox color='primary' size='small' 
                                    disabled={disabled}
                                    checked={merged.logic === 1} 
                                    onChange={e => edit('logic', e.target.checked ? 1 : 0 )}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td12} colSpan={12}>
                            <TextOrTextField
                                placeholder='逻辑陈述'
                                value={merged.logic_Detail}
                                onChange={v => edit('logic_Detail', v)}
                                disabled={disabled}
                            />
                        </td>
                        <td colSpan={2}></td>
                    </tr>
                    <tr>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="广度" dot={ logic ? "green" : "grey" }>
                                <SelectField
                                    edit={!disabled && logic}
                                    value={logic ? merged.trend_Width : 0}
                                    onChange={(v) => edit('trend_Width', v)}
                                    options={TrendWidthLabels}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="持续性" dot={ logic ? "green" : "grey" }>
                                <SelectField
                                    edit={!disabled && logic}
                                    value={logic ? merged.trend_Sustainability : 0}
                                    onChange={(v) => edit('trend_Sustainability', v)}
                                    options={TrendSustainabilityLabels}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="稳定性" dot={ logic ? "green" : "grey" }>
                                <SelectField
                                    edit={!disabled && logic}
                                    value={logic ? merged.trend_Stability : 0}
                                    onChange={(v) => edit('trend_Stability', v)}
                                    options={TrendSustainabilityLabels}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="阶段" dot={ logic ? "green" : "grey" }>
                                <SelectField
                                    edit={!disabled && logic}
                                    value={logic ? merged.trend_Stage : 0}
                                    onChange={(v) => edit('trend_Stage', v)}
                                    options={TrendStageLabels}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="持续季数" dot={ logic ? "green" : "grey" }>
                                <IntegerInput
                                    edit={!disabled && logic}
                                    value={logic ? merged.num_Q : null}
                                    onSubmit={v => edit('num_Q', v)}
                                />
                            </FieldLabel>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="可期下" dot="blue">
                                <BigNumberInput
                                    edit={!disabled}
                                    value={merged.expected_CAGR_Bottomline}
                                    onSubmit={v => edit('expected_CAGR_Bottomline', v)}
                                    placeholder={bns(ticket.expected_CAGR_Bottomline)}
                                />
                            </FieldLabel>
                        </td>
                        <td  className={classes.td2} colSpan={2}>
                            <FieldLabel label="可期上" dot="blue">
                                <BigNumberInput
                                    edit={!disabled}
                                    value={merged.expected_CAGR_Topline}
                                    onSubmit={v => edit('expected_CAGR_Topline', v)}
                                    placeholder={bns(ticket.expected_CAGR_Topline)}
                                />
                            </FieldLabel>
                        </td>
                        <td  className={classes.td2} colSpan={2}>
                            <FieldLabel label="预期数" dot="blue">
                                <BigNumberInput
                                    edit={!disabled}
                                    value={merged.expected_Number}
                                    onSubmit={v => edit('expected_Number', v)}
                                    placeholder={bns(defaults.expected_Number)}
                                />
                            </FieldLabel>
                        </td>
                        <td  className={classes.td2} colSpan={2}>
                            <FieldLabel label="合理倍数下" dot="blue">
                                <BigNumberInput
                                    edit={!disabled}
                                    value={merged.valuation_Price_Multiple_Low}
                                    onSubmit={v => edit('valuation_Price_Multiple_Low', v)}
                                    placeholder={bns(defaults.valuation_Price_Multiple_Low)}
                                />
                            </FieldLabel>
                        </td>
                        <td  className={classes.td2} colSpan={2}>
                            <FieldLabel label="合理倍数上" dot="blue">
                                <BigNumberInput
                                    edit={!disabled}
                                    value={merged.valuation_Price_Multiple_High}
                                    onSubmit={v => edit('valuation_Price_Multiple_High', v)}
                                    placeholder={bns(defaults.valuation_Price_Multiple_High)}
                                />
                            </FieldLabel>
                        </td>
                        <td  className={classes.td2} colSpan={2}>
                            <FieldLabel label="合理下" dot="blue">
                                <BigNumberInput
                                    edit={!disabled}
                                    value={merged.valuation_Price_Low}
                                    onSubmit={v => edit('valuation_Price_Low', v)}
                                    placeholder={bns(defaults.valuation_Price_Low)}
                                />
                            </FieldLabel>
                        </td>
                        <td  className={classes.td2} colSpan={2}>
                            <FieldLabel label="合理上" dot="blue">
                                <BigNumberInput
                                    edit={!disabled}
                                    value={merged.valuation_Price_High}
                                    onSubmit={v => edit('valuation_Price_High', v)}
                                    placeholder={bns(defaults.valuation_Price_High)}
                                />
                            </FieldLabel>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={14} className={classes.td14}>
                            <TextOrRichField
                                placeholder='综合陈述'
                                value={merged.comment}
                                onChange={v => edit('comment', v)}
                                disabled={disabled}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label={FieldNames.quality_Score} dot="green">
                                <SelectOptionField
                                    edit={!disabled}
                                    value={bns(merged.quality_Score, 1)}
                                    onChange={(v) => edit('quality_Score', bn(v))}
                                    options={QualityScoreOptions}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="动能" dot="green">
                                <SelectOptionField
                                    edit={!disabled}
                                    value={bns(merged.trend_Score, 1)}
                                    onChange={(v) => edit('trend_Score', bn(v))}
                                    options={TrendScoreOptions}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="估值" dot="blue">
                                <SelectOptionField
                                    edit={!disabled}
                                    value={bns(merged.pricing_Score, 1)}
                                    onChange={(v) => edit('pricing_Score', bn(v))}
                                    options={PricingScoreOptions}
                                    defaultValue={defaults.pricing_Score}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="外" dot="green">
                                <SelectField
                                    edit={!disabled}
                                    value={merged.external}
                                    onChange={(v) => edit('external', v)}
                                    options={ExternalLabels}
                                    optionOrders={[0,3,5,2,4,1]}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="票" dot="green">
                                <SelectField
                                    edit={!disabled}
                                    value={merged.power}
                                    onChange={(v) => edit('power', v)}
                                    options={PowerLabels}
                                    optionOrders={[0,3,5,2,4,1]}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="多" dot="green">
                                <SelectField
                                    edit={!disabled}
                                    value={merged.status}
                                    onChange={(v) => edit('status', v)}
                                    options={StatusLabels}
                                    optionOrders={[1,2,4]}
                                    hideDefault
                                />
                            </FieldLabel>
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="想空" dot="green">
                                <SelectField
                                    edit={!disabled}
                                    value={merged.short_Wishlist}
                                    onChange={(v) => edit('short_Wishlist', v)}
                                    options={ShortWishlistLabels}
                                    hideDefault
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel
                                label="可空"
                                dot="yellow"
                                plain
                            >
                                { ticket?.shortable_Total_USD ? (
                                    `$${bns(div(ticket?.shortable_Total_USD, "1000000"), 1)}mn/${bns(ticket?.shortable_Weighted_Rate)}`
                                ) : (
                                    ShortableLabels[ticket?.shortable]
                                )}
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="Ft分" dot="blue">
                                <SelectField
                                    edit={!disabled}
                                    value={merged.ft_Type}
                                    onChange={(v) => edit('ft_Type', v)}
                                    options={FtTypeLabels}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label="持有看下季" dot="blue">
                                <SmallRadioBox color='primary' size='small' 
                                    disabled={disabled}
                                    checked={read_Next_FS_Or_Default === 1} 
                                    onChange={e => edit('read_Next_FS', e.target.checked ? 1 : 2 )}
                                />
                            </FieldLabel>
                        </td>
                        <td className={classes.td2} colSpan={2}>
                            <FieldLabel label={FieldNames.alert_Before_Next_FS} dot="blue">
                                <SmallRadioBox color='primary' size='small' 
                                    disabled={disabled}
                                    checked={alert_Before_Next_FS_Or_Default === 1} 
                                    onChange={e => edit('alert_Before_Next_FS', e.target.checked ? 1 : 2 )}
                                />
                            </FieldLabel>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Paper>
        { !disabled ? (
            <Fab
                size="small" 
                color="primary" 
                style={{ position: 'fixed', bottom: 10, right: 20 }} 
                onClick={() => save(ticketFill, editFields, defaults)}
                disabled={saving}
            >
                <CheckRounded />
            </Fab>
        ) : null}
        <RequirePermission required="reviewTicket">
            <Fab
                size="small" 
                color="primary" 
                style={{ position: 'fixed', bottom: 10, right: !disabled ? 80 : 20 }} 
                onClick={() => commit(ticketFill.id, editFields)}
                disabled={saving}
            >
                <SaveRounded />
            </Fab>
        </RequirePermission>
    </div>;
}

/**
 * @param {{
 *   value: string,
 *   placeholder: string,
 *   onChange: (value: string) => void,
 *   disabled: boolean
 * } } param0 
 */
function TextOrTextField({ value, onChange, disabled, placeholder }) {
    const [text, setText] = useState(value);
    useEffect(() => {
        setText(value);
    }, [value]);
    if (disabled) {
        return <div 
            className='MuiPaper-root  MuiPaper-elevation1' 
            style={{
                padding: 8,
                background: '#535353'
            }}
        >
            {value}
        </div>
    }
    return <input
        className='MuiPaper-root  MuiPaper-elevation1'
        style={{ 
            border: 'none', 
            color: 'inherit', 
            width: '100%',
            outline: 'none',
            padding: 8,
            background: '#535353'
        }}
        placeholder={placeholder}
        value={text}
        onChange={e => setText(e.target.value)}
        onBlur={() => onChange(text) }
    />
}

/**
 * @param {{
 *   value: string,
 *   placeholder: string,
 *   onChange: (value: string) => void,
 *   disabled: boolean
 * } } param0 
 */
function TextOrRichField({ value, onChange, disabled, placeholder }) {
    const [text, setText] = useState(value);

    useEffect(() => {
        if (!disabled) {
            setText(value);
        } else {
            setText(toHtml(value, 'div'))
        }
    }, [value, disabled]);
    if (disabled) {
        return <div
            style={{marginTop: 16, padding: 8, background: '#535353'}}
            className='MuiPaper-root  MuiPaper-elevation1'
            dangerouslySetInnerHTML={{ __html: text }}
        />
    }
    return <div  
        style={{marginTop: 16}}
    >
        <RichText
            variant='compat'
            disabled={disabled}
            initialValue={value}
            onChange={onChange}
            className='MuiPaper-root  MuiPaper-elevation1'
        />
    </div>
    
}
