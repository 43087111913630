import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getDailyDataNotExisted } from '../api/stock-daily-import';
import { dayNumOfToday } from '../lib/date';

/** @typedef {import('../api/stock-daily-import').StockInfoDailyResponse} StockInfoDailyResponse */
/** @typedef {import('../Table').Stringified<StockInfoDailyResponse>} StockInfoDailyImportRow */
/** @typedef {'bbg' | 'wind'} Models */

/**
 * @param {Models} model 
 * @param {number} start 
 * @param {number} end 
 * @param {boolean} includePartial
 * @param {number} reload
 * @returns {Promise<StockInfoDailyResponse[]>}
 */
export async function getDailyDataNotExistedWithReload(
    model,
    start,
    end,
    includePartial,
    reload
) {
    if (!reload) return [];
    if (start > end) return [];

    const hour = moment().hour();
    const ntoday = dayNumOfToday();
    const nyesterday = ntoday - 1;
    
    const cn_time_exchanges = {//使用中国刷新时间的交易所
        bbg: ['SZSE','HKEX','TWSE','SSE'],
        wind: ['BJEX']
    };

    const us_time_exchanges = {//使用美国刷新时间的交易所
        bbg: ['NASDAQ','NYSE','AMEX','OO'],
        wind: []
    };

    const cnlist = cn_time_exchanges[model];
    const uslist = us_time_exchanges[model];

    if (end > ntoday) { //结束时间是今天以后没有意义
        end = ntoday;
    }

    /** @type {StockInfoDailyResponse[][]} */
    const result = [];

    if (end === ntoday) {
        if (hour >= 16) {//今日A股已经收盘，今日没有美股
            result.unshift(
                await getDailyDataNotExisted(
                    cnlist,
                    ntoday,
                    ntoday, 
                    includePartial
                )
            );
        }
        end = nyesterday;
        if (start > end) {
            return result.reduce((a, b)=>[...a, ...b], []);
        }
    }

    if (end === nyesterday) {
        if (hour >= 6) {//昨日美股已经收盘，昨日数据是全的
            result.unshift(
                await getDailyDataNotExisted(
                    [...cnlist, ...uslist],
                    nyesterday,
                    nyesterday, 
                    includePartial
                )
            )
        } else {//美股未收盘，昨日只有A股
            result.unshift(
                await getDailyDataNotExisted(
                    cnlist,
                    nyesterday,
                    nyesterday, 
                    includePartial
                )
            );
        }
        end = nyesterday - 1;
        if (start > end) {
            return result.reduce((a, b)=>[...a, ...b], []);
        }
    }

    result.unshift(
        await getDailyDataNotExisted(
            [...cnlist, ...uslist],
            start,
            end, 
            includePartial
        )
    );
    return result.reduce((a, b)=>[...a, ...b], []);
}
export const StockDailyColNames = {
    code: 'Wind Code',
    code_BBG: 'Bbg Code',
    price_Close: 'Close_T',
    price_Open: 'Open_T',
    price_Hi: 'Hi_T',
    price_Lo: 'Lo_T',
    price_Chg: 'Chg_T',
    price_52w_Hi: 'Hi-52W',
    price_52w_Lo: 'Lo-52w',
    last_RSI: 'RSI',
    macd_DIFF: 'DIF(BBG MACD)',
    macd_DEA: 'DEA(BBG SIGNAL)',
    marketValue_USD: 'MktCap US$',
    ev: 'ev',
    book_Value: 'book_Value',
    price_Vol_10D: 'price_Vol_10D',
    price_Vol_6M: 'price_Vol_6M',
    price_Vol_1Y: 'price_Vol_1Y',
    adv_1M: 'Adv-1M',
    adv_10D: 'adv_10D',
    adv_3M: 'adv_3M',
    num_Shares_T0_10K: 'num_Shares_T0_10K',
    eps_Consensus_T0: 'eps_Consensus_T0',
    eps_Consensus_T1: 'eps_Consensus_T1',
    eps_YOY_Consensus_T0: 'eps_YOY_Consensus_T0',
    eps_YOY_Consensus_T1: 'eps_YOY_Consensus_T1',
    eps_PE_Consensus_T0: 'eps_PE_Consensus_T0',
    eps_PE_Consensus_T1: 'eps_PE_Consensus_T1',
    bps_Consensus_T0: 'bps_Consensus_T0',
    bps_Consensus_T1: 'bps_Consensus_T1',
    bps_YOY_Consensus_T0: 'bps_YOY_Consensus_T0',
    bps_YOY_Consensus_T1: 'bps_YOY_Consensus_T1',
    bps_PB_Consensus_T0: 'bps_PB_Consensus_T0',
    bps_PB_Consensus_T1: 'bps_PB_Consensus_T1',
    ebitda_Consensus_T0: 'ebitda_Consensus_T0',
    ebitda_Consensus_T1: 'ebitda_Consensus_T1',
    ebitda_YOY_Consensus_T0: 'ebitda_YOY_Consensus_T0',
    ebitda_YOY_Consensus_T1: 'ebitda_YOY_Consensus_T1',
    ebitda_EVX_Consensus_T0: 'ebitda_EVX_Consensus_T0',
    ebitda_EVX_Consensus_T1: 'ebitda_EVX_Consensus_T1',
    rev_Consensus_T0: 'rev_Consensus_T0',
    rev_Consensus_T1: 'rev_Consensus_T1',
    rev_YOY_Consensus_T0: 'rev_YOY_Consensus_T0',
    rev_YOY_Consensus_T1: 'rev_YOY_Consensus_T1',
    rev_PS_Consensus_T0: 'rev_PS_Consensus_T0',
    rev_PS_Consensus_T1: 'rev_PS_Consensus_T1',
}

/** @type {import('../Table').ColumnOption<StockInfoDailyImportRow>[]} */
export const StockDailyCommonColumns = [
    {
        field: 'code',
        label: StockDailyColNames.code,
        width: 150,
        model: 'none',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code}</Link>
    },
    {
        field: 'code_BBG',
        label: StockDailyColNames.code_BBG,
        width: 150,
        model: 'none',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code_BBG}</Link>
    },
    {
        field: 'name',
        label: 'Name',
        width: 150,
        model: 'none'
    },
    {
        field: 'date',
        label: 'Date',
        width: 150,
        model: 'none'
    }
];

/** @type {import('../Table').ColumnOption<StockInfoDailyImportRow>[]} */
export const StockDailySimpleColumns = [
    {
        field: 'price_Close',
        label: StockDailyColNames.price_Close,
        width: 100,
        model: 'none'
    },
    {
        field: 'price_Open',
        label: StockDailyColNames.price_Open,
        width: 100,
        model: 'none'
    },
    {
        field: 'price_Hi',
        label: StockDailyColNames.price_Hi,
        width: 100,
        model: 'none'
    },
    {
        field: 'price_Lo',
        label: StockDailyColNames.price_Lo,
        width: 100,
        model: 'none'
    },
    {
        field: 'price_Chg',
        label: StockDailyColNames.price_Chg,
        width: 100,
        model: 'none',
    },
    {
        field: 'price_52w_Hi',
        label: StockDailyColNames.price_52w_Hi,
        width: 100,
        model: 'none',
    },
    {
        field: 'price_52w_Lo',
        label: StockDailyColNames.price_52w_Lo,
        width: 100,
        model: 'none',
    },
    {
        field: 'last_RSI',
        label: StockDailyColNames.last_RSI,
        width: 100,
        model: 'none'
    },
    {
        field: 'macd_DIFF',
        label: StockDailyColNames.macd_DIFF,
        width: 200,
        model: 'none'
    },
    {
        field: 'macd_DEA',
        label: StockDailyColNames.macd_DEA,
        width: 200,
        model: 'none'
    },
    {
        field: 'marketValue_USD',
        label: StockDailyColNames.marketValue_USD,
        width: 150,
        model: 'none',
    },
    {
        field: 'adv_1M',
        label: StockDailyColNames.adv_1M,
        width: 150,
        model: 'none',
    }
];

/** @type {import('../Table').ColumnOption<StockInfoDailyImportRow>[]} */
export const StockDailyFullColumns = [
    {
        field: 'ev',
        label: StockDailyColNames.ev,
        width: 150,
        model: 'none',
    },
    {
        field: 'book_Value',
        label: StockDailyColNames.book_Value,
        width: 150,
        model: 'none',
    },
    {
        field: 'price_Vol_10D',
        label: StockDailyColNames.price_Vol_10D,
        width: 150,
        model: 'none',
    },
    {
        field: 'price_Vol_6M',
        label: StockDailyColNames.price_Vol_6M,
        width: 150,
        model: 'none',
    },
    {
        field: 'price_Vol_1Y',
        label: StockDailyColNames.price_Vol_1Y,
        width: 150,
        model: 'none',
    },
    {
        field: 'adv_1M',
        label: StockDailyColNames.adv_1M,
        width: 150,
        model: 'none',
    },
    {
        field: 'adv_10D',
        label: StockDailyColNames.adv_10D,
        width: 150,
        model: 'none',
    },
    {
        field: 'adv_3M',
        label: StockDailyColNames.adv_3M,
        width: 150,
        model: 'none',
    },
    {
        field: 'num_Shares_T0_10K',
        label: StockDailyColNames.num_Shares_T0_10K,
        width: 200,
        model: 'none',
    },
    {
        field: 'eps_Consensus_T0',
        label: StockDailyColNames.eps_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'eps_Consensus_T1',
        label: StockDailyColNames.eps_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'eps_YOY_Consensus_T0',
        label: StockDailyColNames.eps_YOY_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'eps_YOY_Consensus_T1',
        label: StockDailyColNames.eps_YOY_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'eps_PE_Consensus_T0',
        label: StockDailyColNames.eps_PE_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'eps_PE_Consensus_T1',
        label: StockDailyColNames.eps_PE_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'bps_Consensus_T0',
        label: StockDailyColNames.bps_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'bps_Consensus_T1',
        label: StockDailyColNames.bps_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'bps_YOY_Consensus_T0',
        label: StockDailyColNames.bps_YOY_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'bps_YOY_Consensus_T1',
        label: StockDailyColNames.bps_YOY_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'bps_PB_Consensus_T0',
        label: StockDailyColNames.bps_PB_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'bps_PB_Consensus_T1',
        label: StockDailyColNames.bps_PB_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'ebitda_Consensus_T0',
        label: StockDailyColNames.ebitda_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'ebitda_Consensus_T1',
        label: StockDailyColNames.ebitda_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'ebitda_YOY_Consensus_T0',
        label: StockDailyColNames.ebitda_YOY_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'ebitda_YOY_Consensus_T1',
        label: StockDailyColNames.ebitda_YOY_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'ebitda_EVX_Consensus_T0',
        label: StockDailyColNames.ebitda_EVX_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'ebitda_EVX_Consensus_T1',
        label: StockDailyColNames.ebitda_EVX_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'rev_Consensus_T0',
        label: StockDailyColNames.rev_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'rev_Consensus_T1',
        label: StockDailyColNames.rev_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'rev_YOY_Consensus_T0',
        label: StockDailyColNames.rev_YOY_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'rev_YOY_Consensus_T1',
        label: StockDailyColNames.rev_YOY_Consensus_T1,
        width: 200,
        model: 'none',
    },
    {
        field: 'rev_PS_Consensus_T0',
        label: StockDailyColNames.rev_PS_Consensus_T0,
        width: 200,
        model: 'none',
    },
    {
        field: 'rev_PS_Consensus_T1',
        label: StockDailyColNames.rev_PS_Consensus_T1,
        width: 200,
        model: 'none',
    },
];
