import { get, post, put } from "./common";

/**
 * @typedef {{ 
 *   user_ID: number, 
 *   display_Name: string, 
 *   user_Name: string, 
 *   role_ID: Number, 
 *   enabled: boolean, 
 *   visible: boolean,
 *   password?: string,
 * }} User
 */

/**
 * @typedef {{ 
 *   role_ID: number, 
 *   role_Name: string, 
 * } & import("./common").PermissionMap } Role
 */

/** 
 * @returns { Promise<User & import("./common").PermissionMap> }
 */
export function getCurrentUser() {
    return get('/api/user/current');
}

/** 
 * @param { number } id
 * @returns { Promise<User> }
 */
export function getUserInfoById(id) {
    if (!id) return null;
    return get(`/api/user/${id}`);
}

/** 
 * @param { number[] } ids
 * @returns { Promise<User[]> }
 */
export async function getUsersByIds(ids) {
    if (!ids || ids.length === 0) {
        return [];
    }
    return get(`/api/user`, { id: Array.from(new Set(ids)).join(',') });
}

/** 
 * @param {boolean} all
 * @returns { Promise<User[]> }
 */
export function getUsers(all = false) {
    return get('/api/user', {
        all: all ? 1 : 0
    });
}

/**
 * @returns { Promise<void> }
 */
export function changePassword(opwd, npwd) {
    return post('/api/user/change-pwd', { opwd, npwd });
}

/**
 * @param {Omit<User, "user_ID">} user 
 * @returns {Promise<number>}
 */
export function createUser(user) {
    return post('/api/user', user);
}

/**
 * @param {Partial<User> & { deleted?: boolean }} user 
 * @returns {Promise<void>}
 */
export function updateUser(user) {
    return put(`/api/user/${user.user_ID}`, user);
}

/** 
 * @returns { Promise<Role[]> }
 */
export function getAllRoles() {
    return get('/api/user/role');
}
