import { useHistory } from 'react-router-dom';
import querystring from 'querystring';
import { useMemo, useCallback } from 'react';

const defaultParse = a => a;
const defaultStringify = a => `${a}`;

/**
 * @template T
 * @param {string} key
 * @param {T} defaultValue
 * @param {(str:string)=>T} parse
 * @param {(val:T)=>string} stringify
 * @returns { [T, (value: T) => void] }
 */
export default function useQuery(key, defaultValue = undefined, parse = defaultParse, stringify = defaultStringify) {
    const history = useHistory();
    const { location } = history;
    const value = useMemo(() => {
        let qs = location.search;
        if (qs && qs[0] === '?') {
            qs = qs.substr(1);
        }
        const d = querystring.parse(qs);
        const r = d[key];
        if (Array.isArray(r)) {
            return r[0];
        } else {
            return r;
        }
    }, [location]);
    const parsedValue = useMemo(() => value === undefined ? defaultValue : parse(value), [value]);
    /** @type {(value: T) => void} */
    const setQuery = useCallback(value => {
        const location = { ...history.location };
        let qs = location.search;
        if (qs && qs[0] === '?') {
            qs = qs.substr(1);
        }
        const d = querystring.parse(qs);
        if (value === null || value === undefined || value === defaultValue) {
            delete d[key];
        } else {
            d[key] = stringify(value);
        }
        let s = querystring.stringify(d);
        if (s) {
            s = '?' + s;
        }
        location.search = s;
        history.replace(location);
    }, []);
    return [
        parsedValue,
        setQuery
    ];
}