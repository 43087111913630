import React, { useEffect, useRef, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import UserMenu from './UserMenu';
import StockSearcher from './StockSearcher';
import { Link, useRouteMatch } from 'react-router-dom';
import RequirePermission from './components/RequirePermission';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: blue[900]
    },
    toolbar: {
        position: 'relative'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'block',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
    navbtn: {
        alignSelf: 'stretch',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 20,
        fontSize: 18,
        textDecoration: 'none',
        color: 'rgba(255,255,255,0.7)',
        '&.active': {
            color: 'white',
        }
    },
    navhover: {
        position: 'absolute',
        bottom: 0,
        height: 3,
        background: 'white',
        width: 0,
        left: 0,
        transition: 'all 0.5s',
        '&.init': {
            transition: 'none',
        }
    }
}));

/** @type {HTMLDivElement} */
const divNull = null;

export default function NavBar() {
    const classes = useStyles();
    const [activeEl, setActiveEl] = useState(divNull);
    const [style, setStyle] = useState({});
    const [inited, setInited] = useState(false);
    useEffect(() => {
        if (activeEl) {
            setStyle({
                left: activeEl.offsetLeft,
                width: activeEl.offsetWidth
            });
            if (!inited) {
                const timeout = setTimeout(() => {
                    setInited(true);
                }, 100);
                return () => clearTimeout(timeout);
            }
        }
    }, [activeEl, inited]);
    return (
        <AppBar position="fixed" className={classes.root}>
            <Toolbar className={classes.toolbar}>
                <Typography className={classes.title} variant="h6" noWrap>
                    Dao
                </Typography>
                <NavButton name="首页" path="/" exact setActiveEl={setActiveEl} />
                <RequirePermission requiredAny={["editStockList", "editCoveredStock", "editUsers"]}>
                    <NavButton name="管理" path="/manage" setActiveEl={setActiveEl} />
                </RequirePermission>
                {/* <RequirePermission required="editStockList">
                    <NavButton name="池子管理" path="/stocklist" setActiveEl={setActiveEl} />
                </RequirePermission>
                <RequirePermission required="editCoveredStock">
                    <NavButton name="个股标签管理" path="/stocklabel" setActiveEl={setActiveEl} />
                </RequirePermission> */}
                <NavButton name="个股信息表" path="/stock" disabled autoHide setActiveEl={setActiveEl} />
                <RequirePermission required="viewAggrTable">
                    <NavButton name="综合表" path="/aggr" setActiveEl={setActiveEl} />
                </RequirePermission>
                <RequirePermission required="viewEventTable">
                    <NavButton name="信号表" path="/event" setActiveEl={setActiveEl} />
                </RequirePermission>
                <RequirePermission required="viewCallTable">
                    <NavButton name="Call表" path="/call" setActiveEl={setActiveEl} />
                </RequirePermission>
                <RequirePermission required="viewShortTable">
                    <NavButton name="Short表" path="/short" setActiveEl={setActiveEl} />
                </RequirePermission>
                <RequirePermission required="viewCoveredStock">
                    <NavButton name="Cover表" path="/cover" setActiveEl={setActiveEl} />
                </RequirePermission>
                <RequirePermission required="viewWorkTable">
                    <NavButton name="工作汇总" path="/work" setActiveEl={setActiveEl} />
                </RequirePermission>
                <RequirePermission required="viewCoveredStock">
                    <NavButton name="Ticket" path="/user-table" setActiveEl={setActiveEl} />
                </RequirePermission>
                <div className={`${classes.navhover} ${inited ? '' : 'init'}`} style={style} />
                <div style={{flex:1}}/>
                <div className={classes.search}>
                    <StockSearcher size="small" variant="filled" />
                </div>
                <UserMenu />
            </Toolbar>
        </AppBar>
    );
}

/**
 * @param {{
 *   name: string,
 *   path: string,
 *   exact?: boolean,
 *   disabled?: boolean,
 *   autoHide?: boolean,
 *   setActiveEl: (el:HTMLDivElement) => void
 * }} param0 
 */
function NavButton({ name, path, exact=false, disabled=false, setActiveEl, autoHide = false }) {
    const classes = useStyles();
    const m = Boolean(useRouteMatch({ path, exact }));
    const ref = useRef();
    useEffect(() => {
        if (m) {
            setActiveEl(ref.current);
        }
    }, [m]);
    if (m) {
        if (disabled) {
            return (
                <div className={`${classes.navbtn} active`} ref={ref}>
                    {name}
                </div>
            )
        } else {
            return (
                <Link className={`${classes.navbtn} active`} to={path} ref={ref}>
                    {name}
                </Link>
            )
        }
    } else if (autoHide) {
        return (
            <div className={classes.navbtn} style={{display:'none'}}>
                {name}
            </div>
        )
    } else if (disabled) {
        return (
            <div className={classes.navbtn}>
                {name}
            </div>
        )
    } else {
        return (
            <Link className={classes.navbtn} to={path}>
                {name}
            </Link>
        )
    }
}