import { useState } from "react";
import { useCoEffect } from "../lib/useCo";
import BigDecimal from 'js-big-decimal';
import { get, getToken, post, postJson, timeout } from "./common";
import { cached } from "../lib/cached";

function n(any) {
    if (any === null || any === undefined) {
        return null;
    }
    return Number(any);
}

/**
 * @typedef {{
 *    code_Factset: string,
 *    code_BBG: string,
 *    code: string,
 *    name: string,
 *    ipo_Date: number,
 *    exchange: string,
 *    currency: string,
 *    quarterly_Report_Type: number,
 *    keyword: string,
 *    coverer: number[],
 *    introduce: string,
 *    timestamp: number,
 *    user_ID: number,
 *    cluster_Layer: number,
 *    industrial_Chain_ID: number,
 *    section_ID: number,
 *    product_ID: number,
 *    sw1_ID: number,
 *    sw2_ID: number,
 *    sw3_ID: number,
 *    return: number,
 *    industrial_Chain_Position_Importance: number,
 *    security_Importance: number,
 *    management: number,
 *    clients: number,
 *    cashflow: number,
 *    business_Stability: number,
 *    company_Stability: number,
 *    industry_Ceiling: number,
 *    company_Ceiling: number,
 *    company_Growth: number,
 *    expected_CAGR: BigDecimal,
 *    expected_CAGR_Topline: BigDecimal,
 *    expected_CAGR_Bottomline: BigDecimal,
 *    demand_Stage: number,
 *    supply_Stage: number,
 *    competitive_Advantage: number,
 *    ceiling_Source: number,
 *    global_Size: string,
 *    addressable_Size: string,
 *    ceiling_Remark: string,
 *    risk: number,
 *    cycle_Causation: number,
 *    cycle_Position: number,
 *    med_Term_Trend: number,
 *    logic: number,
 *    ft_Width: number,
 *    pt_Width: number,
 *    trend_Stability: number,
 *    trend_Sustainability: number,
 *    trend_Strength: number,
 *    trend_Stage: number,
 *    trend_Width: number,
 *    trend_Score: BigDecimal,
 *    trend_Exists: number,
 *    read_Next_FS: number,
 *    alert_Before_Next_FS: number,
 *    short_Wishlist: number,
 *    status: number,
 *    risk_Line: number,
 *    reward_Line: number,
 *    kelly_Confidence: number,
 *    valuation_Method_And_Keyfactor: number,
 *    keyfactor_Expert_T0: BigDecimal,
 *    keyfactor_Expert_T1: BigDecimal,
 *    keyfactor_YOY_Expert_T0: BigDecimal,
 *    keyfactor_YOY_Expert_T1: BigDecimal,
 *    keyfactor_Conviction_T0: BigDecimal,
 *    keyfactor_Conviction_T1: BigDecimal,
 *    keyfactor_YOY_Conviction_T0: BigDecimal,
 *    keyfactor_YOY_Conviction_T1: BigDecimal,
 *    valuation_Multiple_1: BigDecimal,
 *    valuation_Multiple_2: BigDecimal,
 *    valuation_Multiple_3: BigDecimal,
 *    valuation_Multiple_4: BigDecimal,
 *    valuation_Multiple_5: BigDecimal,
 *    valuation_Multiple_6: BigDecimal,
 *    valuation_Keyfactor_1: BigDecimal,
 *    valuation_Keyfactor_2: BigDecimal,
 *    valuation_Keyfactor_3: BigDecimal,
 *    valuation_Keyfactor_4: BigDecimal,
 *    valuation_Keyfactor_5: BigDecimal,
 *    valuation_Keyfactor_6: BigDecimal,
 *    valuation_Price_Low: BigDecimal,
 *    valuation_Price_High: BigDecimal,
 *    shortable: number,
 *    call_Watch_Date: number,
 *    call_Watch_Date_Price_Close: BigDecimal,
 *    call_Watch_Report_Quarterly_ID: number,
 *    call_Watch_Comment_ID: number,
 *    penetration_Rate: BigDecimal,
 *    cr_N: number,
 *    cr_N_Value: BigDecimal,
 *    potential_GR: BigDecimal,
 *    external_Power: BigDecimal,
 *    stock_Power: BigDecimal,
 * }} StockUpdatableInfo
 */

 /**
  * @typedef {{
  *    date: number,
  *    timestamp: number,
  *    status: number,
  *    quarter_Or_Semi: 0 | 1 | 2,
  *    name: string,
  *    name_Eng: string,
  *    currency: string,
  *    code: string,
  *    user_ID: number,
  *    cluster_Layer: number,
  *    industrial_Chain_ID: number,
  *    section_ID: number,
  *    product_ID: number,
  *    sw1_ID: number,
  *    sw2_ID: number,
  *    sw3_ID: number,
  *    return: number,
  *    industrial_Chain_Position_Importance: number,
  *    security_Importance: number,
  *    management: number,
  *    clients: number,
  *    cashflow: number,
  *    business_Stability: number,
  *    company_Stability: number,
  *    industry_Ceiling: number,
  *    company_Ceiling: number,
  *    company_Growth: number,
  *    expected_CAGR: BigDecimal,
  *    expected_CAGR_Topline: BigDecimal,
  *    expected_CAGR_Bottomline: BigDecimal,
  *    demand_Stage: number,
  *    supply_Stage: number,
  *    competitive_Advantage: number,
  *    ceiling_Source: number,
  *    global_Size: string,
  *    addressable_Size: string,
  *    ceiling_Remark: string,
  *    risk: number,
  *    cycle_Causation: number,
  *    cycle_Position: number,
  *    med_Term_Trend: number,
  *    ft_Score: BigDecimal,
  *    ft_First: number,
  *    logic: 0|1|2,
  *    logic_Default: 1|2,
  *    logic_Or_Default: 1|2,
  *    ft_Width: number,
  *    pt_Width: number,
  *    trend_Stability: number,
  *    trend_Sustainability: number,
  *    trend_Strength: number,
  *    trend_Stage: number,
  *    trend_Width: number,
  *    trend_Score: BigDecimal,
  *    trend_Exists: 0|1|2,
  *    trend_Exists_Or_Default: 1|2,
  *    read_Next_FS: 0|1|2,
  *    read_Next_FS_Default: 1|2,
  *    read_Next_FS_Or_Default: 1|2,
  *    alert_Before_Next_FS: 0|1|2,
  *    alert_Before_Next_FS_Default: 1|2,
  *    alert_Before_Next_FS_Or_Default: 1|2,
  *    short_Wishlist: number,
  *    short_Wishlist_Default: number,
  *    short_Wishlist_Or_Default: number,
  *    risk_Line: number,
  *    reward_Line: number,
  *    coverer: number[],
  *    kelly_Confidence: number,
  *    valuation_Method_And_Keyfactor: number,
  *    keyfactor_Expert_T0: BigDecimal,
  *    keyfactor_Expert_T1: BigDecimal,
  *    keyfactor_YOY_Expert_T0: BigDecimal,
  *    keyfactor_YOY_Expert_T1: BigDecimal,
  *    keyfactor_Conviction_T0: BigDecimal,
  *    keyfactor_Conviction_T1: BigDecimal,
  *    keyfactor_YOY_Conviction_T0: BigDecimal,
  *    keyfactor_YOY_Conviction_T1: BigDecimal,
  *    valuation_Multiple_1: BigDecimal,
  *    valuation_Multiple_2: BigDecimal,
  *    valuation_Multiple_3: BigDecimal,
  *    valuation_Multiple_4: BigDecimal,
  *    valuation_Multiple_5: BigDecimal,
  *    valuation_Multiple_6: BigDecimal,
  *    valuation_Keyfactor_1: BigDecimal,
  *    valuation_Keyfactor_2: BigDecimal,
  *    valuation_Keyfactor_3: BigDecimal,
  *    valuation_Keyfactor_4: BigDecimal,
  *    valuation_Keyfactor_5: BigDecimal,
  *    valuation_Keyfactor_6: BigDecimal,
  *    valuation_Price_Low: BigDecimal,
  *    valuation_Price_High: BigDecimal,
  *    shortable: 0|1|2,
  *    shortable_Default: 1|2,
  *    shortable_Or_Default: 1|2,
  *    call_Watch_Date: number,
  *    call_Watch_Date_Price_Close: BigDecimal,
  *    call_Watch_Report_Quarterly_ID: number,
  *    call_Watch_Comment_ID: number,
  *    ctd_Pct: BigDecimal,
  *    penetration_Rate: BigDecimal,
  *    cr_N: number,
  *    cr_N_Default: number,
  *    cr_N_Or_Default: number,
  *    cr_N_Value: BigDecimal,
  *    potential_GR: BigDecimal,
  *    external_Power: BigDecimal,
  *    stock_Power: BigDecimal,
  *    last_RSI: BigDecimal,
  *    pt_Score: BigDecimal,
  *    pt_1st_Day: number,
  *    price_Close: BigDecimal,
  *    price_Open: BigDecimal,
  *    price_Chg: BigDecimal,
  *    price_Chg_Pct: BigDecimal,
  *    pe_T0: BigDecimal,
  *    pb_TTM: BigDecimal,
  *    marketValue: BigDecimal,
  *    marketValue_USD: BigDecimal,
  *    ev: BigDecimal,
  *    netcash: BigDecimal,
  *    book_Value: BigDecimal,
  *    price_Vol_10D: BigDecimal,
  *    price_Vol_6M: BigDecimal,
  *    price_Vol_1Y: BigDecimal,
  *    adv_1M: BigDecimal,
  *    adv_10D: BigDecimal,
  *    adv_3M: BigDecimal,
  *    last_Rpt_Type: number,
  *    last_Rpt_Date: number,
  *    next_Rpt_Type: number,
  *    next_Rpt_Date: number,
  *    num_Shares_T0_10K: BigDecimal,
  *    eps_Consensus_T0: BigDecimal,
  *    eps_Consensus_T1: BigDecimal,
  *    eps_YOY_Consensus_T0: BigDecimal,
  *    eps_YOY_Consensus_T1: BigDecimal,
  *    eps_PE_Consensus_T0: BigDecimal,
  *    eps_PE_Consensus_T1: BigDecimal,
  *    bps_Consensus_T0: BigDecimal,
  *    bps_Consensus_T1: BigDecimal,
  *    bps_YOY_Consensus_T0: BigDecimal,
  *    bps_YOY_Consensus_T1: BigDecimal,
  *    bps_PB_Consensus_T0: BigDecimal,
  *    bps_PB_Consensus_T1: BigDecimal,
  *    ebitda_Consensus_T0: BigDecimal,
  *    ebitda_Consensus_T1: BigDecimal,
  *    ebitda_YOY_Consensus_T0: BigDecimal,
  *    ebitda_YOY_Consensus_T1: BigDecimal,
  *    ebitda_EVX_Consensus_T0: BigDecimal,
  *    ebitda_EVX_Consensus_T1: BigDecimal,
  *    rev_Consensus_T0: BigDecimal,
  *    rev_Consensus_T1: BigDecimal,
  *    rev_YOY_Consensus_T0: BigDecimal,
  *    rev_YOY_Consensus_T1: BigDecimal,
  *    rev_PS_Consensus_T0: BigDecimal,
  *    rev_PS_Consensus_T1: BigDecimal,
  *    exchange: string,
  *    price_52w_Hi: BigDecimal,
  *    price_52w_Lo: BigDecimal,
  *    macd_DIFF: BigDecimal,
  *    macd_DEA: BigDecimal,
  *    macd_MACD: BigDecimal,
  *    cnt_FY: number,
  *    div_Pct: BigDecimal,
  *    avg_Profit_Parent_Comp: BigDecimal,
  *    avg_OPCF: BigDecimal,
  *    avg_FCF: BigDecimal,
  *    avg_ROE: BigDecimal,
  *    roe_Hi: BigDecimal,
  *    roe_Lo: BigDecimal,
  *    vol_ROE: BigDecimal,
  *    cagr_Revenue: BigDecimal,
  *    cagr_Profit_Parent_Comp: BigDecimal,
  *    vol_Q8_Profit_Parent_Comp_YOY: BigDecimal,
  *    avg_Q8_Profit_Parent_Comp_YOY: BigDecimal,
  *    avg_ROIC: BigDecimal,
  *    avg_ROA: BigDecimal,
  *    avg_Revenue_YOY: BigDecimal,
  *    avg_Profit_Parent_Comp_YOY: BigDecimal,
  *    avg_EPS_Diluted_YOY: BigDecimal,
  *    quality: BigDecimal,
  *    cluster_Layer_Or_Default: number,
  *    cluster_Layer_Value: number,
  *    return_Default: number,
  *    return_Or_Default: number,
  *    fcf_NP: BigDecimal,
  *    cashflow_Default: number,
  *    cashflow_Or_Default: number,
  *    company_Stability_Default: number,
  *    company_Stability_Or_Default: number,
  *    avg_ADV: BigDecimal,
  *    last_ID: number,
  *    cluster_Layer_Default: number,
  *    opcf_NP: BigDecimal,
  *    company_Growth_Default: number,
  *    company_Growth_Or_Default: number,
  *    trend_Score_Default: BigDecimal,
  *    trend_Score_Or_Default: BigDecimal,
  *    status_Default: number,
  *    status_Or_Default: number,
  *    quality_Score: BigDecimal,
  *    quality_Cap_Pct: BigDecimal,
  *    risk_Line_Default: number,
  *    risk_Line_Or_Default: number,
  *    risk_Line_Or_Default_Value: BigDecimal,
  *    reward_Line_Default: number,
  *    reward_Line_Or_Default: number,
  *    reward_Line_Or_Default_Value: BigDecimal,
  *    kelly_Confidence_Default: number,
  *    kelly_Confidence_Or_Default: number,
  *    valuation_Method_And_Keyfactor_Default: number,
  *    valuation_Method_And_Keyfactor_Or_Default: number,
  *    valuation_KeyFactor_1_Default: BigDecimal,
  *    valuation_KeyFactor_2_Default: BigDecimal,
  *    valuation_KeyFactor_3_Default: BigDecimal,
  *    valuation_KeyFactor_4_Default: BigDecimal,
  *    valuation_KeyFactor_5_Default: BigDecimal,
  *    valuation_KeyFactor_6_Default: BigDecimal,
  *    valuation_KeyFactor_1_Or_Default: BigDecimal,
  *    valuation_KeyFactor_2_Or_Default: BigDecimal,
  *    valuation_KeyFactor_3_Or_Default: BigDecimal,
  *    valuation_KeyFactor_4_Or_Default: BigDecimal,
  *    valuation_KeyFactor_5_Or_Default: BigDecimal,
  *    valuation_KeyFactor_6_Or_Default: BigDecimal,
  *    valuation_Price_1: BigDecimal,
  *    valuation_Price_2: BigDecimal,
  *    valuation_Price_3: BigDecimal,
  *    valuation_Price_4: BigDecimal,
  *    valuation_Price_5: BigDecimal,
  *    valuation_Price_6: BigDecimal,
  *    risk_Pct: BigDecimal,
  *    reward_Pct: BigDecimal,
  *    reward_Risk_Ratio: BigDecimal,
  *    info_Completeness: BigDecimal,
  *    pricing_Score: BigDecimal,
  *    pricing_Score_User: BigDecimal,
  *    avg_Price_Vol: BigDecimal,
  *    price_Vol: number,
  *    liquidity: number,
  *    keyfactor_Valuation_Expert_T0: BigDecimal,
  *    keyfactor_Valuation_Expert_T1: BigDecimal,
  *    keyfactor_Valuation_Conviction_T0: BigDecimal,
  *    keyfactor_Valuation_Conviction_T1: BigDecimal,
  *    is_OPCF_Good: number,
  *    is_FCF_Good: number,
  *    one_Mn_Pct: BigDecimal,
  *    total_Score: BigDecimal,
  *    total_Score_User: BigDecimal,
  *    kelly_Position: BigDecimal,
  *    avg_Num_Shares?: BigDecimal,
  *    avg_Cash_Dividend_PS?: BigDecimal,
  *    latest_Comment_ID: number,
  *    shortable_Total_Shares: BigDecimal,
  *    shortable_Total_USD: BigDecimal,
  *    shortable_Weighted_Rate: BigDecimal
  * }} StockInfo
  */

 /**
  * @typedef {Pick<StockInfo, 'cluster_Layer' |'industrial_Chain_ID' |'section_ID' | 'product_ID' | 'sw1_ID' | 'sw2_ID' | 'sw3_ID' |'return' |'industrial_Chain_Position_Importance' |'security_Importance' |'management' |'clients' |'cashflow' |'business_Stability' |'company_Stability' |'industry_Ceiling' |'company_Ceiling' |'company_Growth' |'expected_CAGR' |'expected_CAGR_Topline' |'expected_CAGR_Bottomline' |'demand_Stage' |'supply_Stage' |'competitive_Advantage' |'ceiling_Source' |'global_Size' |'addressable_Size' |'ceiling_Remark' |'risk' |'cycle_Causation' |'cycle_Position' |'med_Term_Trend' |'logic' |'ft_Width' |'pt_Width' |'trend_Stability' | 'trend_Sustainability' |'trend_Strength' |'trend_Stage' |'trend_Width' |'trend_Score' |'read_Next_FS' |'alert_Before_Next_FS' |'short_Wishlist' |'status' |'risk_Line' |'reward_Line' |'coverer' |'kelly_Confidence' |'valuation_Method_And_Keyfactor' |'keyfactor_Expert_T0' |'keyfactor_Expert_T1' |'keyfactor_YOY_Expert_T0' |'keyfactor_YOY_Expert_T1' |'keyfactor_Conviction_T0' |'keyfactor_Conviction_T1' |'keyfactor_YOY_Conviction_T0' |'keyfactor_YOY_Conviction_T1' |'valuation_Multiple_1' |'valuation_Multiple_2' |'valuation_Multiple_3' |'valuation_Multiple_4' |'valuation_Multiple_5' |'valuation_Multiple_6' |'valuation_Keyfactor_1' |'valuation_Keyfactor_2' |'valuation_Keyfactor_3' |'valuation_Keyfactor_4' |'valuation_Keyfactor_5' |'valuation_Keyfactor_6'|'valuation_Price_Low'|'valuation_Price_High'|'shortable' |'penetration_Rate' |'cr_N' |'cr_N_Value' |'potential_GR'>} StockInfoUpdate
  * 
  */

/**
 * @typedef {{
 *    id: number,
 *    code: string,
 *    rpt_Period: number,
 *    last: boolean,
 *    price_Close: BigDecimal,
 *    pt_Score: BigDecimal,
 *    ft_Score: BigDecimal,
 *    ft_Type: number,
 *    trend_Score: BigDecimal,
 *    pricing_Score: BigDecimal,
 *    quality_Score: BigDecimal,
 *    notice_Release_Date: number,
 *    notice_Release_1st_Date: number,
 *    notice_Abstract: string,
 *    notice_Reason: string,
 *    notice_Accm_NP_Dside_Pct: BigDecimal,
 *    notice_Accm_NP_Uside_Pct: BigDecimal,
 *    notice_Accm_NP_Dside: BigDecimal,
 *    notice_Accm_NP_Uside: BigDecimal,
 *    notice_Q_Type: string,
 *    notice_Q_NP_Dside_Pct: BigDecimal,
 *    notice_Q_NP_Uside_Pct: BigDecimal,
 *    notice_Q_NP_Dside: BigDecimal,
 *    notice_Q_NP_Uside: BigDecimal,
 *    status_Action: number,
 *    status_Action_Date: number,
 *    status_Action_User: number,
 *    status_Action_Timestamp: number,
 * }} StockReportQuarterlyNotice
 */

/**
 * @typedef {{
 *    id: number,
 *    code: string,
 *    rpt_Period: number,
 *    last: boolean,
 *    price_Close: BigDecimal,
 *    pt_Score: BigDecimal,
 *    ft_Score: BigDecimal,
 *    ft_Type: number,
 *    trend_Score: BigDecimal,
 *    pricing_Score: BigDecimal,
 *    quality_Score: BigDecimal,
 *    express_Release_Date: number,
 *    express_Accm_Rev: BigDecimal,
 *    express_Accm_Rev_YOY: BigDecimal,
 *    express_Accm_OP_Profit: BigDecimal,
 *    express_Accm_OP_Profit_YOY: BigDecimal,
 *    express_Accm_Profit_Parent_Comp: BigDecimal,
 *    express_Accm_Profit_Parent_Comp_YOY: BigDecimal,
 *    express_Accm_Profit_Parent_Comp_Last_Year: BigDecimal,
 *    express_Accm_OP_Profit_Last_Year: BigDecimal,
 *    express_Q_Rev: BigDecimal,
 *    express_Q_Rev_YOY: BigDecimal,
 *    express_Q_OP_Profit: BigDecimal,
 *    express_Q_OP_Profit_YOY: BigDecimal,
 *    express_Q_Profit_Parent_Comp: BigDecimal,
 *    express_Q_Profit_Parent_Comp_YOY: BigDecimal,
 *    status_Action: number,
 *    status_Action_Date: number,
 *    status_Action_User: number,
 *    status_Action_Timestamp: number,
 * }} StockReportQuarterlyExpress
 */

/**
 * @typedef {{
 *    id: number,
 *    code: string,
 *    rpt_Period: number,
 *    last: boolean,
 *    price_Close: BigDecimal,
 *    pt_Score: BigDecimal,
 *    ft_Score: BigDecimal,
 *    ft_Type: number,
 *    trend_Score: BigDecimal,
 *    pricing_Score: BigDecimal,
 *    quality_Score: BigDecimal,
 *    fa_Expected_Release_Date: number,
 *    fa_Release_Date: number,
 *    fa_Accm_Rev: BigDecimal,
 *    fa_Accm_Rev_YOY: BigDecimal,
 *    fa_Accm_OP_Profit: BigDecimal,
 *    fa_Accm_OP_Profit_YOY: BigDecimal,
 *    fa_Accm_Profit_Parent_Comp: BigDecimal,
 *    fa_Accm_Profit_Parent_Comp_YOY: BigDecimal,
 *    fa_Accm_Profit_Parent_Comp_Deducted: BigDecimal,
 *    fa_Accm_Profit_Parent_Comp_Deducted_YOY: BigDecimal,
 *    fa_Q_Rev: BigDecimal,
 *    fa_Q_Rev_YOY: BigDecimal,
 *    fa_Q_OP_Profit: BigDecimal,
 *    fa_Q_OP_Profit_YOY: BigDecimal,
 *    fa_Q_Profit_Parent_Comp: BigDecimal,
 *    fa_Q_Profit_Parent_Comp_YOY: BigDecimal,
 *    fa_Q_Profit_Parent_Comp_Deducted: BigDecimal,
 *    fa_Q_Profit_Parent_Comp_Deducted_YOY: BigDecimal,
 *    status_Action: number,
 *    status_Action_Date: number,
 *    status_Action_User: number,
 *    status_Action_Timestamp: number,
 * }} StockReportQuarterlyFA
 */

/** @typedef {[date: number, price_Open: string, price_Close: string, price_Lo: string, price_Hi: string, pt_Score: string]} StockKChartItem */
/** @typedef {[date: number, risk_Line_Value: string, reward_Line_Value: string ]} StockRiskRewardChartItem */

/**
 * @param {string} s 
 * @returns { BigDecimal }
 */
function bn(s) {
    if (!s) {
        return null;
    }
    return new BigDecimal(s);
}

/**
 * 
 * @param {any} v
 * @returns {0|1|2} 
 */
function boolOpt(v) {
    const n = Number(v);
    if (n === 1 || n === 2) {
        return n;
    }
    return 0;
}

/**
 * 
 * @param {any} v
 * @returns {1|2} 
 */
function boolReq(v) {
    const n = Number(v);
    if (n === 1 || n === 2) {
        return n;
    }
    throw new Error("Unexpected bool value: " + v);
}

/** 
 * @type {{
 *   [key in keyof StockInfo]: (value: any) => StockInfo[key]
 * }} 
 */
const stockInfoConverters = {
    date: serverResp => Number(serverResp.date),
    timestamp: serverResp => Number(serverResp.timestamp),
    status: serverResp => Number(serverResp.status),
    quarter_Or_Semi: serverResp => serverResp.quarter_Or_Semi,
    name: serverResp => `${serverResp.name}`,
    name_Eng: serverResp => `${serverResp.name_Eng}`,
    currency: serverResp => `${serverResp.currency}`,
    code: serverResp => `${serverResp.code}`,
    user_ID: serverResp => Number(serverResp.user_ID),
    cluster_Layer: serverResp => Number(serverResp.cluster_Layer),
    industrial_Chain_ID: serverResp => Number(serverResp.industrial_Chain_ID),
    section_ID: serverResp => Number(serverResp.section_ID),
    product_ID: serverResp => Number(serverResp.product_ID),
    sw1_ID: serverResp => Number(serverResp.sw1_ID),
    sw2_ID: serverResp => Number(serverResp.sw2_ID),
    sw3_ID: serverResp => Number(serverResp.sw3_ID),
    'return': serverResp => Number(serverResp['return']),
    industrial_Chain_Position_Importance: serverResp => Number(serverResp.industrial_Chain_Position_Importance),
    security_Importance: serverResp => Number(serverResp.security_Importance),
    management: serverResp => Number(serverResp.management),
    clients: serverResp => Number(serverResp.clients),
    cashflow: serverResp => Number(serverResp.cashflow),
    business_Stability: serverResp => Number(serverResp.business_Stability),
    company_Stability: serverResp => Number(serverResp.company_Stability),
    industry_Ceiling: serverResp => Number(serverResp.industry_Ceiling),
    company_Ceiling: serverResp => Number(serverResp.company_Ceiling),
    company_Growth: serverResp => Number(serverResp.company_Growth),
    expected_CAGR: serverResp => bn(serverResp.expected_CAGR),
    expected_CAGR_Topline: serverResp => bn(serverResp.expected_CAGR_Topline),
    expected_CAGR_Bottomline: serverResp => bn(serverResp.expected_CAGR_Bottomline),
    demand_Stage: serverResp => Number(serverResp.demand_Stage),
    supply_Stage: serverResp => Number(serverResp.supply_Stage),
    competitive_Advantage: serverResp => Number(serverResp.competitive_Advantage),
    ceiling_Source: serverResp => Number(serverResp.ceiling_Source),
    global_Size: serverResp => `${serverResp.global_Size}`,
    addressable_Size: serverResp => `${serverResp.addressable_Size}`,
    ceiling_Remark: serverResp => `${serverResp.ceiling_Remark}`,
    risk: serverResp => Number(serverResp.risk),
    cycle_Causation: serverResp => Number(serverResp.cycle_Causation),
    cycle_Position: serverResp => Number(serverResp.cycle_Position),
    med_Term_Trend: serverResp => Number(serverResp.med_Term_Trend),
    ft_Score: serverResp => bn(serverResp.ft_Score),
    ft_First: serverResp => serverResp.ft_First,
    logic: serverResp => boolOpt(serverResp.logic),
    logic_Default: serverResp => boolReq(serverResp.logic_Default),
    logic_Or_Default: serverResp => boolReq(serverResp.logic_Or_Default),
    ft_Width: serverResp => Number(serverResp.ft_Width),
    pt_Width: serverResp => Number(serverResp.pt_Width),
    trend_Stability: serverResp => Number(serverResp.trend_Stability),
    trend_Sustainability: serverResp => Number(serverResp.trend_Sustainability),
    trend_Strength: serverResp => Number(serverResp.trend_Strength),
    trend_Stage: serverResp => Number(serverResp.trend_Stage),
    trend_Width: serverResp => Number(serverResp.trend_Width),
    trend_Score: serverResp => bn(serverResp.trend_Score),
    trend_Exists: serverResp => boolOpt(serverResp.trend_Exists),
    trend_Exists_Or_Default: serverResp => boolReq(serverResp.trend_Exists_Or_Default),
    read_Next_FS: serverResp => boolOpt(serverResp.read_Next_FS),
    read_Next_FS_Default: serverResp => boolReq(serverResp.read_Next_FS_Default),
    read_Next_FS_Or_Default: serverResp => boolReq(serverResp.read_Next_FS_Or_Default),
    alert_Before_Next_FS: serverResp => boolOpt(serverResp.alert_Before_Next_FS),
    alert_Before_Next_FS_Default: serverResp => boolReq(serverResp.alert_Before_Next_FS_Default),
    alert_Before_Next_FS_Or_Default: serverResp => boolReq(serverResp.alert_Before_Next_FS_Or_Default),
    short_Wishlist: serverResp => Number(serverResp.short_Wishlist),
    short_Wishlist_Default: serverResp => Number(serverResp.short_Wishlist_Default),
    short_Wishlist_Or_Default: serverResp => Number(serverResp.short_Wishlist_Or_Default),
    risk_Line: serverResp => Number(serverResp.risk_Line),
    reward_Line: serverResp => Number(serverResp.reward_Line),
    coverer: serverResp => /** @type {number[]} */(serverResp.coverer),
    kelly_Confidence: serverResp => Number(serverResp.kelly_Confidence),
    valuation_Method_And_Keyfactor: serverResp => Number(serverResp.valuation_Method_And_Keyfactor),
    keyfactor_Expert_T0: serverResp => bn(serverResp.keyfactor_Expert_T0),
    keyfactor_Expert_T1: serverResp => bn(serverResp.keyfactor_Expert_T1),
    keyfactor_YOY_Expert_T0: serverResp => bn(serverResp.keyfactor_YOY_Expert_T0),
    keyfactor_YOY_Expert_T1: serverResp => bn(serverResp.keyfactor_YOY_Expert_T1),
    keyfactor_Conviction_T0: serverResp => bn(serverResp.keyfactor_Conviction_T0),
    keyfactor_Conviction_T1: serverResp => bn(serverResp.keyfactor_Conviction_T1),
    keyfactor_YOY_Conviction_T0: serverResp => bn(serverResp.keyfactor_YOY_Conviction_T0),
    keyfactor_YOY_Conviction_T1: serverResp => bn(serverResp.keyfactor_YOY_Conviction_T1),
    valuation_Multiple_1: serverResp => bn(serverResp.valuation_Multiple_1),
    valuation_Multiple_2: serverResp => bn(serverResp.valuation_Multiple_2),
    valuation_Multiple_3: serverResp => bn(serverResp.valuation_Multiple_3),
    valuation_Multiple_4: serverResp => bn(serverResp.valuation_Multiple_4),
    valuation_Multiple_5: serverResp => bn(serverResp.valuation_Multiple_5),
    valuation_Multiple_6: serverResp => bn(serverResp.valuation_Multiple_6),
    valuation_Keyfactor_1: serverResp => bn(serverResp.valuation_Keyfactor_1),
    valuation_Keyfactor_2: serverResp => bn(serverResp.valuation_Keyfactor_2),
    valuation_Keyfactor_3: serverResp => bn(serverResp.valuation_Keyfactor_3),
    valuation_Keyfactor_4: serverResp => bn(serverResp.valuation_Keyfactor_4),
    valuation_Keyfactor_5: serverResp => bn(serverResp.valuation_Keyfactor_5),
    valuation_Keyfactor_6: serverResp => bn(serverResp.valuation_Keyfactor_6),
    shortable: serverResp => boolOpt(serverResp.shortable),
    shortable_Default: serverResp => boolReq(serverResp.shortable_Default),
    shortable_Or_Default: serverResp => boolReq(serverResp.shortable_Or_Default),
    call_Watch_Date: serverResp => serverResp.call_Watch_Date,
    call_Watch_Date_Price_Close: serverResp => bn(serverResp.call_Watch_Date_Price_Close),
    call_Watch_Report_Quarterly_ID: serverResp => serverResp.call_Watch_Date,
    call_Watch_Comment_ID: serverResp => serverResp.call_Watch_Comment_ID,
    ctd_Pct: serverResp => bn(serverResp.ctd_Pct),
    penetration_Rate: serverResp => bn(serverResp.penetration_Rate),
    cr_N: serverResp => Number(serverResp.cr_N),
    cr_N_Default: serverResp => Number(serverResp.cr_N_Default),
    cr_N_Or_Default: serverResp => Number(serverResp.cr_N_Or_Default),
    cr_N_Value: serverResp => bn(serverResp.cr_N_Value),
    potential_GR: serverResp => bn(serverResp.potential_GR),
    external_Power: serverResp => bn(serverResp.external_Power),
    stock_Power: serverResp => bn(serverResp.stock_Power),

    last_RSI: serverResp => bn(serverResp.last_RSI),
    pt_Score: serverResp => bn(serverResp.pt_Score),
    pt_1st_Day: serverResp => n(serverResp.pt_1st_Day),
    price_Close: serverResp => bn(serverResp.price_Close),
    price_Open: serverResp => bn(serverResp.price_Open),
    price_Chg: serverResp => bn(serverResp.price_Chg),
    price_Chg_Pct: serverResp => bn(serverResp.price_Chg_Pct),
    pe_T0: serverResp => bn(serverResp.pe_T0),
    pb_TTM: serverResp => bn(serverResp.pb_TTM),
    marketValue: serverResp => bn(serverResp.marketValue),
    marketValue_USD: serverResp => bn(serverResp.marketValue_USD),
    ev: serverResp => bn(serverResp.ev),
    netcash: serverResp => bn(serverResp.netcash),
    book_Value: serverResp => bn(serverResp.book_Value),
    price_Vol_10D: serverResp => bn(serverResp.price_Vol_10D),
    price_Vol_6M: serverResp => bn(serverResp.price_Vol_6M),
    price_Vol_1Y: serverResp => bn(serverResp.price_Vol_1Y),
    adv_1M: serverResp => bn(serverResp.adv_1M),
    adv_10D: serverResp => bn(serverResp.adv_10D),
    adv_3M: serverResp => bn(serverResp.adv_3M),
    last_Rpt_Type: serverResp => Number(serverResp.last_Rpt_Type),
    last_Rpt_Date: serverResp => Number(serverResp.last_Rpt_Date),
    next_Rpt_Type: serverResp => Number(serverResp.next_Rpt_Type),
    next_Rpt_Date: serverResp => Number(serverResp.next_Rpt_Date),
    num_Shares_T0_10K: serverResp => bn(serverResp.num_Shares_T0_10K),
    eps_Consensus_T0: serverResp => bn(serverResp.eps_Consensus_T0),
    eps_Consensus_T1: serverResp => bn(serverResp.eps_Consensus_T1),
    eps_YOY_Consensus_T0: serverResp => bn(serverResp.eps_YOY_Consensus_T0),
    eps_YOY_Consensus_T1: serverResp => bn(serverResp.eps_YOY_Consensus_T1),
    eps_PE_Consensus_T0: serverResp => bn(serverResp.eps_PE_Consensus_T0),
    eps_PE_Consensus_T1: serverResp => bn(serverResp.eps_PE_Consensus_T1),
    bps_Consensus_T0: serverResp => bn(serverResp.bps_Consensus_T0),
    bps_Consensus_T1: serverResp => bn(serverResp.bps_Consensus_T1),
    bps_YOY_Consensus_T0: serverResp => bn(serverResp.bps_YOY_Consensus_T0),
    bps_YOY_Consensus_T1: serverResp => bn(serverResp.bps_YOY_Consensus_T1),
    bps_PB_Consensus_T0: serverResp => bn(serverResp.bps_PB_Consensus_T0),
    bps_PB_Consensus_T1: serverResp => bn(serverResp.bps_PB_Consensus_T1),
    ebitda_Consensus_T0: serverResp => bn(serverResp.ebitda_Consensus_T0),
    ebitda_Consensus_T1: serverResp => bn(serverResp.ebitda_Consensus_T1),
    ebitda_YOY_Consensus_T0: serverResp => bn(serverResp.ebitda_YOY_Consensus_T0),
    ebitda_YOY_Consensus_T1: serverResp => bn(serverResp.ebitda_YOY_Consensus_T1),
    ebitda_EVX_Consensus_T0: serverResp => bn(serverResp.ebitda_EVX_Consensus_T0),
    ebitda_EVX_Consensus_T1: serverResp => bn(serverResp.ebitda_EVX_Consensus_T1),
    rev_Consensus_T0: serverResp => bn(serverResp.rev_Consensus_T0),
    rev_Consensus_T1: serverResp => bn(serverResp.rev_Consensus_T1),
    rev_YOY_Consensus_T0: serverResp => bn(serverResp.rev_YOY_Consensus_T0),
    rev_YOY_Consensus_T1: serverResp => bn(serverResp.rev_YOY_Consensus_T1),
    rev_PS_Consensus_T0: serverResp => bn(serverResp.rev_PS_Consensus_T0),
    rev_PS_Consensus_T1: serverResp => bn(serverResp.rev_PS_Consensus_T1),
    exchange: serverResp => `${serverResp.exchange}`,
    price_52w_Hi: serverResp => bn(serverResp.price_52w_Hi),
    price_52w_Lo: serverResp => bn(serverResp.price_52w_Lo),
    macd_DIFF: serverResp => bn(serverResp.macd_DIFF),
    macd_DEA: serverResp => bn(serverResp.macd_DEA),
    macd_MACD: serverResp => bn(serverResp.macd_MACD),
    cnt_FY: serverResp => Number(serverResp.cnt_FY),
    div_Pct: serverResp => bn(serverResp.div_Pct),
    avg_Profit_Parent_Comp: serverResp => bn(serverResp.avg_Profit_Parent_Comp),
    avg_OPCF: serverResp => bn(serverResp.avg_OPCF),
    avg_FCF: serverResp => bn(serverResp.avg_FCF),
    avg_ROE: serverResp => bn(serverResp.avg_ROE),
    roe_Hi: serverResp => bn(serverResp.roe_Hi),
    roe_Lo: serverResp => bn(serverResp.roe_Lo),
    vol_ROE: serverResp => bn(serverResp.vol_ROE),
    cagr_Revenue: serverResp => bn(serverResp.cagr_Revenue),
    cagr_Profit_Parent_Comp: serverResp => bn(serverResp.cagr_Profit_Parent_Comp),
    vol_Q8_Profit_Parent_Comp_YOY: serverResp => bn(serverResp.vol_Q8_Profit_Parent_Comp_YOY),
    avg_Q8_Profit_Parent_Comp_YOY: serverResp => bn(serverResp.avg_Q8_Profit_Parent_Comp_YOY),
    avg_ROIC: serverResp => bn(serverResp.avg_ROIC),
    avg_ROA: serverResp => bn(serverResp.avg_ROA),
    avg_Revenue_YOY: serverResp => bn(serverResp.avg_Revenue_YOY),
    avg_Profit_Parent_Comp_YOY: serverResp => bn(serverResp.avg_Profit_Parent_Comp_YOY),
    avg_EPS_Diluted_YOY: serverResp => bn(serverResp.avg_EPS_Diluted_YOY),
    quality: serverResp => bn(serverResp.quality),
    cluster_Layer_Or_Default: serverResp => Number(serverResp.cluster_Layer_Or_Default),
    cluster_Layer_Value: serverResp => Number(serverResp.cluster_Layer_Value),
    return_Default: serverResp => Number(serverResp.return_Default),
    return_Or_Default: serverResp => Number(serverResp.return_Or_Default),
    fcf_NP: serverResp => bn(serverResp.fcf_NP),
    cashflow_Default: serverResp => Number(serverResp.cashflow_Default),
    cashflow_Or_Default: serverResp => Number(serverResp.cashflow_Or_Default),
    company_Stability_Default: serverResp => Number(serverResp.company_Stability_Default),
    company_Stability_Or_Default: serverResp => Number(serverResp.company_Stability_Or_Default),
    avg_ADV: serverResp => bn(serverResp.avg_ADV),
    last_ID: serverResp => Number(serverResp.last_ID),
    cluster_Layer_Default: serverResp => Number(serverResp.cluster_Layer_Default),
    opcf_NP: serverResp => bn(serverResp.opcf_NP),
    company_Growth_Default: serverResp => Number(serverResp.company_Growth_Default),
    company_Growth_Or_Default: serverResp => Number(serverResp.company_Growth_Or_Default),
    trend_Score_Default: serverResp => bn(serverResp.trend_Score_Default),
    trend_Score_Or_Default: serverResp => bn(serverResp.trend_Score_Or_Default),
    status_Default: serverResp => Number(serverResp.status_Default),
    status_Or_Default: serverResp => Number(serverResp.status_Or_Default),
    
    quality_Score: serverResp => bn(serverResp.quality_Score),
    quality_Cap_Pct: serverResp => bn(serverResp.quality_Cap_Pct),
    risk_Line_Default: serverResp => Number(serverResp.risk_Line_Default),
    risk_Line_Or_Default: serverResp => Number(serverResp.risk_Line_Or_Default),
    risk_Line_Or_Default_Value: serverResp => bn(serverResp.risk_Line_Or_Default_Value),
    reward_Line_Default: serverResp => Number(serverResp.reward_Line_Default),
    reward_Line_Or_Default: serverResp => Number(serverResp.reward_Line_Or_Default),
    reward_Line_Or_Default_Value: serverResp => bn(serverResp.reward_Line_Or_Default_Value),
    kelly_Confidence_Default: serverResp => Number(serverResp.kelly_Confidence_Default),
    kelly_Confidence_Or_Default: serverResp => Number(serverResp.kelly_Confidence_Or_Default),
    valuation_Method_And_Keyfactor_Default: serverResp => Number(serverResp.valuation_Method_And_Keyfactor_Default),
    valuation_Method_And_Keyfactor_Or_Default: serverResp => Number(serverResp.valuation_Method_And_Keyfactor_Or_Default),
    valuation_KeyFactor_1_Default: serverResp => bn(serverResp.valuation_KeyFactor_1_Default),
    valuation_KeyFactor_2_Default: serverResp => bn(serverResp.valuation_KeyFactor_2_Default),
    valuation_KeyFactor_3_Default: serverResp => bn(serverResp.valuation_KeyFactor_3_Default),
    valuation_KeyFactor_4_Default: serverResp => bn(serverResp.valuation_KeyFactor_4_Default),
    valuation_KeyFactor_5_Default: serverResp => bn(serverResp.valuation_KeyFactor_5_Default),
    valuation_KeyFactor_6_Default: serverResp => bn(serverResp.valuation_KeyFactor_6_Default),
    valuation_KeyFactor_1_Or_Default: serverResp => bn(serverResp.valuation_KeyFactor_1_Or_Default),
    valuation_KeyFactor_2_Or_Default: serverResp => bn(serverResp.valuation_KeyFactor_2_Or_Default),
    valuation_KeyFactor_3_Or_Default: serverResp => bn(serverResp.valuation_KeyFactor_3_Or_Default),
    valuation_KeyFactor_4_Or_Default: serverResp => bn(serverResp.valuation_KeyFactor_4_Or_Default),
    valuation_KeyFactor_5_Or_Default: serverResp => bn(serverResp.valuation_KeyFactor_5_Or_Default),
    valuation_KeyFactor_6_Or_Default: serverResp => bn(serverResp.valuation_KeyFactor_6_Or_Default),
    valuation_Price_1: serverResp => bn(serverResp.valuation_Price_1),
    valuation_Price_2: serverResp => bn(serverResp.valuation_Price_2),
    valuation_Price_3: serverResp => bn(serverResp.valuation_Price_3),
    valuation_Price_4: serverResp => bn(serverResp.valuation_Price_4),
    valuation_Price_5: serverResp => bn(serverResp.valuation_Price_5),
    valuation_Price_6: serverResp => bn(serverResp.valuation_Price_6),
    valuation_Price_Low: serverResp => bn(serverResp.valuation_Price_Low),
    valuation_Price_High: serverResp => bn(serverResp.valuation_Price_High),
    risk_Pct: serverResp => bn(serverResp.risk_Pct),
    reward_Pct: serverResp => bn(serverResp.reward_Pct),
    reward_Risk_Ratio: serverResp => bn(serverResp.reward_Risk_Ratio),
    info_Completeness: serverResp => bn(serverResp.info_Completeness),
    pricing_Score: serverResp => bn(serverResp.pricing_Score),
    pricing_Score_User: serverResp => bn(serverResp.pricing_Score_User),
    avg_Price_Vol: serverResp => bn(serverResp.avg_Price_Vol),
    price_Vol: serverResp => Number(serverResp.price_Vol),
    liquidity: serverResp => Number(serverResp.liquidity),
    keyfactor_Valuation_Expert_T0: serverResp => bn(serverResp.keyfactor_Valuation_Expert_T0),
    keyfactor_Valuation_Expert_T1: serverResp => bn(serverResp.keyfactor_Valuation_Expert_T1),
    keyfactor_Valuation_Conviction_T0: serverResp => bn(serverResp.keyfactor_Valuation_Conviction_T0),
    keyfactor_Valuation_Conviction_T1: serverResp => bn(serverResp.keyfactor_Valuation_Conviction_T1),
    is_OPCF_Good: serverResp => Number(serverResp.is_OPCF_Good),
    is_FCF_Good: serverResp => Number(serverResp.is_FCF_Good),
    one_Mn_Pct: serverResp => bn(serverResp.one_Mn_Pct),
    total_Score: serverResp => bn(serverResp.total_Score),
    total_Score_User: serverResp => bn(serverResp.total_Score_User),
    kelly_Position: serverResp => bn(serverResp.kelly_Position),

    avg_Num_Shares: serverResp => bn(serverResp.avg_Num_Shares),
    avg_Cash_Dividend_PS: serverResp => bn(serverResp.avg_Cash_Dividend_PS),

    latest_Comment_ID: serverResp => Number(serverResp.latest_Comment_ID),

    shortable_Total_Shares: serverResp => bn(serverResp.shortable_Total_Shares),
    shortable_Total_USD: serverResp => bn(serverResp.shortable_Total_USD),
    shortable_Weighted_Rate: serverResp => bn(serverResp.shortable_Weighted_Rate),
};

/**
 * @param {(keyof StockInfo)[]} select
 */
export function makeStockInfoConverter(select) {
    /**
     * @param {unknown} serverResp 
     * @returns { StockInfo }
     */
    function convertStockInfo(serverResp) {
        const r = /** @type {StockInfo} */({});
        for (const key of select) {
            const converter = stockInfoConverters[key];
            // @ts-ignore
            r[key] = converter(serverResp);
        }
        return r;
    }
    return convertStockInfo;
}

export const convertStockInfo = makeStockInfoConverter(/** @type {(keyof StockInfo)[]} */(Object.keys(stockInfoConverters)));

/**
 * @returns {StockUpdatableInfo}
 */
export function convertStockUpdatableInfo(s) {
    return {
        code_Factset: `${s.code_Factset}`,
        code_BBG: `${s.code_BBG}`,
        code: `${s.code}`,
        name: `${s.name}`,
        ipo_Date: Number(s.ipo_Date),
        exchange: `${s.exchange}`,
        currency: `${s.currency}`,
        quarterly_Report_Type: Number(s.quarterly_Report_Type),
        keyword: `${s.keyword}`,
        coverer: s.coverer,
        introduce: s.introduce,
        timestamp: Number(s.timestamp),
        user_ID: Number(s.user_ID),
        cluster_Layer: Number(s.cluster_Layer),
        industrial_Chain_ID: Number(s.industrial_Chain_ID),
        section_ID: Number(s.section_ID),
        product_ID: Number(s.product_ID),
        sw1_ID: Number(s.sw1_ID),
        sw2_ID: Number(s.sw2_ID),
        sw3_ID: Number(s.sw3_ID),
        return: Number(s.return),
        industrial_Chain_Position_Importance: Number(s.industrial_Chain_Position_Importance),
        security_Importance: Number(s.security_Importance),
        management: Number(s.management),
        clients: Number(s.clients),
        cashflow: Number(s.cashflow),
        business_Stability: Number(s.business_Stability),
        company_Stability: Number(s.company_Stability),
        industry_Ceiling: Number(s.industry_Ceiling),
        company_Ceiling: Number(s.company_Ceiling),
        company_Growth: Number(s.company_Growth),
        expected_CAGR: bn(s.expected_CAGR),
        expected_CAGR_Topline: bn(s.expected_CAGR_Topline),
        expected_CAGR_Bottomline: bn(s.expected_CAGR_Bottomline),
        demand_Stage: Number(s.demand_Stage),
        supply_Stage: Number(s.supply_Stage),
        competitive_Advantage: Number(s.competitive_Advantage),
        ceiling_Source: Number(s.ceiling_Source),
        global_Size: `${s.global_Size}`,
        addressable_Size: `${s.addressable_Size}`,
        ceiling_Remark: `${s.ceiling_Remark}`,
        risk: Number(s.risk),
        cycle_Causation: Number(s.cycle_Causation),
        cycle_Position: Number(s.cycle_Position),
        med_Term_Trend: Number(s.med_Term_Trend),
        logic: Number(s.logic),
        ft_Width: Number(s.ft_Width),
        pt_Width: Number(s.pt_Width),
        trend_Stability: Number(s.trend_Stability),
        trend_Sustainability: Number(s.trend_Sustainability),
        trend_Strength: Number(s.trend_Strength),
        trend_Stage: Number(s.trend_Stage),
        trend_Width: Number(s.trend_Width),
        trend_Score: bn(s.trend_Score),
        trend_Exists: Number(s.trend_Exists),
        read_Next_FS: Number(s.read_Next_FS),
        alert_Before_Next_FS: Number(s.alert_Before_Next_FS),
        short_Wishlist: Number(s.short_Wishlist),
        status: Number(s.status),
        risk_Line: Number(s.risk_Line),
        reward_Line: Number(s.reward_Line),
        kelly_Confidence: Number(s.kelly_Confidence),
        valuation_Method_And_Keyfactor: Number(s.valuation_Method_And_Keyfactor),
        keyfactor_Expert_T0: bn(s.keyfactor_Expert_T0),
        keyfactor_Expert_T1: bn(s.keyfactor_Expert_T1),
        keyfactor_YOY_Expert_T0: bn(s.keyfactor_YOY_Expert_T0),
        keyfactor_YOY_Expert_T1: bn(s.keyfactor_YOY_Expert_T1),
        keyfactor_Conviction_T0: bn(s.keyfactor_Conviction_T0),
        keyfactor_Conviction_T1: bn(s.keyfactor_Conviction_T1),
        keyfactor_YOY_Conviction_T0: bn(s.keyfactor_YOY_Conviction_T0),
        keyfactor_YOY_Conviction_T1: bn(s.keyfactor_YOY_Conviction_T1),
        valuation_Multiple_1: bn(s.valuation_Multiple_1),
        valuation_Multiple_2: bn(s.valuation_Multiple_2),
        valuation_Multiple_3: bn(s.valuation_Multiple_3),
        valuation_Multiple_4: bn(s.valuation_Multiple_4),
        valuation_Multiple_5: bn(s.valuation_Multiple_5),
        valuation_Multiple_6: bn(s.valuation_Multiple_6),
        valuation_Keyfactor_1: bn(s.valuation_Keyfactor_1),
        valuation_Keyfactor_2: bn(s.valuation_Keyfactor_2),
        valuation_Keyfactor_3: bn(s.valuation_Keyfactor_3),
        valuation_Keyfactor_4: bn(s.valuation_Keyfactor_4),
        valuation_Keyfactor_5: bn(s.valuation_Keyfactor_5),
        valuation_Keyfactor_6: bn(s.valuation_Keyfactor_6),
        valuation_Price_Low: bn(s.valuation_Price_Low),
        valuation_Price_High: bn(s.valuation_Price_High),
        shortable: Number(s.shortable),
        call_Watch_Date: Number(s.call_Watch_Date),
        call_Watch_Date_Price_Close: bn(s.call_Watch_Date_Price_Close),
        call_Watch_Report_Quarterly_ID: Number(s.call_Watch_Report_Quarterly_ID),
        call_Watch_Comment_ID: Number(s.call_Watch_Comment_ID),
        penetration_Rate: bn(s.penetration_Rate),
        cr_N: Number(s.cr_N),
        cr_N_Value: bn(s.cr_N_Value),
        potential_GR: bn(s.potential_GR),
        external_Power: bn(s.external_Power),
        stock_Power: bn(s.stock_Power),
    }
}

/**
 * @returns { StockReportQuarterlyNotice }
 */
function convertStockReportQuarterlyNotice(serverResp) {
    return {
        id: Number(serverResp.id),
        code: serverResp.code,
        rpt_Period: n(serverResp.rpt_Period),
        last: Boolean(serverResp.last),
        price_Close: bn(serverResp.price_Close),
        pt_Score: bn(serverResp.pt_Score),
        ft_Score: bn(serverResp.ft_Score),
        ft_Type: n(serverResp.ft_Type),
        trend_Score: bn(serverResp.trend_Score),
        pricing_Score: bn(serverResp.pricing_Score),
        quality_Score: bn(serverResp.quality_Score),
        notice_Release_Date: n(serverResp.notice_Release_Date),
        notice_Release_1st_Date: n(serverResp.notice_Release_1st_Date),
        notice_Abstract: serverResp.notice_Abstract,
        notice_Reason: serverResp.notice_Reason,
        notice_Accm_NP_Dside_Pct: bn(serverResp.notice_Accm_NP_Dside_Pct),
        notice_Accm_NP_Uside_Pct: bn(serverResp.notice_Accm_NP_Uside_Pct),
        notice_Accm_NP_Dside: bn(serverResp.notice_Accm_NP_Dside),
        notice_Accm_NP_Uside: bn(serverResp.notice_Accm_NP_Uside),
        notice_Q_Type: serverResp.notice_Q_Type,
        notice_Q_NP_Dside_Pct: bn(serverResp.notice_Q_NP_Dside_Pct),
        notice_Q_NP_Uside_Pct: bn(serverResp.notice_Q_NP_Uside_Pct),
        notice_Q_NP_Dside: bn(serverResp.notice_Q_NP_Dside),
        notice_Q_NP_Uside: bn(serverResp.notice_Q_NP_Uside),
        status_Action: serverResp.status_Action,
        status_Action_Date: serverResp.status_Action_Date,
        status_Action_User: serverResp.status_Action_User,
        status_Action_Timestamp: serverResp.status_Action_Timestamp
    }
}

/** @returns { StockReportQuarterlyExpress } */
function convertStockReportQuarterlyExpress(serverResp) {
    return {
        id: Number(serverResp.id),
        code: `${(serverResp.code)}`,
        rpt_Period: n(serverResp.rpt_Period),
        last: Boolean(serverResp.last),
        price_Close: bn(serverResp.price_Close),
        pt_Score: bn(serverResp.pt_Score),
        ft_Score: bn(serverResp.ft_Score),
        ft_Type: n(serverResp.ft_Type),
        trend_Score: bn(serverResp.trend_Score),
        pricing_Score: bn(serverResp.pricing_Score),
        quality_Score: bn(serverResp.quality_Score),
        express_Release_Date: n(serverResp.express_Release_Date),
        express_Accm_Rev: bn(serverResp.express_Accm_Rev),
        express_Accm_Rev_YOY: bn(serverResp.express_Accm_Rev_YOY),
        express_Accm_OP_Profit: bn(serverResp.express_Accm_OP_Profit),
        express_Accm_OP_Profit_YOY: bn(serverResp.express_Accm_OP_Profit_YOY),
        express_Accm_Profit_Parent_Comp: bn(serverResp.express_Accm_Profit_Parent_Comp),
        express_Accm_Profit_Parent_Comp_YOY: bn(serverResp.express_Accm_Profit_Parent_Comp_YOY),
        express_Accm_Profit_Parent_Comp_Last_Year: bn(serverResp.express_Accm_Profit_Parent_Comp_Last_Year),
        express_Accm_OP_Profit_Last_Year: bn(serverResp.express_Accm_OP_Profit_Last_Year),
        express_Q_Rev: bn(serverResp.express_Q_Rev),
        express_Q_Rev_YOY: bn(serverResp.express_Q_Rev_YOY),
        express_Q_OP_Profit: bn(serverResp.express_Q_OP_Profit),
        express_Q_OP_Profit_YOY: bn(serverResp.express_Q_OP_Profit_YOY),
        express_Q_Profit_Parent_Comp: bn(serverResp.express_Q_Profit_Parent_Comp),
        express_Q_Profit_Parent_Comp_YOY: bn(serverResp.express_Q_Profit_Parent_Comp_YOY),
        status_Action: serverResp.status_Action,
        status_Action_Date: serverResp.status_Action_Date,
        status_Action_User: serverResp.status_Action_User,
        status_Action_Timestamp: serverResp.status_Action_Timestamp
    };
}

/** @returns { StockReportQuarterlyFA } */
function convertStockReportQuarterlyFA(serverResp) {
    return {
        id: Number(serverResp.id),
        code: `${(serverResp.code)}`,
        rpt_Period: n(serverResp.rpt_Period),
        last: Boolean(serverResp.last),
        price_Close: bn(serverResp.price_Close),
        pt_Score: bn(serverResp.pt_Score),
        ft_Score: bn(serverResp.ft_Score),
        ft_Type: n(serverResp.ft_Type),
        trend_Score: bn(serverResp.trend_Score),
        pricing_Score: bn(serverResp.pricing_Score),
        quality_Score: bn(serverResp.quality_Score),
        fa_Expected_Release_Date: n(serverResp.fa_Expected_Release_Date),
        fa_Release_Date: n(serverResp.fa_Release_Date),
        fa_Accm_Rev: bn(serverResp.fa_Accm_Rev),
        fa_Accm_Rev_YOY: bn(serverResp.fa_Accm_Rev_YOY),
        fa_Accm_OP_Profit: bn(serverResp.fa_Accm_OP_Profit),
        fa_Accm_OP_Profit_YOY: bn(serverResp.fa_Accm_OP_Profit_YOY),
        fa_Accm_Profit_Parent_Comp: bn(serverResp.fa_Accm_Profit_Parent_Comp),
        fa_Accm_Profit_Parent_Comp_YOY: bn(serverResp.fa_Accm_Profit_Parent_Comp_YOY),
        fa_Accm_Profit_Parent_Comp_Deducted: bn(serverResp.fa_Accm_Profit_Parent_Comp_Deducted),
        fa_Accm_Profit_Parent_Comp_Deducted_YOY: bn(serverResp.fa_Accm_Profit_Parent_Comp_Deducted_YOY),
        fa_Q_Rev: bn(serverResp.fa_Q_Rev),
        fa_Q_Rev_YOY: bn(serverResp.fa_Q_Rev_YOY),
        fa_Q_OP_Profit: bn(serverResp.fa_Q_OP_Profit),
        fa_Q_OP_Profit_YOY: bn(serverResp.fa_Q_OP_Profit_YOY),
        fa_Q_Profit_Parent_Comp: bn(serverResp.fa_Q_Profit_Parent_Comp),
        fa_Q_Profit_Parent_Comp_YOY: bn(serverResp.fa_Q_Profit_Parent_Comp_YOY),
        fa_Q_Profit_Parent_Comp_Deducted: bn(serverResp.fa_Q_Profit_Parent_Comp_Deducted),
        fa_Q_Profit_Parent_Comp_Deducted_YOY: bn(serverResp.fa_Q_Profit_Parent_Comp_Deducted_YOY),
        status_Action: serverResp.status_Action,
        status_Action_Date: serverResp.status_Action_Date,
        status_Action_User: serverResp.status_Action_User,
        status_Action_Timestamp: serverResp.status_Action_Timestamp
    };
}

/**
 * @param {string} code 
 * @param {number} lastId 
 * @returns { Promise<StockInfo> }
 */
export async function getStockInfo(code, lastId) {
    const a = await get(`/api/stock/${code}`, { lastId });
    return convertStockInfo(a);
}

/**
 * @param {string} code 
 * @param {Partial<Record<keyof StockInfoUpdate, string>>} data 
 * @returns { Promise<StockInfo> }
 */
export async function updateStockInfo(code, data) {
    const a = await postJson(`/api/stock/${code}`, data);
    return convertStockInfo(a);
}

/**
 * @param {string} code 
 * @param {Partial<Record<keyof StockInfoUpdate, string>>} data 
 * @returns { Promise<StockInfo> }
 */
export async function tryUpdateStockInfo(code, data) {
    return convertStockInfo(
        await postJson(`/api/stock/${code}/try`, data)
    );
}

/**
 * 
 * @param {string} code 
 * @param {number} status 
 * @param {number} date 
 * @param {number} reportId 
 * @param {number} commentId 
 * @returns { Promise<[string, StockInfo]>}
 */
export async function updateStockStatus(code, status, date, reportId, commentId) {
    const [msg, info] = await post(`/api/stock/${code}/status`, { status, date, reportId, commentId });
    return [msg, convertStockInfo(info)];
}

export async function updateReportFTType(reportId, ft_type) {
    return convertStockInfo(
        await post(`/api/report/quarterly/${reportId}/ft`, { ft_type })
    );
}

/**
 * @param {string} code 
 * @param {number[]} coverer 
 * @returns {Promise<StockInfo>}
 */
export async function updateStockCoverer(code, coverer) {
    return await post(`/api/stocklist/${code}/coverer`, { coverer });
}

/**
 * @param {string} code 
 */
export async function getStockReportQuarterly(code) {
    /** @type {{ noticeList: any[], expressList: any[], faList: any[] }} */
    const r = await get(`/api/stock/${code}/report`);
    return {
        noticeList: r.noticeList.map(convertStockReportQuarterlyNotice),
        expressList: r.expressList.map(convertStockReportQuarterlyExpress),
        faList: r.faList.map(convertStockReportQuarterlyFA),
    };
}

/**
 * @param {string} code 
 * @param {number} start 
 * @param {number} end 
 * @returns {Promise<StockKChartItem[]>}
 */
export async function getStockKChartList(code, start, end) {
    return get(`/api/stock/${code}/kchart`, { start, end });
}

/**
 * @param {string} code 
 * @param {number} start 
 * @param {number} end 
 * @returns {Promise<StockRiskRewardChartItem[]>}
 */
export async function getStockRiskRewardList(code, start, end) {
    return get(`/api/stock/${code}/risk-reward`, { start, end });
}

async function _getStockSearchList(token) {
    return get('/api/stock/search-list');
}

const _cached_getStockSearchList = cached(_getStockSearchList);

/**
 * @returns {Promise<[code: string, name: string, keyword: string][]>}
 */
export async function getStockSearchList() {
    const token = getToken();
    return _cached_getStockSearchList(token?.accessToken);
}

/** @type {[StockInfo|undefined, boolean, Error]} */
const initData = [undefined, true, null];

/**
 * @param {string} code 
 * @returns {[StockInfo|undefined, boolean, Error, (data:[StockInfo, boolean, Error]) => void]}
 */
export function useStockInfo(code) {
    const [data, setData] = useState(initData);
    useCoEffect(function *(){
        let lastId = 0;
        while (true) {
            try {
                setData(data => [data[0], true, data[2]]);
                /** @type {StockInfo} */
                const r = yield getStockInfo(code, lastId);
                if (r) {
                    lastId = r.last_ID;
                    setData([r, false, null]);
                } else {
                    setData(data => [data[0], false, null]);
                }
            } catch (e) {
                setData(data => [data[0], false, e]);
            }
            return;//禁用更新功能
            yield timeout(1000);
        }
    }, [code]);
    return [...data, setData];
}

export function addEmptyReport(code, type, rpt_period, release_date) {
    return post('/api/report/quarterly/empty', { code, type, rpt_period, release_date });
}

export function addReport(report) {
    return postJson('/api/report/quarterly', report);
}

export function urlOfExportCallTable() {
    return '/export/call?access_token=' + getToken()?.accessToken;
}

/** @returns {Promise<{[key: string]: string}>} */
export function getStockExternalInfo(code) {
    return get(`/api/stock/${code}/external`);
}

export async function getStockUpdatableList() {
    /** @type {any[]} */
    const l = await get('/api/stock/info-list');
    return l.map(convertStockUpdatableInfo);
}

/**
 * @param {{
 *   Code: string,
 *   Code_Factset: string,
 *   Code_BBG: string,
 *   Name: string,
 *   IPO_Date?: number,
 *   Exchange?: string,
 *   Currency?: string,
 *   Quarterly_Report_Type: number,
 *   Keyword?: string,
 *   Industrial_Chain?: string,
 *   Section?: string,
 *   Product?: string,
 *   SW1?: string,
 *   SW2?: string,
 *   SW3?: string,
 *   Coverer?: number[],
 *   Shortable?: number,
 *   Cluster_Layer?: number,
 *   Introduce: string,
 * }} stock 
 */
export async function addNewStock(stock) {
    return await post('/init-data/stock', stock);
}

/**
 * @param {string[]} exchanges 
 * @returns { Promise<string[]> }
 */
export async function getStockCodesWithExchanges(exchanges = []) {
    return await get('/api/stocklist/code', { exchange: exchanges });
}

/**
 * @param {string[]} exchanges 
 * @returns { Promise<{ code: string, code_BBG:string, exchange: string }[]> }
 */
 export async function getStockCodesAndExchangeWithExchanges(exchanges = []) {
    const r = /** @type {string[][]} */(
        await get('/api/stocklist/codes-and-exchange', { exchange: exchanges })
    );
    return r.map(item => ({
        code: item[0],
        code_BBG: item[1],
        exchange: item[2]
    }));
}

export async function getStockListItem(code) {
    if (!code) return null;
    return await get(`/api/stocklist/${code}`);
}

/** 
 * @typedef {{
 *   code: string,
 *   date: number,
 *   next_trade_date: number,
 *   price_Close: BigDecimal
 * }} CodeDateAndPriceClose
 */

/**
 * @param {{code:string, date:number}[]} codeAndDates
 */
export async function getNextTradeDayPriceCloseForCodes(codeAndDates) {
    if (!codeAndDates?.length) return [];
    const req = /** @type {{[date: number]: string[]}} */({});
    for (const codeAndDate of codeAndDates) {
        let dates = req[codeAndDate.date];
        if (!dates) {
            dates = [];
            req[codeAndDate.date] = dates;
        }
        dates.push(codeAndDate.code);
    }
    /** @type {[string, number, number, string][]} */
    const r = await postJson('/api/stock/next-trade-day-price', req);
    return r.map(item => /** @type {CodeDateAndPriceClose} */({
        code: item[0],
        date: item[1],
        next_trade_date: item[2],
        price_Close: bn(item[3])
    }));
}