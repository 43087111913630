import { get, post, put } from "./common";

/**
 * @typedef {{
 *   id: number,
 *   code: string,
 *   version: number,
 *   report_Date: number,
 *   title: string,
 *   theme: string,
 *   release_Time: number,
 *   parsed_Type: 1 | 2 | 3 | 4,
 *   parsed_Quarter: number,
 *   parsed_Quarter_Or_Semi: 1 | 2,
 *   conflict: 0 | 1 | 2,
 * }} StockReportUploadCN
 */

/**
 * @returns { Promise<number> }
 */
export async function createStockReportUploadCNTable() {
    return await post('/api/user-table/stock-report-upload-cn');
}

/**
 * @returns { Promise<{valid: number, uploaded: number }> }
 */
export async function getStockReportUploadCNTableVersions() {
    return await get('/api/user-table/stock-report-upload-cn/version')
}

/**
 * @param { number } version
 * @returns { Promise<StockReportUploadCN[]> }
 */
export async function getStockReportUploadCNTable(version) {
    const list = /** @type{any[]} */(await get('/api/user-table/stock-report-upload-cn', { version }));
    return list.map(s => ({
        id: s.id,
        code: s.code,
        version: s.version,
        report_Date: s.report_Date,
        title: s.title,
        theme: s.theme,
        release_Time: s.release_Time,
        parsed_Type: s.parsed_Type,
        parsed_Quarter: s.parsed_Quarter,
        parsed_Quarter_Or_Semi: s.parsed_Quarter_Or_Semi,
        conflict: s.conflict,
    }));
}

/**
 * @param { number } version
 * @param { string } code
 * @param { Omit<StockReportUploadCN, 'id'|'code'|'version'> } row
 * @returns { Promise<number> }
 */
export async function uploadStockReportUploadCNTableRow(version, code, row) {
    const {
        report_Date,
        title,
        theme,
        release_Time,
        parsed_Type,
        parsed_Quarter,
        parsed_Quarter_Or_Semi,
        conflict,
    } = row;
    const data = {
        code,
        report_Date,
        title,
        theme,
        release_Time,
        parsed_Type,
        parsed_Quarter,
        parsed_Quarter_Or_Semi,
        conflict,
    };
    return await post(`/api/user-table/stock-report-upload-cn/${version}/row`, data);
}

/**
 * @param { number } id
 * @param { Partial<Pick<StockReportUploadCN, 'parsed_Quarter'|'parsed_Type'|'parsed_Quarter_Or_Semi'|'conflict'>> } row
 * @returns { Promise<void> }
 */
 export async function updateStockReportUploadCNTableRow(id, row) {
    const {
        parsed_Type,
        parsed_Quarter,
        parsed_Quarter_Or_Semi,
        conflict
    } = row;
    const data = {
        parsed_Type,
        parsed_Quarter,
        parsed_Quarter_Or_Semi,
        conflict
    };
    return await put(`/api/user-table/stock-report-upload-cn/${id}`, data);
}

/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function commitStockReportUploadCNTable(version) {
    return await post(`/api/user-table/stock-report-upload-cn/${version}/commit`);
}

/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function validStockReportUploadCNTable(version) {
    return await post(`/api/user-table/stock-report-upload-cn/${version}/valid`);
}


/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function invalidStockReportUploadCNTable(version) {
    return await post(`/api/user-table/stock-report-upload-cn/${version}/invalid`);
}
