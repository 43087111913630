import bigDecimal from "js-big-decimal";
import { bn } from "../lib/bn";
import { del, get, post, put } from "./common";

/**
 * @typedef {{
 *   id: number,
 *   code: string,
 *   stock_Report_Quarterly_ID: number,
 *   date: number,
 *   content: string,
 *   create_User_ID: number,
 *   create_Timestamp: number,
 *   create_Stock_Snapshot_ID: number,
 *   update_User_ID: number,
 *   update_Timestamp: number,
 *   status_Action: number,
 *   status_Action_Date: number,
 *   status_Action_Timestamp: number,
 *   status_Action_User: number,
 *   pricing_Score: bigDecimal,
 *   quality_Score: bigDecimal,
 *   trend_Score: bigDecimal,
 *   valuation_Price_Low: bigDecimal,
 *   valuation_Price_High: bigDecimal,
 *   ticket_Comment_ID: number,
 *   external: number,
 *   power: number,
 *   short_Wishlist: number,
 * }} Comment
 */

/**
 * @param {any} s
 * @returns { Comment }
 */
function parseComment(s) {
    return {
        ...s,
        pricing_Score: bn(s.pricing_Score),
        quality_Score: bn(s.quality_Score),
        trend_Score: bn(s.trend_Score),
        valuation_Price_Low: bn(s.valuation_Price_Low),
        valuation_Price_High: bn(s.valuation_Price_High)
    };
}

/**
 * @param {string} code 
 * @returns { Promise<Comment[]> }
 */
export async function getAllCommentsForStock(code) {
    const s = await get('/api/comment', { code });
    return s.map(parseComment);
}

/**
 * @param {number[]} ids 
 * @returns { Promise<Comment[]> }
 */
export async function getCommentsByIds(ids) {
    const s = await get('/api/comment', { id: ids.join(',' )});
    return s.map(parseComment);
}

/** 
 * @param {string} code
 * @param {number} reportId
 * @param {number} date
 * @param {string} content
 * @returns { Promise<{ reportId?: number, commentId: number }> }
 */
export function createComment(code, reportId, date, content) {
    return post('/api/comment', {
        code, reportId, date, content
    });
}

export function updateComment(id, content) {
    return put(`/api/comment/${id}`, { content });
}

/**
 * @param {number} id
 * @param {{
 *   quality_Score?: string,
 *   trend_Score?: string,
 *   pricing_Score?: string,
 *   valuation_Price_Low?: string,
 *   valuation_Price_High?: string,
 *   external?: number,
 *   power?: number,
 *   short_Wishlist?: number,
 * }} fields 
 */
export function  updateCommentFields(id, fields) {
    return put(`/api/comment/${id}`, fields);
}

export function deleteComment(id) {
    return del(`/api/comment/${id}`);
}

