
/**
 * multiconcat([['a1'], ['a2']], [['b1'], ['b2']]) = [['a1', 'b1'], ['a1', 'b2'], ['a2', 'b1'], ['a2', 'b2']]
 * @param {string[][]} a 
 * @param {string[][]} b 
 * @returns {string[][]}
 */
 function multiconcat(a, b) {
    /** @type {string[][]} */
    const r = [];
    a.forEach(ai => {
        b.forEach(bi => {
            r.push([...ai, ...bi]);
        });
    });
    return r;
}

/**
 * @param {string} words 
 */
async function getPinyinList(words) {
    const pinyin = await import('pinyin');
    const py = pinyin.default(words, { style: pinyin.STYLE_NORMAL, heteronym: true, segment: true })
    .map(chars => [[chars[0]]]);
    // .map(chars => chars.map(py => [py]));
    const all = py.reduce(multiconcat, [[]]);
    return all;
}

export async function makePinyinKeyword(name) {
    const pys = await getPinyinList(name);
    const full = pys.map(py => py.join('')).join(' ');
    const short = pys.map(py => py.map(s => s[0]).join('')).join(' ');
    return (short + ' ' + full).trim();
}
