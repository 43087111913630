import echarts from 'echarts';
import React, { useEffect, useRef } from 'react';

/**
 * 
 * @param {{
 *   style?: React.CSSProperties,
 *   className?: string,
 *   option: any,
 *   updateOption: any,
 *   onClick?: (event: any) => void,
 *   onDblClick?: (event: any) => void
 * }} param0 
 */
export default function ECharts({ style, className, option, updateOption, onClick, onDblClick }) {
    const divRef = useRef();
    /** @type { React.MutableRefObject<echarts.ECharts> } */
    const chartRef = useRef();
    useEffect(() => {
        console.log('[echarts] init chart');
        const chart = echarts.init(divRef.current);
        chartRef.current = chart;
        return () => {
            console.log('[echarts] destroyed');
            chart.dispose();
        }
    }, []);
    useEffect(() => {
        console.log('[echarts] init chart option', option);
        chartRef.current.setOption(option, true);
    }, [option]);
    useEffect(() => {
        console.log('[echarts] update chart option', option);
        chartRef.current.setOption(updateOption, false);
    }, [updateOption]);
    useEffect(() => {
        if (onClick) {
            const chart = chartRef.current;
            chart.on('click', onClick);
            return () => chart.off('click', onClick);
        }
    }, [onClick]);
    useEffect(() => {
        if (onDblClick) {
            const chart = chartRef.current;
            chart.on('dblclick', onDblClick);
            return () => chart.off('dblclick', onDblClick);
        }
    }, [onDblClick]);
    return <div style={style} className={className} ref={divRef} />;
}