import { get, post } from "./common";

/**
 * @typedef {{
 *   cycleCausationFlagList: {
 *     cycle_Causation_Flag: number,
 *     cycle_Causation_Name: string
 *   }[],
 *   industrialChainList: {
 *     industrial_Chain_ID: number,
 *     industrial_Chain_Name: string,
 *   }[],
 *   sectionList: {
 *     section_ID: number,
 *     section_Name: string,
 *   }[],
 *   industryList: {
 *     industry_ID: number,
 *     industry_Name: string,
 *   }[],
 *   subIndustryList: {
 *     sub_Industry_ID: number,
 *     sub_Industry_Name: string,
 *   }[],
 *   productList: {
 *     product_ID: number,
 *     product_Name: string,
 *   }[],
 *   sw1List: {
 *     sw1_ID: number,
 *     sw1_Name: string
 *   }[],
 *   sw2List: {
 *     sw2_ID: number,
 *     sw2_Name: string
 *   }[],
 *   sw3List: {
 *     sw3_ID: number,
 *     sw3_Name: string
 *   }[],
 * }} Enums
 */

/**
 * @returns { Promise<Enums> }
 */
export async function getEnums() {
    const r = /** @type {[number, string][][]} */(await get('/api/enum'));
    return {
        cycleCausationFlagList: r[0].map(t => ({ cycle_Causation_Flag: t[0], cycle_Causation_Name: t[1] })),
        industrialChainList: r[1].map(t => ({ industrial_Chain_ID: t[0], industrial_Chain_Name: t[1] })),
        sectionList: r[2].map(t => ({ section_ID: t[0], section_Name: t[1] })),
        industryList: r[3].map(t => ({ industry_ID: t[0], industry_Name: t[1] })),
        subIndustryList: r[4].map(t => ({ sub_Industry_ID: t[0], sub_Industry_Name: t[1] })),
        productList: r[5].map(t => ({ product_ID: t[0], product_Name: t[1] })),
        sw1List: r[6].map(t => ({ sw1_ID: t[0], sw1_Name: t[1] })),
        sw2List: r[7].map(t => ({ sw2_ID: t[0], sw2_Name: t[1] })),
        sw3List: r[8].map(t => ({ sw3_ID: t[0], sw3_Name: t[1] })),
    };
}

export async function createEnum(type, data) {
    return post(`/api/enum/${type}`, data);
}