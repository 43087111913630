import { Fab } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { FullHeightVirtualScrollTable } from '../../Table';
import { FieldNames } from '../../lib/enums';
import { useApiState } from '../../api/common';
import useErrorMessage from '../../lib/useErrorMessage';
import { useSnackbar } from 'notistack';
import { AddRounded } from '@material-ui/icons';
import { bns } from '../../lib/bn';
import { getRelationCNHKTable } from '../../api/relation-cn-hk';
import { getStockInfo, getStockSearchList } from '../../api/stock';


/** 
 * @typedef {{
 *   code_CN: string,
 *   name_CN: string,
 *   code_HK: string,
 *   name_HK: string,
 *   rate: string,
 * }} RelationCNHKRenderedRow 
 */

/**
 * @param {import('../../api/relation-cn-hk').RelationCNHK} r
 * @param {{[code: string]: string}} nameMap
 * @returns { RelationCNHKRenderedRow }
 */
function renderRow(r, nameMap) {
    return {
        code_CN: r.code_CN,
        name_CN: nameMap[r.code_CN],
        code_HK: r.code_HK,
        name_HK: nameMap[r.code_HK],
        rate: bns(r.rate)
    };
}

/** @type {import('../../Table').ColumnOption<RelationCNHKRenderedRow>[]} */
const columns = [
    {
        field: 'code_CN',
        label: 'A股代码',
        width: 150,
        model: 'string',
        render: row => <Link to={`/stock/${row.code_CN}`} target="_blank">{row.code_CN}</Link>
    },
    {
        field: 'name_CN',
        label: 'A股名称',
        width: 150,
        model: 'string',
    },
    {
        field: 'code_HK',
        label: 'H股代码',
        width: 150,
        model: 'string',
        render: row => <Link to={`/stock/${row.code_HK}`} target="_blank">{row.code_HK}</Link>
    },
    {
        field: 'name_HK',
        label: 'H股名称',
        width: 150,
        model: 'string',
    },
    {
        field: 'rate',
        label: 'A股/H股',
        width: 200,
        model: 'number'
    },
];

/** 
 * @type {import('../../Table').SorterAndFilters<RelationCNHKRenderedRow>} */
const initSorterAndFilters = null;

/** @type {import('../../Table').Fields<RelationCNHKRenderedRow>[]} */
const visibleColumns = columns.map(col => col.field);

/** @type {import('../../Table').FullHeightVirtualScrollTableComponent<RelationCNHKRenderedRow>} */
// @ts-ignore
const Table = FullHeightVirtualScrollTable;

export default function RelationCNHKTable({  }) {
    const [reload, setReload] = useState(0);
    const [table, loading, error] = useApiState(getRelationCNHKTableReloadable, reload);
    const [stocks] = useApiState(getStockSearchList);
    const nameMap = useMemo(() => {
        /** @type {{[code: string]: string}} */
        const r = {};
        if (!stocks) return r;
        for (const s of stocks) {
            r[s[0]] = s[1];
        }
        return r;
    }, [stocks]);
    const data = useMemo(() => (table||[]).map(row => renderRow(row, nameMap)), [table, nameMap]);
    useErrorMessage(error);

    const { enqueueSnackbar } = useSnackbar();
    const [showImport, setShowImport] = useState(false);
    const { url } = useRouteMatch();
    
    const [sorterAndFilters, setSorterAndFilters] = useState(initSorterAndFilters);
    
    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, position: 'relative' }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, display: 'flex' }}>
                <Table
                    data={data}
                    columns={columns}
                    sorterAndFilters={sorterAndFilters}
                    onSorterAndFiltersChange={setSorterAndFilters}
                    variant="full"
                    visibleColumnOrder={visibleColumns}
                />
            </div>
        </div>
        <div style={{height:60}}>
            <Link to={`${url}/add`}>
                <Fab
                    size="small" 
                    color="primary" 
                    style={{ position: 'fixed', bottom: 10, left: 20 }} 
                    onClick={() => setShowImport(true)}
                >
                    <AddRounded />
                </Fab>
            </Link>
        </div>
    </div>
}

function getRelationCNHKTableReloadable(ignored) {
    return getRelationCNHKTable();
}
