import { Tooltip } from '@material-ui/core';
import React from 'react';

/**
 * @param {{
 *   children: string
 * }} param0 
 */
export default function DetailTextPopup({ children }) {
    return <Tooltip title={children}><span>{ children }</span></Tooltip>
}