import React from 'react';
import moment from 'moment';
import { sortFilterData } from './lib';

/**
 * @param {unknown} node 
 * @returns {string}
 */
function sanitizeNode(node) {
    if (node === undefined || node === null) {
        return "";
    } else if (Array.isArray(node)) {
        return node.map(sanitizeNode).join('');
    } else if (typeof node === 'object') {
        return sanitizeNode(node.props?.children);
    } else {
        return `${node ?? ''}`
    }
}

/** 
 * @template T
 * @typedef {{
 *   data: Readonly<Readonly<T>[]>,
 *   columns: Readonly<Readonly<import(".").ColumnOption<T>>[]>,
 *   visibleColumnOrder: Readonly<import(".").Fields<T>[]>,
 *   sorterAndFilters: import("./lib").SorterAndFilters<T>,
 *   types: Partial<{[key in keyof T]: 'string' | 'number' | 'date' | 'percentage' }>,
 * }} ExcelSheetData
 */


/** 
 * @template T
 * @param {ExcelSheetData<T>} param0 
 */
function createSheetAoa({
    data,
    columns,
    visibleColumnOrder,
    sorterAndFilters,
    types
}) {
    if (!visibleColumnOrder) {
        visibleColumnOrder = columns.map(col => col.field);
    }
    data = sortFilterData(data, sorterAndFilters);
    /** @type {{[key: string]: Readonly<import(".").ColumnOption<T>>}} */
    const colMap = {};
    for (const col of columns) {
        colMap[col.field] = col;
    }
    /** @type {(string | Date | number)[][]} */
    const aoa = [];
    const header = [];
    for (const colName of visibleColumnOrder) {
        let label = colMap[colName].label;
        header.push(sanitizeNode(label));
    }
    aoa.push(header);
    for (const row of data) {
        /** @type {(string | Date | number)[]} */
        const sheetRow = [];
        for (const colName of visibleColumnOrder) {
            let renderer = colMap[colName].render || (a => a[colName]);
            /** @type {string | Date | number} */
            let content = sanitizeNode(renderer(row));
            if (content !== "") {
                const type = types?.[colName];
                if (type === 'string') {
                    content = `${content}`;
                } else if (type === 'number') {
                    content = Number(content);
                } else if (type === 'date') {
                    const m = moment(`${content}`);
                    if (m.isValid()) {
                        m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                        content = m.toDate();
                    } else {
                        content = '';
                    }
                } else if (type === 'percentage') {
                    content = `${content}`;
                    if (content.endsWith('%')) {
                        content = content.substr(0, content.length - 1);
                    }
                    content = Number(content);
                }
            }
            sheetRow.push(content);
        }
        aoa.push(sheetRow);
    }
    return aoa;
}

/**
 * @template {{[key:string]: any}} T
 * @param {Readonly<Readonly<T>[]>} data
 * @param {Readonly<Readonly<import(".").ColumnOption<T>>[]>} columns
 * @param {Readonly<import(".").Fields<T>[]>} visibleColumnOrder
 * @param {import("./lib").SorterAndFilters<T>} sorterAndFilters
 * @param {Partial<{[key in keyof T]: 'string' | 'number' | 'date' | 'percentage' }>} types
 * @param {string} name
 * @param {string} sheetName
 * 
 */
export default async function createExcel(data, columns, visibleColumnOrder, sorterAndFilters, types, name, sheetName) {
    const aoa = createSheetAoa({
        data,
        columns,
        visibleColumnOrder,
        sorterAndFilters,
        types
    });
    console.log(aoa);
    const { default: XLSX } = await import('xlsx');
    const sheet = XLSX.utils.aoa_to_sheet(aoa);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
    XLSX.writeFile(workbook, name + '.xlsx');
}

/**
 * @param { (ExcelSheetData<unknown> & { sheetName: string })[]} sheets 
 * @param {string} filename 
 */
export async function createExcelSheets(sheets, filename) {
    const { default: XLSX } = await import('xlsx');
    const workbook = XLSX.utils.book_new();

    for (const sheet of sheets) {
        const aoa = createSheetAoa(sheet);
        const s = XLSX.utils.aoa_to_sheet(aoa);
        XLSX.utils.book_append_sheet(workbook, s, sheet.sheetName);
    }
    
    XLSX.writeFile(workbook, filename + '.xlsx');
}
