import { Checkbox, darken, FormControl, InputLabel, ListItemText, makeStyles, MenuItem, Paper, Select } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { FixedTable, VirtualScrollTable } from './Table';
import { useGlobal } from './lib/useGlobal';
import { useApiState } from './api/common';
import useErrorMessage from './lib/useErrorMessage';
import { bns, bnsPercent } from './lib/bn';
import { fromDays2000, toDate } from './lib/date';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { getEnums } from './api/enum';
import { getAggrClusterLayer, getAggrQualityScore } from './api/aggr';
import bigDecimal from 'js-big-decimal';
import useQuery from './lib/useQuery';
import { FieldNames } from './lib/enums';

/** @template {{}} T @typedef {import('./api/aggr').AggrFields<T>} AggrFields */
/** @typedef {import('./api/aggr').AggrClusterLayer} AggrClusterLayer */
/** @typedef {import('./api/aggr').AggrQualityScore} AggrQualityScore */

/** 
 * @typedef {{
 *  id: number,
 *  count: number,
 *  avg_Reward_Pct: string,
 *  avg_Risk_Pct: string,
 *  reward_Risk_Ratio: string,
 *  avg_Chg_Pct_1D: string,
 *  avg_Chg_Pct_1W: string,
 *  avg_Chg_Pct_1M: string,
 *  pct_FT_Score_Pos: string,
 *  pct_FT_Score_Neg: string,
 *  pct_FT_Score_Zero: string,
 *  pct_PT_Score_Pos: string,
 *  pct_PT_Score_Neg: string,
 *  pct_PT_Score_Zero: string,
 *  pct_Price_Up: string,
 *  pct_Price_Down: string,
 *  pct_Price_Eq: string,
 *  avg_Expected_CAGR: string,
 *  avg_EPS_YOY_Consensus_T0: string,
 *  avg_EPS_YOY_Consensus_T1: string,
 *  avg_EPS_PE_Consensus_T0: string,
 *  avg_EPS_PE_Consensus_T1: string,
 * }} AggrRow 
 */

/**
 * @typedef { AggrRow & { quality_Score: string } } AggrQualityScoreRow
 */

/**
 * @typedef { AggrRow & { cluster_Layer_Name: string } } AggrClusterLayerRow
 */

const colNames = {
    avg_Reward_Pct: '回报%',
    avg_Risk_Pct: '风险%',
    reward_Risk_Ratio: '风险回报比',
    avg_Chg_Pct_1D: '1DChg%',
    avg_Chg_Pct_1W: '1WChg%',
    avg_Chg_Pct_1M: '1MChg%',
    pct_FT_Score_Pos: 'Ft+%',
    pct_FT_Score_Neg: 'Ft-%',
    pct_FT_Score_Zero: 'Ft0%',
    pct_PT_Score_Pos: 'Pt+%',
    pct_PT_Score_Neg: 'Pt-%',
    pct_PT_Score_Zero: 'Pt0%',
    pct_Price_Up: 'Price+%',
    pct_Price_Down: 'Price-%',
    pct_Price_Eq: 'Price=%',
    avg_Expected_CAGR: '可期中',
    avg_EPS_YOY_Consensus_T0: '增速T0',
    avg_EPS_YOY_Consensus_T1: '增速T1',
    avg_EPS_PE_Consensus_T0: 'PE T0',
    avg_EPS_PE_Consensus_T1: 'PE T1',
}

/** @type {import('./Table').ColumnOption<AggrRow>[]} */
const columns = [
    {
        field: 'count',
        label: '数量',
        model: 'none',
        sortable: false,
    },
    {
        field: 'avg_Reward_Pct',
        label: colNames.avg_Reward_Pct,
        model: 'none',
        sortable: true,
    },
    {
        field: 'avg_Risk_Pct',
        label: colNames.avg_Risk_Pct,
        model: 'none',
        sortable: true,
    },
    {
        field: 'reward_Risk_Ratio',
        label: colNames.reward_Risk_Ratio,
        model: 'none',
        sortable: true,
    },
    {
        field: 'avg_Chg_Pct_1D',
        label: colNames.avg_Chg_Pct_1D,
        model: 'none',
        sortable: true,
    },
    {
        field: 'avg_Chg_Pct_1W',
        label: colNames.avg_Chg_Pct_1W,
        model: 'none',
        sortable: true,
    },
    {
        field: 'avg_Chg_Pct_1M',
        label: colNames.avg_Chg_Pct_1M,
        model: 'none',
        sortable: true,
    },
    {
        field: 'pct_FT_Score_Pos',
        label: colNames.pct_FT_Score_Pos,
        model: 'none',
        sortable: false,
    },
    {
        field: 'pct_FT_Score_Neg',
        label: colNames.pct_FT_Score_Neg,
        model: 'none',
        sortable: false,
    },
    {
        field: 'pct_FT_Score_Zero',
        label: colNames.pct_FT_Score_Zero,
        model: 'number'
    },
    {
        field: 'pct_PT_Score_Pos',
        label: colNames.pct_PT_Score_Pos,
        model: 'none',
        sortable: false,
    },
    {
        field: 'pct_PT_Score_Neg',
        label: colNames.pct_PT_Score_Neg,
        model: 'none',
        sortable: false,
    },
    {
        field: 'pct_PT_Score_Zero',
        label: colNames.pct_PT_Score_Zero,
        model: 'none',
        sortable: false,
    },
    {
        field: 'pct_Price_Up',
        label: colNames.pct_Price_Up,
        model: 'none',
        sortable: false,
    }, 
    {
        field: 'pct_Price_Down',
        label: colNames.pct_Price_Down,
        model: 'none',
        sortable: false,
    },
    {
        field: 'pct_Price_Eq',
        label: colNames.pct_Price_Eq,
        model: 'none',
        sortable: false,
    }, 
    {
        field: 'avg_Expected_CAGR',
        label: colNames.avg_Expected_CAGR,
        model: 'none',
        sortable: false,
    }, 
    {
        field: 'avg_EPS_YOY_Consensus_T0',
        label: colNames.avg_EPS_YOY_Consensus_T0,
        model: 'none',
        sortable: false,
    }, 
    {
        field: 'avg_EPS_YOY_Consensus_T1',
        label: colNames.avg_EPS_YOY_Consensus_T1,
        model: 'none',
        sortable: false,
    }, 
    {
        field: 'avg_EPS_PE_Consensus_T0',
        label: colNames.avg_EPS_PE_Consensus_T0,
        model: 'none',
        sortable: false,
    }, 
    {
        field: 'avg_EPS_PE_Consensus_T1',
        label: colNames.avg_EPS_PE_Consensus_T1,
        model: 'none',
        sortable: false,
    }
];

/** @type {import('./Table').ColumnOption<AggrQualityScoreRow>[]} */
const qualityScoreColumns = [
    {
        field: 'quality_Score',
        label: FieldNames.quality_Score,
        model: 'none',
        sortable: false,
    },
    ...columns
];

/** @type {import('./Table').ColumnOption<AggrClusterLayerRow>[]} */
const clusterLayerColumns = [
    {
        field: 'cluster_Layer_Name',
        label: '归层',
        model: 'none',
        sortable: false,
    },
    ...columns
];

/** @type {import('./Table').Fields<AggrRow>[]} */
const initVisbleCols = [
    'count',
    'avg_Reward_Pct',
    'avg_Risk_Pct',
    'reward_Risk_Ratio',
    'avg_Chg_Pct_1D',
    'avg_Chg_Pct_1W',
    'avg_Chg_Pct_1M',
    'pct_FT_Score_Pos',
    'pct_PT_Score_Pos',
    'pct_Price_Up',
    'avg_Expected_CAGR',
    'avg_EPS_YOY_Consensus_T0',
    'avg_EPS_YOY_Consensus_T1',
    'avg_EPS_PE_Consensus_T0',
    'avg_EPS_PE_Consensus_T1',
];


/** @type {import('./Table').SorterAndFilters<AggrQualityScoreRow>} */
const initQualitySorterAndFilters = null;

/** @type {import('./Table').SorterAndFilters<AggrClusterLayerRow>} */
const initClusterSorterAndFilters = null;

/** @type {import('./Table').Fields<AggrQualityScoreRow>[]} */
const initQualityVisibleColumns = ['quality_Score', ...initVisbleCols];

const todayMoment = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

const zero = new bigDecimal(0);

/**
 * @template {{}} T
 * @template {{}} R
 * @param {AggrFields<T>} item 
 * @param {(key: T) => R} formatKey
 * @returns {AggrRow & R}
 */
function formatAggrRow(item, formatKey) {
    const key = formatKey(item);
    return {
        id: item.id,
        count: item.count,
        avg_Reward_Pct: bns(item.avg_Reward_Pct),
        avg_Risk_Pct: bns(item.avg_Risk_Pct),
        reward_Risk_Ratio: (item.avg_Reward_Pct && item.avg_Risk_Pct && item.avg_Risk_Pct.compareTo(zero) !== 0) ? bns(item.avg_Reward_Pct.divide(item.avg_Risk_Pct)) : '',
        avg_Chg_Pct_1D: bnsPercent(item.avg_Chg_Pct_1D),
        avg_Chg_Pct_1W: bnsPercent(item.avg_Chg_Pct_1W),
        avg_Chg_Pct_1M: bnsPercent(item.avg_Chg_Pct_1M),
        pct_FT_Score_Pos: bnsPercent(item.cnt_FT_Score_Pos / item.count),
        pct_FT_Score_Neg: bnsPercent(item.cnt_FT_Score_Neg / item.count),
        pct_FT_Score_Zero: bnsPercent((item.count - item.cnt_FT_Score_Pos - item.cnt_FT_Score_Neg) / item.count),
        pct_PT_Score_Pos: bnsPercent(item.cnt_PT_Score_Pos / item.count),
        pct_PT_Score_Neg: bnsPercent(item.cnt_PT_Score_Neg / item.count),
        pct_PT_Score_Zero: bnsPercent((item.count - item.cnt_PT_Score_Pos - item.cnt_PT_Score_Neg) / item.count),
        pct_Price_Up: bnsPercent(item.cnt_Price_Up / item.count),
        pct_Price_Down: bnsPercent(item.cnt_Price_Down / item.count),
        pct_Price_Eq: bnsPercent((item.count - item.cnt_Price_Up - item.cnt_Price_Down) / item.count),
        avg_Expected_CAGR: bns(item.avg_Expected_CAGR),
        avg_EPS_YOY_Consensus_T0: bns(item.avg_EPS_YOY_Consensus_T0),
        avg_EPS_YOY_Consensus_T1: bns(item.avg_EPS_YOY_Consensus_T1),
        avg_EPS_PE_Consensus_T0: bns(item.avg_EPS_PE_Consensus_T0),
        avg_EPS_PE_Consensus_T1: bns(item.avg_EPS_PE_Consensus_T1),
        ...key,
    };
}

/**
 * @param {AggrQualityScore} item
 * @returns {AggrQualityScoreRow}
 */
function formatAggrQualityScore(item) {
    return formatAggrRow(item, key => ({
        quality_Score: bns(key.quality_Score, 1)
    }));
}

/**
 * @param {AggrClusterLayer} item
 * @param {import('./api/enum').Enums} enums
 * @returns {AggrClusterLayerRow}
 */
function formatAggrClusterLayer(item, enums) {
    return formatAggrRow(item, key => {
        /** @type {string} */
        let cluster_Layer;
        /** @type {string} */
        let cluster_Layer_Name;
        switch (key.cluster_Layer) {
            case 0: 
                cluster_Layer = '';
                cluster_Layer_Name = '';
                break;
            case 1:
                cluster_Layer = '产业链';
                cluster_Layer_Name = enums?.industrialChainList
                    ?.find(e => e.industrial_Chain_ID === key.cluster_Layer_Value)
                    ?.industrial_Chain_Name;
                break;
            case 2:
                cluster_Layer = '板块';
                cluster_Layer_Name = enums?.sectionList
                    ?.find(e => e.section_ID === key.cluster_Layer_Value)
                    ?.section_Name;
                break;
            case 3:
                cluster_Layer = '行业';
                cluster_Layer_Name = enums?.industryList
                    ?.find(e => e.industry_ID === key.cluster_Layer_Value)
                    ?.industry_Name;
                break;
            case 4:
                cluster_Layer = '子行业';
                cluster_Layer_Name = enums?.subIndustryList
                    ?.find(e => e.sub_Industry_ID === key.cluster_Layer_Value)
                    ?.sub_Industry_Name;
                break;
            case 5:
                cluster_Layer = '产品与服务';
                cluster_Layer_Name = enums?.productList
                    ?.find(e => e.product_ID === key.cluster_Layer_Value)
                    ?.product_Name;
                break;
            case 6:
                cluster_Layer = 'SW1';
                cluster_Layer_Name = enums?.sw1List
                    ?.find(e => e.sw1_ID === key.cluster_Layer_Value)
                    ?.sw1_Name;
                break;
            case 7:
                cluster_Layer = 'SW2';
                cluster_Layer_Name = enums?.sw2List
                    ?.find(e => e.sw2_ID === key.cluster_Layer_Value)
                    ?.sw2_Name;
                break;
            case 8:
                cluster_Layer = 'SW3';
                cluster_Layer_Name = enums?.sw3List
                    ?.find(e => e.sw3_ID === key.cluster_Layer_Value)
                    ?.sw3_Name;
                break;
            default:
                cluster_Layer = '';
                cluster_Layer_Name = '';
                break;
        }
        return {
            cluster_Layer_Name: cluster_Layer ? 
                `${cluster_Layer_Name}(${cluster_Layer})` :
                '无归层'
        };
    });
}
const yesterday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(1, 'day');

const useStyles = makeStyles(theme => ({
    qualityContainer: {
        marginBottom: 20, 
        paddingRight: 17, 
        background: darken(theme.palette.grey[800], 0.1),
        borderRadius: theme.shape.borderRadius,
    }
}));

export default function AggrTable() {
    const classes = useStyles();
    const [date, setDate] = useQuery('date', yesterday, s => Number.isSafeInteger(Number(s)) ? fromDays2000(Number(s)) : null, m => `${toDate(m)}`);
    const [enums,] = useApiState(getEnums);
    const [aggrQualityScoreData,, aggrQualityScoreError] = useApiState(getAggrQualityScore, toDate(date));
    const [aggrClusterLayerData,, aggrClusterLayerError] = useApiState(getAggrClusterLayer, toDate(date));
    
    const aggrQualityScoreRow = useMemo(() => {
        if (!aggrQualityScoreData) return [];
        const r = aggrQualityScoreData.map(formatAggrQualityScore);
        // r.sort((a, b) => Number(a.quality_Score) - Number(b.quality_Score));
        return r;
    }, [aggrQualityScoreData]);

    const aggrClusterLayerRow = useMemo(() => {
        if (!aggrClusterLayerData) return [];
        const r = aggrClusterLayerData.map(item => formatAggrClusterLayer(item, enums));
        // r.sort((a, b) => (a.cluster_Layer_Name || '').localeCompare(b.cluster_Layer_Name || ''));
        return r;
    }, [aggrClusterLayerData, enums])

    useErrorMessage(aggrQualityScoreError || aggrClusterLayerError); 
    const { enqueueSnackbar } = useSnackbar();
    
    const [qualitySorterAndFilters, setQualitySorterAndFilters] = useGlobal('AggrQualityScore_SorterAndFilters', initQualitySorterAndFilters);
    const [qualityVisibleColOrder, setQualityVisibleColOrder] = useGlobal('AggrQualityScore_VisibleColOrder', initQualityVisibleColumns);

    const [clusterSorterAndFilters, setClusterSorterAndFilters] = useGlobal('AggrClusterLayer_SorterAndFilters', initClusterSorterAndFilters);

    useEffect(() => {
        if (qualityVisibleColOrder.indexOf('count') < 0) {
            const n = [...qualityVisibleColOrder];
            n.splice(1, 0, 'count');
            setQualityVisibleColOrder(n);
        }
    }, []);

    const cols = Object.keys(colNames).filter(key => qualityVisibleColOrder.indexOf(key) >= 0);

    const clusterVisibleColOrder = qualityVisibleColOrder.map(q => q === 'quality_Score' ? 'cluster_Layer_Name' : q);
    const setClusterVisibleColOrder = o => setQualityVisibleColOrder(o.map(c => c === 'cluster_Layer_Name' ? 'quality_Score' : c));

    return <div style={{width:'100%',padding: 20,display: 'flex', flexDirection: 'column'}}>
        <Paper style={{padding:20,  marginBottom: 20}}>
            <FormControl>
                <InputLabel htmlFor="event-types">选择显示指标</InputLabel>
                <Select
                    id="event-types"
                    style={{marginRight: 16, width:200}}
                    value={cols} multiple
                    onChange={e => {
                        /** @type {import('./Table').Fields<AggrRow>[]} */
                        const newCols = e.target.value;
                        if (newCols.length === 0) {
                            enqueueSnackbar('必须选择至少一个类型');
                            return;
                        }
                        const toAdd = newCols.filter(c => cols.indexOf(c) < 0);
                        const toRemove = cols.filter(c => newCols.indexOf(c) < 0);
                        console.log("to add", toAdd, "to remove", toRemove);
                        const n = [
                            ...qualityVisibleColOrder,
                            ...toAdd
                        ].filter(item => toRemove.indexOf(item) < 0);
                        setQualityVisibleColOrder(n);
                    }}
                    renderValue={v => `${v.length}个指标`}
                >
                    { Object.keys(colNames).map(key => (
                            <MenuItem key={key} value={key}>
                                <Checkbox checked={cols.indexOf(key) >= 0} />
                                <ListItemText primary={colNames[key]} />
                            </MenuItem>
                    ))}
                </Select>
            </FormControl>  
            <DatePicker
                style={{marginRight: 16, width: 200}}
                autoOk
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="none"
                label="日期"
                value={date}
                maxDate={todayMoment}
                onChange={setDate}
            />

        </Paper>
        <div className={classes.qualityContainer}>
            <FixedTable
                data={aggrQualityScoreRow}
                columns={qualityScoreColumns}
                sorterAndFilters={qualitySorterAndFilters}
                onSorterAndFiltersChange={setQualitySorterAndFilters}
                visibleColumnOrder={qualityVisibleColOrder}
                onVisibleColumnOrderChange={setQualityVisibleColOrder}
                variant="compat"
            />
        </div>
        <VirtualScrollTable
            height={700}
            data={aggrClusterLayerRow}
            columns={clusterLayerColumns}
            sorterAndFilters={clusterSorterAndFilters}
            onSorterAndFiltersChange={setClusterSorterAndFilters}
            visibleColumnOrder={clusterVisibleColOrder}
            onVisibleColumnOrderChange={setClusterVisibleColOrder}
            variant="compat"
        />
    </div>
}