import React, { memo } from 'react';
import { Grid, makeStyles, Paper } from "@material-ui/core";
import FieldLabel from '../components/FieldLabel';
import SmallCheckbox from '../components/SmallCheckbox';
import { yellow } from '@material-ui/core/colors';
import { bn, bns } from '../lib/bn';
import { date2str } from '../lib/date';
import SelectField from './SelectField';
import { FTWidthLabels, TrendStabilityLabels, PTWidthLabels, TrendWidthLabels, TrendStrengthLabels, TrendStageLabels, TrendSustainabilityLabels, MedTermTrendLabels, ShortWishlistLabels, FieldNames } from '../lib/enums';
import SmallRadioBox from '../components/SmallRadioBox';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    select: {
        width: '100%',
        fontSize: 12,
    },
    input: {
        width: '100%'
    },
    scoreLabel: {
        textAlign: 'center',
        fontSize: 16,
        color: yellow[400],
    },
    scoreValue: {
        textAlign: 'center',
        fontSize: 24,
        color: yellow[400],
    },
    grid7col: {
        flexGrow: 0,
        maxWidth: '14.285714286%',
        flexBasis: '14.285714286%'
    }
}));

/**
 * @param {{
 *   edit: boolean,
 *   stock: import('../api/stock').StockInfo,
 *   setStockFields: (data: any) => void,
 *   noticeList: import('../api/stock').StockReportQuarterlyNotice[],
 *   expressList: import('../api/stock').StockReportQuarterlyExpress[],
 *   faList: import('../api/stock').StockReportQuarterlyFA[],
 * }} param0
 */
function TrendMeasures({ edit, stock, setStockFields, noticeList, expressList, faList }) {
    const classes = useStyles();
    const lastNoticeReportWithFTScore = noticeList?.find?.(notice => notice.ft_Score);
    const lastExpressReportWithFTScore = expressList?.find?.(express => express.ft_Score);
    const lastFAReportWithFTScore = faList?.find?.(fa => fa.ft_Score);
    const ftScoreList = [lastNoticeReportWithFTScore, lastExpressReportWithFTScore, lastFAReportWithFTScore].filter(Boolean);
    ftScoreList.sort((a,b) => b.rpt_Period - a.rpt_Period);
    const ftScore = ftScoreList[0]?.ft_Score;

    const lastNoticeReportWithFTType = noticeList?.find?.(notice => notice.ft_Type);
    const lastExpressReportWithFTType = expressList?.find?.(express => express.ft_Type);
    const lastFAReportWithFTType = faList?.find?.(fa => fa.ft_Type);
    let ftFirstDay = Math.max(
        lastNoticeReportWithFTType?.notice_Release_Date || -999999,
        lastExpressReportWithFTType?.express_Release_Date || -999999,
        lastFAReportWithFTType?.fa_Release_Date || -999999
    );
    if (ftFirstDay == -999999) {
        ftFirstDay = null;
    }
    return <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={2}>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="反馈模式" dot="blue">
                    <SelectField
                        edit={edit}
                        value={ stock.trend_Width }
                        onChange={v => setStockFields({ trend_Width: v })}
                        options={TrendWidthLabels}
                    />
                </FieldLabel>
                <FieldLabel label="Pt今日" dot="yellow" plain>
                    {bns(stock.last_RSI)}
                </FieldLabel>
                <FieldLabel label="有行情吗?" dot="blue">
                    <SmallCheckbox color='primary' size='small' disabled={!edit}
                        checked={stock.trend_Exists_Or_Default === 1} 
                        onChange={e => setStockFields({ trend_Exists: e.target.checked ? 1 : 0 })}
                    />
                </FieldLabel>
                <FieldLabel label="行情持续性" dot="blue">
                    <SelectField
                        edit={edit}
                        value={ stock.trend_Sustainability }
                        onChange={v => setStockFields({ trend_Sustainability: v })}
                        options={TrendSustainabilityLabels}
                    />
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="Ft分" dot="blue" plain>
                    {bns(ftScore, 0)}
                </FieldLabel>
                <FieldLabel label="Pt分" dot="yellow" plain>
                    {bns(stock.pt_Score, 1)}
                </FieldLabel>
                <FieldLabel label="有逻辑吗?" dot="blue">
                    <SmallCheckbox color='primary' size='small' disabled={!edit}
                        checked={stock.logic_Or_Default === 1} 
                        onChange={e => setStockFields({ logic: e.target.checked ? 1 : 2 })}
                    />
                </FieldLabel>
                <FieldLabel label="行情稳定度" dot="blue">
                    <SelectField
                        edit={edit}
                        value={ stock.trend_Stability }
                        onChange={v => setStockFields({ trend_Stability: v })}
                        options={TrendStabilityLabels}
                    />
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="Ft首日" dot="yellow" plain>
                    {date2str(ftFirstDay)}
                </FieldLabel>
                <FieldLabel label="Pt首日" dot="yellow" plain>
                    {date2str(stock.pt_1st_Day)}
                </FieldLabel>
                <FieldLabel label="中期趋势" dot="blue">
                    <SelectField
                        edit={edit}
                        options={MedTermTrendLabels}
                        value={stock.med_Term_Trend} 
                        onChange={e => setStockFields({ med_Term_Trend: e })}
                    />
                </FieldLabel>
                <FieldLabel label="行情阶段" dot="blue">
                    <SelectField
                        edit={edit}
                        value={ stock.trend_Stage }
                        onChange={v => setStockFields({ trend_Stage: v })}
                        options={TrendStageLabels}
                    />
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="Ft宽度" dot="blue">
                    <SelectField
                        edit={edit}
                        value={ stock.ft_Width }
                        onChange={v => setStockFields({ ft_Width: v })}
                        options={FTWidthLabels}
                    />
                </FieldLabel>
                <FieldLabel label="Pt宽度" dot="blue">
                    <SelectField
                        edit={edit}
                        value={ stock.pt_Width }
                        onChange={v => setStockFields({ pt_Width: v })}
                        options={PTWidthLabels}
                    />
                </FieldLabel>
                <FieldLabel label={FieldNames.short_Wishlist} dot="green">
                    <SelectField
                        edit={edit}
                        disableDefault
                        value={ stock.short_Wishlist_Or_Default }
                        onChange={v => setStockFields({ short_Wishlist: v })}
                        options={ShortWishlistLabels}
                    />
                </FieldLabel>
                <FieldLabel label="行情强度" dot="blue">
                    <SelectField
                        edit={edit}
                        value={ stock.trend_Strength }
                        onChange={v => setStockFields({ trend_Strength: v })}
                        options={TrendStrengthLabels}
                    />
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel></FieldLabel>
                <FieldLabel></FieldLabel>
                <FieldLabel label={FieldNames.alert_Before_Next_FS} dot="green">
                    <SmallRadioBox color='primary' size='small' disabled={!edit}
                        checked={stock.alert_Before_Next_FS_Or_Default === 1} 
                        onChange={e => setStockFields({ alert_Before_Next_FS: e.target.checked ? 1 : 2 })}/>
                </FieldLabel>
                <FieldLabel label={FieldNames.external_Power} dot="blue" plain>
                    { bns(stock.external_Power) || '-' }
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel></FieldLabel>
                <FieldLabel></FieldLabel>
                <FieldLabel label={FieldNames.read_Next_FS} dot="green">
                    <SmallRadioBox color='primary' size='small' disabled={!edit}
                        checked={stock.read_Next_FS_Or_Default === 1} 
                        onChange={e => setStockFields({ read_Next_FS: e.target.checked ? 1 : 2 })}/>
                </FieldLabel>
                <FieldLabel label={FieldNames.stock_Power} dot="blue" plain>
                    { bns(stock.stock_Power) || '-' }
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel col={0} row={2}>
                    <div className={classes.scoreLabel}>{FieldNames.trend_Score}</div>
                    <div className={classes.scoreValue}>{bns(stock.trend_Score_Or_Default, 1) || '-'}</div>
                </FieldLabel>
                
                
            </Grid>
        </Grid>
    </Paper>    
}

export default memo(TrendMeasures);
