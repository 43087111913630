import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import StockReportUploadCNTable from './StockReportUploadCNTable';
import StockReportUploadCNTableImport from './StockReportUploadCNTableImport';

export default function StockReportUploadCNTableRouter() {
    return <Switch>
        <Route path="/user-table/stock-report-upload-cn" exact component={StockReportUploadCNTable} />
        <Route path="/user-table/stock-report-upload-cn/add" exact component={StockReportUploadCNTableImport} />
        <Route path="/user-table/stock-report-upload-cn/:version" exact component={StockReportUploadCNTable} />
        <Redirect to="/user-table/stock-report-upload-cn" />
    </Switch>
}