import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Quill from 'quill';
import Delta from 'quill-delta';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import { makeStyles } from '@material-ui/core';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'; 
import ImageUploadPlugin from './ImageUploadPlugin';
import { urlOfUploadFile } from '../api/file';
import { useSnackbar, SnackbarContent  } from 'notistack';
import uploadImage from './upload.png';
import uploadHoverImage from './upload-hover.png';
import FileUploadPlugin from './FileUploadPlugin';
import EventEmitter from 'events';
import { Alert } from '@material-ui/lab';

Quill.register('modules/imageUpload', ImageUploadPlugin);
Quill.register('modules/fileUpload', FileUploadPlugin);

const useStyle = makeStyles(theme => ({
    normal: {
        '& > .ql-toolbar.ql-snow': {
            background: '#535353',
            border: 'none',
            position: 'sticky',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1,
        },
        '& > .ql-container.ql-snow': {
            background: '#535353',
            border: 'none'
        },
        '& > .ql-container.ql-snow .ql-editor': {
            minHeight: 300,
            '& a': {
                color: 'inherit',
            }
        },
        '& > .ql-container.ql-snow .ql-tooltip': {
            display: 'none',
        },
        '& .ql-toolbar.ql-snow button .ql-stroke': {
            stroke: '#aaa'
        },
        '& .ql-toolbar.ql-snow button:hover .ql-stroke': {
            stroke: 'white'
        },
        '& .ql-toolbar.ql-snow button .ql-fill': {
            fill: '#aaa'
        },
        '& .ql-toolbar.ql-snow button.ql-file::before': {
            content: `url(${uploadImage})`
        },
        '& .ql-toolbar.ql-snow button.ql-file:hover::before': {
            content: `url(${uploadHoverImage})`
        },
        '& .ql-toolbar.ql-snow button:hover .ql-fill': {
            fill: 'white'
        },
        '& .ql-toolbar.ql-snow .ql-picker-label': {
            color: '#aaa',
            '& .ql-stroke': {
                stroke: '#aaa'    
            },
            '& .ql-fill': {
                fill: '#aaa'
            },
            '&:hover': {
                color: 'white',

            },
            '&:hover .ql-stroke': {
                stroke: 'white'    
            },
            '&:hover .ql-fill': {
                fill: 'white'
            },
            '&:focus': {
                outline: 'none'
            }
        },
        '& .ql-toolbar.ql-snow .ql-picker-options': {
            background: '#424242',
        }
    },
    compat: {
        '& > .ql-toolbar.ql-snow': {
            background: '#535353',
            border: 'none',
            position: 'sticky',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1,
        },
        '& > .ql-container.ql-snow': {
            background: '#535353',
            border: 'none'
        },
        '& > .ql-container.ql-snow .ql-editor': {
            '& a': {
                color: 'inherit',
            }
        },
        '& > .ql-container.ql-snow .ql-tooltip': {
            display: 'none',
        },
        '& .ql-toolbar.ql-snow button .ql-stroke': {
            stroke: '#aaa'
        },
        '& .ql-toolbar.ql-snow button:hover .ql-stroke': {
            stroke: 'white'
        },
        '& .ql-toolbar.ql-snow button .ql-fill': {
            fill: '#aaa'
        },
        '& .ql-toolbar.ql-snow button.ql-file::before': {
            content: `url(${uploadImage})`
        },
        '& .ql-toolbar.ql-snow button.ql-file:hover::before': {
            content: `url(${uploadHoverImage})`
        },
        '& .ql-toolbar.ql-snow button:hover .ql-fill': {
            fill: 'white'
        },
        '& .ql-toolbar.ql-snow .ql-picker-label': {
            color: '#aaa',
            '& .ql-stroke': {
                stroke: '#aaa'    
            },
            '& .ql-fill': {
                fill: '#aaa'
            },
            '&:hover': {
                color: 'white',

            },
            '&:hover .ql-stroke': {
                stroke: 'white'    
            },
            '&:hover .ql-fill': {
                fill: 'white'
            },
            '&:focus': {
                outline: 'none'
            }
        },
        '& .ql-toolbar.ql-snow .ql-picker-options': {
            background: '#424242',
        }
    },
    disabled: {
        '& > .ql-toolbar': {
            display: 'none',
        }
    }
}));


function parseValue(str) {
    if (!str) {
        return new Delta().insert('');
    }
    try {
        const d = JSON.parse(str);
        if (d.delta) {
            return new Delta(d.delta);
        }
        return new Delta().insert(str);
    } catch (e) {
        return new Delta().insert(str);
    }
}

function linkHandler(value) {
    if (value) {
        var href = prompt('输入链接网址');
        this.quill.format('link', href);
    } else {
        this.quill.format('link', false);
    }
}

export function toHtml(value, rootElem) {
    const delta = parseValue(value);
    return new QuillDeltaToHtmlConverter(delta.ops, { paragraphTag: rootElem }).convert();
}

/**
 * 
 * @param {{
 *   id: string,
 *   stateStorage: { 
 *     [id: string] : { 
 *       state: 'init' | 'progress' | 'success' | 'error', 
 *       loaded: number, 
 *       total: number, 
 *       message: string 
 *     },
 *   },
 *   events: EventEmitter
 * }} param0 
 */
function UploadInfoSnackbarContent({ id, stateStorage, events }, ref) {
    const [state, setState] = useState(stateStorage[id]);
    useEffect(() => {
        const listener = (data) => {
            if (data.id !== id) return;
            setState(data);
        };
        events.on('change', listener);
        return () => events.off('change', listener);
    }, [events]);
    let severity;
    switch (state.state) {
        case 'init':
        case 'progress':
            severity = 'info';
            break;
        case 'success':
            severity = 'success';
            break;
        case 'error':
            severity = 'error';
            break;
    }
    return <SnackbarContent ref={ref}>
        <Alert variant="filled" severity={severity}>
            { state.message }
        </Alert>
    </SnackbarContent>
}

const UploadInfoSnackbarContentWithRef = forwardRef(UploadInfoSnackbarContent);

/**
 * 
 * @param {{
 *   events: EventEmitter
 * }} param0 
 */
function UploadInfoSnackbars({ events }) {
    /** 
     * @type {React.MutableRefObject<{
     *   [id: string] : { 
     *     state: 'init' | 'progress' | 'success' | 'error', 
     *     loaded: number, 
     *     total: number, 
     *     message: string 
     *   }
     * }> }
     */
    const uploadStates = useRef({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        const listener = (data) => {
            const id = data.id;
            uploadStates.current[id] = data;
            if (data.state === 'init') {
                enqueueSnackbar('', {
                    key: id,
                    persist: true,
                    content: key => <UploadInfoSnackbarContentWithRef key={key} id={key} stateStorage={uploadStates.current} events={events} />
                });
            } else if (data.state === 'success' || data.state === 'error') {
                setTimeout(() => closeSnackbar(id), 3000);
            }
        };
        events.on('change', listener);
        return () => events.off('change', listener);
    }, [events]);
    return null;
}

/**
 * @param {{
 *   disabled: boolean,
 *   initialValue?: string,
 *   value?: string,
 *   onChange: (v: string) => void,
 *   variant?: 'normal' | 'compat'
 *   className?: string,
 * }} param0 
 */
export default function RichText({ disabled, initialValue, value, onChange, variant = 'normal', className,  ...props }) {
    const classes = useStyle();
    /** @type {React.MutableRefObject<HTMLDivElement>} */
    const element = useRef();
    /** @type {React.MutableRefObject<HTMLDivElement>} */
    const container = useRef();
    /** @type {React.MutableRefObject<Quill>} */
    const editor = useRef();
    
    const [events] = useState(() => new EventEmitter());
    
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const e = new Quill(element.current, {
            modules: {
                toolbar: [
                    [{ size: [ 'small', false, 'large', 'huge' ]}],
                    ['bold', 'italic', 'underline', 'strike', 'underline'],
                    [{ 'color': [] }, { 'background': [] }],
                    ['link', 'image', 'file'],
                    ['clean']
                ],
                imageUpload: {
                    url: urlOfUploadFile(),
                    method: 'POST',
                    name: 'file',
                    callbackOK: (serverResponse, next) => {
                        if (serverResponse.code === 0) {
                            next(serverResponse.data);
                        } else {
                            enqueueSnackbar(serverResponse.message);
                        }
                    },
                    callbackKO: () => {
                        enqueueSnackbar("文件上传失败");
                    },
                    checkBeforeSend: (file, next) => {
                        if (!/^image\//.test(file.type)) {
                            enqueueSnackbar("必须选择图像文件");
                            return;
                        }
                        next(file);
                    }
                },
                fileUpload: {
                    url: urlOfUploadFile(),
                    method: 'POST',
                    name: 'file',
                    onUploadProgress: (state) => {
                        events.emit('change', state);
                    },
                    checkResponse: (data) => {
                        if (data.code) {
                            throw new Error(data.message);
                        }
                        return data.data;
                    },
                    callbackOK: (data, next) => {
                        next(data);
                    },
                    callbackKO: () => {
                        enqueueSnackbar("文件上传失败");
                    },
                }
            },
            formats: [
                'background', 'bold', 'color', 'italic', 'link', 'size',
                'strike', 'underline', 'image'
            ],
            theme: 'snow'
        });
        e.getModule('toolbar').addHandler('link', linkHandler);
        e.setContents(parseValue(value || initialValue));
        editor.current = e;
        return () => {
            e.getModule('fileUpload').destroy();
        }
    }, []);
    useEffect(() => {
        if (value != undefined) {
            editor.current.setContents(parseValue(initialValue));
        }
    }, [value]);
    useEffect(() => {
        const listener = () => {
            const text = JSON.stringify({ delta: editor.current.getContents() });
            onChange(text);
        }
        editor.current.on('text-change', listener);
        return () => editor.current.off('text-change', listener);
    }, [onChange]);
    useEffect(() => {
        editor.current.enable(!disabled);
        
    }, [disabled]);
    const classname = disabled ? `${classes[variant]} ${className || ''} ${classes.disabled}` : `${classes[variant]} ${className || ''}`;
    return <>
        <UploadInfoSnackbars events={events} />
        <div ref={container} {...props} className={classname}>
            <div ref={element}>
                
            </div>
        </div>
    </>;
}