import moment from 'moment';

const base = moment('2000-01-01', 'YYYY-MM-DD');

const SupportedFormat = ['YYYY-MM-DD', 'YYYY-M-D', 'YYYY/MM/DD', 'YYYY/M/D', 'M/D/YYYY'];

/**
 * @param {string} str 
 */
export function parseDate(str) {
    for (const f of SupportedFormat) {
        const m = moment(str, f);
        if (m.isValid() && m.year() >= 1900 && m.year() <= 2099) {
            return m;
        }
    }
    return null;
}

export function fromDays2000(days) {
    if (days == null) return null;
    const r = moment(base).add(days, 'days');
    if (r.isValid()) return r;
    return null;
}

export { fromDays2000 as dayNumToDate };

export function date2str(date) {
    if (date === null || date === undefined || date === "" || date === 0 || date === -1) return "";
    return fromDays2000(date).format('YYYY-MM-DD');
}

export function date2quarter(date) {
    if (date === null || date === undefined || date === "") return "";
    const m = fromDays2000(date);
    const y = m.format('YY');
    const mmdd = m.format('MMDD');
    switch (mmdd) {
        case '0331':
            return `1Q${y}`;
        case '0630':
            return `2Q${y}`;
        case '0930':
            return `3Q${y}`;
        case '1231':
            return `4Q${y}`;
        default:
            return date2str(date);
    }
}

export function date2semi(date) {
    if (date === null || date === undefined || date === "") return "";
    const m = fromDays2000(date);
    const y = m.format('YY');
    const mmdd = m.format('MMDD');
    switch (mmdd) {
        case '0331':
            return `1Q${y}`;
        case '0630':
            return `1H${y}`;
        case '0930':
            return `3Q${y}`;
        case '1231':
            return `2H${y}`;
        default:
            return date2str(date);
    }
}

export { date2semi as semiFromDayNum };

export function date2quarterOrSemi(type, date) {
    switch(type) {
        case 2:
            return date2semi(date);
        default:
            return date2quarter(date);
    }
}

/**
 * @param {import('moment').MomentInput} d 
 */
export function toDate(d) {
    return Math.floor((moment(d).valueOf() - base.valueOf())/(24*3600*1000));
}

export { toDate as dateToDayNum };

export function today() {
    return toDate(moment());
}

export { today as dayNumOfToday };

export function yesterday() {
    return today() - 1;
}

export { yesterday as dayNumOfYesterday };

export function quarterFromDays2000(days) {
    const m = fromDays2000(days);
    const v = m.format('MMDD');
    const y = m.format('YY');
    switch(v) {
        case '0331':
            return `1Q${y}`;
        case '0630':
            return `2Q${y}`;
        case '0930':
            return `3Q${y}`;
        case '1231':
            return `4Q${y}`;
        default:
            return date2str(m);
    }
}

export { quarterFromDays2000 as quarterFromDayNum }

/** 返回指定日期的季度序数（2000Q1为0） */
export function dayNumToQuarterNum(daynum) {
    const date = fromDays2000(daynum);
    const y = date.year();
    const baseZeroMonth = date.month();
    let q;
    switch (baseZeroMonth) {
        case 0:
        case 1:
        case 2:
            q = 0;
            break;
        case 3:
        case 4:
        case 5:
            q = 1;
            break;
        case 6:
        case 7:
        case 8:
            q = 2;
            break;
        case 9:
        case 10:
        case 11:
            q = 3;
            break;
        default:
            //not supposed to run here
            throw new Error("Invalid month: " + baseZeroMonth);
    }
    return (y - 2000) * 4 + q;
}

/**
 * @param {number} quarterNum 
 */
export function quarterNumToDayNum(quarterNum) {
    const year = 2000 + Math.floor(quarterNum / 4);
    const quarter = quarterNum % 4;
    switch (quarter) {
        case 0:
            return toDate(moment({ year, month: 2, date: 31}));
        case 1:
            return toDate(moment({ year, month: 5, date: 30}));
        case 2:
            return toDate(moment({ year, month: 8, date: 30}));
        case 3:
            return toDate(moment({ year, month: 11, date: 31}));
        default:
            throw new Error("Should not run here");
    }
}

export function quarterNumOfToday() {
    return dayNumToQuarterNum(today());
}

window.fromDays2000 = fromDays2000;
window.toDate = toDate;