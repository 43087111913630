import React, { useCallback, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import { Typography, Popper } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 36;

    const getChildSize = (child) => {
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width={300}
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const useStyles = makeStyles({
    listbox: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
    plain: {
        fontSize: 12,
        borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
        height: 27,
        lineHeight: '27px',
    } 
});

const filter = createFilterOptions({
    stringify: item => item.name
});

const StyledPopper = React.forwardRef(function StyledPopper(props, ref) {
    return (
        <Popper ref={ref} {...props} style={null} placement="bottom-end" />
    );
});


/**
 * @template T
 * @param {{
 *   edit: boolean,
 *   prefix: string,
 *   value: number,
 *   onChange: (newValue: number) => void,
 *   enums: T[],
 *   onAddEnum: (prefix: string) => void,
 * }} param0 
 */
export default function EnumSearchableSelect({ edit, prefix, value, onChange, enums, onAddEnum }) {
    const classes = useStyles();
    const options = useMemo(() => {
        const r = enums.map(e => ({ id: e[`${prefix}_ID`], name: e[`${prefix}_Name`]}));
        r.unshift({ id: 0, name: '-' });
        return r;
    }, [value, prefix, enums]);
    const handleChange = useCallback((event, newValue, reason) => {
        if (!newValue || reason !== 'select-option') {
            return;
        } 
        if (newValue.id === -1) {
            if (onAddEnum) onAddEnum(prefix);
        } else {
            if (onChange) onChange(newValue.id);
        }
    }, [onChange, onAddEnum, prefix]);
    const selectedItem = useMemo(() => options.find(opt => opt.id === value), [value, options]) || null;
    if (!edit) {
        return <div className={classes.plain}>{selectedItem?.name||'-'}</div>
    }
    return <>
        <Autocomplete
            // debug
            disableClearable
            disableListWrap
            classes={{ listbox: classes.listbox }}
            ListboxComponent={ListboxComponent}
            PopperComponent={StyledPopper}
            value={selectedItem}
            onChange={handleChange}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (filtered.length === 0) {
                    filtered.push({
                        name: '找不到匹配的项',
                        disabled: true,
                    });
                }
                filtered.push({
                    id: -1,
                    name: `新增...`
                });
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            getOptionLabel={option => option.name}
            renderInput={(params) => <TextField {...params} InputProps={{...params.InputProps, style: { fontSize: 12 }}} />}
            renderOption={(option) => option.id === -1 ? <Typography noWrap><AddRoundedIcon style={{verticalAlign:'middle'}} />{option.name}</Typography> : <Typography noWrap>{option.name}</Typography>}
        />
    </>;
}
