import { Checkbox, Chip, makeStyles, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { memo, useMemo, useRef, useState } from 'react';
import { add, bns, val } from '../lib/bn';
import { date2str, date2quarterOrSemi } from '../lib/date';
import commentPin from '../images/comment-pin@2x.png';
import logicPin from '../images/logic-pin@2x.png';
import callPin from '../images/call-pin@2x.png';
import watchPin from '../images/watch-pin@2x.png';
import shortPin from '../images/short-pin@2x.png';
import passPin from '../images/pass-pin@2x.png';
import { grey } from '@material-ui/core/colors';
import { useStockIsEditable } from './StockIsEditable';
import { EventNames, ExternalLabels, FieldNames, FTTypeOptions } from '../lib/enums';
import { FilterListRounded, RadioButtonCheckedRounded, RadioButtonUncheckedRounded } from '@material-ui/icons';
import bigDecimal from 'js-big-decimal';
import { pricing_Score } from '../lib/computed';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        '& .MuiTableCell-sizeSmall': {
            padding: 6,
            '&:first-child': {
                paddingLeft: 40,
                position: 'relative'
            },
            '&:last-child': {
                paddingRight: 24
            }
        },
        '& .MuiTableCell-head': {
            textAlign: 'left',
        }
    },
    action: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
    },
    titleText: {
        fontWeight: 'bold',
    },
    titleNum: {
        fontWeight: 'bold',
    },
    titleAction: {
        fontWeight: 'bold',
        textAlign: 'right',
    },
    cellNum: {
        lineHeight: '30px',
        textAlign: 'center',
    },
    cellText: {
        lineHeight: '30px',
        textAlign: 'left',
    },
    cellAction: {
        lineHeight: '30px',
        textAlign: 'right',
    },
    actionButton: {
        minWidth: 0
    },
    table: {
        tableLayout: 'fixed',
        '& .MuiTableCell-head': {
            textAlign: 'center',
            padding: '6px 3px',
        },
        '& .MuiTableCell-body': {
            padding: '6px 3px',
        }
    },
    hover: {
        background: grey[600],
    },
    effective: {
        background: grey[700],
    },
    pointer: {
        cursor: 'pointer',
    },
    ftUp: {
        backgroundColor: '#b23c17',
    },
    ftDown: {
        backgroundColor: '#357a38',
    },
    commentIcon: {
        width: 32,
        height: 32,
        position: 'absolute',
        left: 6,
        top: 6,
    },
    typeMenu: {
        width: 500,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '& > li': {
            width: '25%',
        },
    },
    tdHover: {
        cursor: 'pointer',
        '&:hover': {
            background: grey[600],
        }
    }
}));

/**
 * 
 * @param {import('../api/comment').Comment} comment 
 * @param {boolean} commentOnly 
 */
function getCommentPin(comment, commentOnly) {
    switch (comment.status_Action) {
        case 1:
            return callPin;
        case 2:
            return watchPin;
        case 3:
            return shortPin;
        case 4:
            if (comment.short_Wishlist === 1) {
                return shortPin;
            } else {
                return passPin;
            }
        default:
            return commentOnly ? logicPin : commentPin;
    }
}

/**
 * 
 * @param {import('../api/comment').Comment} comment
 * @param {bigDecimal} pricing_Close
 * @param {0|1|2} shortable
 * @param {bigDecimal} defaultPricingScore
 */
function computeDisplayPricingScore(comment, pricing_Close, shortable, defaultPricingScore) {
    if (comment?.pricing_Score) {
        return bns(comment.pricing_Score, 1);
    }
    if (comment?.valuation_Price_Low && comment?.valuation_Price_High) {
        return bns(pricing_Score(pricing_Close, shortable, comment.valuation_Price_Low, comment.valuation_Price_High), 1);
    }
    return bns(defaultPricingScore, 1);
}

/**
 * @param {{
 *   stock: import('../api/stock').StockInfo,
 *   chartData: import('../api/stock').StockKChartItem[],
 *   quarterOrSemi: 0 | 1 | 2,
 *   reports: {
 *     noticeList?: (import('../api/stock').StockReportQuarterlyNotice & { haveEffectiveStatus?: boolean })[],
 *     expressList?: (import('../api/stock').StockReportQuarterlyExpress & { haveEffectiveStatus?: boolean })[],
 *     faList?: (import('../api/stock').StockReportQuarterlyFA & { haveEffectiveStatus?: boolean })[],
 *   },
 *   comments: import('../api/comment').Comment[],
 *   events: import('../api/event').SignalEvent[],
 *   onChangeStockStatus: (status: number, reportId: number, commentId: number, date: number) => void,
 *   onChangeFTType: (type, reportId) => void,
 *   onShowCommentDialog: (opts: import('../components/CommentDialog').CommentDialogState) => void,
 * }} param0 
 */
function FATable({ 
    stock,
    chartData,
    reports,
    comments = [],
    events = [],
    quarterOrSemi,
    onChangeStockStatus, onChangeFTType,
    onShowCommentDialog,
}) {
    const classes = useStyles();
    const editable = useStockIsEditable(stock);
    const [
        types, 
        setTypes
    ] = useState({
        notice: true,
        express: true,
        fa: true,
        comment: true,
        rsi_50_Up: true,
        rsi_50_Down: true,
        rsi_75: true,
        rsi_25: true,
        weeks_52_Hi: true,
        weeks_52_Lo: true,
        macd_Up: true,
        macd_Down: true,
        comment_only: true,
    });
    const list = useMemo(() => {
        const cs = [...comments];
        const nl = (reports?.noticeList||[]).map(row => { 
            const ci = cs.findIndex(c => c.stock_Report_Quarterly_ID === row.id || c.date === row.notice_Release_Date);
            /** @type {import('../api/comment').Comment} */
            let c = null;
            if (ci >= 0) {
                c = cs[ci];
                cs.splice(ci, 1);
            }
            if (!types.notice && (!types.comment || !c)) return null;
            return {
                key: `notice-${row.id}`,
                id: row.id,
                order: row.notice_Release_Date || row.rpt_Period,
                comment: c,
                commentOnly: false, 
                haveEffectiveStatus: row.haveEffectiveStatus,
                status: row.status_Action,
                statusEnabled: row.last,
                ftEnabled: true,

                event: date2quarterOrSemi(quarterOrSemi, row.rpt_Period) + '预',
                datenum: row.notice_Release_Date,
                date: date2str(row.notice_Release_Date),
                rev: '',
                revYOY: '',
                profit: (!row.notice_Q_NP_Dside && !row.notice_Q_NP_Uside) ? '' : `${bns(row.notice_Q_NP_Dside)}-${bns(row.notice_Q_NP_Uside)}`,
                profitYOY: (!row.notice_Q_NP_Dside_Pct && !row.notice_Q_NP_Uside_Pct) ? '' : `${bns(row.notice_Q_NP_Dside_Pct)}${row.notice_Q_NP_Dside_Pct ? '%' : ''}-${bns(row.notice_Q_NP_Uside_Pct)}${row.notice_Q_NP_Uside_Pct ? '%' : ''}`,
                deducted: '',
                deductedYOY: '',
                price_Close: bns(chartData.find(item => item[0] === row.notice_Release_Date)?.[2] || row.price_Close),
                ft_Type: row.ft_Type,
                ft_Score: bns(row.ft_Score, 0),
                pt_Score: bns(chartData.find(item => item[0] === row.notice_Release_Date)?.[5], 1),
                external: c ? ExternalLabels[c.external || 0] : "",
                trend_Score: bns(c?.trend_Score || row.trend_Score, 1),
                pricing_Score: computeDisplayPricingScore(c, row.price_Close, stock.shortable_Or_Default, row.pricing_Score),
                quality_Score: bns(c?.quality_Score || row.quality_Score,1 ),

                defaultQualityScore: row.quality_Score,
                defaultPricingScore: row.pricing_Score,
                defaultTrendScore: row.trend_Score,
            }
        }).filter(Boolean);
        const el = (reports?.expressList||[]).map(row => { 
            const ci = cs.findIndex(c => c.stock_Report_Quarterly_ID === row.id || c.date === row.express_Release_Date);
            let c = null;
            if (ci >= 0) {
                c = cs[ci];
                cs.splice(ci, 1);
            }
            if (!types.express && (!types.comment || !c)) return null;
            return {
                key: `express-${row.id}`,
                id: row.id,
                order: row.express_Release_Date || row.rpt_Period,
                comment: c,
                commentOnly: false,
                haveEffectiveStatus: row.haveEffectiveStatus,
                status: row.status_Action,
                statusEnabled: row.last,
                ftEnabled: true,

                event: date2quarterOrSemi(quarterOrSemi, row.rpt_Period) + '快',
                datenum: row.express_Release_Date,
                date: date2str(row.express_Release_Date),
                rev: bns(row.express_Q_Rev),
                revYOY: `${bns(row.express_Q_Rev_YOY)}${row.express_Q_Rev_YOY ? '%' : ''}`,
                profit: bns(row.express_Q_Profit_Parent_Comp),
                profitYOY: `${bns(row.express_Q_Profit_Parent_Comp_YOY)}${row.express_Q_Profit_Parent_Comp_YOY ? '%' : ''}`,
                deducted: '',
                deductedYOY: '',
                price_Close: bns(chartData.find(item => item[0] === row.express_Release_Date)?.[2] || row.price_Close),
                ft_Type: row.ft_Type,
                ft_Score: bns(row.ft_Score, 0),
                pt_Score: bns(chartData.find(item => item[0] === row.express_Release_Date)?.[5], 1),
                external: c ? ExternalLabels[c.external || 0] : "",
                trend_Score: bns(c?.trend_Score || row.trend_Score, 1),
                pricing_Score: computeDisplayPricingScore(c, row.price_Close, stock.shortable_Or_Default, row.pricing_Score),
                quality_Score: bns(c?.quality_Score || row.quality_Score,1 ),

                defaultQualityScore: row.quality_Score,
                defaultPricingScore: row.pricing_Score,
                defaultTrendScore: row.trend_Score,
            }
        }).filter(Boolean);
        const fl = (reports?.faList||[]).map(row => {
            const ci = cs.findIndex(c => c.stock_Report_Quarterly_ID === row.id || c.date === row.fa_Release_Date);
            let c = null;
            if (ci >= 0) {
                c = cs[ci];
                cs.splice(ci, 1);
            }
            if (!types.fa && (!types.comment || !c)) return null;
            return {
                key: `fa-${row.id}`,
                id: row.id,
                order: row.fa_Release_Date || row.fa_Expected_Release_Date || row.rpt_Period,
                comment: c,
                commentOnly: false,
                haveEffectiveStatus: row.haveEffectiveStatus,
                status: row.status_Action,
                statusEnabled: row.last,
                ftEnabled: true,

                event: date2quarterOrSemi(quarterOrSemi, row.rpt_Period) + '财',
                datenum: row.fa_Release_Date||row.fa_Expected_Release_Date,
                date: date2str(row.fa_Release_Date||row.fa_Expected_Release_Date),
                rev: bns(row.fa_Q_Rev),
                revYOY: `${bns(row.fa_Q_Rev_YOY)}${row.fa_Q_Rev_YOY ? '%' : ''}`,
                profit: bns(row.fa_Q_Profit_Parent_Comp),
                profitYOY: `${bns(row.fa_Q_Profit_Parent_Comp_YOY)}${row.fa_Q_Profit_Parent_Comp_YOY ? '%' : ''}`,
                deducted: bns(row.fa_Q_Profit_Parent_Comp_Deducted),
                deductedYOY: `${bns(row.fa_Q_Profit_Parent_Comp_Deducted_YOY)}${row.fa_Q_Profit_Parent_Comp_Deducted_YOY ? '%' : ''}`,
                price_Close: bns(chartData.find(item => item[0] === row.fa_Release_Date)?.[2] || row.price_Close),
                ft_Type: row.ft_Type,
                ft_Score: bns(row.ft_Score, 0),
                pt_Score: bns(chartData.find(item => item[0] === row.fa_Release_Date)?.[5], 1),
                external: c ? ExternalLabels[c.external || 0] : "",
                trend_Score: bns(c?.trend_Score || row.trend_Score, 1),
                pricing_Score: computeDisplayPricingScore(c, row.price_Close, stock.shortable_Or_Default, row.pricing_Score),
                quality_Score: bns(c?.quality_Score || row.quality_Score,1 ),

                defaultQualityScore: row.quality_Score,
                defaultPricingScore: row.pricing_Score,
                defaultTrendScore: row.trend_Score,
            }
        }).filter(Boolean);
        const evl = events.filter(filterEvent).map(ev => {
            const ci = cs.findIndex(c => c.date === ev.date_First);
            if (ci < 0) {
                return null;
            }
            const c = cs[ci];
            cs.splice(ci, 1);
            if (EventKeys.every(k => !ev[k] || !types[k]) && (!types.comment || !c)) return null;
            return {
                key: `event-${ev.id}`,
                id: ev.id,
                order: ev.date_First,
                comment: c,
                commentOnly: false,
                haveEffectiveStatus: false,
                status: 0,
                statusEnabled: false,
                ftEnabled: false,

                event: formatEventType(ev),
                datenum: ev.date_First,
                date: date2str(ev.date_First),
                rev: '',
                revYOY: '',
                profit: '',
                profitYOY: '',
                deducted: '',
                deductedYOY: '',
                price_Close: bns(chartData.find(item => item[0] === ev.date_First)?.[2] || ev.price_Close),
                ft_Type: 0,
                ft_Score: bns(ev.ft_Score, 0),
                pt_Score: bns(chartData.find(item => item[0] === ev.date_First)?.[5], 1),
                external: c ? ExternalLabels[c.external || 0] : "",
                trend_Score: bns(c?.trend_Score || ev.trend_Score_Or_Default, 1),
                pricing_Score: computeDisplayPricingScore(c, ev.price_Close, stock.shortable_Or_Default, ev.pricing_Score),
                quality_Score: bns(c?.quality_Score || ev.quality_Score,1 ),

                defaultQualityScore: ev.quality_Score,
                defaultPricingScore: ev.pricing_Score,
                defaultTrendScore: ev.trend_Score_Or_Default,
            }
        }).filter(Boolean);
        const cl = cs.map(c => {
            if (!types.comment && !types.comment_only) return null;
            return {
                key: `comment-${c.id}`,
                id: c.id,
                order: c.date,
                comment: c,
                commentOnly: true,
                haveEffectiveStatus: false,
                status: 0,
                statusEnabled: false,
                ftEnabled: false,

                event: '观点评论',
                datenum: c.date,
                date: date2str(c.date),
                rev: '',
                revYOY: '',
                profit: '',
                profitYOY: '',
                deducted: '',
                deductedYOY: '',
                price_Close: bns(chartData.find(item => item[0] === c.date)?.[2]),
                ft_Type: 0,
                ft_Score: '',
                pt_Score: bns(chartData.find(item => item[0] === c.date)?.[5], 1),
                external: ExternalLabels[c.external || 0],
                trend_Score: bns(c.trend_Score || stock.trend_Score_Or_Default, 1),
                pricing_Score: computeDisplayPricingScore(c, val(chartData.find(item => item[0] === c.date)?.[2]), stock.shortable_Or_Default, c.pricing_Score),
                quality_Score: bns(c.quality_Score || stock.quality_Score, 1),

                defaultQualityScore: stock.quality_Score,
                defaultPricingScore: stock.pricing_Score,
                defaultTrendScore: stock.trend_Score_Or_Default,
            }
        }).filter(Boolean);
        
        const list = [...nl,...el,...fl,...evl,...cl];
        list.sort((a,b) => b.order - a.order);
        return list;
    }, [reports, comments, events, types, quarterOrSemi, chartData]);

    const eventSelectDom = useRef();
    const [selectTypeOpen, setSelectTypeOpen] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const showList = showMore ? list : list.slice(0, 15);
    const hasMore = !showMore && list.length > 15;
    return (
        <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width:32}}/>
                        <TableCell className={classes.titleNum}>
                            <span 
                                ref={eventSelectDom} 
                                onClick={()=>setSelectTypeOpen(true)}
                                style={{cursor:'pointer'}}
                            >
                                <span style={{verticalAlign: 'middle'}}>事件</span>
                                <FilterListRounded style={{verticalAlign: 'middle'}}/>
                            </span>
                            <Menu 
                                anchorEl={eventSelectDom.current}
                                classes={{ list: classes.typeMenu }} 
                                open={selectTypeOpen}
                                onClose={()=>setSelectTypeOpen(false)}
                            >
                                { Object.keys(EventNamesWithReports).map(key => (
                                        <MenuItem key={key} value={key} 
                                            onClick={()=>{setTypes(ts => ({...ts, [key]: !ts[key]}))}}
                                        >
                                            <Checkbox checked={Boolean(types[key])} size="small" />
                                            <span style={{fontSize:'0.875rem', fontWeight: 400}}>{EventNamesWithReports[key]}</span>
                                        </MenuItem>
                                ))}
                                <MenuItem 
                                    onClick={()=>{
                                        setTypes(types => {
                                            const to = !Object.keys(EventNamesWithReports).every(k => types[k]);
                                            const r = {...types};
                                            for (const key in EventNamesWithReports) {
                                                r[key] = to;
                                            }
                                            return r;
                                        });
                                    }}
                                >
                                    <Checkbox checked={Object.keys(EventNamesWithReports).every(k => types[k])} size="small" />
                                    <span style={{fontSize:'0.875rem', fontWeight: 400}}>全选</span>
                                </MenuItem>
                            </Menu>
                        </TableCell>
                        <TableCell className={classes.titleNum}>股分</TableCell>
                        <TableCell className={classes.titleNum}>日期</TableCell>
                        <TableCell className={classes.titleNum}>营收</TableCell>
                        <TableCell className={classes.titleNum}>同比%</TableCell>
                        <TableCell className={classes.titleNum}>净利/归母</TableCell>
                        <TableCell className={classes.titleNum}>同比%</TableCell>
                        <TableCell className={classes.titleNum}>扣非</TableCell>
                        <TableCell className={classes.titleNum}>同比%</TableCell>
                        <TableCell className={classes.titleNum}>收盘价</TableCell>
                        <TableCell className={classes.titleNum}>Ft</TableCell>
                        <TableCell className={classes.titleNum}>Pt</TableCell>
                        <TableCell className={classes.titleNum}>外</TableCell>
                        <TableCell className={classes.titleNum}>{FieldNames.quality_Score}</TableCell>
                        <TableCell className={classes.titleNum}>动能</TableCell>
                        <TableCell className={classes.titleNum}>估值</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {list.length === 0 ? <TableRow>
                    <TableCell colSpan={16} style={{color:'rgba(255,255,255,0.7)', textAlign: 'center'}}>
                        暂无事件
                    </TableCell>
                </TableRow> : null}
                {showList.map(row => (
                    <FATableRow
                        key={row.key}
                        stock={stock}
                        row={row}
                        onShowCommentDialog={onShowCommentDialog}
                        classes={classes}
                        editable={editable}
                        onChangeStockStatus={onChangeStockStatus}
                        onChangeFTType={onChangeFTType}
                    />
                ))}
                { hasMore ? <TableRow className={classes.tdHover} onClick={()=>setShowMore(true)}>
                    <TableCell  colSpan={16} style={{color:'rgba(255,255,255,0.7)', textAlign: 'center'}}>
                        显示更多
                    </TableCell>
                </TableRow> : null}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

function FATableRow({ 
    stock, row, classes, editable,
    onShowCommentDialog, onChangeStockStatus, onChangeFTType
}) {
    const [hoverRow, setHoverRow] = useState(false);
    const [hoverFT, setHoverFT] = useState(false);
    const [stateOpen, setStateOpen] = useState(false);
    const tdRef = useRef();
    return (
        <TableRow
            onMouseEnter={()=>setHoverRow(true)}
            onMouseLeave={()=>setHoverRow(false)}
            className={`${classes.pointer} ${(hoverRow && !hoverFT) ? classes.hover : (row.haveEffectiveStatus ? classes.effective : '') }`} 
            onClick={() => onShowCommentDialog({
                commentId: row.comment?.id,
                reportId: row.id,
                date: row.datenum,
                price_Close: row.price_Close,
                defaultPricingScore: row.defaultPricingScore,
                defaultQualityScore: row.defaultQualityScore,
                defaultTrendScore: row.defaultTrendScore,
            })}
        >
            <TableCell className={classes.cellNum}>
                { row.comment ? (
                    <img src={getCommentPin(row.comment, row.commentOnly, stock)} className={classes.commentIcon} />
                ) : null }
            </TableCell>
            <TableCell className={classes.cellNum}>
                {row.event}
            </TableCell>
            <TableCell className={classes.cellNum}>
                {bns(add(
                    row.quality_Score,
                    row.pricing_Score,
                    row.trend_Score
                ))}
            </TableCell>
            <TableCell className={classes.cellNum}>{row.date}</TableCell>
            <TableCell className={classes.cellNum}>{row.rev}</TableCell>
            <TableCell className={classes.cellNum}>{row.revYOY}</TableCell>
            <TableCell className={classes.cellNum}>{row.profit}</TableCell>
            <TableCell className={classes.cellNum}>{row.profitYOY}</TableCell>
            <TableCell className={classes.cellNum}>{row.deducted}</TableCell>
            <TableCell className={classes.cellNum}>{row.deductedYOY}</TableCell>
            <TableCell className={classes.cellNum}>{row.price_Close}</TableCell>
            <TableCell className={`${classes.cellNum} ${hoverFT ? classes.hover : '' }`}
                ref={tdRef}
                onMouseEnter={()=>setHoverFT(true)}
                onMouseLeave={()=>setHoverFT(false)}
                onClick={(e) => {
                    e.stopPropagation();
                    setStateOpen(true);
                    setHoverRow(false);
                    setHoverFT(false);
                }}
            >
                { !row.ft_Type ? row.ft_Score : null }
                { row.ft_Type === 1 ? (
                    <Chip size="small" label={row.ft_Score} className={classes.ftUp}/>
                ) : null}
                { row.ft_Type === 2 ? (
                    <Chip size="small" label={row.ft_Score} className={classes.ftDown}/>
                ) : null}
                { row.ft_Type === 3 ? (
                    <Chip size="small" label={row.ft_Score}/>
                ) : null}
                <Menu
                    anchorEl={tdRef.current}
                    open={stateOpen}
                    onClose={e => {
                        e?.stopPropagation && e.stopPropagation();
                        setStateOpen(false);
                    }}
                >
                    {FTTypeOptions.map((option) => (
                        <MenuItem
                            key={option.id}
                            selected={option.id === row.ft_Type}
                            onClick={(e) => {
                                e.stopPropagation();
                                onChangeFTType(option.id, row.id);
                                setStateOpen(false);
                            }}
                        >
                            { option.id === row.ft_Type ? 
                                <RadioButtonCheckedRounded /> :
                                <RadioButtonUncheckedRounded />
                            }
                            <span style={{marginLeft:8}}>{option.name}</span>
                        </MenuItem>
                    ))}
                </Menu>
            </TableCell>
            <TableCell className={classes.cellNum}>{row.pt_Score}</TableCell>
            <TableCell className={classes.cellNum}>{row.external}</TableCell>
            <TableCell className={classes.cellNum}>{row.quality_Score}</TableCell>
            <TableCell className={classes.cellNum}>{row.trend_Score}</TableCell>
            <TableCell className={classes.cellNum}>{row.pricing_Score}</TableCell>
        </TableRow>
    );
}

export default memo(FATable);

const { report_Quarterly: _, ...EventNamesNoQuarterReport } = EventNames;

const EventNamesWithReports = {
    notice: '预',
    express: '快',
    fa: '财',
    comment_only: '逻',
    ...EventNamesNoQuarterReport,
    comment: '评',
}

const EventKeys = Object.keys(EventNamesNoQuarterReport);

function filterEvent(event) {
    for (let k of EventKeys) {
        if (event[k]) {
            return true;
        }
    }
    return false;
}

/**
 * @param {import('../api/event').SignalEvent} event 
 */
function formatEventType(event) {
    return Object.keys(EventNames).map(key => {
        if (event[key]) {
            return EventNames[key];
        }
    }).filter(Boolean)
    .join(', ');
}

