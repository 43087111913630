import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import ShortableCNTable from './ShortableCNTable';
import ShortableCNTableImport from './ShortableCNTableImport';

export default function ShortableCNTableRouter() {
    const { path, url } = useRouteMatch();
    return <Switch>
        <Route path={path} exact component={ShortableCNTable} />
        <Route path={`${path}/add`} exact component={ShortableCNTableImport} />
        <Route path={`${path}/:version`} exact component={ShortableCNTable} />
        <Redirect to={url} />
    </Switch>
}