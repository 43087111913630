import React, { useEffect, useState } from "react";
import SearchableSelect from "../../components/SearchableSelect";
import { DialogContent, DialogActions, Button } from "@material-ui/core";
import moment from "moment";
import { dateToDayNum, quarterFromDayNum, semiFromDayNum, toDate } from "../../lib/date";
import { useCoCallback } from "../../lib/useCo";
import { useSnackbar } from "notistack";
import { addEmptyReport } from "../../api/stock";
import { timeout } from "../../api/common";
import { DatePicker } from "@material-ui/pickers";

const currentYear = moment().year();
/** @type {{ id:number, name: string, year: number, quarter: number }[] } */
const qoptions = [];
/** @type {{ id:number, name: string, year: number, quarter: number }[] } */
const soptions = [];
for (let y = currentYear - 1; y <= currentYear + 3; y++) {
    for (let q = 0; q < 4; q++) {
        const date = moment();
        let isValidSemi;
        switch(q) {
            case 0:
                date.set({ year: y, month: 2, date: 31, hour: 0, minute: 0, second: 0, millisecond: 0 });
                isValidSemi = false;
                break;
            case 1:
                date.set({ year: y, month: 5, date: 30, hour: 0, minute: 0, second: 0, millisecond: 0 });
                isValidSemi = true;
                break;
            case 2:
                date.set({ year: y, month: 8, date: 30, hour: 0, minute: 0, second: 0, millisecond: 0 });
                isValidSemi = false;
                break;
            case 3:
                date.set({ year: y, month: 11, date: 31, hour: 0, minute: 0, second: 0, millisecond: 0 });
                isValidSemi = true;
                break;
        }
        const dayNum = dateToDayNum(date);
        qoptions.push({
            id: dayNum,
            name: quarterFromDayNum(dayNum),
            year: y,
            quarter: q,
        });
        if (isValidSemi) {
            soptions.push({
                id: dayNum,
                name: semiFromDayNum(dayNum),
                year: y,
                quarter: q,
            })
        }
    }
}
const typeOptions = [{
    id: 1,
    name: '预告'
}, {
    id: 2,
    name: '快报'
}, {
    id: 3,
    name: '财报'
}];

/**
* @param {{
*   state: import(".").AddReportDialogState,
*   onReloadReport: () => void
* }} param0 
*/
export default function AddReport({ state, onReloadReport }) {
    const [q, setQ] = useState(null);
    const [type, setType] = useState(null);
    const { open, code, variant, onClose } = state;
    const [date, setDate] = useState(null);
    useEffect(() => {
        if (open) {
            const month = moment().get('month');//0-11 month
            let year = moment().get('year');
            let quarter;
            switch(month) {
                case 11:
                    quarter = 3;
                    break;
                case 0:
                case 1:
                case 2:
                    year = year - 1;
                    quarter = 3;
                    break;
                case 3:
                    quarter = 0;
                    break;
                case 4:
                case 5:
                case 6:
                case 7:
                    quarter = 1;
                    break;
                case 8:
                case 9:
                case 10:
                    quarter = 2;
                    break;
                default:
                    throw new Error('Bad month ' + month);
            }
            if (variant === 'quarter') {
                const i = qoptions.findIndex(opt => opt.year === year && opt.quarter === quarter);
                setQ(qoptions[i]);
            } else {
                if (quarter === 0) quarter = 1;
                if (quarter === 2) quarter = 3;
                const i = soptions.findIndex(opt => opt.year === year && opt.quarter === quarter);
                setQ(soptions[i]);
            }
        }
    }, [open]);
    const options = variant === 'quarter' ? qoptions : soptions;
    const [submitting, setSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = useCoCallback(function*(){
        try {
            if (!q?.id) {
                throw new Error('请选择一个季度');
            }
            if (!type?.id) {
                throw new Error('请选择一个类型');
            }
            if (!date || !date.isValid()) {
                throw new Error('请选择一个发布日期');
            }
            setSubmitting(true);
            yield addEmptyReport(code, type.id, q.id, toDate(date));
            enqueueSnackbar('添加成功', { variant: 'success' });
            onReloadReport();
            yield timeout(500);
            onClose();
            setSubmitting(false);
        } catch (e) {
            console.warn(e);
            enqueueSnackbar(e.message);
            setSubmitting(false);
        }
    }, [code, onReloadReport, onClose, q, type, date]);

    return <>
        <DialogContent>
            <SearchableSelect
                disabled={submitting}
                options={typeOptions} 
                label="类型" 
                style={{width:300}} 
                value={type}
                onChange={setType}
            />
            <SearchableSelect
                style={{marginTop: 16, width:300}}
                disabled={submitting}
                options={options} 
                label="季度/年度"
                value={q}
                onChange={setQ}
            />
            <DatePicker
                style={{marginTop: 16, width: 300}}
                autoOk
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="none"
                label="发布日期"
                value={date}
                onChange={setDate}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} disabled={submitting}>
                取消
            </Button>
            <Button onClick={onSubmit} disabled={submitting} color="primary">
                添加
            </Button>
        </DialogActions>
    </>
}