import { useCallback, useEffect, useRef, useState } from "react";

/**
 * @template T
 * @param {T} value 
 * @param {(value: T) => void} onChange 
 * @param {T} defaultValue
 * @returns {[T, React.Dispatch<React.SetStateAction<T>>]}
 */
export function useControlledState(value, onChange, defaultValue = undefined) {
    const stateRef = useRef({
        value: value === undefined ? defaultValue : value,
        isManaged: value !== undefined,
        onChange: onChange
    });
    const [, setRender] = useState(0);
    const setValue = useCallback(v => {
        const state = stateRef.current;
        const newValue = (typeof v === 'function') ? v(state.value) : v;
        if (newValue === state.value) {
            return;
        }
        state.value = newValue;
        if (state.onChange) {
            state.onChange(newValue);
        }
        if (!state.isManaged) {
            setRender(r => r + 1);
        }
    }, []);
    useEffect(() => {
        const isManaged = value !== undefined;
        // if (isManaged !== stateRef.current.isManaged) {
        //     console.warn("Changing 'value' between undefined and other values affects component's managed state, which is undefined behavior.");
        // }
        stateRef.current.isManaged = isManaged;
        if (isManaged) {
            stateRef.current.value = value;
        }
    }, [value]);
    const actualValue = stateRef.current.isManaged ? value : stateRef.current.value;
    return [actualValue, setValue];
}