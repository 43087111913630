import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataTable } from './primereact/DataTable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Button, ButtonGroup, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Popover, Popper, Slider } from '@material-ui/core';
import { Chips } from './Chips';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useControlledState } from '../lib/useControlledState';
import useTableStyle from './useTableStyle';
import { comparer } from '../lib/sort';
import { sortFilterData } from './lib';
import FocusScope from '../lib/FocusScope';

/**
 * @template {{[key:string]: unknown}} T
 * @typedef {{
 *   [K in keyof T]: string;
 * }} Stringified
 */

/** @typedef {import('mui-datatables').MUIDataTableProps} MUIDataTableProps */
/**  
 * @typedef {import('./lib').FieldFilter} FieldFilter
 */

/** 
 * @template T 
 * @typedef {import('./lib').Fields<T>} Fields
 */

/**
 * @template T
 * @typedef {import('./lib').SorterAndFilters<T>} SorterAndFilters
 */

/**
 * @template T
 * @typedef {{
 *   field: Fields<T>,
 *   label: React.ReactNode,
 *   model: 'none' | 'string' | 'number' | 'enum',
 *   headerClassName?: string,
 *   headerStyle?: React.CSSProperties,
 *   cellClassName?: string,
 *   cellStyle?: React.CSSProperties,
 *   render?: (row: Readonly<T>) => React.ReactNode, 
 *   enumRender?: (value: string) => React.ReactNode,
 *   footer?: (values: Readonly<Readonly<T>[]>) => React.ReactNode,
 *   sortable?: boolean,
 *   width?: number,
 *   widthFlex?: number,
 *   frozen?: boolean,
 * }} ColumnOption
 */

/**
 * @template T
 * @typedef {{
 *   width?: number,
 *   height: number,
 *   frozenWidth?: number | string,
 *   data: Readonly<Readonly<T>[]>,
 *   dataKey?: keyof T,
 *   columns: Readonly<Readonly<ColumnOption<T>>[]>,
 *   sorterAndFilters?: SorterAndFilters<T>,
 *   maxSorters?: number,
 *   onSorterAndFiltersChange?: (v:SorterAndFilters<T>) => void,
 *   visibleColumnOrder?: Readonly<Fields<T>[]>,
 *   onVisibleColumnOrderChange?: (v:Fields<T>[]) => void,
 *   variant: 'full' | 'compat',
 * }} TableProps
 */

/**
 * @template T
 * @typedef {(props: TableProps<T>) => JSX.Element} VirtualScrollTableComponent
 */

const extraHeight = 0;
const cellHeight = 35;

/**
 * @template T
 * @param {TableProps<T>} param0 
 */
function VirtualScrollTable({
    width,
    height,
    frozenWidth,
    data, 
    columns,
    variant = 'full',
    maxSorters = 2,
    sorterAndFilters,
    onSorterAndFiltersChange,
    visibleColumnOrder,
    onVisibleColumnOrderChange,
    dataKey,
}) {
    const viewLength = 10 + Math.ceil((height - extraHeight) / cellHeight);

    const [, setRender] = useState(0);

    const classes = useTableStyle();

    useEffect(() => {
        setRender(r => r+1);
    }, []);

    const visibleColumns = useMemo(() => {
        if (!visibleColumnOrder || visibleColumnOrder.length === 0) return columns;
        return visibleColumnOrder
            .map(field => columns.find(col => col.field === field))
            .filter(a => a);
    }, [columns, visibleColumnOrder]);

    const onColReorder = useCallback(e => {
        const fields = e.columns.map(col => col.props.field);
        if (onVisibleColumnOrderChange) {
            onVisibleColumnOrderChange(fields);
        }
    }, [onVisibleColumnOrderChange]);

    const [scrollState, setScrollState] = useState({
        start: 0,
        length: viewLength,
    });

    /**
     * @type {SorterAndFilters<T>} 
     */
    const initSorterAndFilters = null;
    const [sortFilterState, setSortFilterState] = useControlledState(sorterAndFilters, onSorterAndFiltersChange, initSorterAndFilters);

    const onSort = useCallback(({multiSortMeta}) => {
        if (multiSortMeta.length > maxSorters) {
            multiSortMeta = multiSortMeta.slice(multiSortMeta.length - maxSorters);
        }
        setSortFilterState(s => ({ ...s, sorters: multiSortMeta }));
    }, [setSortFilterState]);

    const onFilter = useCallback(({filters}) => {
        setSortFilterState(s => ({ ...s, filters }));
    }, [setSortFilterState]);

    /** @type {(col: ColumnOption<T>, value: FieldFilter) => void} */
    const onFilterChange = useCallback((col, value) => {
        setSortFilterState(s => ({
            ...s,
            filters: {
                ...s?.filters,
                [col.field]: value
            }
        }))
    }, [setSortFilterState]);

    /** @type {(col: ColumnOption<T>, value: string) => void} */
    const onSearchChange = useCallback((col, value) => {
        setSortFilterState(s => ({
            ...s,
            filters: {
                ...s?.filters,
                [col.field]: {
                    ...s?.filters?.[col.field],
                    search: value
                }
            }
        }));
    }, [setSortFilterState]);

    const onVirtualScroll = useCallback(({ first, rows }) => {
        setScrollState({
            start: first,
            length: rows,
        });
    }, []);

    const sortedFilteredData = useMemo(() => {
        return sortFilterData(data, sortFilterState);
    }, [data, sortFilterState]);
    
    const viewData = useMemo(() => {
        if (!sortedFilteredData) return [];
        return sortedFilteredData.slice(scrollState.start, scrollState.start + scrollState.length);
    }, [scrollState, sortedFilteredData]);

    const enums = useMemo(() => {
        /** @type {{[key in Fields<T>]?: string[]}} */
        const r = {};
        columns.filter(c => c.model === 'enum').forEach(col => {
            const set = new Set();
            data.forEach(item => {
                const vs = item[col.field];
                if (Array.isArray(vs)) {
                    vs.forEach(v => set.add(v));
                } else {
                    set.add(vs);
                }
            });
            const l = Array.from(set);
            l.sort(comparer([{ field: '', order: 1}]));
            r[col.field] = l;
        });
        return r;
    }, [data, columns]);

    const minmax = useMemo(() => {
        /** @type {{[key in Fields<T>]?: { min: number, max: number }}} */
        const r = {};
        columns.filter(c => c.model === 'number').forEach(col => {
            const n = data.map(item => Number(item[col.field]) || 0);
            const max = Math.max(...n);
            const min = Math.min(...n);
            r[col.field] = {
                max, min
            }
        });
        return r;
    }, [data, columns]);

    const footerCols = useMemo(() => {
        if (columns.some(col => col.footer)) {
            return (
                <ColumnGroup>
                    <Row>{
                        visibleColumns.map(col => (
                            <Column key={col.field} footer={col.footer ? col.footer(sortedFilteredData) : ""} colSpan={col.widthFlex} />
                        ))
                    }</Row>
                </ColumnGroup>
            );
        }
        return null;
    }, [columns, visibleColumns, sortedFilteredData]);
    
    return <Paper className={classes[variant]}>
        <DataTable
            style={ width ? { width } : null }
            frozenWidth={frozenWidth}
            className="p-datatable-sm"
            value={viewData} 
            scrollable
            scrollHeight={`${height-extraHeight}px`}
            virtualScroll
            lazy 
            rows={viewLength} 
            loading={false}
            virtualRowHeight={cellHeight} 
            onVirtualScroll={onVirtualScroll} 
            virtualScrollDelay={0}
            totalRecords={sortedFilteredData.length}
            // reorderableColumns
            onColReorder={onColReorder}
            removableSort
            sortMode="multiple"
            multiSortMeta={sortFilterState?.sorters}
            onSort={onSort}
            filters={sortFilterState?.filters ?? {}}
            onFilter={onFilter}
            footerColumnGroup={footerCols}
            dataKey={dataKey}
        >
            {
                visibleColumns.map(col => renderColumn(
                    col, 
                    sortFilterState?.filters?.[col.field],
                    onFilterChange,
                    sortFilterState?.filters?.[col.field]?.search,
                    onSearchChange,
                    enums[col.field],
                    minmax[col.field]
                ))
            }
        </DataTable>
    </Paper>
}

/**
 * @template T
 * @param {{
 *   height: number,
 *   data: Readonly<Readonly<T>[]>,
 *   columns: Readonly<Readonly<ColumnOption<T>>[]>,
 *   sorterAndFilters?: SorterAndFilters<T>,
 *   onSorterAndFiltersChange?: (v:SorterAndFilters<T>) => void,
 *   visibleColumnOrder?: Readonly<Fields<T>[]>,
 *   onVisibleColumnOrderChange?: (v:Fields<T>[]) => void,
 *   variant?: 'full' | 'compat'
 * }} param0 
 */
function FixedTable({ 
    data, 
    columns, 
    variant = 'full',
    sorterAndFilters, 
    onSorterAndFiltersChange,
    visibleColumnOrder,
    onVisibleColumnOrderChange,
}) {
    const [, setRender] = useState(0);

    useEffect(() => {
        setRender(r => r+1);
    }, []);

    const classes = useTableStyle();

    const visibleColumns = useMemo(() => {
        if (!visibleColumnOrder || visibleColumnOrder.length === 0) return columns;
        return visibleColumnOrder
            .map(field => columns.find(col => col.field === field))
            .filter(a => a);
    }, [columns, visibleColumnOrder]);

    const onColReorder = useCallback(e => {
        const fields = e.columns.map(col => col.props.field);
        if (onVisibleColumnOrderChange) {
            onVisibleColumnOrderChange(fields);
        }
    }, [onVisibleColumnOrderChange]);

    /**
     * @type {SorterAndFilters<T>} 
     */
    const initSorterAndFilters = null;
    const [sortFilterState, setSortFilterState] = useControlledState(sorterAndFilters, onSorterAndFiltersChange, initSorterAndFilters);
    
    const onSort = useCallback(({multiSortMeta}) => {
        setSortFilterState(s => ({ ...s, sorters: multiSortMeta }));
    }, [setSortFilterState]);

    const onFilter = useCallback(({filters}) => {
        setSortFilterState(s => ({ ...s, filters }));
    }, [setSortFilterState]);

    /** @type {(col: ColumnOption<T>, value: FieldFilter) => void} */
    const onFilterChange = useCallback((col, value) => {
        setSortFilterState(s => ({
            ...s,
            filters: {
                ...s?.filters,
                [col.field]: value
            }
        }))
    }, [setSortFilterState]);

    /** @type {(col: ColumnOption<T>, value: string) => void} */
    const onSearchChange = useCallback((col, value) => {
        setSortFilterState(s => ({
            ...s,
            filters: {
                ...s?.filters,
                [col.field]: {
                    ...s?.filters?.[col.field],
                    search: value
                }
            }
        }));
    }, [setSortFilterState]);

    const sortedFilteredData = useMemo(() => {
        return sortFilterData(data, sortFilterState);
    }, [data, sortFilterState]);
    
    const enums = useMemo(() => {
        /** @type {{[key in Fields<T>]?: string[]}} */
        const r = {};
        columns.filter(c => c.model === 'enum').forEach(col => {
            const set = new Set();
            data.forEach(item => {
                const vs = item[col.field];
                if (Array.isArray(vs)) {
                    vs.forEach(v => set.add(v));
                } else {
                    set.add(vs);
                }
            });
            const l = Array.from(set);
            l.sort(comparer([{ field: '', order: 1 }]));
            r[col.field] = l;
        });
        return r;
    }, [data, columns]);

    const minmax = useMemo(() => {
        /** @type {{[key in Fields<T>]?: { min: number, max: number }}} */
        const r = {};
        columns.filter(c => c.model === 'number').forEach(col => {
            const n = data.map(item => Number(item[col.field]) || 0);
            const max = Math.max(...n);
            const min = Math.min(...n);
            r[col.field] = {
                max, min
            }
        });
        return r;
    }, [data, columns]);

    const footerCols = useMemo(() => {
        if (columns.some(col => col.footer)) {
            return (
                <ColumnGroup>
                    <Row>{
                        visibleColumns.map(col => (
                            <Column key={col.field} footer={col.footer ? col.footer(sortedFilteredData) : ""} />
                        ))
                    }</Row>
                </ColumnGroup>
            );
        }
        return null;
    }, [columns, visibleColumns, sortedFilteredData]);
    

    return <Paper className={classes[variant]}>
        <DataTable
            className="p-datatable-sm"
            value={sortedFilteredData} 
            loading={false}
            totalRecords={sortedFilteredData.length}
            // reorderableColumns
            sortMode="multiple"
            multiSortMeta={sortFilterState?.sorters}
            onColReorder={onColReorder}
            removableSort
            onSort={onSort}
            filters={sortFilterState?.filters ?? {}}
            onFilter={onFilter}
            footerColumnGroup={footerCols}
        >
            {
                visibleColumns.map(col => renderColumn(
                    col, 
                    sortFilterState?.filters?.[col.field],
                    onFilterChange,
                    sortFilterState?.filters?.[col.field]?.search,
                    onSearchChange,
                    enums[col.field],
                    minmax[col.field]
                ))
            }
        </DataTable>
    </Paper>
}

const loadingBody = () => <span>{'  '}</span>;

/**
 * @template T
 * @param {ColumnOption<T>} col
 * @param {FieldFilter} filter
 * @param {(col: ColumnOption<T>, value: FieldFilter) => void} onFilterChange
 * @param {string} search
 * @param {(col: ColumnOption<T>, value: string) => void} onSearchChange
 * @param {string[]} enums
 * @param {{min: number, max: number}} minmax
 */
function renderColumn(col, filter, onFilterChange, search, onSearchChange, enums, minmax) {
    let filterEl;
    switch (col.model) {
        case 'enum':
            filterEl = (
                <EnumFilter enums={enums} enumRenderer={col.enumRender} column={col} filter={filter} onFilterChange={onFilterChange} search={search} onSearchChange={onSearchChange} />
            );
            break;
        case 'number':
            filterEl = (
                <NumberFilter minmax={minmax} column={col} filter={filter} onFilterChange={onFilterChange} search={search} onSearchChange={onSearchChange} />
            );
            break;
        default:
            filterEl = (
                <TextFilter column={col} search={search} onSearchChange={onSearchChange} />
            );
            break;
    }
    let style = null;
    let colSpan = null;
    if (col.width) {
        style = { width: col.width };
    } else if (col.widthFlex) {
        colSpan = col.widthFlex;
    }
    return <Column 
        key={col.field} 
        field={col.field} 
        header={col.label} 
        loadingBody={loadingBody}
        body={col.render}
        bodyClassName={col.cellClassName}
        bodyStyle={col.cellStyle}
        headerClassName={col.headerClassName}
        headerStyle={col.headerStyle}
        sortable={col.sortable ?? true}
        filter={col.model!=='none'}
        filterElement={filterEl}
        style={style}
        colSpan={colSpan}
        frozen={col.frozen}
    />
}

/**
 * @template T
 * @param {{
 *   column: ColumnOption<T>,
 *   search: string,
 *   onSearchChange: (col: ColumnOption<T>, value: string) => void
 * }} param0 
 */
function TextFilter({ column, search, onSearchChange }) {
    return <Chips
        text={search || ""}
        onChangeText={text => onSearchChange(column, text)}
        placeholder=""
    />    
}

/**
 * @template T
 * @param {{
 *   enums: string[],
 *   enumRenderer?: (v:string) => React.ReactNode,
 *   column: ColumnOption<T>,
 *   filter: FieldFilter,
 *   onFilterChange: (col: ColumnOption<T>, value: FieldFilter) => void,
 *   search: string,
 *   onSearchChange: (col: ColumnOption<T>, value: string) => void
 * }} param0 
 */
function EnumFilter({ enums, enumRenderer, column, filter, onFilterChange, search, onSearchChange }) {
    const ref = useRef();
    const [visible, setVisible] = useState(false);
    const selections = filter?.in || [];
    function onClick(val) {
        const r = [...selections];
        const idx = r.indexOf(val);
        if (idx >= 0) {
            r.splice(idx, 1);
        } else {
            r.push(val);
        }
        onFilterChange(column, r.length === 0 ? undefined : { in: r });
    }
    return <FocusScope
            onFocus={() => setVisible(true) }
            onBlur={() => setVisible(false) }
        >
        <Chips
            value={selections}
            itemTemplate={enumRenderer}
            onChange={e => onFilterChange(column, { ...filter, in: e.value })}
            wrapperRef={ref}
            text={search || ""}
            onChangeText={text => onSearchChange(column, text)}
            placeholder=""
        />
        <Popper
            open={visible && enums.length > 0}
            anchorEl={ref.current}           
        >
            <Paper elevation={8}>
                {enums.map(val => (
                    <MenuItem
                        key={val || '-'}
                        onClick={() => onClick(val)}
                    >
                        <ListItemIcon>
                            { selections.indexOf(val) >= 0 ?
                                <CheckBoxIcon fontSize="small" />
                                :
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                            }
                        </ListItemIcon>
                        <ListItemText primary={column.enumRender ? column.enumRender(val) : (val || '-')} />
                    </MenuItem>
                ))}
            </Paper>
        </Popper>
    </FocusScope>
}

/**
 * @template T
 * @param {{
 *   column: ColumnOption<T>,
 *   filter: FieldFilter,
 *   onFilterChange: (col: ColumnOption<T>, value: FieldFilter) => void,
 *   minmax: { min: number, max: number },
 *   search: string,
 *   onSearchChange: (col: ColumnOption<T>, value: string) => void
 * }} param0 
 */
function NumberFilter({ minmax, column, filter, onFilterChange, search, onSearchChange }) {
    const min = Math.floor(minmax.min);
    const max = Math.ceil(minmax.max);
    const ref = useRef();
    const [visible, setVisible] = useState(false);
    const [range, setRange] = useState([min, max]);

    const callbackTimeoutRef = useRef(null);

    const inner = !filter?.rangeType || filter.rangeType === 'in';

    function onChange(e, range) {
        setRange(range);
        if (callbackTimeoutRef.current) {
            clearTimeout(callbackTimeoutRef.current);
        }
        callbackTimeoutRef.current = setTimeout(() => {
            callbackTimeoutRef.current = null;
            let [l, h] = range;
            if (l === min) {
                l = null;
            }
            if (h === max) {
                h = null;
            }
            onFilterChange(column, (l === null && h === null && inner) ? undefined : { ...filter, range: [l, h]});
        }, 300);
    }
    
    useEffect(() => {
        const l = typeof filter?.range?.[0] === 'number' ? filter.range[0] : min;
        const h = typeof filter?.range?.[1] === 'number' ? filter.range[1] : max;
        setRange([l, h]);
    }, [filter, min, max]);
    let chips;
    if (inner) {
        if (typeof filter?.range?.[0] === 'number' && typeof filter?.range?.[1] === 'number') {
            chips = [`${filter?.range?.[0]} ≤ N ≤ ${filter?.range?.[1]}`]
        } else if (typeof filter?.range?.[0] === 'number') {
            chips = [`N ≥ ${filter?.range?.[0]}`]
        } else if (typeof filter?.range?.[1] === 'number') {
            chips = [`N ≤ ${filter?.range?.[1]}`]
        } else {
            chips = [];
        }
    } else {
        if (typeof filter?.range?.[0] === 'number' && typeof filter?.range?.[1] === 'number') {
            chips = [`N < ${filter?.range?.[0]}`, `N > ${filter?.range?.[1]}`];
        } else if (typeof filter?.range?.[0] === 'number') {
            chips = [`N < ${filter?.range?.[0]}`]
        } else if (typeof filter?.range?.[1] === 'number') {
            chips = [`N > ${filter?.range?.[1]}`]
        } else {
            chips = [];
        }
    }
    function onDeleteChip(e) {
        const newChips = e.value;
        if (newChips.length === 0) {
            onFilterChange(column, { ...filter, range: [null, null] })
        } else {
            const remain = newChips[0];
            if (remain === chips[1]) {
                onFilterChange(column, { ...filter, range: [null, filter.range[1]] })
            } else {
                onFilterChange(column, { ...filter, range: [filter.range[0], null] })
            }
        }
    }
    return <FocusScope
            onFocus={() => setVisible(true) }
            onBlur={() => setVisible(false) }
        >
        <Chips
            value={chips}
            onChange={onDeleteChip}
            wrapperRef={ref}
            text={search || ""}
            onChangeText={text => onSearchChange(column, text)}
            placeholder=""
        />
        <Popper
            open={visible}
            anchorEl={ref.current}
        >
            <Paper elevation={8} style={{width: 300, padding: 20, textAlign: "center"}}>
                <ButtonGroup color="primary">
                    <Button variant={inner ? "contained" : "outlined"}
                        onClick={() => filter?.rangeType !== 'in' && onFilterChange(column, { ...filter, rangeType: undefined })}
                    >
                        范围之内
                    </Button>
                    <Button variant={!inner ? "contained" : "outlined"}
                        onClick={() => filter?.rangeType !== 'out' && onFilterChange(column, { ...filter, rangeType: 'out' })}
                    >
                        范围之外
                    </Button>
                </ButtonGroup>
                <Slider
                    track={inner ? "normal" : "inverted"}
                    style={{marginTop: 20}}
                    min={min}
                    max={max}
                    value={range}
                    onChange={onChange}
                    valueLabelDisplay="auto"
                />
            </Paper>
        </Popper>
    </FocusScope>
}

/** 
 * @template T
 * @param {(props:T)=>JSX.Element} Component
 * @returns {(props:Omit<T, "height">)=>JSX.Element}
 */
function withFullHeight(Component) {
    // @ts-ignore
    return function FullHeight({ ...props }) {
        /** @type {React.MutableRefObject<HTMLDivElement>} */
        const container = useRef();
        const [h, setH] = useState(0);
        const [w, setW] = useState(0);
        useEffect(() => {
            function update() {
                const header = container.current.querySelector('.p-datatable-scrollable-header')
                const footer = container.current.querySelector('.p-datatable-scrollable-footer')
                const extraHeight = (header ? header.clientHeight : 0) + (footer ? footer.clientHeight : 0);
                setH(container.current.clientHeight - extraHeight);
                setW(container.current.clientWidth);
            }
            update();
            const t = setInterval(update, 500);
            return () => clearInterval(t);
        }, []);
        return <div ref={container} style={{flex:1, position:'relative'}}>
            {h > 0 ? 
                // @ts-ignore
                <Component {...props} height={h} width={w} />
            :null }
        </div>
    }
}

const MemoedVirtualScrollTable = React.memo(VirtualScrollTable);

const MemoedFixedTable = React.memo(FixedTable);

/**
 * @template T
 * @typedef {(props: Omit<TableProps<T>, "height">) => JSX.Element} FullHeightVirtualScrollTableComponent
 */
const FullHeightVirtualScrollTable = React.memo(withFullHeight(VirtualScrollTable));

export { 
    MemoedVirtualScrollTable as VirtualScrollTable, 
    MemoedFixedTable as FixedTable,
    FullHeightVirtualScrollTable
};