import bigDecimal from "js-big-decimal";
import { bn } from "../lib/bn";
import { get } from "./common";

/**
 * @typedef {{
 *   code: string,
 *   total_Shares: bigDecimal,
 *   total_USD: bigDecimal,
 *   weighted_Rate: bigDecimal,
 * }} QuantitatedShortable
 */

/**
 * @param {String} code
 * @returns { Promise<QuantitatedShortable> }
 */
export async function getShortable(code) {
    /** @type {import("./common").ApiSerialized<QuantitatedShortable>} */
    const r = await get(`/api/shortable/${code}`);
    return {
        code: r.code,
        total_Shares: bn(r.total_Shares),
        total_USD: bn(r.total_USD),
        weighted_Rate: bn(r.weighted_Rate)
    };
}