import { useSnackbar } from "notistack";
import { useEffect } from "react";

/**
 * @param {{ message: string }} err 
 */
export default function useErrorMessage(err) {
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (err) {
            console.error(err);
            enqueueSnackbar(err.message, { variant: 'error' });
        }
    }, [err, enqueueSnackbar]);
}