import bigDecimal from "js-big-decimal";

const zero = new bigDecimal(0);
const oneHundred = new bigDecimal(100);

/** @typedef { bigDecimal | string | number } DecimalConvertable */

/**
 * @param { DecimalConvertable } a 
 */
export function isValid(a) {
    if (a instanceof bigDecimal) {
        return true;
    } else if (typeof a === 'number') {
        return Number.isSafeInteger(a);
    } else if (typeof a === 'string') {
        try {
            new bigDecimal(a);
            return true;
        } catch (e) {
            return false;
        }
    } else {
        return false;
    }
}

/**
 * @param { DecimalConvertable } a 
 */
export function bn(a) {
    if (a instanceof bigDecimal) {
        return a;
    } else if (typeof a === 'number') {
        if (Number.isSafeInteger(a)) {
            return new bigDecimal(a);
        } else {
            return null;
        }
    } else if (typeof a === 'string') {
        if (a === "") return null;//empty string is invalid number, however bigDecimal will convert it to 0
        try {
            return new bigDecimal(a.replace(/,/g, ""));
        } catch (e) {
            return null;
        }
    } else {
        return null;
    }
}

export const val = bn;

/**
 * @param {DecimalConvertable} bn 
 */
export function bns(bn, n = 2) {
    const nn = val(bn);
    if (!nn) return "";
    return new bigDecimal(Number(nn.getValue()).toFixed(n)).getPrettyValue(3, ',');
}

/**
 * @param {DecimalConvertable} bn 
 */
 export function bnsMax(bn, max = 2) {
    const nn = val(bn);
    if (!nn) return "";
    const s = new bigDecimal(Number(nn.getValue()).toFixed(max)).getPrettyValue(3, ',');
    return s.replace(/\.?0+$/, '');
}

/**
 * @param {DecimalConvertable} bn 
 */
export function bnsPercent(bn, n = 2) {
    const nn = val(bn);
    if (!nn) return "";
    return new bigDecimal(Number(nn.multiply(oneHundred).getValue()).toFixed(n)).getPrettyValue(3, ',') + "%";
}

/**
 * @param {...DecimalConvertable } args 
 */
 export function add(...args) {
    let r = val(0);
    for (const n of args) {
        const bn = val(n);
        if (bn == null) return null;
        r = r.add(bn);
    }
    return r;
}

/**
 * @param {...DecimalConvertable } args 
 */
export function mul(...args) {
    let r = val(1);
    for (const n of args) {
        const bn = val(n);
        if (bn == null) return null;
        r = r.multiply(bn);
    }
    return r;
}

/**
 * invalid-safe a / b
 * @param {DecimalConvertable} a 
 * @param {DecimalConvertable} b 
 */
export function div(a, b) {
    const an = val(a);
    const bn = val(b);
    if (an === null || bn === null) {
        return null;
    }
    if (bn.compareTo(zero) == 0) {
        return null;
    }
    return an.divide(bn);
}

/**
 * invalid-safe a - b
 * @param {DecimalConvertable} a 
 * @param {DecimalConvertable} b 
 */
export function sub(a, b) {
    const an = val(a);
    const bn = val(b);
    if (an === null || bn === null) {
        return null;
    }
    return an.subtract(bn);
}

/**
 * @param {...DecimalConvertable } args 
 */
 export function min(...args) {
    let r = null;
    for (const n of args) {
        const bn = val(n);
        if (bn === null) continue;
        if (r === null || bn.compareTo(r) < 0) {
            r = bn;
        }
    }
    return r;
}

/**
 * invalid-safe a <= b
 * @param {DecimalConvertable} a 
 * @param {DecimalConvertable} b 
 */
export function lte(a, b) {
    const an = val(a);
    const bn = val(b);
    if (an === null || bn === null) {
        return null;
    }
    return an.compareTo(bn) <= 0;
}

export function eq(a, b) {
    const an = val(a);
    const bn = val(b);
    if (an === null && bn === null) {
        return true;
    } else if (an === null || bn === null) {
        return false;
    } else {
        return an.compareTo(bn) === 0;
    }
}

/**
 * invalid-safe a < b
 * @param {DecimalConvertable} a 
 * @param {DecimalConvertable} b 
 */
export function lt(a, b) {
    const an = val(a);
    const bn = val(b);
    if (an === null || bn === null) {
        return null;
    }
    return an.compareTo(bn) < 0;
}

/**
 * invalid-safe a >= b
 * @param {DecimalConvertable} a 
 * @param {DecimalConvertable} b 
 */
 export function gte(a, b) {
    const an = val(a);
    const bn = val(b);
    if (an === null || bn === null) {
        return null;
    }
    return an.compareTo(bn) >= 0;
}

/**
 * invalid-safe a > b
 * @param {DecimalConvertable} a 
 * @param {DecimalConvertable} b 
 */
 export function gt(a, b) {
    const an = val(a);
    const bn = val(b);
    if (an === null || bn === null) {
        return null;
    }
    return an.compareTo(bn) > 0;
}