import React, { useCallback, useState } from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";


/**
 * @typedef {{
 *   open: boolean,
 *   content: string,
 *   okText?: string,
 *   okType?: 'primary' | 'success' | 'warning' | 'danger',
 *   cancelText?: string,
 * }} AlertState
 */

/**
 * @param {{
 *   state: AlertState
 *   onOk: () => void,
 *   onCancel: () => void,
 * }} param0 
 */
export default function Alert({ state, onOk, onCancel }) {
    return <Dialog
        open={Boolean(state?.open)}
        onClose={onCancel}
    >
        <DialogTitle>{state?.content}</DialogTitle>
        <DialogActions>
            <Button onClick={onCancel}>
                { state?.cancelText || '取消' }
            </Button>
            <Button onClick={onOk} autoFocus>
                { state?.okText || '确定' }
            </Button>
        </DialogActions>
    </Dialog>
}

/** @type { AlertState } */
const nullState = null;

/**
 * @returns {[
 *     state: AlertState,
 *     open: (state: AlertState | string) => void,
 *     close: () => void
 * ]}
 */
export function useAlertState() {
    const [state, setState] = useState(nullState);
    const open = useCallback((state) => {
        if (typeof state === 'string') {
            setState({ open: true, content: state });
        } else {
            setState(state);
        }
    }, []);
    const close = useCallback(() => {
        setState(s => ({ ...s, open: false }));
    }, []);
    return [ state, open, close ];
}
