import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useState } from 'react';
import useAuth from './api/auth';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAllGlobals } from './lib/useGlobal';
import RequirePermission from './components/RequirePermission';

export default function MenuAppBar() {
    
    const [auth, authActions] = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();

    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const gotoChangePassword = () => {
        history.push('/user/change-password');
        handleClose();
    }

    const gotoUsers = () => {
        history.push('/user');
        handleClose();
    }

    const [,,setGlobal] = useAllGlobals();

    const logout = () => {
        console.log(setGlobal);
        setGlobal({});
        authActions.logout();
    }

    return (
        <div style={{marginLeft: 8}}>
            <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
                <span style={{marginLeft: 8}}>{auth.displayName}</span>
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                {/* <RequirePermission required="editUsers">
                    <MenuItem onClick={gotoUsers}>管理用户</MenuItem>
                </RequirePermission> */}
                <MenuItem onClick={gotoChangePassword}>修改密码</MenuItem>
                <MenuItem onClick={logout}>登出</MenuItem>
            </Menu>
        </div>
    );
}