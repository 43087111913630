import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCoCallback } from './useCo';

/**
 * @param {{
 *   onFocus: () => void,
 *   onBlur: () => void,
 *   component?: React.ComponentType<{onFocus: Function, onBlur: Function, children: React.ReactNode}> | string,
 *   children: React.ReactNode
 * }} param0 
 */
export default function FocusScope({
    onFocus,
    onBlur,
    component='div',
    children
}) {
    const Component = component;
    const ref = useRef(null);
    const onFocusDebounced = useCallback(() => {
        if (!ref.current) {
            onFocus();
        } else {
            clearTimeout(ref.current);
            ref.current = null;
        }
    }, [onFocus]);
    const onBlurDebounced = useCallback(() => {
        if (ref.current) {
            clearTimeout(ref.current);
        }
        ref.current = setTimeout(() => {
            onBlur();
            ref.current = null;
        }, 100);
    }, [onBlur]);
    return <Component onFocus={onFocusDebounced} onBlur={onBlurDebounced}>{children}</Component>
}