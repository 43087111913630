import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useCoEffect } from './lib/useCo';
import useAuth from './api/auth';

export default function Bootstrap() {
    const [auth, authActions] = useAuth();
    useCoEffect(function *() {
        try {
            if (auth.authenticated && !auth.validated) {
                yield authActions.validate();
            }
        } catch (e) {
            authActions.logout();
        }
    }, []);
    return <Backdrop open={true}>
        <CircularProgress color="primary" />
    </Backdrop>
}