import React, { useCallback, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useApiState } from './api/common';
import { getStockSearchList } from './api/stock';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import { Typography, Popper } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { useHistory } from 'react-router-dom';
import { SearchRounded } from '@material-ui/icons';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 36;

    const getChildSize = (child) => {
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width={props.width}
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const useStyles = makeStyles({
    listbox: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
    plain: {
        fontSize: 12,
        borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
        height: 27,
        lineHeight: '27px',
    },
    input: {
        paddingRight: 8,
    },
    inputOutlineFilled: {
        border: 'none',
        background: 'rgba(255, 255, 255, 0.23)',
    }
});

const filter = createFilterOptions({
    stringify: item => item.keyword,
    ignoreCase: true,
});

const StyledPopper = React.forwardRef(function StyledPopper(props, ref) {
    return (
        <Popper ref={ref} {...props} style={null} placement="bottom" />
    );
});


/**
 * @param {{
 *   size: 'small' | 'medium',
 *   variant: 'outlined' | 'filled',
 *   autoFocus?: boolean,
 *   width?: number,
 * }} param0 
 */
export default function StockSearcher({ size, variant, autoFocus = false, width = 300 }) {
    const classes = useStyles();
    const [data = [], loading, error] = useApiState(getStockSearchList);
//     const list = useMemo(() => data?.map(item => [item[0], `${item[0]} ${item[1]}`, `${item[0]} ${item[1]} ${item[2]}`]), [data]);
    const options = useMemo(
        () => data.map(item => ({ id: item[0], name: `${item[0]} ${item[1]}`, keyword: `${item[0]} ${item[1]} ${item[2]}` })), 
        [data]
    );
    const history = useHistory();
    const handleChange = useCallback((event, newValue, reason) => {
        if (!newValue || reason !== 'select-option') {
            return;
        } 
        history.push(`/stock/${newValue.id}`);
    }, [history]);
    const handleFilter = useCallback((options, params) => {
        const filtered = filter(options, params);
        if (filtered.length === 0) {
            filtered.push({
                name: '找不到匹配的项',
                disabled: true,
            });
        }
        return filtered;
    }, []);
    
    return <>
        <Autocomplete
            // debug
            disableClearable
            disableListWrap
            classes={{ listbox: classes.listbox }}
            ListboxComponent={ListboxComponent}
            ListboxProps={{ width }}
            PopperComponent={StyledPopper}
            value={null}
            onChange={handleChange}
            filterOptions={handleFilter}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            getOptionLabel={option => option.name}
            getOptionDisabled={(option) => option.disabled}
            renderInput={(params) => (
                <TextField 
                    autoFocus={autoFocus}
                    {...params} 
                    InputProps={{
                        ...params.InputProps,
                        classes: variant === 'filled' ? {
                            notchedOutline:  classes.inputOutlineFilled
                        } : undefined,
                        className: classes.input,
                        style: { width },
                        endAdornment: <SearchRounded /> 
                    }} 
                    variant="outlined"  
                    placeholder="搜索股票代码或名称"
                    size={size}
                />
            )}
            renderOption={(option) => option.id === -1 ? <Typography noWrap><AddRoundedIcon style={{verticalAlign:'middle'}} />{option.name}</Typography> : <Typography noWrap>{option.name}</Typography>}
        />
    </>;
}


// import React, { useCallback, useMemo, useState } from 'react';
// import { useApiState } from './api/common';
// import { getStockSearchList } from './api/stock';
// import { AutoComplete } from 'primereact/autocomplete';
// import { useHistory } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core';
// import SearchIcon from '@material-ui/icons/Search';

// const useStyle = makeStyles(theme => ({
//     container: {
//         position: 'relative',
//         height: 36,
//         '&.large': {
//             height: 48
//         }
//     },
//     icon: {
//         position: 'absolute',
//         left: 8,
//         top: 6,
//         '.large &': {
//             top: 12,
//             left: 12,
//         }
//     },
//     searcher: {
//         width: '100%',
//         display: 'block',
    
//         '& input': {
//             width: '100%',
//             height: 36,
//             border: 'none',
//             background: 'rgba(255,255,255,0.15)',
//             paddingLeft: 40,

//             '&:focus': {
//                 boxShadow: 'none !important',
//                 background: 'rgba(255,255,255,0.25)',
//             },
//             '&:hover': {
//                 background: 'rgba(255,255,255,0.25)',
//             }
//         },

//         '.large & input': {
//             height: 48,
//             paddingLeft: 48,
//         }
//     }
// }));

// /**
//  * 
//  * @param {{
//  *   size: 'large' | 'small'
//  * }} param0 
//  */
// export default function StockSearcher({ size = 'small', ...props }){
//     const classes = useStyle();
//     const [data, loading, error] = useApiState(getStockSearchList);
//     const list = useMemo(() => data?.map(item => [item[0], `${item[0]} ${item[1]}`, `${item[0]} ${item[1]} ${item[2]}`]), [data]);
//     const [value, setValue] = useState();
//     const [suggestions, setSuggestions] = useState([]);
//     const history = useHistory();
//     const searchItem = useCallback((event) => {
//         let suggestions;
//         if (!event.query?.trim()?.length) {
//             suggestions = list;
//         } else {
//             const q = event.query.trim();
//             let count = 0;
//             suggestions = list
//                 .filter(l => {
//                     if (count < 10 && l[2].toLowerCase().indexOf(q) >= 0) {
//                         count ++;
//                         return true;
//                     }
//                     return false;
//                 });
//         }
//         suggestions = suggestions.map(l => ({ id: l[0], name: l[1]}))
//         setSuggestions(suggestions);
//     }, [list]);
//     return <div className={`${classes.container} ${size}`}>
//         <SearchIcon className={classes.icon}/>
//         <AutoComplete
//             {...props}
//             placeholder="搜索股票代码或名称"
//             className={classes.searcher}
//             value={value} 
//             field="name"
//             suggestions={suggestions} 
//             completeMethod={searchItem} 
//             onChange={(e) => { 
//                 if (e.value?.id) {
//                     history.push(`/stock/${e.value.id}`);
//                 } else {
//                     setValue(e.value);
//                 }
//             }} 
//         />
//     </div>
// }
