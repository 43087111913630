import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useAuth from '../api/auth';
import { getCallTable } from '../api/call';
import { cancelNetworkIdleCallback, requestNetworkIdleCallback } from '../api/common';
import { getUsers } from '../api/user';
import { useCoCallback } from './useCo';

/**
 * @typedef {{
 *   users: import('../api/user').User[],
 *   callTable: import('../api/stock').StockInfo[],
 *   shortTable: import('../api/stock').StockInfo[],
 *   requestUsersReload: () => void,
 *   requestCallTableReload: () => void
 *   requestShortTableReload: () => void
 * }} PreloadContext
 */

/** @type { PreloadContext } */
const nullPreloadContext = null;

/** @type {import('../api/stock').StockInfo[]} */
const emptyStockInfoList = [];

/** @type {import('../api/user').User[] } */
const emptyUsers = [];

const PreloadContext = createContext(nullPreloadContext);

export function PreloadProvider({ children }) {
    const [user] = useAuth();
    const [users, setUsers] = useState(emptyUsers);
    const [callTable, setCallTable] = useState(emptyStockInfoList);
    const [shortTable, setShortTable] = useState(emptyStockInfoList);
    const reloadUsers = useCoCallback(function*() {
        try {
            const users = yield getUsers();
            setUsers(users);
        } catch (e) {
            console.error(e);
        }
    }, []);
    const reloadCallTable = useCoCallback(function*() {
        try {
            const callTable = yield getCallTable([
                'code', 'name', 'call_Watch_Date', 'status_Or_Default', 
                'price_Close', 'ctd_Pct', 'total_Score_User', 
                'quality_Score', 'trend_Score', 'pricing_Score_User', 
                'ft_Score', 'pt_Score', 'external_Power', 'stock_Power', 'coverer',
                'trend_Stage', 'trend_Strength', 'trend_Sustainability', 
                'trend_Stability', 'alert_Before_Next_FS_Or_Default', 
                'last_RSI', 'risk_Line_Or_Default_Value', 'reward_Line_Or_Default_Value', 
                'reward_Risk_Ratio', 'latest_Comment_ID',
            ]);
            setCallTable(callTable);
        } catch (e) {
            console.error(e);
        }
    }, []);
    const requestUsersReload = useCallback(() => {
        cancelNetworkIdleCallback(reloadUsers);
        requestNetworkIdleCallback(reloadUsers, true);
    }, []);
    const requestCallTableReload = useCallback(() => {
        cancelNetworkIdleCallback(reloadCallTable);
        requestNetworkIdleCallback(reloadCallTable, true);
    }, []);
    const requestShortTableReload = useCallback(() => {

    }, []);
    useEffect(() => {
        if (user.authenticated) {
            requestNetworkIdleCallback(reloadUsers, false);
            requestNetworkIdleCallback(reloadCallTable, false);
            return () => {
                cancelNetworkIdleCallback(reloadUsers);
                cancelNetworkIdleCallback(reloadCallTable);
            };
        } else {
            setCallTable([]);
            setShortTable([]);
            setUsers([]);
        }
    }, [user.authenticated]);
    const data = useMemo(() => {
        return {
            users,
            callTable,
            shortTable,
            requestUsersReload,
            requestCallTableReload,
            requestShortTableReload
        }
    }, [
        users, callTable, shortTable, 
        requestUsersReload, requestCallTableReload, requestShortTableReload
    ]);
    return <PreloadContext.Provider value={data}>
        { children }
    </PreloadContext.Provider>
}

export function usePreload() {
    return useContext(PreloadContext);
}
