import bigDecimal from "js-big-decimal";
import { bn } from "../lib/bn";
import { get, postJson } from "./common";

/** 
 * @typedef {{
 *    code_BBG: string,
 *    code: string,
 *    name: string,
 *    date: number,
 *    price_Open: bigDecimal,
 *    price_Close: bigDecimal,
 *    price_Lo: bigDecimal, 
 *    price_Hi: bigDecimal,
 *    last_RSI: bigDecimal,
 *    macd_DIFF: bigDecimal,
 *    macd_DEA: bigDecimal,
 *    macd_MACD: bigDecimal,
 *    price_Chg: bigDecimal,
 *    price_Chg_Pct: bigDecimal,
 *    pe_T0: bigDecimal,
 *    pb_TTM: bigDecimal,
 *    marketValue: bigDecimal,
 *    marketValue_USD: bigDecimal,
 *    price_52w_Hi: bigDecimal,
 *    price_52w_Lo: bigDecimal,
 *    ev: bigDecimal,
 *    book_Value: bigDecimal,
 *    price_Vol_10D: bigDecimal,
 *    price_Vol_6M: bigDecimal,
 *    price_Vol_1Y: bigDecimal,
 *    adv_1M: bigDecimal,
 *    adv_10D: bigDecimal,
 *    adv_3M: bigDecimal,
 *    num_Shares_T0_10K: bigDecimal,
 *    eps_Consensus_T0: bigDecimal,
 *    eps_Consensus_T1: bigDecimal,
 *    eps_YOY_Consensus_T0: bigDecimal,
 *    eps_YOY_Consensus_T1: bigDecimal,
 *    eps_PE_Consensus_T0: bigDecimal,
 *    eps_PE_Consensus_T1: bigDecimal,
 *    bps_Consensus_T0: bigDecimal,
 *    bps_Consensus_T1: bigDecimal,
 *    bps_YOY_Consensus_T0: bigDecimal,
 *    bps_YOY_Consensus_T1: bigDecimal,
 *    bps_PB_Consensus_T0: bigDecimal,
 *    bps_PB_Consensus_T1: bigDecimal,
 *    ebitda_Consensus_T0: bigDecimal,
 *    ebitda_Consensus_T1: bigDecimal,
 *    ebitda_YOY_Consensus_T0: bigDecimal,
 *    ebitda_YOY_Consensus_T1: bigDecimal,
 *    ebitda_EVX_Consensus_T0: bigDecimal,
 *    ebitda_EVX_Consensus_T1: bigDecimal,
 *    rev_Consensus_T0: bigDecimal,
 *    rev_Consensus_T1: bigDecimal,
 *    rev_YOY_Consensus_T0: bigDecimal,
 *    rev_YOY_Consensus_T1: bigDecimal,
 *    rev_PS_Consensus_T0: bigDecimal,
 *    rev_PS_Consensus_T1: bigDecimal,
 * }} StockInfoDailyResponse
 */

/**
 * @param {string[]} exchanges 
 * @param {number} start 
 * @param {number} end 
 * @param {boolean} includePartial
 * @returns {Promise<StockInfoDailyResponse[]>}
 */
export async function getDailyDataNotExisted(
    exchanges,
    start,
    end,
    includePartial
) {
    const r = /** @type {any[]} */(await get("/api/stock-daily-upload/daily-not-existed", {
        exchange: exchanges.join(","),
        start,
        end,
        includePartial: includePartial ? 1 : 0
    }));
    return r.map(s => ({
        code_BBG: s.code_BBG,
        code: s.code,
        name: s.name,
        date: s.date,
        price_Open: bn(s.price_Open),
        price_Close: bn(s.price_Close),
        price_Lo: bn(s.price_Lo), 
        price_Hi: bn(s.price_Hi),
        last_RSI: bn(s.last_RSI),
        macd_DIFF: bn(s.macd_DIFF),
        macd_DEA: bn(s.macd_DEA),
        macd_MACD: bn(s.macd_MACD),
        price_Chg: bn(s.price_Chg),
        price_Chg_Pct: bn(s.price_Chg_Pct),
        pe_T0: bn(s.pe_T0),
        pb_TTM: bn(s.pb_TTM),
        marketValue: bn(s.marketValue),
        marketValue_USD: bn(s.marketValue_USD),
        price_52w_Hi: bn(s.price_52w_Hi),
        price_52w_Lo: bn(s.price_52w_Lo),
        ev: bn(s.ev),
        book_Value: bn(s.book_Value),
        price_Vol_10D: bn(s.price_Vol_10D),
        price_Vol_6M: bn(s.price_Vol_6M),
        price_Vol_1Y: bn(s.price_Vol_1Y),
        adv_1M: bn(s.adv_1M),
        adv_10D: bn(s.adv_10D),
        adv_3M: bn(s.adv_3M),
        num_Shares_T0_10K: bn(s.num_Shares_T0_10K),
        eps_Consensus_T0: bn(s.eps_Consensus_T0),
        eps_Consensus_T1: bn(s.eps_Consensus_T1),
        eps_YOY_Consensus_T0: bn(s.eps_YOY_Consensus_T0),
        eps_YOY_Consensus_T1: bn(s.eps_YOY_Consensus_T1),
        eps_PE_Consensus_T0: bn(s.eps_PE_Consensus_T0),
        eps_PE_Consensus_T1: bn(s.eps_PE_Consensus_T1),
        bps_Consensus_T0: bn(s.bps_Consensus_T0),
        bps_Consensus_T1: bn(s.bps_Consensus_T1),
        bps_YOY_Consensus_T0: bn(s.bps_YOY_Consensus_T0),
        bps_YOY_Consensus_T1: bn(s.bps_YOY_Consensus_T1),
        bps_PB_Consensus_T0: bn(s.bps_PB_Consensus_T0),
        bps_PB_Consensus_T1: bn(s.bps_PB_Consensus_T1),
        ebitda_Consensus_T0: bn(s.ebitda_Consensus_T0),
        ebitda_Consensus_T1: bn(s.ebitda_Consensus_T1),
        ebitda_YOY_Consensus_T0: bn(s.ebitda_YOY_Consensus_T0),
        ebitda_YOY_Consensus_T1: bn(s.ebitda_YOY_Consensus_T1),
        ebitda_EVX_Consensus_T0: bn(s.ebitda_EVX_Consensus_T0),
        ebitda_EVX_Consensus_T1: bn(s.ebitda_EVX_Consensus_T1),
        rev_Consensus_T0: bn(s.rev_Consensus_T0),
        rev_Consensus_T1: bn(s.rev_Consensus_T1),
        rev_YOY_Consensus_T0: bn(s.rev_YOY_Consensus_T0),
        rev_YOY_Consensus_T1: bn(s.rev_YOY_Consensus_T1),
        rev_PS_Consensus_T0: bn(s.rev_PS_Consensus_T0),
        rev_PS_Consensus_T1: bn(s.rev_PS_Consensus_T1),
    }));
}

/**
 * @param {import("./common").ApiSerialized<Omit<StockInfoDailyResponse,'name'|'macd_MACD'|'price_Chg_Pct'|'pe_T0'|'pb_TTM'|'marketValue'>>} data 
 * @returns {Promise<void>}
 */
export async function uploadDailyData(
    data
) {
    return await postJson("/api/stock-daily-upload/upload", data);
}
