import { createTheme, makeStyles, MuiThemeProvider } from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import React from 'react';
import StockSearcher from './StockSearcher';
import homePic from './images/home.jpg';
import './font/HanWangLiSuMedium.css';

const useStyles = makeStyles(theme => ({
    home: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f2f2f2'
    },
    title: {
        fontSize: 96,
        marginBottom: 36,
        textAlign: 'center',
        letterSpacing: 64,
        paddingLeft: 64,
        color: '#333',
    },
    search: {
        width: 1100,
        margin: '0 auto',
        marginBottom: 32,
    },
    tips: {
        fontSize: 32,
        marginBottom: 32,
        color: '#333',
        textAlign: 'center',
        lineHeight: 1.5,
        
    },
    img: {
        width: 550,
        height: 550,
    }
}));

const theme = createTheme({
    palette: {
        type: 'light',
        primary: blue,
        secondary: grey
    },
    overrides: {
        MuiTypography: {
            h1: {
                fontSize: 32,
            },
            h2: {
                fontSize: 28,
            },
            h3: {
                fontSize: 24,
            }
        }
    }
});

export default function Home(){
    const classes = useStyles();
    return <MuiThemeProvider theme={theme}>
        <div className={classes.home}>
            <div className="HanWangLiSu">
                <div className={classes.title}>循道精進</div>
                <div className={classes.tips}>
                    大勢所趨<span style={{marginLeft:16}}>人心所向</span><br/>
                    因緣際會<span style={{marginLeft:16}}>水到渠成</span>
                </div>
                <div className={classes.search}>
                    <StockSearcher size="medium" variant="outlined" autoFocus width={1100}/>
                </div>
                <div className={classes.tips}>
                    <img className={classes.img} src={homePic} />
                </div>
            </div>
        </div>
    </MuiThemeProvider>
}