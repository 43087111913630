import { CircularProgress, Fab } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { getStockUpdatableList } from '../api/stock';
import { FullHeightVirtualScrollTable } from '../Table';
import { BoolLabels, ClusterLayerLabels, FieldNames } from '../lib/enums';
import { useApiState, withReload } from '../api/common';
import useErrorMessage from '../lib/useErrorMessage';
import { getUsers } from '../api/user';
import { getEnums } from '../api/enum';
import bigDecimal from 'js-big-decimal';
import { useCoCallback } from '../lib/useCo';
import createExcel from '../Table/excel';
import { useSnackbar } from 'notistack';
import { AddRounded, GetAppRounded } from '@material-ui/icons';
import ImportStockBasicInfoDialog from './ImportStockBasicInfoDialog';
import { Link } from 'react-router-dom';
import { dayNumToDate } from '../lib/date';
import DetailTextPopup from '../components/DetailTextPopup';


/** 
 * @typedef {{
 *   code_Factset: string,
 *   code_BBG: string,
 *   code: string,
 *   quarterly_Report_Type: string,
 *   ipo_Date: string,
 *   exchange: string,
 *   name: string,
 *   industrial_Chain: string,
 *   cluster_Layer: string,
 *   section: string,
 *   product: string,
 *   sw1: string,
 *   sw2: string,
 *   sw3: string,
 *   coverer: string,
 *   shortable: string,
 *   introduce: string,
 * }} StockListItemRow 
 */

/**
 * @param {bigDecimal} n 
 */
function ns(n) {
    return n ? n.getValue() : "";
}

const QuarterlyReportTypeLabels = ['-', '季报', '半年报', '季报'];

/**
 * @param {import('../api/stock').StockUpdatableInfo} r 
 * @param {{[userId: number]: string}} users
 * @param {import('../api/enum').Enums} enums
 * @returns { StockListItemRow }
 */
 function renderRow(r, users, enums) {
    return {
        code_Factset: r.code_Factset,
        code_BBG: r.code_BBG,
        code: r.code,
        quarterly_Report_Type: QuarterlyReportTypeLabels[r.quarterly_Report_Type],
        ipo_Date: dayNumToDate(r.ipo_Date)?.format('YYYY-MM-DD') || '',
        exchange: r.exchange,
        name: r.name,
        industrial_Chain: r.industrial_Chain_ID === 0 ? '-' : enums.industrialChainList.find(i => i.industrial_Chain_ID === r.industrial_Chain_ID)?.industrial_Chain_Name || '',
        section: r.section_ID === 0 ? '-' : enums.sectionList.find(i => i.section_ID === r.section_ID)?.section_Name || '',
        product: r.product_ID === 0 ? '-' : enums.productList.find(i => i.product_ID === r.product_ID)?.product_Name || '',
        sw1: r.sw1_ID === 0 ? '-' : enums.sw1List.find(i => i.sw1_ID === r.sw1_ID)?.sw1_Name || '',
        sw2: r.sw2_ID === 0 ? '-' : enums.sw2List.find(i => i.sw2_ID === r.sw2_ID)?.sw2_Name || '',
        sw3: r.sw3_ID === 0 ? '-' : enums.sw3List.find(i => i.sw3_ID === r.sw3_ID)?.sw3_Name || '',
        cluster_Layer: ClusterLayerLabels[r.cluster_Layer],
        coverer: r.coverer?.map(c => users[c]).filter(Boolean).join("/") || '-',
        shortable: BoolLabels[r.shortable],
        introduce: r.introduce,
    };
}

/** @type {import('../Table').ColumnOption<StockListItemRow>[]} */
const columns = [
    // === 基本信息 === //
    {
        field: 'code_Factset',
        label: FieldNames.code_Factset,
        width: 150,
        model: 'string',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code_Factset}</Link>
    },
    {
        field: 'code',
        label: FieldNames.code,
        width: 150,
        model: 'string',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code}</Link>
    },
    {
        field: 'code_BBG',
        label: FieldNames.code_BBG,
        width: 150,
        model: 'string',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code_BBG}</Link>
    },
    {
        field: 'quarterly_Report_Type',
        label: FieldNames.quarterly_Report_Type,
        width: 100,
        model: 'enum',
    },
    {
        field: 'ipo_Date',
        label: FieldNames.ipo_Date,
        width: 100,
        model: 'string'
    },
    {
        field: 'exchange',
        label: FieldNames.exchange,
        width: 100,
        model: 'enum',
    },
    {
        field: 'name',
        label: FieldNames.name,
        width: 150,
        model: 'string'
    },
    {
        field: 'section',
        label: FieldNames.section,
        width: 100,
        model: 'enum',
    },
    {
        field: 'industrial_Chain',
        label: FieldNames.industrial_Chain,
        width: 100,
        model: 'enum',
    },
    {
        field: 'product',
        label: FieldNames.product,
        width: 100,
        model: 'string',
    },
    {
        field: 'sw1',
        label: FieldNames.sw1,
        width: 100,
        model: 'string',
    },
    {
        field: 'sw2',
        label: FieldNames.sw2,
        width: 100,
        model: 'string',
    },
    {
        field: 'sw3',
        label: FieldNames.sw3,
        width: 100,
        model: 'string',
    },
    {
        field: 'cluster_Layer',
        label: FieldNames.cluster_Layer,
        width: 100,
        model: 'enum'
    },
    {
        field: 'coverer',
        label: FieldNames.coverer,
        width: 100,
        model: 'enum'
    },
    {
        field: 'shortable',
        label: FieldNames.shortable,
        width: 100,
        model: 'enum'
    },
    {
        field: 'introduce',
        label: FieldNames.introduce,
        width: 150,
        model: 'string',
        render: row => <DetailTextPopup>{row.introduce}</DetailTextPopup>
    }
];

/** 
 * @type {import('../Table').SorterAndFilters<StockListItemRow>} */
const initSorterAndFilters = null;

/** @type {import('../Table').Fields<StockListItemRow>[]} */
const visibleColumns = columns.map(col => col.field);

/** @type {import('../Table').FullHeightVirtualScrollTableComponent<StockListItemRow>} */
// @ts-ignore
const Table = FullHeightVirtualScrollTable;

const getUsersReloadable = withReload(getUsers);
const getEnumsReloadable = withReload(getEnums);

export default function StockBasicInfoTable({  }) {
    const [reload, setReload] = useState(0);
    const [users, usersLoading, usersError] = useApiState(getUsersReloadable, false, reload);
    const [enums, enumsLoading, enumsError] = useApiState(getEnumsReloadable, reload);
    const [stocks=[], stocksLoading, stocksError] = useApiState(getStockUpdatableListReloadable, reload);
    const data = useMemo(() => {
        if (!stocks || !users || !enums) return [];
        /** @type {{[userId: number]: string}} */
        const userMap = {};
        users.forEach(u => userMap[u.user_ID] = u.display_Name);
        return stocks.map(s => renderRow(s, userMap, enums));
    }, [stocks, users, enums]);
    const codes = useMemo(() => stocks.map(s => s.code), [stocks]);
    const [sorterAndFilters, setSorterAndFilters] = useState(initSorterAndFilters);
    useErrorMessage(usersError || enumsError || stocksError);
    const [exportInProgress, setExportInProgress] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [showImport, setShowImport] = useState(false);
    const exportExcel = useCoCallback(function*(){
        try {
            setExportInProgress(true);
            const filename = `基本信息表`;
            yield createExcel(data, columns, visibleColumns, sorterAndFilters, {
                code_Factset: 'string',
                code: 'string',
                quarterly_Report_Type: 'string',
                ipo_Date: 'date',
                exchange: 'string',
                name: 'string',
                industrial_Chain: 'string',
                section: 'string',
                product: 'string',
                sw1: 'string',
                sw2: 'string',
                sw3: 'string',
                cluster_Layer: 'string',
                coverer: 'string',
                shortable: 'string',
                introduce: 'string'
            }, filename, "基本信息表");
        } catch (e) {
            console.warn(e);
            enqueueSnackbar('生成Excel文件出错');
        } finally {
            setExportInProgress(false);
        }
    }, [data, columns, visibleColumns, sorterAndFilters]);
    
    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, position: 'relative' }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, display: 'flex' }}>
                <Table
                    data={data}
                    columns={columns}
                    sorterAndFilters={sorterAndFilters}
                    onSorterAndFiltersChange={setSorterAndFilters}
                    variant="full"
                    visibleColumnOrder={visibleColumns}
                />
            </div>
        </div>
        <div style={{height:60}}>
            <Fab 
                size="small" 
                color="primary" 
                style={{ position: 'fixed', bottom: 10, left: 20 }} 
                onClick={() => setShowImport(true)}
            >
                <AddRounded />
            </Fab>
            <Fab
                size="small" 
                color="primary" 
                style={{ position: 'fixed', right: 20, bottom: 10 }}
                disabled={exportInProgress}
                onClick={exportExcel}
            >
                { exportInProgress ? <CircularProgress color="inherit" size={20} /> : <GetAppRounded /> }
            </Fab>
        </div>
        <ImportStockBasicInfoDialog
            users={users}
            enums={enums}
            codes={codes}
            open={showImport} 
            onClose={()=>setShowImport(false)}
            onReload={()=>setReload(r => r+1)}
        />
    </div>
}

function getStockUpdatableListReloadable(ignored) {
    return getStockUpdatableList();
}
