import { get } from "./common";
import { convertStockInfo, makeStockInfoConverter } from "./stock";

/** @typedef {import('js-big-decimal')} BigDecimal */

/** 
 * @param { (keyof import('./stock').StockInfo)[] | undefined } select
 * @returns {Promise<import('./stock').StockInfo[]>} 
 */
export async function getCallTable(select = undefined) {
    const r = await get('/api/stock/call', { select: select ? select.join(",") : undefined });
    let converter;
    if (select && select.length > 0) {
        const conv = makeStockInfoConverter(select);
        converter = function(row) {
            const r = {};
            select.forEach((key, i) => {
                r[key] = row[i];
            });
            return conv(r);
        }
    } else {
        converter = convertStockInfo;
    }
    return r.map(converter);
}

/** 
 * @param { (keyof import('./stock').StockInfo)[] } select
 * @param { string } more
 * @returns {Promise<import('./stock').StockInfo[]>} 
 */
 export async function getCoverTable(select = undefined, more) {
    const r = await get('/api/stock/cover', { 
        select: select ? select.join(",") : undefined,
        more
    });
    let converter;
    if (select && select.length > 0) {
        const conv = makeStockInfoConverter(select);
        converter = function(row) {
            const r = {};
            select.forEach((key, i) => {
                r[key] = row[i];
            });
            return conv(r);
        }
    } else {
        converter = convertStockInfo;
    }
    return r.map(converter);
}

/** 
 * @param { (keyof import('./stock').StockInfo)[] } select
 * @returns {Promise<import('./stock').StockInfo[]>} 
 */
export async function getShortTable(select) {
    const r = await get('/api/stock/short', {
        select: select ? select.join(",") : undefined,
    });
    let converter;
    if (select && select.length > 0) {
        const conv = makeStockInfoConverter(select);
        converter = function(row) {
            const r = {};
            select.forEach((key, i) => {
                r[key] = row[i];
            });
            return conv(r);
        }
    } else {
        converter = convertStockInfo;
    }
    return r.map(converter);
}