import bigDecimal from "js-big-decimal";
import { val } from "./bn";
import { fromDays2000, parseDate, toDate } from "./date";

/** @typedef {{ ref?: string, row?: number, col?: number, message: string }} ErrorMessage */

const excelDayNum0 = toDate("1899-12-31");
const dayNumValidRangeMin = toDate("1950-01-01") - excelDayNum0;
const dayNumValidRangeMax = toDate("2099-12-31") - excelDayNum0;

/**
 * @param {unknown} excelDate 
 */
export function parseExcelDayNum(excelDate) {
    if (excelDate === null || excelDate === undefined) {
        return null;
    }
    const n = Number(excelDate);
    if (dayNumValidRangeMin <= n && n <= dayNumValidRangeMax) {
        const dayNum = Math.floor(n + excelDayNum0);
        return dayNum;
    }

    const m = parseDate(`${excelDate}`);
    if (!m || !m.isValid()) {
        return null;
    }
    return toDate(m);
}

function any() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
     function parseAny (str) {
        return [true, str];
    };
    return parseAny;
}

function requiredDayNum() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    return function parseRequiredDayNum (str) {
        if (!str) {
            return [false, `单元格不能为空`];
        }
        const m = parseExcelDayNum(str);
        if (typeof(m) !== 'number') {
            return [false, `单元格日期格式错误`];
        }
        return [true, `${m}`];
    };
}

function requiredString() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseRequiredString (str) {
        if (str) {
            return [true, str];
        } else {
            return [false, `单元格不能为空`];
        }
    };
    return parseRequiredString;
}

/**
 * @param {RegExp} pattern 
 * @param {string} formatTip
 */
function requiredStringWithPattern(pattern, formatTip = '单元格格式错误') {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseRequiredString (str) {
        if (str && pattern.test(str)) {
            return [true, str];
        } else {
            return [false, formatTip.replace(/\$\{value\}/g, str)];
        }
    };
    return parseRequiredString;
}

function optionalString() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseOptionalString (str) {
        return [true, str];
    };
    return parseOptionalString;
}

/**
 * @param {string[]} labels 
 */
function optionalEnum(labels) {
    const m = {};
    labels.forEach((n,i) => m[n] = `${i}`);
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseOptionalEnum(str) {
        if (!str) {
            return [true, ''];
        }
        if (str in m) {
            return [true, m[str]];
        }
        return [false, `单元格的值只能是：${labels.slice(1).join(", ")}`];
    }
    return parseOptionalEnum;
}

const yes = ['是', 'y', 'yes'];
const no = ['否', 'n', 'no'];

function bool() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
     function parseBool012(str) {
        if (!str) {
            return [true, ''];
        } else if (str == '-') {
            return [true, '0'];
        } else if (yes.indexOf(str.toLowerCase()) >= 0) {
            return [true, '1'];
        } else if (no.indexOf(str.toLowerCase()) >= 0) {
            return [true, '2'];
        } else {
            return  [false, `单元格的值只能是'是'或'否'`];
        }
    }
    return parseBool012;
}

const invalidNumbers = ['#value!', '#div/0!', 'nan', 'inf', '-'];

/**
 * @param {string[]} ignoreValues 
 */
function optionalBigNumber(ignoreValues = []) {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
     function parseOptionalBigNumber(str) {
        if (!str || ignoreValues.indexOf(str) >= 0) {
            return [true, ''];
        }
        if (invalidNumbers.indexOf(str.toLowerCase()) >= 0) {
            return [true, ''];
        }
        /** @type {(v:bigDecimal)=>bigDecimal} */
        let negIfQuoted = a => a;
        const m = /^[(（]\s*(.*)\s*[)）]$/.exec(str);
        if (m) {
            str = m[1];
            negIfQuoted = a => a.negate();
        }
        let n = val(str);
        if (!n) {
            return [false, `单元格的值"${str}"是一个无效的数值`];
        }
        n = negIfQuoted(n);
        return [true, n.getValue()];
    }
    return parseOptionalBigNumber;
}

/**
 * @param {{ id: number, name: string }[]} flags
 */
function optionalMultiEnum(flags) {
    if (!flags) flags = [];
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseOptionalMultiEnum(str) {
        if (!str) {
            return [true, ''];
        }
        let v = 0;
        const strarr = str.split(',').map(s => s.trim()).filter(Boolean);
        for (const s of strarr) {
            const f = flags.find(f => f.name === s);
            if (!f) {
                return [false, `单元格中包含了无效的值：${s}`];
            }
            v = v | f.id;
        }
        return [true, `${v}`];
    }
    return parseOptionalMultiEnum;
}

/**
 * @param {Parser} parser
 */
function required(parser) {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseRequired(str) {
        const r = parser(str);
        if (!r[0]) {
            return r;
        }
        if (!r[1]) {
            return [false, `单元格不能为空`];
        }
        return r;
    }
    return parseRequired;
}

function makeParserBuilder() {
    return {
        any,
        bool,
        required,
        requiredString,
        requiredStringWithPattern,
        requiredDayNum,
        optionalString,
        optionalEnum,
        optionalBigNumber,
        bigNumber: optionalBigNumber,
        optionalMultiEnum
    };
}

function makeCellRef(row, col) {
    if (col === null || col === undefined) {
        return `Row ${(row+1)}`;
    }
    const ten = Math.floor(col / 26);
    const one = col % 26;
    let colRef;
    if (ten) {
        colRef = '_ABCDEFGHIJKLMNOPQRSTUVWXYZ'[ten] + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[one];
    } else {
        colRef = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[one];
    }
    return colRef + String(row+1);
}

/** @typedef { (str: string) => [boolean, string] } Parser */
/** @typedef { ReturnType<typeof makeParserBuilder> } ParserBuilder */

/**
 * @template {{[key: string]: string}} T
 * @param {(parserBuilder: ParserBuilder) => { [col in keyof T] : Parser }} callback 
 * @param { T } returnType
 * @param { (row: T) => { col?: number, message: string } } rowValidator
 */
export function makeParser(callback, returnType, rowValidator = () => null) {
    const parser = callback(makeParserBuilder());

    const lowerParserMap = /** @type {{ [key: string]: keyof T }} */({});
    for (const key of Object.keys(parser)) {
        lowerParserMap[key.toLowerCase()] = key;
    }
    
    /**
     * @param {string[][]} aoa
     * @param {number} headerRowCount
     * @param {number} headerRowIndex
     * @param { {[val:string]: string}} headerMap
     * @param { string[] } requiredHeaders
     * @returns {{ 
     *   warnings: ErrorMessage[], 
     *   errors: ErrorMessage[], 
     *   parseErrors: string[],
     *   data: T[]
     * }}
     */
    return function parse(aoa, headerRowCount = 1, headerRowIndex = headerRowCount - 1, headerMap = {}, requiredHeaders = []) {
        const headers = aoa[headerRowIndex].map((h, index) => {
            if (typeof h !== 'string') return { mapped: false, index, name: h };
            if (headerMap[h]) {
                return { mapped: true, index, name: headerMap[h] };
            }
            const mappedWithLower = lowerParserMap[h.toLowerCase()];
            if (mappedWithLower) {
                return { mapped: true, index, name: mappedWithLower };
            }
            return { mapped: false, index, name: h };
        });
        const errors = /** @type{ErrorMessage[]} */([]);
        const warnings = /** @type{ErrorMessage[]} */([]);
        const parseErrors = /** @type{string[]} */([]);
        let headerValidationFailed = false;
        for (const requiredHeader of requiredHeaders) {
            if (!headers.find(h => h.name === requiredHeader)) {
                const alterNames = Object.keys(headerMap).filter(key => headerMap[key] === requiredHeader);
                alterNames.push(requiredHeader);
                parseErrors.push(`表格中缺少必须的列：${alterNames.map(n => `"${n}"`).join(' 或 ')}`);
                headerValidationFailed = true;
            }
        }
        if (headerValidationFailed) {
            return { warnings, errors, parseErrors, data: [] };
        }
        for (const headerColIndex in headers) {
            const header = headers[headerColIndex];
            if (!header?.mapped) {
                warnings.push({
                    row: headerRowIndex,
                    col: Number(headerColIndex),
                    ref: makeCellRef(headerRowIndex, headerColIndex),
                    message: header?.name ? `多余的列将会被忽略：${header.name}` : '没有表头的列将会被忽略'
                });
            }
        }
        const data = /** @type{T[]} */([]);
        for (let i = headerRowCount; i < aoa.length; i++) {
            const row = aoa[i];
            const parsedRow = /** @type{{[key in keyof T]: string}} */({});
            let anyError = false;
            for (let col = 0; col < headers.length; col ++) {
                const header = headers[col];
                const value = row[col];
                const rowParser = header.mapped && parser[header.name];
                if (rowParser) {
                    const [success, val] = rowParser(value);
                    if (!success) {
                        anyError = true;
                        errors.push({
                           row: i,
                           col,
                           ref: makeCellRef(i, col),
                           message: val
                        });
                    } else {
                        parsedRow[header.name] = val;
                    }
                }
            }
            const r = /** @type {T} */(parsedRow);
            if (!anyError) {
                const errorMessage = rowValidator(r);
                if (errorMessage) {
                    anyError = true;
                    errors.push({
                        ...errorMessage,
                        ref: makeCellRef(i, errorMessage.col),
                    });
                }
            }
            if (!anyError) {
                data.push(r);
            }
        }
        return { warnings, errors, parseErrors, data };
    }
}
