export const FieldNames = {
    cluster_Layer: '归层',
    code_Factset: 'Factset Code',
    code: 'Wind Code',
    code_BBG: 'BBG Code',
    coverer: 'Analyst',
    business_Stability: '稳健(生意)',
    industry_Ceiling: '天花板(行业)',
    demand_Stage: '需求阶段',
    supply_Stage: '格局和方向',
    penetration_Rate: '渗透率',
    cr_N_Value: 'CR(X)',
    cr_N: 'X',
    industrial_Chain_Position_Importance: '环节级别',
    cashflow: '现金流',
    'return': '回报',
    company_Stability: '稳健(公司)',
    cycle_Causation: '波源',
    cycle_Position: '周期位置方向',
    company_Ceiling: '天花板(公司)',
    ceiling_Source: '天源(公司)',
    company_Growth: '潜在增速',
    expected_CAGR_Bottomline: '可期-下',
    expected_CAGR_Topline: '可期-上',
    clients: '下游',
    security_Importance: '票级别',
    competitive_Advantage: '竞争优势',
    management: '靠谱度',
    trend_Width: '反馈模式',
    trend_Exists: '有行情',
    logic: '有逻辑',
    med_Term_Trend: '中期趋势',
    short_Wishlist: '想空',
    ft_Width: 'Ft宽度',
    pt_Width: 'Pt宽度',
    trend_Sustainability: '行情持续性',
    trend_Stability: '行情稳定度',
    trend_Strength: '行情强度',
    trend_Stage: '行情阶段',
    alert_Before_Next_FS: '非优先',
    read_Next_FS: '看下季数',
    shortable: '可空',
    name: 'Name',
    quarterly_Report_Type: '季报/半年报',
    ipo_Date: '上市日期',
    exchange: '交易所',
    currency: '货币',
    industrial_Chain: '产业链',
    section: '板块',
    industry: '行业',
    sub_Industry: '子行业',
    product: '产品和服务',
    sw1: 'SW1',
    sw2: 'SW2',
    sw3: 'SW3',
    quality_Score: '质量',
    trend_Score: '行情',
    pricing_Score: '风回比',
    introduce: '公司简介',
    last_RSI: 'RSI',
    macd_DEA: 'DEA',
    macd_DIFF: 'DIFF',
    macd_MACD: 'MACD',
    price_Open: '开盘价',
    price_Close: '收盘价',
    price_Lo: '最低价',
    price_Hi: '最高价',
    price_Chg: '价格变动',
    price_Chg_Pct: '价格变动百分比',
    pe_T0: '可期PE',
    pb_TTM: '当前PB',
    marketValue: '市值',
    marketValue_USD: '美元市值',
    ev: 'EV',
    book_Value: 'BookValue',
    price_52w_Hi: '过去一年最高股价',
    price_52w_Lo: '过去一年最低股价',
    status: '状态',
    external_Power: '外力',
    stock_Power: '票力',
};

export const BoolLabels = ['-', '是', '否'];
export const CashflowLabels = ['-', '特别好', '好', '一般', '坏', '特别坏', '不适用'];
export const ReturnLabels = ['-', '特别高', '高', '中', '低', '废'];
export const BusinessStabilityLabels = ['-', '波', '稳'];
export const CompanyStabilityLabels = ['-', '波', '稳'];
export const CyclePositionLabels = ['-', '上行早', '上行中', '上行晚', '下行早', '下行中', '下行晚', '无观点', '非周期'];
export const MedTermTrendLabels = ['-', '上', '平', '下'];
export const IndustryCeilingLabels = ['-', '远', '近'];
export const DemandStageLabels = ['-', '升级', '渗透', '替换'];
export const SupplyStageLabels = ['-', '万马上', '万马下', '集中上', '垄断', '割据', '多变', '集中下'];
export const CompanyCeilingLabels = ['-', '远', '近'];
export const CompanyGrowthLabels = ['-', '特别快', '快', '中', '慢', '废'];
export const ClientsLabels = ['-', '2B', '2C', 'B+C'];
export const IndustrialChainPositionImportanceLabels = ['-', '核心', '正常', '边缘'];
export const SecurityImportanceLabels = ['-', '核心', '正常', '边缘'];
export const CompetitiveAdvantageLabels = ['-', 'A', 'B', 'C'];
export const ManagementLabels = ['-', '好', '中', '中', '差', '-'];
export const KeyFactorLabels = ['-', 'EPS', 'BPS', 'EBITDA', 'Rev', '直接定价'];
export const ValuationMethodLabels = ['-', 'PE', 'PB', 'EVEBITDA', 'PS', '直接定价'];
export const PriceVolLabels = ['-', '小', '中', '大', '特别大'];
export const LiquidityLabels = ['-', '非常好', '好', '一般', '差', '非常差'];
export const TrendStageLabels = ['-', '早', '中', '后', '晚'];
export const TrendStrengthLabels = ['-', '强', '一般', '弱'];
export const TrendStabilityLabels = ['-', '好', '一般', '坏'];
export const TrendSustainabilityLabels = ['-', '好', '一般', '差'];
export const TrendWidthLabels = ['-', '行业', '个股', '宏观'];
export const FTWidthLabels = ['-', '行业', '公司'];
export const PTWidthLabels = ['-', '行业', '个股'];
export const ShortableLabels = ['-', '可空', '不可空'];
export const ShortWishlistLabels = ['-', '是', '否' ];
export const ConflictLabels = ['-', '以此为准', '忽略'];
export const ExternalLabels = ['-', '-1', '0', '1', '-0.5', '0.5'];
export const PowerLabels = ['-', '-1', '0', '1', '-0.5', '0.5'];
export const CeilingSourceFlags = [{
    id: 1,
    name: '份额',
}, {
    id: 2,
    name: '定价权'
}, {
    id: 4,
    name: '大盘量价'
}, {
    id: 8,
    name: '新品类/产品'
}]
export const StatusLabels = ['-', 'Call', 'Watch', 'Short', 'Pass', '-'];
export const StatusOptions = [1, 2, 3, 4].map(id => ({ id, name: StatusLabels[id] }));

export const ClusterLayerLabels = ['-', '产业链', '板块', '行业', '子行业', '产品', 'SW1', 'SW2', 'SW3'];

/** @type {string[]} */
export const KellyConfidenceLabels = ['-'];
KellyConfidenceLabels[50] = '50';
KellyConfidenceLabels[60] = '60';
KellyConfidenceLabels[70] = '70';
KellyConfidenceLabels[80] = '80';

export const FTTypeOptions = [{
    id: 0,
    name: '非Ft首尾'
}, {
    id: 1,
    name: 'Ft首日（向上）',
}, {
    id: 2,
    name: 'Ft首日（向下）',
}, {
    id: 3,
    name: 'Ft尾日',
}];

export const EventNames = {
    rsi_50_Up: '50上',
    rsi_50_Down: '50下',
    rsi_75: '75',
    rsi_25: '25',
    weeks_52_Hi: 'Hi',
    weeks_52_Lo: 'Lo',
    macd_Up: 'MA+',
    macd_Down: 'MA-',
    report_Quarterly: '财',
}

/**
 * @param {string[]} labels 
 * @param {boolean} includeDefault
 * @param {number[]} reorder
 */
export function OptionsFromLabels(labels, includeDefault = true, reorder = null) {
    let r = labels.map((name, id) => ({ id, name }));
    if (reorder) {
        r = reorder.map(i => r[i]);
    }
    if (!includeDefault) {
        r = r.filter(opt => opt.id > 0);
    }
    return r;
}