import { Button, CircularProgress, Dialog, DialogActions, Fab, makeStyles, Paper } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApiState, withReload } from '../api/common';
import { getTicketById, getTicketComment } from '../api/ticket';
import { getUserInfoById } from '../api/user';
import { bn, bns, div, isValid, lt, mul } from '../lib/bn';
import { date2quarterOrSemi, fromDays2000 } from '../lib/date';
import useErrorMessage from '../lib/useErrorMessage';
import { ExternalLabels, FieldNames, PowerLabels, ShortableLabels, ShortWishlistLabels, StatusLabels, TrendStageLabels, TrendSustainabilityLabels } from '../lib/enums';
import bigDecimal from 'js-big-decimal';
import { SaveRounded } from '@material-ui/icons';
import FieldLabel from '../components/FieldLabel';
import SelectField from '../SingleStock/SelectField';
import SmallCheckbox from '../components/SmallCheckbox';
import BigNumberInput from '../SingleStock/BigNumberInput';
import IntegerInput from '../SingleStock/IntegerInput';
import RichText, { toHtml } from '../components/RichText';
import SelectOptionField from '../SingleStock/SelectOptionField';
import RequirePermission from '../components/RequirePermission';
import { useTicketCommentActions } from './useTicketCommentActions';
import SmallRadioBox from '../components/SmallRadioBox';

const cols = 14;

const useStyle = makeStyles({
    paper: {
        marginBottom: 10,
        padding: 10,
    },
    paperDialog: {
        width: '95%',
        maxWidth: '95%',
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
    },
    td: {
        width: `${100/cols}%`
    },
    td2: {
        width: `${2*100/cols}%`
    },
    td10: {
        width: `${10*100/cols}%`
    },
    td12: {
        width: `${12*100/cols}%`
    },
    td14: {
        width: `${14*100/cols}%`
    },
    link: {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

const DelayOptions = ['0','1','2','3','4','5','6','7','8','9','10'];
const TrendWidthLabels = ['-', '行业', '公司', '产业'];

const QualityScoreOptions = [
    // { id: "-", name: "-" },
    { id: "1.5", name: "1.5" },
    { id: "1.0", name: "1.0" },
    { id: "0.5", name: "0.5" },
    { id: "0.0", name: "0.0" },
    { id: "-0.5", name: "-0.5" },
    { id: "-1.0", name: "-1.0" },
];

const TrendScoreOptions = [
    // { id: "-", name: "-" },
    { id: "1.0", name: "1.0" },
    { id: "0.5", name: "0.5" },
    { id: "0.0", name: "0.0" },
    { id: "-0.5", name: "-0.5" },
    { id: "-1.0", name: "-1.0" },
];

const PricingScoreOptions = [
    // { id: "-", name: "-" },
    { id: "1.0", name: "1.0" },
    { id: "0.5", name: "0.5" },
    { id: "0.0", name: "0.0" },
    { id: "-0.5", name: "-0.5" },
    { id: "-1.0", name: "-1.0" },
];

const getTicketCommentReloadable = withReload(getTicketComment);

/**
 * @param {{
 *   ticketCommentId: number,
 *   dialogOpen?: boolean,
 *   onCloseDialog?: () => void,
 *   onTicketCommentDeleted?: () => void,
 *   variant?: 'standalone' | 'dialog'
 * }} param0 
 */
export default function TicketCommentView({ ticketCommentId, dialogOpen, onCloseDialog, variant = 'standalone', onTicketCommentDeleted }) {
    
    const [reload, setReload] = useState(0);
    const [comment, loading, error] = useApiState(getTicketCommentReloadable, ticketCommentId, reload);
    const [user] = useApiState(getUserInfoById, comment?.user_ID);
    const [ticket] = useApiState(getTicketById, comment?.ticket_ID);
    const [editFields, setEditFields] = useState(/** @type {Partial<import('../api/ticket').TicketFill>} */({}));
    
    /**
     * @template { keyof import('../api/ticket').TicketFill } T
     * @param { T } field 
     * @param { import('../api/ticket').TicketFill[T] } value 
     */
    function edit(field, value) {
        setEditFields(fields => ({ ...fields, [field]: value }));
    }

    const merged = { ...comment, ...editFields };

    const onComplete = useCallback(() => {
        setReload(r => r+1);
        setEditFields({});
    }, []);

    const onDeleteComplete = useCallback(() => {
        onTicketCommentDeleted();
        onCloseDialog();
    }, [onCloseDialog, onTicketCommentDeleted]);

    const { canSave, isSaving, onSave, onDelete } = useTicketCommentActions({ onComplete, onDeleteComplete });
    
    useErrorMessage(error);

    const classes = useStyle();

    const disabled = !canSave;
    const delayVisible = !disabled;
    const delay = (merged?.public_Date - comment?.report_Date) || 0;
    const logic = merged.logic === 1;

    const defaults = useMemo(() => {
        const merged = { ...comment, ...editFields };
        const valuation_Price_Multiple_Low = merged.expected_CAGR_Bottomline || comment?.expected_CAGR_Bottomline;
        const valuation_Price_Multiple_High = merged.expected_CAGR_Topline || comment?.expected_CAGR_Topline;
        const expected_Number = comment?.expected_Number;
        const valuation_Price_Low = mul(
            merged.valuation_Price_Multiple_Low || valuation_Price_Multiple_Low,
            merged.expected_Number || expected_Number
        );
        const valuation_Price_High = mul(
            merged.valuation_Price_Multiple_High || valuation_Price_Multiple_High,
            merged.expected_Number || expected_Number
        );
    
        let pricing_Score;
        if (
            !isValid(merged.valuation_Price_Low || valuation_Price_Low) ||
            !isValid(merged.valuation_Price_High || valuation_Price_High)
        ) {
            pricing_Score = undefined;
        } else if (lt(comment?.price_Close, merged.valuation_Price_Low || valuation_Price_Low)) {
            pricing_Score = "1.0";
        } else if (lt(comment?.price_Close, merged.valuation_Price_High || valuation_Price_High)) {
            pricing_Score = "0.5";
        } else {
            pricing_Score = '0.0';
        }
    
        return {
            valuation_Price_Multiple_Low,
            valuation_Price_Multiple_High,
            expected_Number,
            valuation_Price_Low,
            valuation_Price_High,
            pricing_Score,
        }
    }, [comment, editFields]);

    const read_Next_FS_Or_Default = merged.read_Next_FS || comment?.read_Next_FS;
    const alert_Before_Next_FS_Or_Default = merged.alert_Before_Next_FS || comment?.alert_Before_Next_FS;
    
    const FtTypeLabels = useMemo(() => {
        if (!comment || !isValid(comment.ft_Score)) {
            return ['- (非首尾)', '1 (首日向上)', '-1 (首日向下)', '0 (尾日)'];
        } else {
            return [bns(comment.ft_Score, 0)+' (非首尾)', '1 (首日向上)', '-1 (首日向下)', '0 (尾日)'];
        }
    }, [comment]);
    
    if (variant === 'standalone') {
        if (loading || !comment || !ticket) {
            return <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
                <CircularProgress color="primary" />
            </div>;
        }
        return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div style={{marginBottom: 60}}>
                { renderContent(Paper) }
            </div>
            <RequirePermission required="reviewTicket">
                <Fab
                    size="small" 
                    color="primary" 
                    style={{ position: 'fixed', bottom: 10, right: 20 }} 
                    onClick={() => onSave(comment, editFields, defaults)}
                    disabled={isSaving}
                >
                    <SaveRounded />
                </Fab>
            </RequirePermission>
        </div>;
    }
    if (variant === 'dialog') {
        if (loading || !comment || !ticket) {
            return <Dialog open={dialogOpen} classes={{ paper: classes.paperDialog }}>
                <div style={{flex:1, display:'flex',alignItems: 'center', justifyContent:'center', padding: 20}}>
                    <CircularProgress color="primary" />
                </div>
                <DialogActions>
                    <Button onClick={onCloseDialog} disabled={isSaving}>
                        关闭
                    </Button>
                </DialogActions>
            </Dialog>
        }
        return (
            <Dialog open={dialogOpen} classes={{ paper: classes.paperDialog }}>
                { renderContent('div') }
                <DialogActions>
                    <Button onClick={onCloseDialog} disabled={isSaving}>
                        关闭
                    </Button>
                    { canSave ? (
                        <Button 
                            color='secondary'
                            onClick={() => onDelete(comment.id)}
                            disabled={isSaving}
                        >
                            删除
                        </Button>
                    ) : null }
                    { canSave ? (
                        <Button 
                            color='primary'
                            onClick={() => onSave(comment, editFields, defaults)}
                            disabled={isSaving}
                        >
                            保存
                        </Button>
                    ) : null }
                </DialogActions>
            </Dialog>
        )
    }

    return null

    function renderContent(Container){
        return <>
            <Container className={classes.paper}>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <td className={classes.td}>
                                <Link to={`/stock/${comment.code}`} target="_blank" className={classes.link}>
                                    {comment.code}
                                </Link>
                            </td>
                            <td className={classes.td}>
                                <Link to={`/stock/${comment.code}`} target="_blank" className={classes.link}>
                                    {ticket.name}
                                </Link>
                            </td>
                            <td className={classes.td}>
                                {date2quarterOrSemi(ticket.quarter_Or_Semi, comment?.rpt_Period)}
                                {['', '预', '快', '财'][comment?.type]}
                            </td>
                            <td className={classes.td}>{fromDays2000(comment?.report_Date)?.format('YYYY-MM-DD')}</td>
                            <td className={classes.td}>{user?.display_Name}</td>
                            <td className={classes.td}></td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="收盘价"
                                    dot="yellow"
                                    plain
                                >
                                    {bns(comment?.price_Close)}
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="市值"
                                    dot="yellow"
                                    plain
                                >
                                    {comment?.currency}{bns(comment?.marketValue?.divide(new bigDecimal(100), 2))}亿
                                </FieldLabel>
                            </td>
                            <td className={classes.td}></td>
                            <td className={classes.td}></td>
                            <td className={classes.td2} colSpan={2}>
                                { delayVisible ? (
                                    <FieldLabel label="延迟" dot="green">
                                        <SelectField
                                            edit={!disabled}
                                            value={delay}
                                            onChange={(delay) => comment && edit('public_Date', comment.report_Date + delay )}
                                            options={DelayOptions}
                                        />
                                    </FieldLabel>
                                ) : null}
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="当季营收"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="同"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="环"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="单季归母"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="同"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="环"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="累计营收"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="同"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="环"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="累计归母"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="同"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="环"
                                    dot="yellow"
                                    plain
                                >
                                    
                                </FieldLabel>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Container>
            <Container className={classes.paper}>
                <table className={classes.table}>
                    <tbody>
                        <tr>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="逻辑" dot="green">
                                    <SmallCheckbox color='primary' size='small' 
                                        disabled={disabled}
                                        checked={merged.logic === 1} 
                                        onChange={e => edit('logic', e.target.checked ? 1 : 0 )}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td12} colSpan={12}>
                                <TextOrTextField
                                    placeholder='逻辑陈述'
                                    value={merged.logic_Detail}
                                    onChange={v => edit('logic_Detail', v)}
                                    disabled={disabled}
                                />
                            </td>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="广度" dot={ logic ? "green" : "grey" }>
                                    <SelectField
                                        edit={!disabled && logic}
                                        value={logic ? merged.trend_Width : 0}
                                        onChange={(v) => edit('trend_Width', v)}
                                        options={TrendWidthLabels}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="持续性" dot={ logic ? "green" : "grey" }>
                                    <SelectField
                                        edit={!disabled && logic}
                                        value={logic ? merged.trend_Sustainability : 0}
                                        onChange={(v) => edit('trend_Sustainability', v)}
                                        options={TrendSustainabilityLabels}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="稳定性" dot={ logic ? "green" : "grey" }>
                                    <SelectField
                                        edit={!disabled && logic}
                                        value={logic ? merged.trend_Stability : 0}
                                        onChange={(v) => edit('trend_Stability', v)}
                                        options={TrendSustainabilityLabels}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="阶段" dot={ logic ? "green" : "grey" }>
                                    <SelectField
                                        edit={!disabled && logic}
                                        value={logic ? merged.trend_Stage : 0}
                                        onChange={(v) => edit('trend_Stage', v)}
                                        options={TrendStageLabels}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="持续季数" dot={ logic ? "green" : "grey" }>
                                    <IntegerInput
                                        edit={!disabled && logic}
                                        value={logic ? merged.num_Q : null}
                                        onSubmit={v => edit('num_Q', v)}
                                    />
                                </FieldLabel>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="可期下" dot="blue">
                                    <BigNumberInput
                                        edit={!disabled}
                                        value={merged.expected_CAGR_Bottomline}
                                        onSubmit={v => edit('expected_CAGR_Bottomline', v)}
                                        placeholder={bns(comment.expected_CAGR_Bottomline)}
                                    />
                                </FieldLabel>
                            </td>
                            <td  className={classes.td2} colSpan={2}>
                                <FieldLabel label="可期上" dot="blue">
                                    <BigNumberInput
                                        edit={!disabled}
                                        value={merged.expected_CAGR_Topline}
                                        onSubmit={v => edit('expected_CAGR_Topline', v)}
                                        placeholder={bns(comment.expected_CAGR_Topline)}
                                    />
                                </FieldLabel>
                            </td>
                            <td  className={classes.td2} colSpan={2}>
                                <FieldLabel label="预期数" dot="blue">
                                    <BigNumberInput
                                        edit={!disabled}
                                        value={merged.expected_Number}
                                        onSubmit={v => edit('expected_Number', v)}
                                        placeholder={bns(defaults.expected_Number)}
                                    />
                                </FieldLabel>
                            </td>
                            <td  className={classes.td2} colSpan={2}>
                                <FieldLabel label="合理倍数下" dot="blue">
                                    <BigNumberInput
                                        edit={!disabled}
                                        value={merged.valuation_Price_Multiple_Low}
                                        onSubmit={v => edit('valuation_Price_Multiple_Low', v)}
                                        placeholder={bns(defaults.valuation_Price_Multiple_Low)}
                                    />
                                </FieldLabel>
                            </td>
                            <td  className={classes.td2} colSpan={2}>
                                <FieldLabel label="合理倍数上" dot="blue">
                                    <BigNumberInput
                                        edit={!disabled}
                                        value={merged.valuation_Price_Multiple_High}
                                        onSubmit={v => edit('valuation_Price_Multiple_High', v)}
                                        placeholder={bns(defaults.valuation_Price_Multiple_High)}
                                    />
                                </FieldLabel>
                            </td>
                            <td  className={classes.td2} colSpan={2}>
                                <FieldLabel label="合理下" dot="blue">
                                    <BigNumberInput
                                        edit={!disabled}
                                        value={merged.valuation_Price_Low}
                                        onSubmit={v => edit('valuation_Price_Low', v)}
                                        placeholder={bns(defaults.valuation_Price_Low)}
                                    />
                                </FieldLabel>
                            </td>
                            <td  className={classes.td2} colSpan={2}>
                                <FieldLabel label="合理上" dot="blue">
                                    <BigNumberInput
                                        edit={!disabled}
                                        value={merged.valuation_Price_High}
                                        onSubmit={v => edit('valuation_Price_High', v)}
                                        placeholder={bns(defaults.valuation_Price_High)}
                                    />
                                </FieldLabel>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={14} className={classes.td14}>
                                <TextOrRichField
                                    placeholder='综合陈述'
                                    value={merged.comment}
                                    onChange={v => edit('comment', v)}
                                    disabled={disabled}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label={FieldNames.quality_Score} dot="green">
                                    <SelectOptionField
                                        edit={!disabled}
                                        value={bns(merged.quality_Score, 1)}
                                        onChange={(v) => edit('quality_Score', bn(v))}
                                        options={QualityScoreOptions}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="动能" dot="green">
                                    <SelectOptionField
                                        edit={!disabled}
                                        value={bns(merged.trend_Score, 1)}
                                        onChange={(v) => edit('trend_Score', bn(v))}
                                        options={TrendScoreOptions}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="估值" dot="blue">
                                    <SelectOptionField
                                        edit={!disabled}
                                        value={bns(merged.pricing_Score, 1)}
                                        onChange={(v) => edit('pricing_Score', bn(v))}
                                        options={PricingScoreOptions}
                                        defaultValue={defaults.pricing_Score}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="外" dot="green">
                                    <SelectField
                                        edit={!disabled}
                                        value={merged.external}
                                        onChange={(v) => edit('external', v)}
                                        options={ExternalLabels}
                                        optionOrders={[0,3,5,2,4,1]}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="票" dot="green">
                                    <SelectField
                                        edit={!disabled}
                                        value={merged.power}
                                        onChange={(v) => edit('power', v)}
                                        options={PowerLabels}
                                        optionOrders={[0,3,5,2,4,1]}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="多" dot="green">
                                    <SelectField
                                        hideDefault
                                        edit={!disabled}
                                        value={merged.status}
                                        onChange={(v) => edit('status', v)}
                                        options={StatusLabels}
                                        optionOrders={[1,2,4]}
                                    />
                                </FieldLabel>
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="想空" dot="green">
                                    <SelectField
                                        edit={!disabled}
                                        value={merged.short_Wishlist}
                                        onChange={(v) => edit('short_Wishlist', v)}
                                        options={ShortWishlistLabels}
                                        hideDefault
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel
                                    label="可空"
                                    dot="yellow"
                                    plain
                                >
                                    { comment?.shortable_Total_USD ? (
                                        `$${bns(div(comment?.shortable_Total_USD, "1000000"), 1)}mn/${bns(comment?.shortable_Weighted_Rate)}`
                                    ) : (
                                        ShortableLabels[comment?.shortable]
                                    )}
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="Ft分" dot="blue">
                                    <SelectField
                                        edit={!disabled}
                                        value={merged.ft_Type}
                                        onChange={(v) => edit('ft_Type', v)}
                                        options={FtTypeLabels}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label="持有看下季" dot="blue">
                                    <SmallRadioBox color='primary' size='small' 
                                        disabled={disabled}
                                        checked={read_Next_FS_Or_Default === 1} 
                                        onChange={e => edit('read_Next_FS', e.target.checked ? 1 : 2 )}
                                    />
                                </FieldLabel>
                            </td>
                            <td className={classes.td2} colSpan={2}>
                                <FieldLabel label={FieldNames.alert_Before_Next_FS} dot="blue">
                                    <SmallRadioBox color='primary' size='small' 
                                        disabled={disabled}
                                        checked={alert_Before_Next_FS_Or_Default === 1} 
                                        onChange={e => edit('alert_Before_Next_FS', e.target.checked ? 1 : 2 )}
                                    />
                                </FieldLabel>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Container>
        </>;
    }
}

/**
 * @param {{
 *   value: string,
 *   placeholder: string,
 *   onChange: (value: string) => void,
 *   disabled: boolean
 * } } param0 
 */
function TextOrTextField({ value, onChange, disabled, placeholder }) {
    const [text, setText] = useState(value);
    useEffect(() => {
        setText(value);
    }, [value]);
    if (disabled) {
        return <div 
            className='MuiPaper-root  MuiPaper-elevation1' 
            style={{
                padding: 8,
                background: '#535353'
            }}
        >
            {value}
        </div>
    }
    return <input
        className='MuiPaper-root  MuiPaper-elevation1'
        style={{ 
            border: 'none', 
            color: 'inherit', 
            width: '100%',
            outline: 'none',
            padding: 8,
            background: '#535353'
        }}
        placeholder={placeholder}
        value={text}
        onChange={e => setText(e.target.value)}
        onBlur={() => onChange(text) }
    />
}

/**
 * @param {{
 *   value: string,
 *   placeholder: string,
 *   onChange: (value: string) => void,
 *   disabled: boolean
 * } } param0 
 */
function TextOrRichField({ value, onChange, disabled, placeholder }) {
    const [text, setText] = useState(value);

    useEffect(() => {
        if (!disabled) {
            setText(value);
        } else {
            setText(toHtml(value, 'div'))
        }
    }, [value, disabled]);
    if (disabled) {
        return <div
            style={{marginTop: 16, padding: 8, background: '#535353'}}
            className='MuiPaper-root  MuiPaper-elevation1'
            dangerouslySetInnerHTML={{ __html: text }}
        />
    }
    return <div  
        style={{marginTop: 16}}
    >
        <RichText
            variant='compat'
            disabled={disabled}
            initialValue={value}
            onChange={onChange}
            className='MuiPaper-root  MuiPaper-elevation1'
        />
    </div>
}
