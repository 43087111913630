import bigDecimal from "js-big-decimal";
import { bn, bns } from "../lib/bn";
import { del, get, post, postJson, put, putJson } from "./common";

/** 
 * @template {{[key:string]: unknown}} T
 * @typedef { import("./common").ApiSerialized<T> } ApiSerialized 
 */

/**
 * @typedef {{
 *   id: number,
 *   report_Date: number,
 *   code: string,
 *   title: string,
 *   type: number,
 *   rpt_Period: number,
 *   quarter_Or_Semi: number,
 *   is_Core: boolean,
 *   is_Quality: boolean,
 *   is_Fast: boolean,
 *   is_Hold: boolean,
 *   is_Call: boolean,
 *   is_Watch: boolean,
 *   is_Read_Next: boolean,
 *   price_Close: bigDecimal,
 *   name: string,
 *   introduce: string,
 *   shortable: number,
 *   coverer: number[],
 *   marketValue: bigDecimal,
 *   currency: string,
 *   shortable_Total_USD: bigDecimal,
 *   shortable_Weighted_Rate: bigDecimal,
 *   eps_Consensus_T1: bigDecimal,
 *   expected_CAGR_Bottomline: bigDecimal,
 *   expected_CAGR_Topline: bigDecimal,
 *   read_Next_FS: number,
 *   alert_Before_Next_FS: number,
 *   short_Wishlist: number,
 *   ft_Type: number,
 *   ft_Score: bigDecimal,
 * }} Ticket
 */

/** 
 * @typedef {{
 *   id: number,
 *   ticket_ID: number,
 *   user_ID: number,
 *   public_Date: number,
 *   logic: number,
 *   logic_Detail: string,
 *   comment: string,
 *   trend_Width: number,
 *   trend_Sustainability: number,
 *   trend_Stability: number,
 *   trend_Stage: number,
 *   num_Q: number,
 *   expected_CAGR_Bottomline: bigDecimal,
 *   expected_CAGR_Topline: bigDecimal,
 *   expected_Number: bigDecimal,
 *   valuation_Price_Multiple_Low: bigDecimal,
 *   valuation_Price_Multiple_High: bigDecimal,
 *   valuation_Price_Low: bigDecimal,
 *   valuation_Price_High: bigDecimal,
 *   quality_Score: bigDecimal,
 *   trend_Score: bigDecimal,
 *   pricing_Score: bigDecimal,
 *   external: number,
 *   power: number,
 *   status: number,
 *   short_Wishlist: number,
 *   ft_Type: number,
 *   read_Next_FS: number,
 *   alert_Before_Next_FS: number,
 * }} TicketFill
 */

/** 
 * @typedef {{
 *   id: number,
 *   ticket_ID: number,
 *   ticket_Fill_ID: number,
 *   user_ID: number,
 *   code: string,
 *   rpt_Period: number,
 *   type: number,
 *   report_Date: number,
 *   public_Date: number,
 *   logic: number,
 *   logic_Detail: string,
 *   comment: string,
 *   trend_Width: number,
 *   trend_Sustainability: number,
 *   trend_Stability: number,
 *   trend_Stage: number,
 *   num_Q: number,
 *   expected_CAGR_Bottomline: bigDecimal,
 *   expected_CAGR_Topline: bigDecimal,
 *   expected_Number: bigDecimal,
 *   valuation_Price_Multiple_Low: bigDecimal,
 *   valuation_Price_Multiple_High: bigDecimal,
 *   valuation_Price_Low: bigDecimal,
 *   valuation_Price_High: bigDecimal,
 *   quality_Score: bigDecimal,
 *   trend_Score: bigDecimal,
 *   pricing_Score: bigDecimal,
 *   external: number,
 *   power: number,
 *   status: number,
 *   short_Wishlist: number,
 *   ft_Type: number,
 *   ft_Score: bigDecimal,
 *   read_Next_FS: number,
 *   alert_Before_Next_FS: number,
 *   price_Close: bigDecimal,
 *   marketValue: bigDecimal,
 *   currency: string,
 *   shortable: number,
 *   shortable_Total_USD: bigDecimal,
 *   shortable_Weighted_Rate: bigDecimal,
 * }} TicketComment
 */

/**
 * @param {ApiSerialized<Ticket>} r 
 * @returns {Ticket}
 */
function convertTicket(r) {
    return {
        id: r.id,
        report_Date: r.report_Date,
        code: r.code,
        title: r.title,
        type: r.type,
        rpt_Period: r.rpt_Period,
        quarter_Or_Semi: r.quarter_Or_Semi,
        is_Core: Boolean(r.is_Core),
        is_Quality: Boolean(r.is_Quality),
        is_Fast: Boolean(r.is_Fast),
        is_Hold: Boolean(r.is_Hold),
        is_Call: Boolean(r.is_Call),
        is_Watch: Boolean(r.is_Watch),
        is_Read_Next: Boolean(r.is_Read_Next),
        price_Close: bn(r.price_Close),
        name: r.name,
        introduce: r.introduce,
        shortable: r.shortable,
        coverer: r.coverer,
        marketValue: bn(r.marketValue),
        currency: r.currency,
        shortable_Total_USD: bn(r.shortable_Total_USD),
        shortable_Weighted_Rate: bn(r.shortable_Weighted_Rate),
        eps_Consensus_T1: bn(r.eps_Consensus_T1),
        expected_CAGR_Bottomline: bn(r.expected_CAGR_Bottomline),
        expected_CAGR_Topline: bn(r.expected_CAGR_Topline),
        read_Next_FS: r.read_Next_FS,
        alert_Before_Next_FS: r.alert_Before_Next_FS,
        short_Wishlist: r.short_Wishlist,
        ft_Type: r.ft_Type,
        ft_Score: bn(r.ft_Score)
    };
}

/**
 * @param {number} dayNum
 * @param {number[]} coverers
 * @param {number} rpt_period
 * @param {number} cny_gte
 * @param {number} hkd_gte
 * @param {number} usd_gte
 * @param {boolean} onlyHasFill
 */
export async function getTickets(dayNum, coverers, rpt_period, cny_gte, hkd_gte, usd_gte, onlyHasFill) {
    const list = /** @type{any[]} */(await get(`/api/ticket`, { 
        date: dayNum,
        coverer: coverers,
        rpt_period,
        cny_gte,
        hkd_gte,
        usd_gte,
        fill: onlyHasFill ? '1' : null
    }));
    return list.map(convertTicket);
}

/**
 * @param {number} id 
 */
export async function getTicketById(id) {
    if (!id) return null;
    return convertTicket(
        await get(`/api/ticket/${id}`)
    );
}

/**
 * @param {ApiSerialized<TicketFill>} r 
 * @returns {TicketFill}
 */
function convertTicketFill(r) {
    return {
        id: r.id,
        ticket_ID: r.ticket_ID,
        user_ID: r.user_ID,
        public_Date: r.public_Date,
        logic: r.logic,
        logic_Detail: r.logic_Detail,
        comment: r.comment,
        trend_Width: r.trend_Width,
        trend_Sustainability: r.trend_Sustainability,
        trend_Stability: r.trend_Stability,
        trend_Stage: r.trend_Stage,
        num_Q: r.num_Q,
        expected_CAGR_Bottomline: bn(r.expected_CAGR_Bottomline),
        expected_CAGR_Topline: bn(r.expected_CAGR_Topline),
        expected_Number: bn(r.expected_Number),
        valuation_Price_Multiple_Low: bn(r.valuation_Price_Multiple_Low),
        valuation_Price_Multiple_High: bn(r.valuation_Price_Multiple_High),
        valuation_Price_Low: bn(r.valuation_Price_Low),
        valuation_Price_High: bn(r.valuation_Price_High),
        quality_Score: bn(r.quality_Score),
        trend_Score: bn(r.trend_Score),
        pricing_Score: bn(r.pricing_Score),
        external: r.external,
        power: r.power,
        status: r.status,
        short_Wishlist: r.short_Wishlist,
        ft_Type: r.ft_Type,
        read_Next_FS: r.read_Next_FS,
        alert_Before_Next_FS: r.alert_Before_Next_FS,
    };
}

/**
 * @param {number[]} ticketIds
 */
export async function getTicketFillsOfTickets(ticketIds) {
    if (!ticketIds || ticketIds.length === 0) {
        return [];
    }
    const list = /** @type{ApiSerialized<TicketFill>[]} */(await postJson(`/api/ticket-fill`, ticketIds));
    return list.map(convertTicketFill);
}

/**
 * @param { number } ticketId
 * @param { number } userId
 */
export async function getTicketFill(ticketId, userId) {
    if (!ticketId || !userId) return null;
    return convertTicketFill(
        await get(`/api/ticket/${ticketId}/fill-user/${userId}`)
    );
}

/**
 * @param { number } ticketId
 * @param { number } userId
 * @param { Partial<Omit<TicketFill, 'id'|'ticket_ID'|'user_ID'>> } ticketFill 
 * @returns { Promise<void> }
 */
export async function updateTicketFill(ticketId, userId, ticketFill) {
    await putJson(`/api/ticket/${ticketId}/fill-user/${userId}`, {
        logic: ticketFill.logic,
        logic_Detail: ticketFill.logic_Detail,
        comment: ticketFill.comment,
        trend_Width: ticketFill.trend_Width,
        trend_Sustainability: ticketFill.trend_Sustainability,
        trend_Stability: ticketFill.trend_Stability,
        trend_Stage: ticketFill.trend_Stage,
        num_Q: ticketFill.num_Q,
        expected_CAGR_Bottomline: ticketFill.expected_CAGR_Bottomline && bns(ticketFill.expected_CAGR_Bottomline),
        expected_CAGR_Topline: ticketFill.expected_CAGR_Topline && bns(ticketFill.expected_CAGR_Topline),
        expected_Number: ticketFill.expected_Number && bns(ticketFill.expected_Number),
        valuation_Price_Multiple_Low: ticketFill.valuation_Price_Multiple_Low && bns(ticketFill.valuation_Price_Multiple_Low),
        valuation_Price_Multiple_High: ticketFill.valuation_Price_Multiple_High && bns(ticketFill.valuation_Price_Multiple_High),
        valuation_Price_Low: ticketFill.valuation_Price_Low && bns(ticketFill.valuation_Price_Low),
        valuation_Price_High: ticketFill.valuation_Price_High && bns(ticketFill.valuation_Price_High),
        quality_Score: ticketFill.quality_Score && bns(ticketFill.quality_Score),
        trend_Score: ticketFill.trend_Score && bns(ticketFill.trend_Score),
        pricing_Score: ticketFill.pricing_Score ? bns(ticketFill.pricing_Score) : (ticketFill.pricing_Score === null ? "" : undefined),
        external: ticketFill.external,
        power: ticketFill.power,
        status: ticketFill.status,
        short_Wishlist: ticketFill.short_Wishlist,
        ft_Type: ticketFill.ft_Type,
        read_Next_FS: ticketFill.read_Next_FS,
        alert_Before_Next_FS: ticketFill.alert_Before_Next_FS
    });
}

/**
 * @param {number} ticketFillId 
 * @returns {Promise<number>}
 */
export async function commitTicketFill(ticketFillId) {
    return post(`/api/ticket-fill/${ticketFillId}/commit`);
}

/**
 * @param {ApiSerialized<TicketComment>} r 
 * @returns {TicketComment}
 */
function convertTicketComment(r) {
    return {
        id: r.id,
        ticket_ID: r.ticket_ID,
        ticket_Fill_ID: r.ticket_Fill_ID,
        user_ID: r.user_ID,
        code: r.code,
        rpt_Period: r.rpt_Period,
        type: r.type,
        report_Date: r.report_Date,
        public_Date: r.public_Date,
        logic: r.logic,
        logic_Detail: r.logic_Detail,
        comment: r.comment,
        trend_Width: r.trend_Width,
        trend_Sustainability: r.trend_Sustainability,
        trend_Stability: r.trend_Stability,
        trend_Stage: r.trend_Stage,
        num_Q: r.num_Q,
        expected_CAGR_Bottomline: bn(r.expected_CAGR_Bottomline),
        expected_CAGR_Topline: bn(r.expected_CAGR_Topline),
        expected_Number: bn(r.expected_Number),
        valuation_Price_Multiple_Low: bn(r.valuation_Price_Multiple_Low),
        valuation_Price_Multiple_High: bn(r.valuation_Price_Multiple_High),
        valuation_Price_Low: bn(r.valuation_Price_Low),
        valuation_Price_High: bn(r.valuation_Price_High),
        quality_Score: bn(r.quality_Score),
        trend_Score: bn(r.trend_Score),
        pricing_Score: bn(r.pricing_Score),
        external: r.external,
        power: r.power,
        status: r.status,
        short_Wishlist: r.short_Wishlist,
        ft_Type: r.ft_Type,
        ft_Score: bn(r.ft_Score),
        read_Next_FS: r.read_Next_FS,
        alert_Before_Next_FS: r.alert_Before_Next_FS,
        price_Close: bn(r.price_Close),
        marketValue: bn(r.marketValue),
        currency: r.currency,
        shortable: r.shortable,
        shortable_Total_USD: bn(r.shortable_Total_USD),
        shortable_Weighted_Rate: bn(r.shortable_Weighted_Rate)
    };
}

/**
 * @param {number[]} ticketIds
 */
export async function getTicketCommentsOfTickets(ticketIds) {
    if (!ticketIds || ticketIds.length === 0) {
        return [];
    }
    const list = /** @type{ApiSerialized<TicketComment>[]} */(await postJson(`/api/ticket-comment`, ticketIds));
    return list.map(convertTicketComment);
}

/**
 * @param {number} ticketCommentId 
 */
export async function getTicketComment(ticketCommentId) {
    const r = /** @type{ApiSerialized<TicketComment>} */(await get(`/api/ticket-comment/${ticketCommentId}`));
    return convertTicketComment(r);
}

/**
 * @param { number } ticketCommentId
 * @param { Partial<Omit<TicketFill, 'id'|'ticket_ID'|'user_ID'>> } changes 
 * @returns { Promise<void> }
 */
export async function updateTicketComment(ticketCommentId, changes) {
    await postJson(`/api/ticket-comment/${ticketCommentId}/commit`, {
        logic: changes.logic,
        logic_Detail: changes.logic_Detail,
        comment: changes.comment,
        trend_Width: changes.trend_Width,
        trend_Sustainability: changes.trend_Sustainability,
        trend_Stability: changes.trend_Stability,
        trend_Stage: changes.trend_Stage,
        num_Q: changes.num_Q,
        expected_CAGR_Bottomline: bnsOrEmpty(changes.expected_CAGR_Bottomline),
        expected_CAGR_Topline: bnsOrEmpty(changes.expected_CAGR_Topline),
        expected_Number: bnsOrEmpty(changes.expected_Number),
        valuation_Price_Multiple_Low: bnsOrEmpty(changes.valuation_Price_Multiple_Low),
        valuation_Price_Multiple_High: bnsOrEmpty(changes.valuation_Price_Multiple_High),
        valuation_Price_Low: bnsOrEmpty(changes.valuation_Price_Low),
        valuation_Price_High: bnsOrEmpty(changes.valuation_Price_High),
        quality_Score: bnsOrEmpty(changes.quality_Score),
        trend_Score: bnsOrEmpty(changes.trend_Score),
        pricing_Score: bnsOrEmpty(changes.pricing_Score),
        external: changes.external,
        power: changes.power,
        status: changes.status,
        short_Wishlist: changes.short_Wishlist,
        ft_Type: changes.ft_Type,
        read_Next_FS: changes.read_Next_FS,
        alert_Before_Next_FS: changes.alert_Before_Next_FS
    });
}

/**
 * @param {number} ticketCommentId 
 */
export async function deleteTicketComment(ticketCommentId) {
    await del(`/api/ticket-comment/${ticketCommentId}`);
}

/**
 * @param {bigDecimal} bn 
 */
function bnsOrEmpty(bn) {
    if (bn === undefined) {
        return undefined;
    } else if (bn === null) {
        return "";
    } else {
        return bns(bn);
    }
}
