

/** @typedef { import('quill').Quill } Quill  */

export default class FileUploadPlugin {
	/**
	 * Instantiate the module given a quill instance and any options
	 * @param {Quill} quill
	 * @param {Object} options
	 */
	constructor(quill, options = {}) {
		// save the quill reference
		this.quill = quill;
		// save options
		options = {...options};
		if (!options.onUploadProgress) {
			options.onUploadProgress = () => {}
		}
		if (!options.checkResponse) {
			options.checkResponse = a => a
		}
		this.options = options;
		// listen for drop and paste events
		this.quill
            .getModule('toolbar')
            .container
            .querySelector('.ql-file')
			.onclick = this.selectLocalImage.bind(this);
	}

	/**
	 * Select local image
	 */
	selectLocalImage() {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.click();

		// Listen upload local image and save to server
		input.onchange = () => {
			const file = input.files[0];
			this.filename = file.name;
			const checkBeforeSend =
                this.options.checkBeforeSend || this.checkBeforeSend.bind(this);
            checkBeforeSend(file, this.sendToServer.bind(this));
		};
	}

	/**
	 * Check file before sending to the server
	 * @param {File} file
	 * @param {Function} next
	 */
	checkBeforeSend(file, next) {
		next(file);
	}

	/** @type { (() => void)[] } */
	destroyList = [];

	destroy() {
		for (const d of this.destroyList) {
			d();
		}
	}

	/**
	 * Send to server
	 * @param {File} file
	 */
	sendToServer(file) {
		// Handle custom upload
		if (this.options.customUploader) {
			this.options.customUploader(file, dataUrl => {
				this.insert(dataUrl);
			});
		} else {
			const url = this.options.url,
				method = this.options.method || 'POST',
				name = this.options.name || 'image',
				headers = this.options.headers || {},
				callbackOK =
					this.options.callbackOK || this.uploadImageCallbackOK.bind(this),
				callbackKO =
					this.options.callbackKO || this.uploadImageCallbackKO.bind(this);

			if (url) {
				const fd = new FormData();

				fd.append(name, file);
				
				const sessionId = Math.random().toString(36).substr(-4);
				const filename = file.name;

				if (this.options.csrf) {
					// add CSRF
					fd.append(this.options.csrf.token, this.options.csrf.hash);
				}

				const xhr = new XMLHttpRequest();
				// init http query
				xhr.open(method, url, true);
				// add custom headers
				for (var index in headers) {
					xhr.setRequestHeader(index, headers[index]);
				}

				const abort = () => {
					xhr.abort();
					this.options.onUploadProgress({
						id: sessionId,
						state: 'error',
						message: '已取消上传文件' + filename,
					});
				}

				this.destroyList.push(abort);
				
				// listen callback
				xhr.onload = () => {
					const idx = this.destroyList.indexOf(abort);
					if (idx >= 0) {
						this.destroyList.splice(idx, 1);
					}
					if (xhr.status === 200) {
						let r;
						try {
							r = this.options.checkResponse(JSON.parse(xhr.responseText));
							this.options.onUploadProgress({
								id: sessionId,
								state: 'success',
								message: '上传文件' + filename + '成功',
							});
							callbackOK(r, this.insert.bind(this));
						} catch (e) {
							this.options.onUploadProgress({
								id: sessionId,
								state: 'error',
								message: e.message,
							});
						}
					} else {
						this.options.onUploadProgress({
							id: sessionId,
							state: 'error',
							message: '上传失败',
						});
						callbackKO({
							code: xhr.status,
							type: xhr.statusText,
							body: xhr.responseText
						});
					}
				};
				
				this.options.onUploadProgress({
					id: sessionId,
					state: 'init',
					message: '正在上传' + filename
				});

				if (xhr.upload) {
					xhr.upload.onprogress = (e) => {
						if (this.options.onUploadProgress) {
							this.options.onUploadProgress({
								id: sessionId,
								state: 'progress', 
								loaded: e.loaded,
								total: e.total,
								message: '正在上传' + filename + '（' + (100*e.loaded/e.total).toFixed(0) + '%）',
							});
						}
					}
				}

				if (this.options.withCredentials) {
					xhr.withCredentials = true;
				}

				xhr.send(fd);
			} else {
				const reader = new FileReader();

				reader.onload = event => {
					callbackOK(event.target.result, this.insert.bind(this));
				};
				reader.readAsDataURL(file);
			}
		}
	}

	/**
	 * Insert the image into the document at the current cursor position
	 * @param {string} dataUrl  The base64-encoded image URI
	 */
	insert(dataUrl) {
		const index =
			(this.quill.getSelection() || {}).index || this.quill.getLength();
		const filename = this.filename;
		this.quill.insertText(index, filename, 'link', dataUrl, 'user');
	}

	/**
	 * callback on image upload succesfull
	 * @param {any} response http response
	 */
	uploadImageCallbackOK(response, next) {
		next(response);
	}

	/**
	 * callback on image upload failed
	 * @param {any} error http error
	 */
	uploadImageCallbackKO(error) {
		alert(error);
	}
}