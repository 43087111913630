import { comparer } from '../lib/sort';

/**
 * @template {{[key:string]: any}} T
 * @typedef {Extract<keyof T, string>} Fields
 */

/**
 * @typedef { {
 *   in?: string[],
 *   range?: [number, number],
 *   rangeType?: 'in' | 'out',
 *   search?: 'string'
 * }} FieldFilter
 */

/**
 * @template {{[key:string]: any}} T
 * @typedef {{
 *   sorters: {
 *     field: Fields<T>,
 *     order: -1 | 0 | 1,
 *   }[] | null,
 *   filters: {
 *     [key in Fields<T>]?: FieldFilter
 *   }} | null
 * } SorterAndFilters
 */

/**
 * @template {{[key:string]: any}} T
 * @param {Readonly<Readonly<T>[]>} data
 * @param {SorterAndFilters<T>} sortFilterState
 * @returns {Readonly<Readonly<T>[]>}
 */
export function sortFilterData(data, sortFilterState) {
    if (!data) return null;
    let sorted;
    if (sortFilterState?.sorters) {
        const r = [...data];
        r.sort(comparer(sortFilterState.sorters));
        sorted = r;
    } else {
        sorted = data;
    }
    /** @type {((item:T) => boolean)[]} */
    const filters = [];
    if (sortFilterState?.filters) {
        Object.keys(sortFilterState.filters).forEach(field => {
            const filter = sortFilterState.filters[field];
            filters.push((item) => {
                const val = item[field];
                if (filter?.search) {
                    if (Array.isArray(val)) {
                        if (val.every(v => `${v??''}`.toLowerCase().indexOf(filter.search.toLowerCase()) < 0)) {
                            return false;
                        }
                    } else if (`${val??''}`.toLowerCase().indexOf(filter.search.toLowerCase()) < 0) {
                        return false;
                    }
                }
                if (filter?.in && filter.in.length > 0) {
                    if (Array.isArray(val)) {
                        if (val.every(v => filter.in.indexOf(v) < 0)) {
                            return false;
                        }
                    } else if (filter.in.indexOf(val) < 0) {
                        return false;
                    }
                }
                if (filter?.range) {
                    const [left, right] = filter.range;
                    if (!filter.rangeType || filter.rangeType === 'in') {
                        if (typeof left === 'number' && val < left) return false;
                        if (typeof right === 'number' && val > right) return false;
                    } else {
                        if (typeof left === 'number' && typeof right === 'number') {
                            if (val >= left && val <= right) return false;
                        } else if (typeof left === 'number') {
                            if (val >= left) return false;
                        } else if (typeof right === 'number') {
                            if (val <= right) return false;
                        }
                    }
                }
                return true;
            });
        });
    }
    // if (searchState) {
    //     Object.keys(searchState).forEach(field => {
    //         const search = searchState[field];
    //         filters.push((item) => {
    //             const val = item[field];
    //             if (search) {
    //                 if (Array.isArray(val)) {
    //                     if (val.every(v => `${v??''}`.toLowerCase().indexOf(search.toLowerCase()) < 0)) {
    //                         return false;
    //                     }
    //                 } else if (`${val??''}`.toLowerCase().indexOf(search.toLowerCase()) < 0) {
    //                     return false;
    //                 }
    //             }
    //             return true;
    //         });
    //     });
    // }
    if (filters.length > 0) {
        const filter = filters.reduce((a , b) => item => a(item) && b(item), () => true);
        return sorted.filter(filter);
    } else {
        return sorted;
    }
}