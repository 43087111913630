import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import bigDecimal from "js-big-decimal";
import React, { useEffect, useMemo, useState } from "react";
import { updateStockInfo } from "../api/stock";
import { val } from "../lib/bn";
import { BusinessStabilityLabels, CashflowLabels, CeilingSourceFlags, ClientsLabels, CompanyCeilingLabels, CompanyGrowthLabels, CompanyStabilityLabels, CompetitiveAdvantageLabels, CyclePositionLabels, DemandStageLabels, FieldNames, FTWidthLabels, IndustrialChainPositionImportanceLabels, IndustryCeilingLabels, ManagementLabels, MedTermTrendLabels, PTWidthLabels, ReturnLabels, SecurityImportanceLabels, ShortWishlistLabels, SupplyStageLabels, TrendStabilityLabels, TrendStageLabels, TrendStrengthLabels, TrendSustainabilityLabels, TrendWidthLabels } from "../lib/enums";
import { readSheetFromFile } from "../lib/excel";
import { useCoCallback } from "../lib/useCo";

/**
 * @typedef {{
 *   code: string,
 *   business_Stability?: string,
 *   industry_Ceiling?: string,
 *   demand_Stage?: string,
 *   supply_Stage?: string,
 *   penetration_Rate?: string,
 *   cr_N_Value?: string,
 *   cr_N?: string,
 *   industrial_Chain_Position_Importance?: string,
 *   cashflow?: string,
 *   return?: string,
 *   company_Stability?: string,
 *   cycle_Causation?: string,
 *   cycle_Position?: string,
 *   company_Ceiling?: string,
 *   ceiling_Source?: string,
 *   company_Growth?: string,
 *   expected_CAGR_Bottomline?: string,
 *   expected_CAGR_Topline?: string,
 *   clients?: string,
 *   security_Importance?: string,
 *   competitive_Advantage?: string,
 *   management?: string,
 *   trend_Width?: string,
 *   trend_Exists?: string,
 *   logic?: string,
 *   med_Term_Trend?: string,
 *   short_Wishlist?: string,
 *   ft_Width?: string,
 *   pt_Width?: string,
 *   trend_Sustainability?: string,
 *   trend_Stability?: string,
 *   trend_Strength?: string,
 *   trend_Stage?: string,
 *   alert_Before_Next_FS?: string,
 *   read_Next_FS?: string,
 * }} ImportableStockRow
 */

function requiredString() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseRequiredString (str) {
        if (str) {
            return [true, str];
        } else {
            return [false, `不能为空`];
        }
    };
    return parseRequiredString;
}

/**
 * @param {string[]} labels 
 */
function optionalEnum(labels) {
    const m = {};
    labels.forEach((n,i) => m[n] = `${i}`);
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseOptionalEnum(str) {
        if (!str) {
            return [true, ''];
        }
        if (str in m) {
            return [true, m[str]];
        }
        return [false, `的值只能是：${labels.slice(1).join(", ")}`];
    }
    return parseOptionalEnum;
}

const yes = ['是', 'y', 'yes'];
const no = ['否', 'n', 'no'];

function bool() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
     function parseBool012(str) {
        if (!str) {
            return [true, ''];
        } else if (str == '-') {
            return [true, '0'];
        } else if (yes.indexOf(str.toLowerCase()) >= 0) {
            return [true, '1'];
        } else if (no.indexOf(str.toLowerCase()) >= 0) {
            return [true, '2'];
        } else {
            return  [false, `的值只能是'是'或'否'`];
        }
    }
    return parseBool012;
}

function optionalBigNumber() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
     function parseOptionalBigNumber(str) {
        if (!str) {
            return [true, ''];
        }
        /** @type {(v:bigDecimal)=>bigDecimal} */
        let negIfQuoted = a => a;
        const m = /^[(（]\s*(.*)\s*[)）]$/.exec(str);
        if (m) {
            str = m[1];
            negIfQuoted = a => a.negate();
        }
        let n = val(str);
        if (!n) {
            return [false, `必须是一个有效的数值`];
        }
        n = negIfQuoted(n);
        return [true, n.getValue()];
    }
    return parseOptionalBigNumber;
}

/**
 * @param {{ id: number, name: string }[]} flags
 */
function optionalMultiEnum(flags) {
    if (!flags) flags = [];
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseOptionalMultiEnum(str) {
        if (!str) {
            return [true, ''];
        }
        let v = 0;
        const strarr = str.split(',').map(s => s.trim()).filter(Boolean);
        for (const s of strarr) {
            const f = flags.find(f => f.name === s);
            if (!f) {
                return [false, `中包含了无效的值：${s}`];
            }
            v = v | f.id;
        }
        return [true, `${v}`];
    }
    return parseOptionalMultiEnum;
}

/**
 * @param {{
 *   cycle_Causation_Flag: number,
 *   cycle_Causation_Name: string
 * }[]} cycleCausationFlagList 
 */
function makeParser(cycleCausationFlagList) {
    /** 
     * @type {{ 
     *   [key in keyof ImportableStockRow] : (
     *     value: string,
     *   ) => [boolean, string]
     * }} 
     */
    const importableColFields = {
        code: requiredString(),
        business_Stability: optionalEnum(BusinessStabilityLabels),
        industry_Ceiling: optionalEnum(IndustryCeilingLabels),
        demand_Stage: optionalEnum(DemandStageLabels),
        supply_Stage: optionalEnum(SupplyStageLabels),
        penetration_Rate: optionalBigNumber(),
        cr_N_Value: optionalBigNumber(),
        cr_N: optionalBigNumber(),
        industrial_Chain_Position_Importance: optionalEnum(IndustrialChainPositionImportanceLabels),
        cashflow: optionalEnum(CashflowLabels),
        return: optionalEnum(ReturnLabels),
        company_Stability: optionalEnum(CompanyStabilityLabels),
        cycle_Causation: optionalMultiEnum([{ id: 0, name: '-' }, ...(cycleCausationFlagList?.map(c => ({ id: c.cycle_Causation_Flag, name: c.cycle_Causation_Name })) || [])]),
        cycle_Position: optionalEnum(CyclePositionLabels),
        company_Ceiling: optionalEnum(CompanyCeilingLabels),
        ceiling_Source: optionalMultiEnum([{ id: 0, name: '-' }, ...CeilingSourceFlags]),
        company_Growth: optionalEnum(CompanyGrowthLabels),
        expected_CAGR_Bottomline: optionalBigNumber(),
        expected_CAGR_Topline: optionalBigNumber(),
        clients: optionalEnum(ClientsLabels),
        security_Importance: optionalEnum(SecurityImportanceLabels),
        competitive_Advantage: optionalEnum(CompetitiveAdvantageLabels),
        management: optionalEnum(ManagementLabels),
        trend_Width: optionalEnum(TrendWidthLabels),
        trend_Exists: bool(),
        logic: bool(),
        med_Term_Trend: optionalEnum(MedTermTrendLabels),
        short_Wishlist: optionalEnum(ShortWishlistLabels),
        ft_Width: optionalEnum(FTWidthLabels),
        pt_Width: optionalEnum(PTWidthLabels),
        trend_Sustainability: optionalEnum(TrendSustainabilityLabels),
        trend_Stability: optionalEnum(TrendStabilityLabels),
        trend_Strength: optionalEnum(TrendStrengthLabels),
        trend_Stage: optionalEnum(TrendStageLabels),
        alert_Before_Next_FS: bool(),
        read_Next_FS: bool(),
    };
    
    const importableColAliases = {};
    
    for (const key of Object.keys(importableColFields)) {
        if (!FieldNames[key]) {
            throw new Error('Bad import field: ' + key);
        }
        importableColAliases[FieldNames[key]] = key;
    }
    
    /**
     * @param {string[][]} aoa 
     * @param {(setter:(v:React.ReactNode[])=>React.ReactNode[])=>void} setWarnings 
     * @param {(setter:(v:React.ReactNode[])=>React.ReactNode[])=>void} setErrors
     * @returns { {name: string, field: keyof ImportableStockRow} [] }
     */
    function validateHeaders(aoa, setWarnings, setErrors) {
        const unrecognizedHeaders = [];
        const header = aoa[0];
        let codeFound = false;
        let anyOtherFound = false;
        /** @type  { {name: string, field: keyof ImportableStockRow} [] } */
        const cols = [];
        for (let n of header) {
            let name = n;
            if (importableColAliases[n]) {
                n = importableColAliases[n];
            }
            if (!importableColFields[n]) {
                unrecognizedHeaders.push(n);
                cols.push(null);
            } else if (n === 'code') {
                cols.push({ name, field: n });
                codeFound = true;
            } else {
                // @ts-ignore
                cols.push({ name, field: n });
                anyOtherFound = true;
            }
        }
        let r = true;
        if (!codeFound) {
            setErrors(errs => [...errs, "找不到列'Wind Code'"]);
            r = false;
        }
        if (!anyOtherFound) {
            setErrors(errs => [...errs, "找不到任何可识别的数据列"]);
            r = false;
        }
        if (unrecognizedHeaders.length > 0) {
            setWarnings(ws => [...ws, <>以下的列在导入时会被忽略：<br/>{unrecognizedHeaders.join(", ")}</>])
        }
        return r ? cols : null;
    }
    
    /**
     * @param {{name: string, field: keyof ImportableStockRow}[]} cols 
     * @param {string[][]} aoa 
     * @param {(setter:(v:React.ReactNode[])=>React.ReactNode[])=>void} setErrors
     * @returns {ImportableStockRow[]}
     */
    function parseTable(cols, aoa, setErrors) {
        const errors = [];
        let moreErrors = 0;
        /** @type {ImportableStockRow[]} */
        const result = [];
        r: for (let ri = 1; ri < aoa.length; ri++) {
            /** @type {ImportableStockRow} */
            const row = { code: '' };
            const r = aoa[ri];
            for (let ci = 0; ci < r.length; ci++) {
                const col = cols[ci];
                if (!col) continue;
                const { name, field: cn } = col;
                const parser = importableColFields[cn];
                const [success, val] = parser(r[ci]);
                if (!success) {
                    if (errors.length < 5) {
                        errors.push(`第${ri+1}行出错：${name}${val}`);
                    } else {
                        moreErrors ++;
                    }
                    continue r;
                }
                if (val) {
                    row[cn] = val;
                }
            }
            result.push(row);
        }
        if (errors.length > 0) {
            setErrors(errs => [...errs, <>
                数据表中{errors.length+moreErrors}行数据识别失败，这些行不会被导入：
                {errors.map((e, i) => <span key={i}><br/>{e}</span>)}
                {moreErrors?<>...以及{moreErrors}条更多的问题</>:null}
            </>])
        }
        if (result.length === 0) {
            setErrors(errs => [...errs, "没有可导入的数据"]);
        }
        return result.length === 0 ? null : result;
    }

    return { validateHeaders, parseTable };
}

/** @type {React.ReactNode[]} */
const initReactNodes = [];

/** @type {ImportableStockRow[]} */
const initRows = null;

/**
 * @param {{
 *   cycleCausationFlagList: {
 *     cycle_Causation_Flag: number,
 *     cycle_Causation_Name: string
 *   }[],
 *   open: boolean,
 *   onClose: () => void,
 *   onReload: () => void,
 * }} param0
 */
export default function ImportStockInfoDialog({ cycleCausationFlagList, open, onClose, onReload }) {
    const parser = useMemo(() => makeParser(cycleCausationFlagList), [cycleCausationFlagList]);
    const [imported, setImported] = useState(0);
    const [importedCount, setImportedCount] = useState({ success: 0, error: 0 });
    const [errors, setErrors] = useState(initReactNodes);
    const [warnings, setWarnings] = useState(initReactNodes);
    const [rows, setRows] = useState(initRows);
    useEffect(() => {
        if (open) {
            setImported(0);
            setImportedCount({ success: 0, error: 0 });
            setErrors([]);
            setWarnings([]);
            setRows(null);
        }
    }, [open]);
    const onSelect = useCoCallback(function*(_isCancelled, event) {
        setImported(0);
        setImportedCount({ success: 0, error: 0 });
        setErrors([]);
        setWarnings([]);
        setRows(null);
        if (!event?.target?.files[0]) return;
        try {
            const sheet = yield readSheetFromFile(event.target.files[0]);
            const cols = parser.validateHeaders(sheet, setWarnings, setErrors);
            if (!cols) {
                return;
            }
            const rows = parser.parseTable(cols, sheet, setErrors);
            if (!rows) {
                return;
            }
            setRows(rows);
            console.log("data is ready for import", rows);
        } catch (e) {
            console.warn(e);
            setErrors(errs => [...errs, "解析文件时发生错误：" + e.message]);
        }
    }, [parser]);
    const onSubmit = useCoCallback(function*(isCancelled) {
        setWarnings([]);
        setErrors([]);
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            try {
                setImported(i+1);
                const { code, ...update } = row;
                yield updateStockInfo(code, update);
                setImportedCount(c => ({ success: c.success + 1, error: c.error }));
                setImported(i+2);
            } catch (e) {
                setImportedCount(c => ({ success: c.success, error: c.error + 1 }));
                setImported(i+2);
                setErrors(errs => {
                    const old = errs.length < 5 ? errs : errs.slice(1);
                    return [...old, `导入第${i+1}条数据出错：${e.message}`];
                });
            }
        }
        onReload();
    }, [rows, open]);
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>导入数据</DialogTitle>
            <DialogContent>
                <div style={{fontSize: 16, width: 300}}>
                    从此页面导出的表格可以直接作为模板导入，留空的单元格不会被修改。
                </div>
                <div style={{width: 300}}>
                    <div style={{marginTop: 16, marginBottom: 16}}>
                        <input disabled={Boolean(imported)} id="importReportFile" type="file" style={{fontSize: 16}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={onSelect} />
                    </div>
                </div>
                { (!imported && rows ) ? <Alert severity="success">成功识别{rows.length}条可导入数据</Alert> : null }
                { (imported && imported < rows?.length) ? (
                    <Alert severity="warning">
                        如果导入过程被中断，已经导入的数据仍然会存在。
                    </Alert>
                ) : null }
                { (imported && imported <= rows?.length) ? (<Alert severity="info">
                    正在导入第{imported}/{rows?.length}条数据
                    <br/>成功：{importedCount.success}
                    <br/>失败：{importedCount.error}
                </Alert>) : null}
                { (imported && imported > rows?.length && importedCount.success === 0 ) ? (
                    <Alert severity="error">导入完成，所有{importedCount.success}条数据均导入失败</Alert>
                ) : null}
                { (imported && imported > rows?.length && importedCount.success > 0 && importedCount.error > 0 ) ? (
                    <Alert severity="warning">导入完成，{importedCount.success}条数据导入成功；{importedCount.error}条数据导入失败</Alert>
                ) : null}
                { (imported && imported > rows?.length && importedCount.success > 0 && importedCount.error === 0 ) ? (
                    <Alert severity="success">导入完成，{importedCount.success}条数据导入成功</Alert>
                ) : null}
                { errors.map((m, i) => (
                        <Alert key={i} severity="error">{m}</Alert>
                ))}
                { warnings.map((m, i) => (
                        <Alert key={i} severity="warning">{m}</Alert>
                ))}
            </DialogContent>
            <DialogActions style={{position: 'sticky', left: 0, right: 0, bottom: 0, background: '#424242', zIndex: 1 }}>                
                { !imported ? (
                    <Button onClick={onClose}>
                        取消
                    </Button>
                ) : null}
                { !imported ? (
                    <Button disabled={Boolean(imported || !rows)} onClick={onSubmit} color="primary">
                        开始导入
                    </Button>
                ) : null}
                { (imported && imported <= rows?.length) ? (
                    <Button onClick={onClose} color="secondary">
                        中断导入
                    </Button>
                ) : null}
                { (imported && imported > rows?.length) ? (
                    <Button onClick={onClose}>
                        关闭
                    </Button>
                ) : null}
            </DialogActions>
        </Dialog>
    );
}