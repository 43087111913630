import React, { useState, useCallback, memo } from 'react';
import { Checkbox, Grid, ListItemText, makeStyles, MenuItem, Paper, Select, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import FieldLabel from '../components/FieldLabel';
import { yellow } from '@material-ui/core/colors';
import { bns, bnsPercent } from '../lib/bn';
import { 
    BusinessStabilityLabels, 
    CashflowLabels, 
    CeilingSourceFlags, 
    CyclePositionLabels, 
    ReturnLabels,
    IndustryCeilingLabels,
    DemandStageLabels,
    SupplyStageLabels,
    CompanyCeilingLabels,
    ClusterLayerLabels,
    ClientsLabels,
    IndustrialChainPositionImportanceLabels,
    SecurityImportanceLabels,
    CompetitiveAdvantageLabels,
    ManagementLabels,
    CompanyGrowthLabels,
    FieldNames
} from '../lib/enums';
import BigNumberInput from './BigNumberInput';
import { useCoCallback } from '../lib/useCo';
import { createEnum } from '../api/enum';
import { useSnackbar } from 'notistack';
import EnumSearchableSelect from './EnumSearchableSelect';
import SelectField from './SelectField';

const CRNOptions = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map(n => n ? `CR(${n})` : '-');

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    select: {
        width: '100%',
        fontSize: 12,
    },
    input: {
        width: '100%',
    },
    scoreLabel: {
        textAlign: 'center',
        fontSize: 16,
        color: yellow[400],
    },
    scoreValue: {
        textAlign: 'center',
        fontSize: 24,
        color: yellow[400],
    },
    scoreContainer: {
        marginBottom: -theme.spacing(3),
    },
    grid7col: {
        flexGrow: 0,
        maxWidth: '14.285714286%',
        flexBasis: '14.285714286%'
    }
}));

const SupplyStageOrder = [1,2,3,7,4,5,6];

/**
 * 
 * @param {{ 
 *   edit: boolean,
 *   stock: import('../api/stock').StockInfo,
 *   enums: import('../api/enum').Enums,
 *   onChangeEnums: (newEnums: import('../api/enum').Enums) => void,
 *   setStockFields: import('./types').SetStockFieldsCallback
 * }} param0 
 */
function QualityMeasures({ edit, stock, enums, onChangeEnums, setStockFields }) {
    const classes = useStyles();
    const cycleCausationFlagIds = enums.cycleCausationFlagList.filter(cc => (stock.cycle_Causation & cc.cycle_Causation_Flag) !== 0).map(cc => cc.cycle_Causation_Flag);
    const ceilingSourceFlagIds = CeilingSourceFlags.filter(cs => (stock.ceiling_Source & cs.id) !== 0).map(cs => cs.id);
    const [addEnum, setAddEnum] = useState({ open: false, label: "", prefix: "", value: "" });
    const { enqueueSnackbar } = useSnackbar();
    const onSubmitAddEnum = useCoCallback(function*(isCancelled, addEnum){
        try {
            if (!addEnum.value) {
                throw new Error('名称不可为空');
            }
            let enumField;
            switch (addEnum.prefix) {
                case 'industrial_Chain':
                    enumField = 'industrialChainList';
                    break;
                case 'section':
                    enumField = 'sectionList';
                    break;
                case 'product':
                    enumField = 'productList';
                    break;
                case 'sw1':
                    enumField = 'sw1List';
                    break;
                case 'sw2':
                    enumField = 'sw2List';
                    break;
                case 'sw3':
                    enumField = 'sw3List';
                    break;
                default:
                    throw new Error('要创建的项目类型错误');
            }
            const newEnums = yield createEnum(addEnum.prefix.toLowerCase(), { name: addEnum.value });
            enqueueSnackbar('创建成功', { variant: 'success' });
            onChangeEnums({ ...enums, [enumField]: newEnums });
            setAddEnum(r => ({ ...r, open: false }));
        } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    }, [enums]);
    return <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={2}>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="现金流" dot="green" tooltip={<>OPCF/NP:<br/>&lt;0.25,特别坏;<br/>[0.25-0.75),坏;<br/>[0.75-1),一般;<br/>[1-2],好;<br/>2以上,特别好</>}>
                    <SelectField
                        edit={edit}
                        value={stock.cashflow}
                        defaultValue={stock.cashflow_Default}
                        onChange={e => setStockFields({ cashflow: e })}
                        options={CashflowLabels}
                    />
                </FieldLabel>
                <FieldLabel label={`OPCF_Avg(${stock.cnt_FY})`} dot="yellow" plain>
                    {bns(stock.avg_OPCF)}
                </FieldLabel>
                <FieldLabel label={`FCF_Avg(${stock.cnt_FY})`} dot="yellow" plain>
                    {bns(stock.avg_FCF)}
                </FieldLabel>
                <FieldLabel label={`NP_Avg(${stock.cnt_FY})`} dot="yellow" plain>
                    {bns(stock.avg_Profit_Parent_Comp)}
                </FieldLabel>
                <FieldLabel label="OPCF/NP" dot="yellow" plain>
                    {bns(stock.opcf_NP)}
                </FieldLabel>
                <FieldLabel label="FCF/NP" dot="yellow" plain>
                    {bns(stock.fcf_NP)}
                </FieldLabel>
                <FieldLabel label="派息比率" dot="yellow" plain>
                    {bnsPercent(stock.div_Pct)}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="回报" dot="green" tooltip={<>Avg_ROE:<br/>&lt;0,废;<br/>[0-6),低;<br/>(6-12],中;<br/>[12-20],高;<br/>20以上,特别高.</>}>
                    <SelectField
                        edit={edit}
                        options={ReturnLabels}
                        value={stock.return}
                        defaultValue={stock.return_Default}
                        onChange={e => setStockFields({ 'return': e })}
                    />
                </FieldLabel>
                <FieldLabel label={`ROE_Avg(${stock.cnt_FY})`} dot="yellow" plain>
                    {bns(stock.avg_ROE)}
                </FieldLabel>
                <FieldLabel label={`ROE_Vol(${stock.cnt_FY})`} dot="yellow" plain>
                    {bns(stock.vol_ROE)}
                </FieldLabel>
                <FieldLabel label={`ROE-Hi(${stock.cnt_FY})`} dot="yellow" plain>
                    {bns(stock.roe_Hi)}
                </FieldLabel>
                <FieldLabel label={`ROE-Lo(${stock.cnt_FY})`} dot="yellow" plain>
                    {bns(stock.roe_Lo)}
                </FieldLabel>
                <FieldLabel label={`ROIC_Avg(${stock.cnt_FY})`} dot="yellow" plain>
                    {bns(stock.avg_ROIC)}
                </FieldLabel>
                <FieldLabel label={`ROA_Avg(${stock.cnt_FY})`} dot="yellow" plain>
                    {bns(stock.avg_ROA)}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="稳健(生意)" dot="green">
                    <SelectField
                        edit={edit}
                        options={BusinessStabilityLabels}
                        value={stock.business_Stability} 
                        onChange={e => setStockFields({ business_Stability: e })}
                    />
                </FieldLabel>
                <FieldLabel label="稳健(公司)" dot="green" tooltip={<>平均ROE/ROE波动率:<br/>&gt;2,稳;<br/>&lt;=2,波</>}>
                    <SelectField
                        edit={edit}
                        options={BusinessStabilityLabels}
                        value={stock.company_Stability} 
                        defaultValue={stock.company_Stability_Default}
                        onChange={e => setStockFields({ company_Stability: e })}
                    />
                </FieldLabel>
                <FieldLabel label="波源" dot="blue" plain={!edit}>
                    {edit ?
                    <Select 
                        value={cycleCausationFlagIds} multiple className={classes.select}
                        onChange={e => {
                            setStockFields({ cycle_Causation: /** @type {number[]} */(e.target.value).reduce(
                                (a, b) => a | b, 
                                0
                            ) })
                        }}
                        renderValue={flags => /** @type {number[]} */(flags).map(
                            id => enums.cycleCausationFlagList.find(
                                cc => cc.cycle_Causation_Flag === id
                            )?.cycle_Causation_Name
                        ).join(",")}
                    >
                        {enums.cycleCausationFlagList.map(cc => (
                            <MenuItem key={cc.cycle_Causation_Flag} value={cc.cycle_Causation_Flag}>
                                <Checkbox checked={(stock.cycle_Causation & cc.cycle_Causation_Flag) !== 0} />
                                <ListItemText primary={cc.cycle_Causation_Name} />
                            </MenuItem>
                        ))}
                    </Select> : cycleCausationFlagIds.map(
                            id => enums.cycleCausationFlagList.find(
                                cc => cc.cycle_Causation_Flag === id
                            )?.cycle_Causation_Name
                        ).join(",") || '-' }
                </FieldLabel>
                <FieldLabel label="周期位置方向" dot="blue">
                    <SelectField
                        edit={edit}
                        options={CyclePositionLabels}
                        value={stock.cycle_Position} 
                        onChange={e => setStockFields({ cycle_Position: e })}
                    />
                </FieldLabel>
                <FieldLabel label="季度业绩波动" dot="yellow" plain tooltip={<>有季报公司计算近8个季度归母利润增速标准差<br/>无季报公司计算最近四个半年报标准差</>}>
                    {bns(stock.vol_Q8_Profit_Parent_Comp_YOY)}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="天花板(行业)" dot="green">
                    <SelectField
                        edit={edit}
                        options={IndustryCeilingLabels}
                        value={stock.industry_Ceiling} 
                        onChange={e => setStockFields({ industry_Ceiling: e })}
                    />
                </FieldLabel>
                <FieldLabel label="需求阶段" dot="green">
                    <SelectField
                        edit={edit}
                        options={DemandStageLabels}
                        optionOrders={[0,2,1,3]}
                        value={stock.demand_Stage} 
                        onChange={e => setStockFields({ demand_Stage: e })}
                    />
                </FieldLabel>
                <FieldLabel label="渗透率" dot="blue">
                    <BigNumberInput
                        edit={edit}
                        value={stock.penetration_Rate} 
                        onSubmit={v => setStockFields({ penetration_Rate: v })}
                    />
                </FieldLabel>
                <FieldLabel label="格局和方向" dot="green">
                    <SelectField
                        edit={edit}
                        options={SupplyStageLabels}
                        optionOrders={SupplyStageOrder}
                        value={stock.supply_Stage}
                        onChange={e => setStockFields({ supply_Stage: e })}
                    />
                </FieldLabel>
                <FieldLabel
                    label={
                        <SelectField
                            edit={edit}
                            options={CRNOptions}
                            value={stock.cr_N_Or_Default} 
                            onChange={e => setStockFields({ cr_N: e })}
                            underline={edit}
                            disableDefault
                        />
                    } 
                    dot="blue"
                >
                    <BigNumberInput
                        edit={edit}
                        value={stock.cr_N_Value} 
                        onSubmit={v => setStockFields({ cr_N_Value : v })}
                    />
                </FieldLabel>
                <FieldLabel label="天花板(公司)" dot="blue">
                    <SelectField
                        edit={edit}
                        options={CompanyCeilingLabels}
                        value={stock.company_Ceiling} 
                        onChange={e => setStockFields({ company_Ceiling: e })}
                    />
                </FieldLabel>
                <FieldLabel label="天源(公司)" dot="blue" plain={!edit}>
                    {edit ?
                    <Select 
                        value={ceilingSourceFlagIds} multiple  className={classes.select}
                        onChange={e => {
                            setStockFields({ ceiling_Source: /** @type {number[]} */(e.target.value).reduce(
                                (a, b) => a | b, 
                                0
                            ) })
                        }}
                        renderValue={vals => /** @type {number[]} */(vals).map(
                            val => CeilingSourceFlags.find(cs => cs.id === val)?.name
                        ).join(",")}
                    >
                        { CeilingSourceFlags.map(cs => (
                            <MenuItem key={cs.id} value={cs.id}>
                                <Checkbox checked={(stock.ceiling_Source & cs.id) !== 0} />
                                <ListItemText primary={cs.name} />
                            </MenuItem>
                        ))}
                    </Select> : ceilingSourceFlagIds.map(
                            val => CeilingSourceFlags.find(cs => cs.id === val)?.name
                        ).join(",") || '-'}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="潜在增速" dot="blue" tooltip={<>小于0,废;<br/>[0-10),慢;<br/>[10-18),中;<br/>[18-30],快;<br/>30以上,特别快.</>}>
                    <SelectField
                        edit={edit}
                        options={CompanyGrowthLabels}
                        value={stock.company_Growth}
                        defaultValue={stock.company_Growth_Default}
                        onChange={e => setStockFields({ company_Growth: e })}
                    />
                </FieldLabel>
                <FieldLabel label="可期-中" dot="green">
                    <BigNumberInput
                        edit={edit}
                        value={stock.expected_CAGR}
                        onSubmit={v => setStockFields({ expected_CAGR: v })}
                    />
                </FieldLabel>
                <FieldLabel label="可期-上" dot="green">
                    <BigNumberInput
                        edit={edit}
                        value={stock.expected_CAGR_Topline}
                        onSubmit={v => setStockFields({ expected_CAGR_Topline: v })}
                    />
                </FieldLabel>
                <FieldLabel label="可期-下" dot="green">
                    <BigNumberInput
                        edit={edit}
                        value={stock.expected_CAGR_Bottomline}
                        onSubmit={v => setStockFields({ expected_CAGR_Bottomline: v })}
                    />
                </FieldLabel>
                <FieldLabel label={`Rev增速Avg(${stock.cnt_FY})`} dot="blue" plain>
                    {bns(stock.avg_Revenue_YOY)}
                </FieldLabel>
                <FieldLabel label={`NP增速Avg(${stock.cnt_FY})`} dot="yellow" plain>
                    {bns(stock.avg_Profit_Parent_Comp_YOY)}
                </FieldLabel>
                <FieldLabel label={`EPS增速Avg(${stock.cnt_FY})`} dot="blue" plain>
                    {bns(stock.avg_EPS_Diluted_YOY)}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                
                <FieldLabel label="板块" dot="blue" plain={!edit}>
                    { edit ? (
                        <EnumSearchableSelect
                            edit={edit}
                            prefix="section"
                            value={stock.section_ID}
                            enums={enums.sectionList}
                            onChange={v => setStockFields({ section_ID: v })}
                            onAddEnum={() => setAddEnum({ open: true, label: "板块", prefix: "section", value: "" })}
                        />
                    ) : (
                        enums?.sectionList?.find(item => item.section_ID === stock.section_ID)?.section_Name || '-'
                    )}
                </FieldLabel>
                <FieldLabel label="SW1" dot="blue" plain={!edit}>
                    { edit ? (
                        <EnumSearchableSelect
                            edit={edit}
                            prefix="sw1"
                            value={stock.sw1_ID}
                            enums={enums.sw1List}
                            onChange={v => setStockFields({ sw1_ID: v })}
                            onAddEnum={() => setAddEnum({ open: true, label: "SW1", prefix: "sw1", value: "" })}
                        />
                    ) : (
                        enums?.sw1List?.find(item => item.sw1_ID === stock.sw1_ID)?.sw1_Name || '-'
                    )}
                </FieldLabel>
                <FieldLabel label="SW2" dot="blue" plain={!edit}>
                    { edit ? (
                        <EnumSearchableSelect
                            edit={edit}
                            prefix="sw2"
                            value={stock.sw2_ID}
                            enums={enums.sw2List}
                            onChange={v => setStockFields({ sw2_ID: v })}
                            onAddEnum={() => setAddEnum({ open: true, label: "SW2", prefix: "sw2", value: "" })}
                        />
                    ) : (
                        enums?.sw2List?.find(item => item.sw2_ID === stock.sw2_ID)?.sw2_Name || '-'
                    )}
                </FieldLabel>
                <FieldLabel label="SW3" dot="blue" plain={!edit}>
                    { edit ? (
                        <EnumSearchableSelect
                            edit={edit}
                            prefix="sw3"
                            value={stock.sw3_ID}
                            enums={enums.sw3List}
                            onChange={v => setStockFields({ sw3_ID: v })}
                            onAddEnum={() => setAddEnum({ open: true, label: "SW3", prefix: "sw3", value: "" })}
                        />
                    ) : (
                        enums?.sw3List?.find(item => item.sw3_ID === stock.sw3_ID)?.sw3_Name || '-'
                    )}
                </FieldLabel>
                <FieldLabel label="产品服务" dot="blue" plain={!edit}>
                    { edit ? (
                        <EnumSearchableSelect
                            edit={edit}
                            prefix="product"
                            value={stock.product_ID}
                            enums={enums.productList}
                            onChange={v => setStockFields({ product_ID: v })}
                            onAddEnum={() => setAddEnum({ open: true, label: "产品服务", prefix: "product", value: "" })}
                        /> 
                    ) : (
                        enums?.productList?.find(item=>item.product_ID === stock.product_ID)?.product_Name || '-'
                    )}
                   
                </FieldLabel>
                <FieldLabel label="归层" dot="green">
                    <SelectField
                        edit={edit}
                        options={ClusterLayerLabels}
                        optionOrders={[0,1,2,3,4,5,8]}
                        value={stock.cluster_Layer}
                        defaultValue={stock.cluster_Layer_Default}
                        onChange={e => setStockFields({ cluster_Layer: e })}
                    />
                </FieldLabel>
                <FieldLabel label="下游" dot="blue">
                    <SelectField
                        edit={edit}
                        options={ClientsLabels}
                        value={stock.clients} 
                        onChange={e => setStockFields({ clients: e })}
                    />
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel col={0} row={2}>
                    <div className={classes.scoreLabel}>{FieldNames.quality_Score}</div>
                    <div className={classes.scoreValue}>{bns(stock.quality_Score, 1) || '-'}</div>
                </FieldLabel>
                <div style={{height:32, marginBottom:-8}}>&nbsp;</div>
                <FieldLabel label="产业链" dot="blue" plain={!edit}>
                    { edit ? (
                        <EnumSearchableSelect
                            edit={edit}
                            prefix="industrial_Chain"
                            value={stock.industrial_Chain_ID}
                            enums={enums.industrialChainList}
                            onChange={v => {
                                if (v) {
                                    setStockFields({ industrial_Chain_ID: v });
                                } else {
                                    setStockFields({ 
                                        industrial_Chain_ID: v,
                                        industrial_Chain_Position_Importance: 0
                                    });
                                }
                            }}
                            onAddEnum={() => setAddEnum({ open: true, label: "产业链", prefix: "industrial_Chain", value: "" })}
                        />
                    ) : (
                        enums?.industrialChainList?.find(item => item.industrial_Chain_ID === stock.industrial_Chain_ID)?.industrial_Chain_Name || '-'
                    )}
                </FieldLabel>
                <FieldLabel label="环节级别" dot="blue">
                    <SelectField
                        edit={edit}
                        options={IndustrialChainPositionImportanceLabels}
                        value={stock.industrial_Chain_Position_Importance} 
                        onChange={e => setStockFields({ industrial_Chain_Position_Importance: e })}
                    />
                </FieldLabel>
                <FieldLabel label="票级别" dot="blue">
                    <SelectField
                        edit={edit}
                        options={SecurityImportanceLabels}
                        value={stock.security_Importance} 
                        onChange={e => setStockFields({ security_Importance: e })}
                    />
                </FieldLabel>
                <FieldLabel label="利益一致" dot="blue">
                    <SelectField
                        edit={edit}
                        options={ManagementLabels}
                        optionOrders={[0,1,2,4]}
                        value={stock.management} 
                        onChange={e => setStockFields({ management: e })}
                    />
                </FieldLabel>
            </Grid>
        </Grid>
        <Dialog
            open={addEnum.open}
            onClose={() => setAddEnum(v => ({ ...v, open: false }))}
        >
            <DialogTitle>添加{addEnum.label}</DialogTitle>
            <DialogContent>
                <TextField label="名称" value={addEnum.value} onChange={useCallback(e => {
                    const value = e.target.value;
                    setAddEnum(v => ({ ...v, value }));
                }, [])} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setAddEnum(v => ({ ...v, open: false }))}>
                    取消
                </Button>
                <Button onClick={() => onSubmitAddEnum(addEnum)} color="primary">
                    添加
                </Button>
            </DialogActions>
        </Dialog>
    </Paper>    
}

export default memo(QualityMeasures);
