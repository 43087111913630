import { Fab, Paper, Tab, Tabs } from '@material-ui/core';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { FullHeightVirtualScrollTable } from '../../Table';
import { FieldNames } from '../../lib/enums';
import { useApiState } from '../../api/common';
import useErrorMessage from '../../lib/useErrorMessage';
import { useSnackbar } from 'notistack';
import { AddRounded, CheckRounded, CloseRounded } from '@material-ui/icons';
import { bns } from '../../lib/bn';
import { getShortableHKTable, getShortableHKTableVersions, invalidShortableHKTable, validShortableHKTable } from '../../api/shortable-hk';
import { useCoCallback } from '../../lib/useCo';
import { getStockSearchList } from '../../api/stock';
import { urlParent } from '../../lib/url';


/** 
 * @typedef {{
 *   code: string,
 *   name: string,
 *   currency: string,
 * }} ShortableHKRenderedRow 
 */

/**
 * @param {import('../../api/shortable-hk').ShortableHK} r
 * @param {{[code: string]: string}} nameMap
 * @returns { ShortableHKRenderedRow }
 */
function renderRow(r, nameMap) {
    return {
        code: r.code,
        name: nameMap?.[r.code],
        currency: r.currency,
    };
}

/** @type {import('../../Table').ColumnOption<ShortableHKRenderedRow>[]} */
const columns = [
    // === 基本信息 === //
    {
        field: 'code',
        label: FieldNames.code,
        width: 150,
        model: 'string',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code}</Link>
    },
    {
        field: 'name',
        label: FieldNames.name,
        width: 150,
        model: 'string',
    },
    {
        field: 'currency',
        label: '货币类型',
        width: 100,
        model: 'enum',
    }
];

/** 
 * @type {import('../../Table').SorterAndFilters<ShortableHKRenderedRow>} */
const initSorterAndFilters = null;

/** @type {import('../../Table').Fields<ShortableHKRenderedRow>[]} */
const visibleColumns = columns.map(col => col.field);

/** @type {import('../../Table').FullHeightVirtualScrollTableComponent<ShortableHKRenderedRow>} */
// @ts-ignore
const Table = FullHeightVirtualScrollTable;

export default function ShortableHKTable({  }) {
    // @ts-ignore
    const version = useParams()?.version;
    const history = useHistory();
    const [reload, setReload] = useState(0);
    const [versions] = useApiState(getShortableHKTableVersionsReloadable, reload);
    const { url } = useRouteMatch();
    let noVersionUrl = version ? urlParent(url) : url;

    useEffect(() => {
        if (version && versions && Number(version) !== versions.uploaded) {
            history.replace(noVersionUrl);
        }
    }, [version, versions]);

    const [table, loading, error] = useApiState(getShortableHKTableReloadable, version, reload);
    const [stocks] = useApiState(getStockSearchList);
    const nameMap = useMemo(() => {
        /** @type {{[code: string]: string}} */
        const r = {};
        if (!stocks) return r;
        for (const s of stocks) {
            r[s[0]] = s[1];
        }
        return r;
    }, [stocks]);
    
    const data = useMemo(() => (table||[]).map(row => renderRow(row, nameMap)), [table, nameMap]);
    useErrorMessage(error);

    const { enqueueSnackbar } = useSnackbar();
    
    const [sorterAndFilters, setSorterAndFilters] = useState(initSorterAndFilters);
    
    const [submitting, setSubmitting] = useState(false);

    const onMakeValid = useCoCallback(function*(isCancelled, version){
        try {
            setSubmitting(true);
            yield validShortableHKTable(version);
            setSubmitting(false);
            enqueueSnackbar('上传数据已生效');
            history.replace(noVersionUrl);
            setReload(r => r+1);
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, []);

    const onMakeInvalid = useCoCallback(function*(isCancelled, version){
        try {
            setSubmitting(true);
            yield invalidShortableHKTable(version);
            setSubmitting(false);
            enqueueSnackbar('上传数据已取消');
            history.replace(noVersionUrl);
            setReload(r => r+1);
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, []);

    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Paper>
            <Tabs value={version ? 'uploaded' : 'valid' } indicatorColor="primary">
                <Tab value='valid' label="已导入" onClick={() => history.push(noVersionUrl)} />
                {versions?.uploaded ? (
                    <Tab value='uploaded' label="等待导入" onClick={() => history.push(`${noVersionUrl}/${versions?.uploaded}`)} />
                ):null}
            </Tabs>
        </Paper>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, display: 'flex' }}>
                <Table
                    data={data}
                    columns={columns}
                    sorterAndFilters={sorterAndFilters}
                    onSorterAndFiltersChange={setSorterAndFilters}
                    variant="full"
                    visibleColumnOrder={visibleColumns}
                />
            </div>
        </div>
        <div style={{height:60}}>
            <Link to={`${url}/add`}>
                <Fab
                    size="small" 
                    color="primary" 
                    style={{ position: 'fixed', bottom: 10, left: 20 }}
                    disabled={submitting}
                >
                    <AddRounded />
                </Fab>
            </Link>
            { version ? (
                <Fab
                    size="small" 
                    style={{ position: 'fixed', bottom: 10, right: 80 }} 
                    onClick={() => onMakeInvalid(version)}
                    disabled={submitting}
                >
                    <CloseRounded />
                </Fab>
            ) : null }
            { version ? (
                <Fab
                    size="small" 
                    color="primary" 
                    style={{ position: 'fixed', bottom: 10, right: 20 }} 
                    onClick={() => onMakeValid(version)}
                    disabled={submitting}
                >
                    <CheckRounded />
                </Fab>
            ) : null }
        </div>
    </div>
}

function getShortableHKTableReloadable(version, ignored) {
    return getShortableHKTable(version || 0);
}

function getShortableHKTableVersionsReloadable(ignored) {
    return getShortableHKTableVersions();
}
