import { makeStyles } from '@material-ui/core';
import { green, grey, lightBlue, red, yellow } from '@material-ui/core/colors';
import React from 'react';

const dotStyles = {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginLeft: 6,
}

const useStyles = makeStyles(theme => ({
    red: {
        ...dotStyles,
        backgroundColor: red[400],
    },
    green: {
        ...dotStyles,
        backgroundColor: green[400],
    },
    yellow: {
        ...dotStyles,
        backgroundColor: yellow[400],
    },
    blue: {
        ...dotStyles,
        backgroundColor: lightBlue[400],
    },
    grey: {
        ...dotStyles,
        backgroundColor: grey[400],
    },
    none: {
        ...dotStyles,
    },
}));

export default function Dot({ color = "none" }) {
    const classes = useStyles();
    return <div className={classes[color]}/>;
}