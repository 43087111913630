import { Button, CircularProgress, LinearProgress, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApiState } from '../../api/common';
import { getStockCodesWithExchanges } from '../../api/stock';
import { commitStockReportUploadCNTable, createStockReportUploadCNTable, uploadStockReportUploadCNTableRow } from '../../api/stock-report-upload-cn';
import { dayNumToQuarterNum, parseDate, quarterNumToDayNum, toDate } from '../../lib/date';
import { readSheetFromFile } from '../../lib/excel';
import { makeParser } from '../../lib/importer';
import { useCoCallback } from '../../lib/useCo';

/** @typedef {{
 *    code: string,
 *    report_Date: string,
 *    title: string,
 *    theme: string,
 *    release_Time: string,
 *    parsed: string
 * }} ParsedRow
 */

const headerMap = {
    '公告日期': 'report_Date',
    '证券代码': 'code',
    '公告标题': 'title',
    '主题': 'theme',
    '发布时间': 'release_Time',
    '业绩类型': 'parsed',
}

const createParser = (/** @type {string[]} */codes) => makeParser(builder => {
    return {
        code: val => {
            if (codes.indexOf(val) < 0) {
                return [false, `股票代码无效或不存在：${val}`];
            }
            return [true, val];
        },
        report_Date: builder.requiredStringWithPattern(/^\d\d\d\d-\d?\d-\d?\d$/, '单元格不是正确的日期格式：${value}'),
        title: val => {
            const match = /[(（](.+)[)）]\s*$/.exec(val);
            if (match) {
                return [false, `忽略特殊版本的公告(${match[1]})`];
            }
            return [true, val];
        },
        theme: val => {// parsed_Type parsed_Quarter_Or_Semi
            switch (val) {
                case '业绩预告':
                    return [true, '1 1 业绩预告'];
                case '业绩快报':
                    return [true, '2 1 业绩快报'];
                case '季度报告':
                    return [true, '3 1 季度报告'];
                case '半年报告':
                    return [true, '3 2 半年报告'];
                case '年度报告':
                    return [true, '3 1 年度报告 4'];
                default:
                    return [false, `无法识别的主题类型：${val}`];
            }
        },
        release_Time: builder.requiredStringWithPattern(/^\d\d\d\d-\d?\d-\d?\d\s+\d?\d:\d?\d$/, '单元格不是正确的时间格式'),
        parsed: val => {
            if (!val) return [true, ''];
            const match = /(\d)(Q|S)(\d\d)(预|快|财)/.exec(val);
            if (!match) {
                return [false, `业绩类型格式错误：${val}`];
            }
            const parsed_Type = ({'预': 1, '快': 2, '财': 3})[match[4]];
            let parsed_Quarter;
            let parsed_Quarter_Or_Semi;
            if (match[2] === 'Q') {
                parsed_Quarter_Or_Semi = 1;
                parsed_Quarter = Number(match[3])*4 + Number(match[1]) - 1;
            } else {//match[2] === 'S'
                parsed_Quarter_Or_Semi = 2;
                parsed_Quarter = Number(match[3])*4 + (Number(match[1]) * 2) - 1;
            }
            return [true, `${parsed_Type} ${parsed_Quarter} ${parsed_Quarter_Or_Semi}`];
        }
    };
}, /** @type { ParsedRow } */(null));

const exchanges = ['SSE', 'SZSE', "BJEX"];

export default function StockReportUploadCNTableImport() {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [codes] = useApiState(getStockCodesWithExchanges, exchanges);
    const [warnings, setWarnings] = useState(/** @type {import('../../lib/importer').ErrorMessage[]} */([]));
    const [errors, setErrors] = useState(/** @type {import('../../lib/importer').ErrorMessage[]} */([]));
    const [parseErrors, setParseErrors] = useState(/** @type {string[]} */([]));
    const [data, setData] = useState(/** @type {ParsedRow[]} */(null));
    const [originalRows, setOriginalRows] = useState(0);
    const [importSuccessCount, setImportSuccessCount] = useState(0);
    const [importErrorCount, setImportErrorCount] = useState(0);
    const [importErrors, setImportErrors] = useState(/** @type {string[]} */([]));
    const [fileSelectKey, setFileSelectKey] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [importResult, setImportResult] = useState(0);

    const parser = useMemo(() => createParser(codes), [codes]);
    
    function reset() {
        setWarnings([]);
        setErrors([]);
        setParseErrors([]);
        setData(null);
        setOriginalRows(0);
        setImportSuccessCount(0);
        setImportErrorCount(0);
        setImportErrors([]);
        setFileSelectKey(k => k + 1);
        setSubmitting(false);
        setImportResult(0);
    }

    const onSelect = useCoCallback(function*(_isCancelled, event) {
        try {
            setWarnings([]);
            setErrors([]);
            setParseErrors([]);
            setData(null);
            const sheet = /** @type {string[][]} */(yield readSheetFromFile(event.target.files[0]));
            const parseResult = parser(sheet, 1, 0, headerMap);
            setData(parseResult.data);
            setWarnings(parseResult.warnings);
            setErrors(parseResult.errors);
            setParseErrors(parseResult.parseErrors);
            setOriginalRows(sheet.length - 1);
        } catch (e) {
            console.warn(e);
            setErrors([{ message: e.message }]);
        }
    }, [parser]);

    const onSubmit = useCoCallback(function*(isCancelled, /** @type {ParsedRow[]} */ data){
        try {
            setSubmitting(true);
            let anyError = false;
            const version = /** @type { number } */(yield createStockReportUploadCNTable());
            const rows = /** @type {Omit<import('../../api/stock-report-upload-cn').StockReportUploadCN, "id" | "version" | "conflict">[]} */([]);
            for (const item of data) {
                let parsed_Type;
                let parsed_Quarter;
                let parsed_Quarter_Or_Semi;
                const themeArr = item.theme.split(' ');
                const theme = themeArr[2];
                const [_,
                    year, month, date,
                    hour, minute
                ] = /^(\d\d\d\d)-(\d?\d)-(\d?\d)\s+(\d?\d):(\d?\d)$/.exec(item.release_Time);
                const release_Time = moment({
                    year: Number(year),
                    month: Number(month) - 1,
                    date: Number(date),
                    hour: Number(hour),
                    minute: Number(minute)
                });
                if (item.parsed) {
                    const arr = item.parsed.split(' ').map(Number);
                    parsed_Type = arr[0];
                    parsed_Quarter = arr[1];
                    parsed_Quarter_Or_Semi = arr[2];   
                } else {
                    const match = /([二2][零0][0-9零一二三四五六七八九]{2})年?第?(?:([一二三四1234])季度|年度)/.exec(item.title);
                    //             (1:year                              )        (2:quarter     )
                    parsed_Type = Number(themeArr[0]);
                    parsed_Quarter_Or_Semi = Number(themeArr[1]);
                    const quarter_hint = Number(themeArr[3]);
                    if (match) {
                        const year = replaceChineseNumbers(match[1]);
                        const q = match[2] ? replaceChineseNumbers(match[2]) : 4;
                        parsed_Quarter = (Number(year) - 2000) * 4 + Number(q) - 1;
                    } else {
                        const dayNum = toDate(release_Time);
                        parsed_Quarter = dayNumToQuarterNum(dayNum) - 1;
                    }
                    if (quarter_hint) {
                        parsed_Quarter = Math.floor(parsed_Quarter / 4) * 4 + quarter_hint - 1;
                    } else if (parsed_Quarter_Or_Semi === 2 && parsed_Quarter % 2 === 0) {
                        //半年报只允许2，4季度的
                        parsed_Quarter += 1;
                    }
                }
                const report_Date = toDate(parseDate(item.report_Date));
                rows.push({
                    code: item.code,
                    report_Date,
                    title: item.title,
                    theme,
                    release_Time: release_Time.valueOf(),
                    parsed_Type: /** @type {1|2|3} */(parsed_Type),
                    parsed_Quarter,
                    parsed_Quarter_Or_Semi: /** @type {1|2} */(parsed_Quarter_Or_Semi)
                });
            }
            const conflicts = /** @type { { [code_type_quarter_quarter_or_semi: string]: number[] } } */ ({});
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                const key = `${row.code}-${row.parsed_Type}-${row.parsed_Quarter}-${row.parsed_Quarter_Or_Semi}`
                let arr = conflicts[key];
                if (!arr) {
                    arr = [];
                    conflicts[key] = arr;
                }
                arr.push(i);
            }
            for (let i = 0; i < rows.length; i++) {
                try {
                    const row = rows[i];
                    const key = `${row.code}-${row.parsed_Type}-${row.parsed_Quarter}-${row.parsed_Quarter_Or_Semi}`;
                    const arr = conflicts[key];
                    let conflict = /** @type { 0 | 1 | 2} */ (0);
                    if (arr && arr.length > 1) {
                        if (i === arr[0]) {
                            conflict = 1;
                        } else {
                            conflict = 2;
                        }
                    }
                    const { code, ...rest } = row;
                    yield uploadStockReportUploadCNTableRow(
                        version, code, {
                            ...rest,
                            conflict
                        }
                    );
                    setImportSuccessCount(c => c + 1);
                } catch (e) {
                    console.warn(e);
                    setImportErrorCount(c => c + 1);
                    setImportErrors(errs => [...errs, e.message]);
                    anyError = true;
                    break;
                }
            }
            if (!anyError) {
                yield commitStockReportUploadCNTable(version);
                setImportResult(1);
                enqueueSnackbar("上传成功", { variant: 'success' });
                history.replace('/user-table/stock-report-upload-cn/' + version);
            } else {
                setImportResult(2);
            }
            setSubmitting(false);
        } catch (e) {
            console.warn(e);
            setImportErrors(es => [...es, e.message]);
            setImportResult(2);
            setSubmitting(false);
        }
    }, []);
    if (!codes) {
        return (
            <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
                <CircularProgress color="primary" />
            </div>
        );
    }
    return (
        <Paper style={{flex:1, marginBottom: 20, padding: 20}}>
            <div style={{marginBottom: 16}}>
                <input key={fileSelectKey} disabled={data?.length > 0} type="file" style={{fontSize: 16}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv" onChange={onSelect} />
            </div>
            <div>
                { parseErrors?.length ? ( parseErrors.map((e, i) => (
                    <Alert severity="error" key={i}>
                        { e }
                    </Alert>
                ))) : null}
                { data ? (<div>
                    { data.length === 0 && originalRows === 0 ? (
                        <Alert severity="error">
                            导入的表格中没有数据
                        </Alert>
                    ) : null}
                    { data.length === 0 && originalRows !== 0 ? (
                        <Alert severity="error">
                            原始数据量：{ originalRows }<br/>
                            没有可导入的数据，原因如下：
                            {errors?.length ? <span><br/>以下单元格的数据解析出错：</span> : null }
                            {errors?.length ? errors.map((e,i) => (
                                <span key={i}>
                                    <br/>{ e.ref }：{ e.message }
                                </span>
                            )) : null}
                        </Alert>
                    ) : null}
                    { data.length !== 0 && data.length < originalRows ? (
                        <Alert severity="warning">
                            原始数据量：{ originalRows }<br/>
                            可导入数据量：{ data.length }<br/>
                            {errors?.length ? '以下单元格的数据解析出错：' : null }
                            {errors?.length ? errors.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e.ref }：{ e.message }
                                    </span>
                                </span>
                            )) : null}
                        </Alert>
                    ) : null}
                    { data.length !== 0 && data.length === originalRows ? (
                        <Alert severity="success">
                            原始数据量：{ originalRows }<br/>
                            可导入数据量：{ data.length }
                        </Alert>
                    ) : null}
                    { warnings.length ? (
                        <Alert severity="warning">
                            以下单元格可能有问题，但不影响导入：
                            {warnings.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e.ref }：{ e.message }
                                    </span>
                                </span>
                            ))}
                        </Alert>
                    ) : null }
                </div>) : null}
            </div>
            { data?.length > 0 ? (<div style={{marginTop: 20}}>
                <div>
                    <LinearProgress
                        style={{marginTop: 16, marginBottom: 16}}
                        color="primary"
                        variant="determinate"
                        value={(importSuccessCount + importErrorCount) * 100 / data.length}
                    />
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{flex:1}}>
                        导入进度：{importSuccessCount + importErrorCount}/{data.length}
                    </div>
                    <Button disabled={submitting} variant="contained" onClick={reset}>
                        重置
                    </Button>
                    <Button
                        disabled={submitting || importResult > 0 }
                        style={{marginLeft: 16}} 
                        variant="contained"
                        color="primary" 
                        onClick={()=>onSubmit(data)}
                    >
                        开始导入
                    </Button>
                </div>
                <div>
                    {importResult === 1 ? (
                        <Alert severity="success">导入完成</Alert>
                    ):null}
                    {importResult === 2 ? (
                        <Alert severity="warning">导入失败，下方是详细错误信息：</Alert>
                    ):null}
                </div>
                <div>
                    { importErrors.length > 0 ? (
                        <Alert severity="error">
                            导入数据过程中发生以下错误：
                            {importErrors.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e }
                                    </span>
                                </span>
                            ))}
                        </Alert>
                    ) : null}
                </div>
            </div>) : null}
        </Paper>
    );
}

function replaceChineseNumbers(str) {
    return str
        .replace(/零/g, '0')
        .replace(/一/g, '1')
        .replace(/二/g, '2')
        .replace(/三/g, '3')
        .replace(/四/g, '4')
        .replace(/五/g, '5')
        .replace(/六/g, '6')
        .replace(/七/g, '7')
        .replace(/八/g, '8')
        .replace(/九/g, '0')
}
