import { get, post } from "./common";
import BigDecimal from 'js-big-decimal';
import { bn } from "../lib/bn";

/**
 * @returns { Promise<{valid: number, uploaded: number }> }
 */
 export async function getShortableCNTableVersions() {
    return await get('/api/user-table/shortable-cn/version')
}

/**
 * @typedef {{
 *   id: number,
 *   code: string,
 *   version: number,
 *   type: 0 | 1 | 2,
 *   gics: string,
 *   total_Shares: BigDecimal,
 *   total_USD: BigDecimal,
 *   weighted_Rate: BigDecimal,
 *   baml_Rate: BigDecimal,
 *   baml_USD: BigDecimal,
 *   baml_Shares: BigDecimal,
 *   citi_Rate: BigDecimal,
 *   citi_USD: BigDecimal,
 *   citi_Shares: BigDecimal,
 *   clsa_Rate: BigDecimal,
 *   clsa_USD: BigDecimal,
 *   clsa_Shares: BigDecimal,
 *   cs_Rate: BigDecimal,
 *   cs_USD: BigDecimal,
 *   cs_Shares: BigDecimal,
 *   hsbc_Rate: BigDecimal,
 *   hsbc_USD: BigDecimal,
 *   hsbc_Shares: BigDecimal,
 *   jpm_Rate: BigDecimal,
 *   jpm_USD: BigDecimal,
 *   jpm_Shares: BigDecimal,
 *   ubs_Rate: BigDecimal,
 *   ubs_USD: BigDecimal,
 *   ubs_Shares: BigDecimal,
 *   cicc_Rate: BigDecimal,
 *   cicc_USD: BigDecimal,
 *   cicc_Shares: BigDecimal,
 * }} ShortableCN
 */

/**
 * @returns { Promise<number> }
 */
export async function createShortableCNTable() {
    return await post('/api/user-table/shortable-cn');
}

/**
 * @param {string} version
 * @returns { Promise<ShortableCN[]> }
 */
export async function getShortableCNTable(version) {
    const list = /** @type{any[]} */(await get('/api/user-table/shortable-cn', { version }));
    return list.map(s => ({
        id: s.id,
        code: s.code,
        version: s.version,
        type: s.type,
        gics: s.gics,
        total_Shares: bn(s.total_Shares),
        total_USD: bn(s.total_USD),
        weighted_Rate: bn(s.weighted_Rate),
        baml_Rate: bn(s.baml_Rate),
        baml_USD: bn(s.baml_USD),
        baml_Shares: bn(s.baml_Shares),
        citi_Rate: bn(s.citi_Rate),
        citi_USD: bn(s.citi_USD),
        citi_Shares: bn(s.citi_Shares),
        clsa_Rate: bn(s.clsa_Rate),
        clsa_USD: bn(s.clsa_USD),
        clsa_Shares: bn(s.clsa_Shares),
        cs_Rate: bn(s.cs_Rate),
        cs_USD: bn(s.cs_USD),
        cs_Shares: bn(s.cs_Shares),
        hsbc_Rate: bn(s.hsbc_Rate),
        hsbc_USD: bn(s.hsbc_USD),
        hsbc_Shares: bn(s.hsbc_Shares),
        jpm_Rate: bn(s.jpm_Rate),
        jpm_USD: bn(s.jpm_USD),
        jpm_Shares: bn(s.jpm_Shares),
        ubs_Rate: bn(s.ubs_Rate),
        ubs_USD: bn(s.ubs_USD),
        ubs_Shares: bn(s.ubs_Shares),
        cicc_Rate: bn(s.cicc_Rate),
        cicc_USD: bn(s.cicc_USD),
        cicc_Shares: bn(s.cicc_Shares),
    }));
}

/**
 * @param { number } version
 * @param { string } codePrefix
 * @param { Omit<ShortableCN, 'id'|'code'|'version'> } row
 * @returns { Promise<number> }
 */
export async function uploadShortableCNTableRow(version, codePrefix, row) {
    const {
        type, gics, 
        total_Shares, total_USD, weighted_Rate, 
        baml_Rate, baml_USD, baml_Shares, 
        citi_Rate, citi_USD, citi_Shares, 
        clsa_Rate, clsa_USD, clsa_Shares, 
        cs_Rate, cs_USD, cs_Shares, 
        hsbc_Rate, hsbc_USD, hsbc_Shares, 
        jpm_Rate, jpm_USD, jpm_Shares, 
        ubs_Rate, ubs_USD, ubs_Shares,
        cicc_Rate, cicc_USD, cicc_Shares
    } = row;
    const data = {
        codePrefix,
        type, gics, 
        total_Shares: total_Shares?.getValue(), total_USD: total_USD?.getValue(), weighted_Rate: weighted_Rate?.getValue(), 
        baml_Rate: baml_Rate?.getValue(), baml_USD: baml_USD?.getValue(), baml_Shares: baml_Shares?.getValue(), 
        citi_Rate: citi_Rate?.getValue(), citi_USD: citi_USD?.getValue(), citi_Shares: citi_Shares?.getValue(), 
        clsa_Rate: clsa_Rate?.getValue(), clsa_USD: clsa_USD?.getValue(), clsa_Shares: clsa_Shares?.getValue(), 
        cs_Rate: cs_Rate?.getValue(), cs_USD: cs_USD?.getValue(), cs_Shares: cs_Shares?.getValue(), 
        hsbc_Rate: hsbc_Rate?.getValue(), hsbc_USD: hsbc_USD?.getValue(), hsbc_Shares: hsbc_Shares?.getValue(), 
        jpm_Rate: jpm_Rate?.getValue(), jpm_USD: jpm_USD?.getValue(), jpm_Shares: jpm_Shares?.getValue(), 
        ubs_Rate: ubs_Rate?.getValue(), ubs_USD: ubs_USD?.getValue(), ubs_Shares: ubs_Shares?.getValue(),
        cicc_Rate: cicc_Rate?.getValue(), cicc_USD: cicc_USD?.getValue(), cicc_Shares: cicc_Shares?.getValue()
    };
    return await post(`/api/user-table/shortable-cn/${version}/row`, data);
}

/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function commitShortableCNTable(version) {
    return await post(`/api/user-table/shortable-cn/${version}/commit`);
}


/**
 * @param { number } version
 * @returns { Promise<number> }
 */
 export async function validShortableCNTable(version) {
    return await post(`/api/user-table/shortable-cn/${version}/valid`);
}


/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function invalidShortableCNTable(version) {
    return await post(`/api/user-table/shortable-cn/${version}/invalid`);
}
