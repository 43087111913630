import { Button, Dialog, DialogActions, makeStyles, Typography, Popover, CircularProgress  } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createComment, deleteComment, updateComment, updateCommentFields } from '../api/comment';
import PopupMenuSelect from './PopupMenuSelect';
import RichText from './RichText';
import { today } from '../lib/date';
import { useCoCallback } from '../lib/useCo';
import { useStockIsEditable } from '../SingleStock/StockIsEditable';
import { bn, bns, eq } from '../lib/bn';
import bigDecimal from 'js-big-decimal';
import TicketCommentView from '../Ticket/TicketCommentView';

/** 
 * @typedef { { 
 *   commentId: number, 
 *   reportId: number, 
 *   date: number,
 * } } CommentDialogState
 */

const useStyles = makeStyles(_theme => ({
    paper: {
        width: '80%',
        maxWidth: '80%',
    },
    paperTicket: {
        width: '95%',
        maxWidth: '95%',
    },
    input: {
        height: 36,
        background: 'transparent',
        border: 'none',
        outline: 'none',
        borderRadius: 4,
        letterSpacing: '0.02857em',
        color: 'white',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        textAlign: 'center',
        '&:hover, &:focus': {
            background: 'rgba(255, 255, 255, 0.08)'
        }
    },
    label: {
        height: 36,
        lineHeight: '36px',
        borderRadius: 4,
        letterSpacing: '0.02857em',
        color: 'white',
        fontWeight: 'normal',
        textAlign: 'center', 
        display: 'inline-block',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.08)'
        }
    }
}));

const ShortWishlistOptions = [
    { id: 0, name: '-' },
    { id: 1, name: '是' },
    { id: 2, name: '否' }
];

const ShortWishlistLabels = ['空', '空: 是', '空: 否'];

const QualityScoreOptions = [
    { id: "", name: "-" },
    { id: "1.5", name: "1.5" },
    { id: "1.0", name: "1.0" },
    { id: "0.5", name: "0.5" },
    { id: "0.0", name: "0.0" },
    { id: "-0.5", name: "-0.5" },
    { id: "-1.0", name: "-1.0" },
];

const TrendScoreOptions = [
    { id: "", name: "-" },
    { id: "1.0", name: "1.0" },
    { id: "0.5", name: "0.5" },
    { id: "0.0", name: "0.0" },
    { id: "-0.5", name: "-0.5" },
    { id: "-1.0", name: "-1.0" },
];

const PricingScoreOptions = [
    { id: "", name: "-" },
    { id: "1.0", name: "1.0" },
    { id: "0.5", name: "0.5" },
    { id: "0.0", name: "0.0" },
    { id: "-0.5", name: "-0.5" },
    { id: "-1.0", name: "-1.0" },
];

const ExternalLabels = ['外', '外: -1', '外: 0', '外: 1', '外: -0.5', '外: 0.5'];
const ExternalOptions = [
    { id: 0, name: "-" },
    { id: 3, name: "1" },
    { id: 5, name: "0.5" },
    { id: 2, name: "0" },
    { id: 4, name: "-0.5" },
    { id: 1, name: "-1" },
];
const PowerLabels = ['票', '票: -1', '票: 0', '票: 1', '票: -0.5', '票: 0.5'];
const PowerOptions = [
    { id: 0, name: "-" },
    { id: 3, name: "1" },
    { id: 5, name: "0.5" },
    { id: 2, name: "0" },
    { id: 4, name: "-0.5" },
    { id: 1, name: "-1" },
];
const StatusLabels = ['多状态', 'Call', 'Watch', 'Short', 'Pass'];
const StatusOptions = [
    { id: 1, name: "Call" },
    { id: 2, name: "Watch" },
    { id: 4, name: "Pass" },
];

function CommentDialogTextInput({ label, value, onChange, onBlur }) {
    const classes = useStyles();
    const [focus, setFocus] = useState(false);
    /** @type {React.MutableRefObject<HTMLInputElement>} */
    const ref = useRef();
    useEffect(()=>{
        if (focus && ref.current) {
            ref.current.select();
        }
    }, [focus]);
    const text = focus ? value : `${label} ${value}`;
    return <input
        ref={ref}
        onFocus={(e)=>{
            setFocus(true);
        }}
        onBlur={(e)=>{
            setFocus(false);
            if (onBlur) onBlur(e);
        }}
        style={{fontWeight: 'normal', width: 100}}
        type="text"
        className={classes.input}
        value={text}
        onChange={e => {
            if (/^[0-9]*\.?[0-9]{0,2}$/.test(e.target.value)) {
                onChange(e.target.value);
            }
        }}
    />
}

/**
 * @typedef {{
 *   quality_Score?: string, 
 *   trend_Score?: string, 
 *   pricing_Score?: string, 
 *   external?: number,
 *   power?: number,
 *   short_Wishlist?: number
 *}} OverrideFields
 * @type { OverrideFields }
 */
const emptyOverrideFields = {};

/** @type { bigDecimal } */
const undefinedBigDecimal = undefined;

/**
 * @param {{
 *   stock?: import('../api/stock').StockInfo,
 *   show: boolean,
 *   onClose: () => void,
 *   code?: string,
 *   comment: import("../api/comment").Comment,
 *   onReloadComment?: (commentId?: number) => void,
 *   onReloadReport?: (reportId?: number) => void,
 *   onReloadStock?: (code: string) => void,
 *   onChangeStockStatus?: (status: number, reportId: number, commentId: number, date: number) => void,
 *   onChangeCommentDialog?: (opts: CommentDialogState) => void,
 *   reportId?: number,
 *   date?: number,
 *   changeStatusDisabled?: boolean,
 *   editDisabled?: boolean,
 *   closeWithBackdropClickAndEscapeKeyEnabled?: boolean
 * }} param0 
 */
export function CommentDialog({
    stock,
    show, 
    code, 
    comment,
    reportId, 
    date, 
    changeStatusDisabled = false,
    editDisabled,
    closeWithBackdropClickAndEscapeKeyEnabled,

    onClose, 
    onReloadComment, 
    onReloadReport, 
    onReloadStock,

    onChangeStockStatus, 
    onChangeCommentDialog,
}) {
    const [busy, setBusy] = useState(false);
    const content = useRef(comment?.content);
    const [disabled, setDisabled] = useState(Boolean(comment));
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [deleteEl, setDeleteEl] = React.useState(null);
    const [overrideFields, setOverrideFields] = useState(emptyOverrideFields);

    const [reloadCommendIdOnClose, setReloadCommendIdOnClose] = useState(0);
    const [reloadStockOnClose, setReloadStockOnClose] = useState("");

    const onCloseAndReload = useCallback(() => {
        if (reloadCommendIdOnClose && onReloadComment) {
            onReloadComment(reloadCommendIdOnClose);
        }
        if (reloadStockOnClose && onReloadStock) {
            onReloadStock(reloadStockOnClose);
        }
        onClose();
    }, [onClose, onReloadComment, onReloadStock, reloadCommendIdOnClose, reloadStockOnClose])
    const handleSubmit = useCoCallback(function*(){
        try {
            if (!content.current) {
                throw new Error('评论内容不能为空');
            }
            setBusy(true);
            if (comment) {
                yield updateComment(comment.id, content.current);
                enqueueSnackbar('评论修改成功', { variant: 'success' });
                setBusy(false);
                onReloadComment(comment.id);
                setDisabled(true);
            } else {
                const { commentId: newCommentId, reportId: newReportId } = yield createComment(code, reportId, date, content.current);
                if (newReportId !== 0) {
                    onReloadReport(newReportId);
                }
                setBusy(false);
                onChangeCommentDialog({ 
                    commentId: newCommentId,
                    reportId,
                    date,
                });
                onReloadComment(newCommentId);
                setReloadStockOnClose(code);
                setDisabled(true);
                enqueueSnackbar('评论发表成功', { variant: 'success' });
            }
        } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
            setBusy(false);
        }
    }, [code, reportId, comment, content, date]);
    const handleDelete = useCoCallback(function*(){
        try {
            setDeleteEl(null);
            setBusy(true);
            yield deleteComment(comment.id);
            setBusy(false);
            enqueueSnackbar('评论删除成功', { variant: 'success' });
            onReloadStock(comment.code);
            onReloadComment(comment.id);
            onClose();
        } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
            setBusy(false);
        }
    }, [comment]);
    const handleUpdateCommentField = useCoCallback(function*(isCancelled, /** @type {OverrideFields } */fields) {
        try {
            const values = { ...fields };
            for (const key of Object.keys(values)) {
                if (values[key] === "-") {
                    values[key] = "";
                }
            }
            setBusy(true);
            yield updateCommentFields(comment.id, fields);
            setBusy(false);
            setOverrideFields(s => ({ ...s, ...fields}));
            setReloadCommendIdOnClose(comment.id);
            setReloadStockOnClose(comment.code);
            enqueueSnackbar('更新成功', { variant: 'success' });
        } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
            setBusy(false);
        }
    }, [comment]);
    
    const stockIsEditable = useStockIsEditable(stock);
    const [valuations, setValuations] = useState({ 
        valuation_Price_Low_Old: "",
        valuation_Price_Low: "", 
        valuation_Price_High_Old: "",
        valuation_Price_High: "",
    });

    useEffect(() => {
        if (comment) {
            setValuations({
                valuation_Price_Low_Old: bns(comment.valuation_Price_Low),
                valuation_Price_Low: bns(comment.valuation_Price_Low),
                valuation_Price_High_Old: bns(comment.valuation_Price_High),
                valuation_Price_High: bns(comment.valuation_Price_High)
            });
        }
    }, [comment]);
    
    const trend_Score = bns(
        overrideFields.trend_Score || 
        (overrideFields.trend_Score !== "" && comment?.trend_Score),
        1
    );
    
    const quality_Score = bns(
        overrideFields.quality_Score || 
        (overrideFields.quality_Score !== "" && comment?.quality_Score),
        1
    );
    
    const pricing_Score = bns(
        overrideFields.pricing_Score ||
        (overrideFields.pricing_Score !== "" && comment?.pricing_Score),
        1
    );

    const external = overrideFields?.external ?? comment?.external;
    const power = overrideFields?.power ?? comment?.power;
    const short_Wishlist = overrideFields?.short_Wishlist ?? comment?.short_Wishlist;

    const handleUpdateValuation = useCoCallback(function*(isCancelled, /** @type {"valuation_Price_Low" | "valuation_Price_High"} */key, /** @type {string} */value) {
        try {
            let str = "";
            let n = undefinedBigDecimal;
            if (value) {
                n = bn(value);
                str = bns(n);
                if (!str) {
                    setValuations(s => ({ ...s, [key]: "" }))
                    throw new Error('数值格式错误');
                }
            }
            const fields = { [key]: str };
            setBusy(true);
            yield updateCommentFields(comment.id, fields);
            setBusy(false);
            setValuations(s => ({ ...s, [key]: str, [`${key}_Old`]: str }));
            setReloadCommendIdOnClose(comment.id);
            setReloadStockOnClose(comment.code);
            enqueueSnackbar('更新成功', { variant: 'success' });
        } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
            setBusy(false);
        }
    }, [comment, valuations, stock]);

    if (comment?.ticket_Comment_ID) {
        return (
            <TicketCommentView
                ticketCommentId={comment.ticket_Comment_ID} 
                variant='dialog'
                dialogOpen={show}
                onCloseDialog={onCloseAndReload}
                onTicketCommentDeleted={() => onReloadComment(comment.id)}
            />
        );
    }

    return (
        <Dialog open={show} classes={{ paper: classes.paper }} onClose={() => { if (closeWithBackdropClickAndEscapeKeyEnabled) { onCloseAndReload() }}}>
            <RichText
                disabled={disabled || busy}
                initialValue={content.current}
                onChange={v => content.current = v}
            />
            <DialogActions style={{position: 'sticky', left: 0, right: 0, bottom: 0, background: '#424242', zIndex: 1 }}>
                { (comment && !changeStatusDisabled) ? (
                    <>  
                        <PopupMenuSelect
                            style={{fontWeight: 'normal', width: 100}}
                            disabled={!stockIsEditable || busy}
                            value={quality_Score}
                            onChange={v => handleUpdateCommentField({ quality_Score: v })}
                            options={QualityScoreOptions}
                            text={quality_Score ? `质量: ${quality_Score}` : "质量"}
                        />
                        <PopupMenuSelect
                            style={{fontWeight: 'normal', width: 100}}
                            disabled={!stockIsEditable || busy}
                            value={trend_Score}
                            onChange={v => handleUpdateCommentField({ trend_Score: v })}
                            options={TrendScoreOptions}
                            text={trend_Score ? `动能: ${trend_Score}` : "动能"}
                        />
                        <PopupMenuSelect
                            style={{fontWeight: 'normal', width: 100}}
                            disabled={!stockIsEditable || busy}
                            value={pricing_Score}
                            onChange={v => handleUpdateCommentField({ pricing_Score: v})}
                            placeholder={"估值"}
                            options={PricingScoreOptions}
                            text={pricing_Score ? `估值: ${pricing_Score}` : "估值"}
                        />
                        <PopupMenuSelect
                            style={{fontWeight: 'normal', width: 50}}
                            disabled={!stockIsEditable || busy}
                            value={external}
                            onChange={v => handleUpdateCommentField({ external: v })}
                            placeholder="外"
                            options={ExternalOptions}
                            text={ExternalLabels[external]}
                        />
                        <PopupMenuSelect
                            style={{fontWeight: 'normal', width: 50}}
                            disabled={!stockIsEditable || busy}
                            value={power}
                            onChange={v => handleUpdateCommentField({ power: v })}
                            placeholder="票"
                            options={PowerOptions}
                            text={PowerLabels[power]}
                        />
                        <span style={{width: 20}}></span>
                        <PopupMenuSelect
                            style={{fontWeight: 'normal', width: 80}}
                            disabled={!stockIsEditable || busy}
                            value={comment.status_Action}
                            onChange={v => onChangeStockStatus(v, 0, comment.id, today())}
                            placeholder="多状态"
                            options={StatusOptions}
                            text={StatusLabels[comment.status_Action]}
                        />
                        <PopupMenuSelect
                            style={{fontWeight: 'normal', width: 80}}
                            disabled={!stockIsEditable || busy}
                            value={short_Wishlist}
                            onChange={v => handleUpdateCommentField({ short_Wishlist: v })}
                            placeholder={'空'}
                            options={ShortWishlistOptions}
                            text={ShortWishlistLabels[short_Wishlist]}
                        />
                        <span style={{width: 20}}></span>
                        <CommentDialogTextInput
                            label="合理下"
                            value={valuations.valuation_Price_Low}
                            onChange={v => setValuations(vs => ({...vs, valuation_Price_Low: v }))}
                            onBlur={() => {
                                if (!eq(valuations.valuation_Price_Low, valuations.valuation_Price_Low_Old)) {
                                    handleUpdateValuation('valuation_Price_Low', valuations.valuation_Price_Low);
                                }
                            }}
                        />
                        <CommentDialogTextInput
                            label="合理上"
                            value={valuations.valuation_Price_High}
                            onChange={v => setValuations(vs => ({...vs, valuation_Price_High: v }))}
                            onBlur={() => {
                                if (!eq(valuations.valuation_Price_High, valuations.valuation_Price_High_Old)) {
                                    handleUpdateValuation('valuation_Price_High', valuations.valuation_Price_High);
                                }
                            }}
                        />
                        
                    </>
                ) : null}
                <div style={{flex:1}}></div>
                <Button disabled={busy} onClick={onCloseAndReload}>
                    关闭
                </Button>
                { (stockIsEditable && !editDisabled) ? <>
                    <Popover
                        open={Boolean(deleteEl)}
                        onClose={()=>setDeleteEl(null)}
                        classes={{}}
                        anchorEl={deleteEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div style={{padding:16, textAlign:'right'}}>
                            <Typography style={{marginBottom:8}}>
                                删除后将无法恢复，请谨慎操作。
                            </Typography>
                            <Button disabled={busy} color="secondary" onClick={handleDelete} variant="contained">
                                确认删除
                            </Button>
                        </div>
                    </Popover>
                    <Button disabled={busy} color="secondary" onClick={e=>setDeleteEl(e.currentTarget)}>
                        删除评论
                    </Button>
                    <Button disabled={busy} onClick={disabled ? (() => setDisabled(false)) : handleSubmit} color="primary">
                        { comment ? ( disabled ? '修改评论' : '保存修改' ) : '发表评论' }
                    </Button>
                </> : null}
            </DialogActions>
        </Dialog>
    );
}