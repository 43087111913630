import React, { memo, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, Paper } from "@material-ui/core";
import FieldLabel from '../components/FieldLabel';
import SelectField from './SelectField';
import { yellow } from '@material-ui/core/colors';
import BigNumberInput from './BigNumberInput';
import { bn, bns } from '../lib/bn';
import AssignmentTurnedInRounded from '@material-ui/icons/AssignmentTurnedInRounded';
import TrendingFlatRounded from '@material-ui/icons/TrendingFlatRounded';
import { ValuationMethodLabels, KeyFactorLabels, FieldNames } from '../lib/enums';
import bigDecimal from 'js-big-decimal';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    select: {
        width: '100%',
        fontSize: 12,
    },
    input: {
        width: '100%'
    },
    scoreLabel: {
        textAlign: 'center',
        fontSize: 16,
        color: yellow[400],
    },
    scoreValue: {
        textAlign: 'center',
        fontSize: 24,
        color: yellow[400],
    },
    grid7col: {
        flexGrow: 0,
        maxWidth: '14.285714286%',
        flexBasis: '14.285714286%'
    }
}));

/** @type {{ show: boolean, label: string, value: bigDecimal }} */
const nullShowUpdateNumbers = null;

/**
 * @param {{
 *   edit: boolean,
 *   stock: import('../api/stock').StockInfo,
 *   setStockFields: (data: any) => void
 * }} param0 
 */
function PricingMeasures({ edit, stock, setStockFields }) {
    const classes = useStyles();
    const [showUpdateNumbers, setShowUpdateNumbers] = useState(nullShowUpdateNumbers);
    /** @type { bigDecimal } */
    let consensus_t0;
    switch(stock.valuation_Method_And_Keyfactor_Or_Default) {
        case 1:
            consensus_t0 = stock.eps_Consensus_T0;
            break;
        case 2:
            consensus_t0 = stock.bps_Consensus_T0;
            break;
        case 3:
            consensus_t0 = stock.ebitda_Consensus_T0;
            break;
        case 4:
            consensus_t0 = stock.rev_Consensus_T0;
            break;
        default:
            consensus_t0 = null;
            break;
    }
    /** @type { bigDecimal } */
    let consensus_t1;
    switch(stock.valuation_Method_And_Keyfactor_Or_Default) {
        case 1:
            consensus_t1 = stock.eps_Consensus_T1;
            break;
        case 2:
            consensus_t1 = stock.bps_Consensus_T1;
            break;
        case 3:
            consensus_t1 = stock.ebitda_Consensus_T1;
            break;
        case 4:
            consensus_t1 = stock.rev_Consensus_T1;
            break;
        default:
            consensus_t1 = null;
            break;
    }
    const vp1 = bns(stock.valuation_Price_1);
    const vp2 = bns(stock.valuation_Price_2);
    const vp3 = bns(stock.valuation_Price_3);
    const vp4 = bns(stock.valuation_Price_4);
    const vp5 = bns(stock.valuation_Price_5);
    const vp6 = bns(stock.valuation_Price_6);
    const vpl = stock.valuation_Price_Low ? bns(stock.valuation_Price_Low) : '';
    const vph = stock.valuation_Price_High ? bns(stock.valuation_Price_High) : '';
    // eslint-disable-next-line no-sparse-arrays
    const riskLineLabels = useMemo(() => {
        const r = ['-',/*1*/,/*2*/,/*3*/,`${vp4}(理下)`,`${vp5}(宜上)`,`${vp6}(宜下)`];
        if (vpl) {
            r[7] = `${vpl}(合理下)`;
        }
        return r;
    }, [vp4, vp5, vp6, vpl]);
    const rewardLineLabels = useMemo(() => {
        const r = ['-',`${vp1}(贵上)`,`${vp2}(贵下)`,`${vp3}(理上)`];
        if (vph) {
            r[8] = `${vph}(合理上)`;
        }
        return r;
    }, [vp1, vp2, vp3, vph]);
    return <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={2}>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="关键数" dot="green">
                    <SelectField
                        edit={edit}
                        value={ stock.valuation_Method_And_Keyfactor_Or_Default }
                        defaultValue={stock.valuation_Method_And_Keyfactor_Default}
                        onChange={v => setStockFields({ valuation_Method_And_Keyfactor: v })}
                        options={KeyFactorLabels}
                    />
                </FieldLabel>
                <FieldLabel label="数-贵上" dot="green">
                    <BigNumberInput
                        edit={edit && stock.valuation_Method_And_Keyfactor_Or_Default !== 5 } 
                        value={stock.valuation_Method_And_Keyfactor_Or_Default === 5 ? null : stock.valuation_KeyFactor_1_Or_Default} 
                        onSubmit={v => setStockFields({ valuation_Keyfactor_1: v })}
                    />
                </FieldLabel>
                <FieldLabel label="数-贵下" dot="green">
                    <BigNumberInput
                        edit={edit && stock.valuation_Method_And_Keyfactor_Or_Default !== 5 } 
                        value={stock.valuation_Method_And_Keyfactor_Or_Default === 5 ? null : stock.valuation_KeyFactor_2_Or_Default} 
                        onSubmit={v => setStockFields({ valuation_Keyfactor_2: v })}
                    />
                </FieldLabel>
                <FieldLabel label="数-理上" dot="green">
                    <BigNumberInput
                        edit={edit && stock.valuation_Method_And_Keyfactor_Or_Default !== 5 } 
                        value={stock.valuation_Method_And_Keyfactor_Or_Default === 5 ? null : stock.valuation_KeyFactor_3_Or_Default} 
                        onSubmit={v => setStockFields({ valuation_Keyfactor_3: v })}
                    />
                </FieldLabel>
                <FieldLabel label="数-理下" dot="green">
                    <BigNumberInput
                        edit={edit && stock.valuation_Method_And_Keyfactor_Or_Default !== 5 } 
                        value={stock.valuation_Method_And_Keyfactor_Or_Default === 5 ? null : stock.valuation_KeyFactor_4_Or_Default} 
                        onSubmit={v => setStockFields({ valuation_Keyfactor_4: v })}
                    />
                </FieldLabel>
                <FieldLabel label="数-宜上" dot="green">
                    <BigNumberInput
                        edit={edit && stock.valuation_Method_And_Keyfactor_Or_Default !== 5 } 
                        value={stock.valuation_Method_And_Keyfactor_Or_Default === 5 ? null : stock.valuation_KeyFactor_5_Or_Default} 
                        onSubmit={v => setStockFields({ valuation_Keyfactor_5: v })}
                    />
                </FieldLabel>
                <FieldLabel label="数-宜下" dot="green">
                    <BigNumberInput
                        edit={edit && stock.valuation_Method_And_Keyfactor_Or_Default !== 5 } 
                        value={stock.valuation_Method_And_Keyfactor_Or_Default === 5 ? null : stock.valuation_KeyFactor_6_Or_Default} 
                        onSubmit={v => setStockFields({ valuation_Keyfactor_6: v })}
                    />
                </FieldLabel>
                <FieldLabel
                    label="共识-T0" dot="yellow" plain
                    suffix={(edit && consensus_t0) ? (
                        <AssignmentTurnedInRounded fontSize="small" style={{cursor:'pointer'}} onClick={() => {
                            setShowUpdateNumbers({label:'共识-T0', value: consensus_t0, show: true});
                        }} />
                    ):null}
                >
                    { bns(consensus_t0) }
                </FieldLabel>
                <FieldLabel
                    label="外-T0" dot="red"
                >
                    <BigNumberInput
                        edit={edit} 
                        value={stock.keyfactor_Expert_T0} 
                        onSubmit={v => setStockFields({ keyfactor_Expert_T0: v })}
                        suffixIcon={AssignmentTurnedInRounded}
                        onSuffixIconClick={(text) => setShowUpdateNumbers({label:'外-T0', value: bn(text), show: true})}
                    />
                </FieldLabel>
                <FieldLabel label="内-T0" dot="blue">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.keyfactor_Conviction_T0} 
                        onSubmit={v => setStockFields({ keyfactor_Conviction_T0: v })}
                        suffixIcon={AssignmentTurnedInRounded}
                        onSuffixIconClick={(text) => setShowUpdateNumbers({label:'内-T0', value: bn(text), show: true})}
                    />
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="估值方法" dot="green">
                    <SelectField
                        edit={edit}
                        value={ stock.valuation_Method_And_Keyfactor }
                        defaultValue={stock.valuation_Method_And_Keyfactor_Default}
                        onChange={v => setStockFields({ valuation_Method_And_Keyfactor: v })}
                        options={ValuationMethodLabels}
                    />
                </FieldLabel>
                <FieldLabel label="估值-贵上" dot="blue">
                    <BigNumberInput 
                        edit={edit}
                        value={stock.valuation_Multiple_1} 
                        onSubmit={v => setStockFields({ valuation_Multiple_1: v })}
                    />
                </FieldLabel>
                <FieldLabel label="估值-贵下" dot="blue">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.valuation_Multiple_2} 
                        onSubmit={v => setStockFields({ valuation_Multiple_2: v })}
                    />
                </FieldLabel>
                <FieldLabel label="估值-理上" dot="blue">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.valuation_Multiple_3} 
                        onSubmit={v => setStockFields({ valuation_Multiple_3: v })}
                    />
                </FieldLabel>
                <FieldLabel label="估值-理下" dot="blue">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.valuation_Multiple_4} 
                        onSubmit={v => setStockFields({ valuation_Multiple_4: v })}
                    />
                </FieldLabel>
                <FieldLabel label="估值-宜上" dot="blue">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.valuation_Multiple_5} 
                        onSubmit={v => setStockFields({ valuation_Multiple_5: v })}
                    />
                </FieldLabel>
                <FieldLabel label="估值-宜下" dot="blue">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.valuation_Multiple_6} 
                        onSubmit={v => setStockFields({ valuation_Multiple_6: v })}
                    />
                </FieldLabel>
                <FieldLabel
                    label="共识-T1" dot="yellow" plain
                    suffix={(edit && consensus_t1) ? (
                        <AssignmentTurnedInRounded fontSize="small" style={{cursor:'pointer'}} onClick={() => {
                            setShowUpdateNumbers({label:'共识-T1', value: consensus_t1, show: true});
                        }} />
                    ):null}
                >
                    { bns(consensus_t1) }
                </FieldLabel>
                <FieldLabel label="外-T1" dot="red">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.keyfactor_Expert_T1} 
                        onSubmit={v => setStockFields({ keyfactor_Expert_T1: v })}
                        suffixIcon={AssignmentTurnedInRounded}
                        onSuffixIconClick={(text) => setShowUpdateNumbers({label:'外-T1', value: bn(text), show: true})}
                    />
                </FieldLabel>
                <FieldLabel label="内-T1" dot="blue">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.keyfactor_Conviction_T1} 
                        onSubmit={v => setStockFields({ keyfactor_Conviction_T1: v })}
                        suffixIcon={AssignmentTurnedInRounded}
                        onSuffixIconClick={(text) => setShowUpdateNumbers({label:'内-T1', value: bn(text), show: true})}
                    />
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="货币对" dot="yellow" plain>
                    {stock.currency}/{stock.currency}
                </FieldLabel>
                <FieldLabel label="定价-贵上" dot="yellow" plain>
                    {bns(stock.valuation_Price_1)}
                </FieldLabel>
                <FieldLabel label="定价-贵下" dot="yellow" plain>
                    {bns(stock.valuation_Price_2)}
                </FieldLabel>
                <FieldLabel label="定价-理上" dot="yellow" plain>
                    {bns(stock.valuation_Price_3)}
                </FieldLabel>
                <FieldLabel label="定价-理下" dot="yellow" plain>
                    {bns(stock.valuation_Price_4)}
                </FieldLabel>
                <FieldLabel label="定价-宜上" dot="yellow" plain>
                    {bns(stock.valuation_Price_5)}
                </FieldLabel>
                <FieldLabel label="定价-宜下" dot="yellow" plain>
                    {bns(stock.valuation_Price_6)}
                </FieldLabel>
                <FieldLabel label="增速-共0" dot="yellow" plain>
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 1 ? (
                        bns(stock.eps_YOY_Consensus_T0)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 2 ? (
                        bns(stock.bps_YOY_Consensus_T0)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 3 ? (
                        bns(stock.ebitda_YOY_Consensus_T0)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 4 ? (
                        bns(stock.rev_YOY_Consensus_T0)
                    ):null}
                </FieldLabel>
                <FieldLabel label="增速-外0" dot="red">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.keyfactor_YOY_Expert_T0} 
                        onSubmit={v => setStockFields({ keyfactor_YOY_Expert_T0: v })}
                    />
                </FieldLabel>
                <FieldLabel label="增速-内0" dot="blue">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.keyfactor_YOY_Conviction_T0} 
                        onSubmit={v => setStockFields({ keyfactor_YOY_Conviction_T0: v })}
                    />
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="风险线" dot="green">
                    <SelectField
                        edit={edit}
                        value={ stock.risk_Line_Or_Default }
                        defaultValue={stock.risk_Line_Default}
                        onChange={v => setStockFields({ risk_Line: v })}
                        options={riskLineLabels}
                    />
                </FieldLabel>
                <FieldLabel label="风险%" dot="yellow" plain>
                    {bns(stock.risk_Pct)}%
                </FieldLabel>
                <FieldLabel label="回报线" dot="green">
                    <SelectField
                        edit={edit}
                        value={ stock.reward_Line_Or_Default }
                        defaultValue={stock.reward_Line_Default}
                        onChange={v => setStockFields({ reward_Line: v })}
                        options={rewardLineLabels}
                    />
                </FieldLabel>
                <FieldLabel label="回报%" dot="yellow" plain>
                    {bns(stock.reward_Pct)}%
                </FieldLabel>
                <FieldLabel label="风险回报比" dot="yellow" plain>
                    {bns(stock.reward_Risk_Ratio)}
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="增速-共1" dot="yellow" plain>
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 1 ? (
                        bns(stock.eps_YOY_Consensus_T1)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 2 ? (
                        bns(stock.bps_YOY_Consensus_T1)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 3 ? (
                        bns(stock.ebitda_YOY_Consensus_T1)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 4 ? (
                        bns(stock.rev_YOY_Consensus_T1)
                    ):null}
                </FieldLabel>
                <FieldLabel label="增速-外1" dot="red">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.keyfactor_YOY_Expert_T1} 
                        onSubmit={v => setStockFields({ keyfactor_YOY_Expert_T1: v })}
                    />
                </FieldLabel>
                <FieldLabel label="增速-内1" dot="blue">
                    <BigNumberInput
                        edit={edit} 
                        value={stock.keyfactor_YOY_Conviction_T1} 
                        onSubmit={v => setStockFields({ keyfactor_YOY_Conviction_T1: v })}
                    />
                </FieldLabel>
                
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="估值-共0" dot="yellow" plain>
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 1 ? (
                        bns(stock.eps_PE_Consensus_T0)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 2 ? (
                        bns(stock.bps_PB_Consensus_T0)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 3 ? (
                        bns(stock.ebitda_EVX_Consensus_T0)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 4 ? (
                        bns(stock.rev_PS_Consensus_T0)
                    ):null}
                </FieldLabel>
                <FieldLabel label="估值-外0" dot="yellow" plain>
                    {bns(stock.keyfactor_Valuation_Expert_T0)}
                </FieldLabel>
                <FieldLabel label="估值-内0" dot="yellow" plain>
                    {bns(stock.keyfactor_Valuation_Conviction_T0)}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="">
                </FieldLabel>
                <FieldLabel label="估值-共1" dot="yellow" plain>
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 1 ? (
                        bns(stock.eps_PE_Consensus_T1)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 2 ? (
                        bns(stock.bps_PB_Consensus_T1)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 3 ? (
                        bns(stock.ebitda_EVX_Consensus_T1)
                    ):null}
                    { stock.valuation_Method_And_Keyfactor_Or_Default === 4 ? (
                        bns(stock.rev_PS_Consensus_T1)
                    ):null}
                </FieldLabel>
                <FieldLabel label="估值-外1" dot="yellow" plain>
                    {bns(stock.keyfactor_Valuation_Expert_T1)}
                </FieldLabel>
                <FieldLabel label="估值-内1" dot="yellow" plain>
                    {bns(stock.keyfactor_Valuation_Conviction_T1)}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel col={0} row={3}>
                    <div className={classes.scoreLabel}>{
                        (stock.risk_Line_Or_Default === 7 && stock.reward_Line_Or_Default === 8) ? '估值' : FieldNames.pricing_Score
                    }</div>
                    <div className={classes.scoreValue}>{bns(stock.pricing_Score, 1)}</div>
                </FieldLabel>
            </Grid>
        </Grid>
        <Dialog
            open={Boolean(showUpdateNumbers?.show)}
            onClose={() => setShowUpdateNumbers(null)}
        >
            <DialogTitle>是否使用{showUpdateNumbers?.label}的值替换关键数：</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    数-贵上：{bns(stock.valuation_KeyFactor_1_Or_Default)} <TrendingFlatRounded style={{verticalAlign: 'middle'}} /> {bns(showUpdateNumbers?.value)}
                    <br/>
                    数-贵下：{bns(stock.valuation_KeyFactor_2_Or_Default)} <TrendingFlatRounded style={{verticalAlign: 'middle'}} /> {bns(showUpdateNumbers?.value)}
                    <br/>
                    数-理上：{bns(stock.valuation_KeyFactor_3_Or_Default)} <TrendingFlatRounded style={{verticalAlign: 'middle'}} /> {bns(showUpdateNumbers?.value)}
                    <br/>
                    数-理下：{bns(stock.valuation_KeyFactor_4_Or_Default)} <TrendingFlatRounded style={{verticalAlign: 'middle'}} /> {bns(showUpdateNumbers?.value)}
                    <br/>
                    数-宜上：{bns(stock.valuation_KeyFactor_5_Or_Default)} <TrendingFlatRounded style={{verticalAlign: 'middle'}} /> {bns(showUpdateNumbers?.value)}
                    <br/>
                    数-宜下：{bns(stock.valuation_KeyFactor_6_Or_Default)} <TrendingFlatRounded style={{verticalAlign: 'middle'}} /> {bns(showUpdateNumbers?.value)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowUpdateNumbers(r => ({...r, show: false}))}>
                    取消
                </Button>
                <Button onClick={() => {
                    setShowUpdateNumbers(r => ({...r, show: false}));
                    setStockFields({ 
                        valuation_Keyfactor_1: showUpdateNumbers?.value?.getValue(),
                        valuation_Keyfactor_2: showUpdateNumbers?.value?.getValue(),
                        valuation_Keyfactor_3: showUpdateNumbers?.value?.getValue(),
                        valuation_Keyfactor_4: showUpdateNumbers?.value?.getValue(),
                        valuation_Keyfactor_5: showUpdateNumbers?.value?.getValue(),
                        valuation_Keyfactor_6: showUpdateNumbers?.value?.getValue()
                    });
                }} color="primary">
                    替换
                </Button>
            </DialogActions>
        </Dialog>
    </Paper>    
}

export default memo(PricingMeasures);
