import { Checkbox, FormControl, InputLabel, ListItemText, makeStyles, MenuItem, Paper, Select } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { FullHeightVirtualScrollTable } from './Table';
import { useGlobal } from './lib/useGlobal';
import { useApiState } from './api/common';
import useErrorMessage from './lib/useErrorMessage';
import { fromDays2000, toDate } from './lib/date';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getDailyWork } from './api/work';
import useQuery from './lib/useQuery';
import { CommentDialog } from './components/CommentDialog';
import { useCoCallback, useCoEffect } from './lib/useCo';
import { getCommentsByIds } from './api/comment';

/** @typedef {import('./api/work').WorkDaily} WorkDaily */
/** @typedef {import('./api/comment').Comment} Comment */

const SignalLabels = {
    rsi_50_Up: '50+',
    rsi_50_Down: '50-',
    rsi_75: '75',
    rsi_25: '25',
    weeks_52_Hi: 'Hi',
    weeks_52_Lo: 'Lo',
    macd_Up: 'Ma+',
    macd_Down: 'Ma-'
}

/** 
 * @typedef {{
 *   date: string,
 *   code: string,
 *   name: string,
 *   user: string[],
 *   update_stock: string,
 *   new_comment_list: Comment[],
 *   new_comment: string[],
 *   update_comment_list: Comment[],
 *   update_comment: string[],
 *   events: string[]
 * }} WorkDailyRow 
 */

/** 
 * @type {import('./Table').SorterAndFilters<WorkDailyRow>} */
const initSorterAndFilters = null;

/** @type {import('./Table').Fields<WorkDailyRow>[]} */
const initVisibleColumns = null;

const todayMoment = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
const defaultDate = moment(todayMoment).subtract(1, 'day');

/** @type { Comment & { coverer : number } } */
const nullComment = null;
/** @type { Comment[] } */
const nullComments = null;


const useStyles = makeStyles({
    left: {
        // @ts-ignore
        textAlign: 'left !important',
    }
});

const ChangeTypes = {
    '1': '改标签',
    '2': '新评论',
    '3': '改评论',
};

export default function WorkTable() {
    const [showComment, setShowComment] = useState(nullComment);
    const classes = useStyles();
    const columns = useMemo(() => {
        /** @type {import('./Table').ColumnOption<WorkDailyRow>[]} */
        const columns = [
            {
                field: 'code',
                label: 'Code',
                model: 'none',
                headerClassName: classes.left,
                cellClassName: classes.left,
                sortable: false,
                width: 100,
                render: row => <Link to={`/stock/${row.code}`} target="_blank" className={classes.link}>{row.code}</Link>
            },
            {
                field: 'name',
                label: 'Name',
                model: 'none',
                headerClassName: classes.left,
                cellClassName: classes.left,
                sortable: false,
                width: 100,
                render: row => <Link to={`/stock/${row.code}`} target="_blank" className={classes.link}>{row.name}</Link>
            },
            {
                field: 'user',
                label: '负责人',
                model: 'none',
                sortable: false,
                render: row => row.user.join(', ')
            },
            {
                field: 'update_stock',
                label: '改标签',
                model: 'none',
                sortable: false
            },
            {
                field: 'new_comment',
                label: '新评论(事件日期)',
                model: 'none',
                sortable: false,
                widthFlex: 3,
                render: row => row.new_comment_list.map((c, i) => (
                    <React.Fragment key={c.id}><a
                        onClick={() => setShowComment(c)}
                    >{row.new_comment[i]}</a>
                    {i != row.new_comment_list.length - 1 ? ', ' : ''}
                    </React.Fragment>
                ))
            },
            {
                field: 'update_comment',
                label: '改评论(事件日期)',
                model: 'none',
                sortable: false,
                widthFlex: 3,
                render: row => row.update_comment_list.map((c, i) => (
                    <React.Fragment key={c.id}><a
                        onClick={() => setShowComment(c)}
                    >{row.update_comment[i]}</a>
                    {i != row.update_comment_list.length - 1 ? ', ' : ''}
                    </React.Fragment>
                ))
            },
            {
                field: 'events',
                label: '当日事件',
                model: 'none',
                sortable: false,
                widthFlex: 2,
                render: row => row.events.join(', ')
            }
        ];
        return columns;
    }, [classes]);

    const [date, setDate] = useQuery('date', defaultDate, s => Number.isSafeInteger(Number(s)) ? fromDays2000(Number(s)) : null, m => `${toDate(m)}`);
    const [origData,, error] = useApiState(getDailyWork, toDate(date));
    const [comments, setComments] = useState(nullComments);
    const covererList = useMemo(() => {
        /** @type {Set<string>} */
        const nameSet = new Set();
        if (origData) origData.forEach(item => {
            item.user_Display_Name_List.forEach(s => {
                nameSet.add(s);
            });
        });
        return Array.from(nameSet);
    }, [origData]);
    const [selectedCoverer, setSelectedCoverer] = useState([]);
    const [selectedChangeTypes, setSelectedChangeTypes] = useState([]);

    useCoEffect(function*(){
        if (!origData || origData.length === 0) {
            setComments([]);
        } else {
            /** @type {Set<number>} */
            const commentIdSet = new Set();
            origData.forEach(d => {
                d.comment_Create_ID_List.forEach(id => commentIdSet.add(id));
                d.comment_Update_ID_List.forEach(id => commentIdSet.add(id));
            });
            const commentIds = Array.from(commentIdSet);
            /** @type {Comment[]} */
            const comments = yield getCommentsByIds(commentIds);
            setComments(comments);
        }
    }, [origData]);
    const onReloadComment = useCoCallback(function*(_isCancelled, /** @type { number } */commentId){
        /** @type {Comment[]} */
        const r = yield getCommentsByIds([commentId]);
        if (r[0]) {
            setComments(old => {
                const n = [...old];
                for (let i = 0; i < n.length; i++) {
                    if (n[i].id === commentId) {
                        n[i] = r[0];
                    }
                }
                return n;
            });
        } else {
            setComments(old => old.filter(c => c.id !== commentId));
        }
    }, []);

    const data = useMemo(() => {
        if (!origData || !comments) return [];
        /** @type {{[id: number]: Comment}} */
        const commentMap = {};
        comments.forEach(c => commentMap[c.id] = c);
        return origData
        .filter(d => {
            if (selectedCoverer.length > 0) {
                if (selectedCoverer.every(c => d.user_Display_Name_List.indexOf(c) < 0)) {
                    return false;
                }
            }
            if (selectedChangeTypes.length > 0) {
                if (selectedChangeTypes.every(t => {
                    switch(t) {
                        case '1':
                            return !d.stock_Info_Update_ID_List?.length;
                        case '2':
                            return !d.comment_Create_ID_List?.length;
                        case '3':
                            return !d.comment_Update_ID_List?.length;
                        default:
                            return true;
                    }
                })) {
                    return false;
                }
            }
            return true;
        })
        .map(d => {
            const new_comment_list = (d.comment_Create_ID_List||[]).map(id => commentMap[id]).filter(Boolean);
            const update_comment_list = (d.comment_Update_ID_List||[]).map(id => commentMap[id]).filter(Boolean);
            /** @type {WorkDailyRow} */
            const r = {
                date: fromDays2000(d.date).format('YYYY-MM-DD'),
                code: d.code,
                name: d.name,
                user: (d.user_Display_Name_List||[]),
                update_stock: d.stock_Info_Update_ID_List && d.stock_Info_Update_ID_List.length > 0 ? '✓' : '',
                new_comment_list,
                new_comment: new_comment_list.map(c => fromDays2000(c.date).format('YYYY-MM-DD')),
                update_comment_list,
                update_comment: update_comment_list.map(c => fromDays2000(c.date).format('YYYY-MM-DD')),
                events: Object.keys(SignalLabels).map(key => d.event?.[key] ? SignalLabels[key] : null).filter(Boolean)
            };
            return r;
        })
    }, [origData, comments, selectedCoverer, selectedChangeTypes]);
    useErrorMessage(error);
    const [sorterAndFilters, setSorterAndFilters] = useGlobal('WorkTable_SorterAndFilters', initSorterAndFilters);
    const [visibleColOrder, setVisibleColOrder] = useGlobal('WorkTable_VisibleColOrder', initVisibleColumns);
    if (!data) return null;
    return <div style={{width:'100%',padding: 20,display: 'flex', flexDirection: 'column'}}>
        <Paper style={{padding:20,  marginBottom: 20}}>
            <DatePicker
                style={{marginRight: 16, width: 200}}
                autoOk
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="none"
                label="日期"
                value={date}
                maxDate={todayMoment}
                onChange={setDate}
            />
            <FormControl>
                <InputLabel htmlFor="coverer">负责人</InputLabel>
                <Select
                    id="coverer"
                    style={{marginRight: 16, width:200}}
                    value={selectedCoverer}
                    multiple
                    onChange={e => {
                        setSelectedCoverer(e.target.value);
                    }}
                    renderValue={v => v.join(", ")}
                >
                    { covererList.map(name => (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={selectedCoverer.indexOf(name) >= 0} />
                                <ListItemText primary={name} />
                            </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel htmlFor="change-types">修改类型</InputLabel>
                <Select
                    id="change-types"
                    style={{marginRight: 16, width:200}}
                    value={selectedChangeTypes}
                    multiple
                    onChange={e => {
                        setSelectedChangeTypes(e.target.value);
                    }}
                    renderValue={v => v.map(v => ChangeTypes[v]).join(", ")}
                >
                    { Object.keys(ChangeTypes).map(type => (
                            <MenuItem key={type} value={type}>
                                <Checkbox checked={selectedChangeTypes.indexOf(type) >= 0} />
                                <ListItemText primary={ChangeTypes[type]} />
                            </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Paper>
        <FullHeightVirtualScrollTable
            height={700}
            data={data}
            columns={columns}
            sorterAndFilters={sorterAndFilters}
            onSorterAndFiltersChange={setSorterAndFilters}
            visibleColumnOrder={visibleColOrder}
            onVisibleColumnOrderChange={setVisibleColOrder}
            variant="compat"
        />
        { showComment ? <CommentDialog
            show={true}
            comment={showComment}
            onClose={()=>setShowComment(null)}
            onReloadComment={onReloadComment}
            onReloadReport={()=>{}}
            onReloadStock={()=>{}}
            onChangeStockStatus={()=>{}}
            code={showComment.code}
            reportId={showComment.stock_Report_Quarterly_ID}
            date={showComment.date}
            changeStatusDisabled={true}
            onChangeCommentDialog={()=>{}}
            editDisabled
            closeWithBackdropClickAndEscapeKeyEnabled
        /> : null}
        

    </div>
}