import { get, post } from "./common";
import BigDecimal from 'js-big-decimal';
import { bn } from "../lib/bn";

/**
 * @typedef {{
 *   id: number,
 *   version: number,
 *   code_CN: string,
 *   code_HK: string,
 *   rate: BigDecimal,
 * }} RelationCNHK
 */

/**
 * @returns { Promise<number> }
 */
export async function createRelationCNHKTable() {
    return await post('/api/user-table/relation-cn-hk');
}

/**
 * @returns { Promise<RelationCNHK[]> }
 */
export async function getRelationCNHKTable() {
    const list = /** @type{any[]} */(await get('/api/user-table/relation-cn-hk'));
    return list.map(s => ({
        id: s.id,
        version: s.version,
        code_CN: s.code_CN,
        code_HK: s.code_HK,
        rate: bn(s.rate)
    }))
}

/**
 * @param { number } version
 * @param { Omit<RelationCNHK, 'id'|'version'> } row
 * @returns { Promise<number> }
 */
export async function uploadRelationCNHKTableRow(version, row) {
    const {
        code_CN, code_HK, rate
    } = row;
    const data = {
        code_CN, code_HK, rate: rate?.getValue(),
    };
    return await post(`/api/user-table/relation-cn-hk/${version}/row`, data);
}

/**
 * @param { number } version
 * @returns { Promise<number> }
 */
export async function commitRelationCNHKTable(version) {
    return await post(`/api/user-table/relation-cn-hk/${version}/commit`);
}
