import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import RelationCNHKTable from './RelationCNHKTable';
import RelationCNHKTableImport from './RelationCNHKTableImport';

export default function RelationCNHKTableRouter() {
    const { path, url } = useRouteMatch();
    return <Switch>
        <Route path={path} exact component={RelationCNHKTable} />
        <Route path={`${path}/add`} exact component={RelationCNHKTableImport} />
        <Redirect to={url} />
    </Switch>
}