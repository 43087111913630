import { Tooltip } from "@material-ui/core";
import React, { useRef, useState } from "react";

/**
 * @param {{
 *   children: React.ReactNode,
 *   classes?: { tooltip: string, normal: string },
 *   style?: React.CSSProperties,
 *   title?: React.ReactNode,
 *   placement?: import("@material-ui/core").TooltipProps['placement']
 * }} param0 
 * @returns 
 */
export default function OverflowTooltip({ title, children, style, classes, placement = 'top-end' }) {
    const [open, setOpen] = useState(false);
    const div = useRef(/** @type {HTMLDivElement} */(null));
    return <Tooltip title={<div className={classes?.tooltip}>{title || children}</div>} placement={placement} open={open}>
        <div ref={div} style={style} className={classes?.normal} onMouseEnter={() => {
            if (div.current) {
                const e = div.current;
                if (e.offsetWidth < e.scrollWidth) {
                    setOpen(true);
                }
            }
        }} onMouseLeave={()=>setOpen(false)}>
            { children }
        </div>
    </Tooltip>
}