import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import ShortableHKTable from './ShortableHKTable';
import ShortableHKTableImport from './ShortableHKTableImport';

export default function ShortableHKTableRouter() {
    const { path, url } = useRouteMatch();
    return <Switch>
        <Route path={path} exact component={ShortableHKTable} />
        <Route path={`${path}/add`} exact component={ShortableHKTableImport} />
        <Route path={`${path}/:version`} exact component={ShortableHKTable} />
        <Redirect to={url} />
    </Switch>
}