import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LoginImage from './images/login.jpg';
import TextField from '@material-ui/core/TextField';
import { useCoCallback } from './lib/useCo';
import { useSnackbar } from 'notistack';
import useAuth from './api/auth';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    flex: {
        flex: 1
    },
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    card: {
        width: 320,
        display: 'flex',
        flexDirection: 'column'
    },
    media: {
        height: 160,
    },
    box: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    }
}));

export default function Login() {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const [auth, authActions] = useAuth();
    const [loading, setLoading] = useState(false);

    const onSubmit = useCoCallback(function*(isCancelled, username, password){
        try {
            setLoading(true);
            yield authActions.login(username, password);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            enqueueSnackbar(e.message);
            console.warn(e);
        }
    }, []);

    return (
        <div className={classes.root}>
            <Card className={classes.card} elevation={5}>
                <CardMedia
                    className={classes.media}
                    image={LoginImage}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        登录
                    </Typography>
                    <div className={classes.box}>
                        <TextField label="用户名" fullWidth value={username} onChange={e => setUsername(e.target.value)} />
                    </div>
                    <div className={classes.box}>
                        <TextField label="密码" fullWidth type="password" value={password} onChange={e => setPassword(e.target.value)} onKeyPress={e => {
                            if (e.key === "Enter") {
                                onSubmit(username, password);
                            }
                        }}/>
                    </div>
                </CardContent>
                <div className={classes.flex} />
                <CardActions>
                    <div className={classes.flex} />
                    <Button variant="contained" disabled={loading}  color="primary" onClick={() => onSubmit(username, password)}>
                        {loading ? <CircularProgress size="1.53rem" color="inherit" /> : '登录'}
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}