import { makeStyles, Paper, useTheme } from '@material-ui/core';
import React, { memo, useMemo, useState } from 'react';
import { getStockKChartList, getStockRiskRewardList } from '../api/stock';
import ECharts from '../Chart';
import { date2str, fromDays2000, toDate, today, yesterday } from '../lib/date';
import { useCoEffect } from '../lib/useCo';
import moment from 'moment';
import { toHtml } from '../components/RichText';
import { EventNames } from '../lib/enums';
import bigDecimal from 'js-big-decimal';
import { useSnackbar } from 'notistack';
import { val } from '../lib/bn';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    chart: {
        height: 600
    }
}));

const faNames = {
    '0331': '第一季度',
    '0630': '第二季度',
    '0930': '第三季度',
    '1231': '第四季度',
}

const SignalPriority = [
    'rsi_25', 'rsi_50_Up', 'rsi_50_Down', 'rsi_75', 
    /*'weeks_52_Hi', 'weeks_52_Lo', 'macd_Up', 'macd_Down' 需求需要隐藏这几个信号*/
]

const SignalLabels = {
    rsi_50_Up: '50+',
    rsi_50_Down: '50-',
    rsi_75: '75',
    rsi_25: '25',
    weeks_52_Hi: 'Hi',
    weeks_52_Lo: 'Lo',
    macd_Up: 'Ma+',
    macd_Down: 'Ma-'
}

/**
 * @typedef {{ 
 *   date: number,
 *   content: string,
 *   id: number,
 *   type: string,
 *   price_Close: bigDecimal,
 *   defaultQualityScore: bigDecimal,
 *   defaultPricingScore: bigDecimal,
 *   defaultTrendScore: bigDecimal,
 * }} ReportItem
 * 
 * @typedef {{ date: number, content: string, type: string }} CommentItem
 * 
 * @typedef {{ 
 *   date: number,
 *   content: string,
 *   type: string,
 *   price_Close: bigDecimal,
 *   defaultQualityScore: bigDecimal,
 *   defaultPricingScore: bigDecimal,
 *   defaultTrendScore: bigDecimal,
 * }} SignalItem
 */



/**
 * @param {{
 *   chartData: import('../api/stock').StockKChartItem[],
 *   riskRewardData: import('../api/stock').StockRiskRewardChartItem[],
 *   faList: import('../api/stock').StockReportQuarterlyFA[],
 *   noticeList: import('../api/stock').StockReportQuarterlyNotice[],
 *   expressList: import('../api/stock').StockReportQuarterlyExpress[],
 *   commentList: import('../api/comment').Comment[],
 *   events: import('../api/event').SignalEvent[],
 *   onShowCommentDialog: (opts: import('../components/CommentDialog').CommentDialogState) => void,
 * }} param0 
 */
function StockChart({ chartData = [], riskRewardData = [], faList = [], noticeList = [], expressList = [], commentList = [], events = [], onShowCommentDialog }) {
    const classes = useStyles();
    const theme = useTheme();
    const padding = theme.spacing(3);
    const reports = useMemo(() => {
        /** @type {ReportItem[]} */
        const reports = [
            ...faList.map(fa => ({ 
                date: fa.fa_Release_Date, 
                content: `发布了${faNames[fromDays2000(fa.rpt_Period).format('MMDD')] || date2str(fa.rpt_Period)}财报`, 
                id: fa.id, 
                type: '财',
                price_Close: fa.price_Close,
                defaultQualityScore: fa.quality_Score,
                defaultPricingScore: fa.pricing_Score,
                defaultTrendScore: fa.trend_Score
            })),
            ...expressList.map(ex => ({ 
                date: ex.express_Release_Date, 
                content: `发布了${faNames[fromDays2000(ex.rpt_Period).format('MMDD')] || date2str(ex.rpt_Period)}快报`, 
                id: ex.id, 
                type: '快',
                price_Close: ex.price_Close,
                defaultQualityScore: ex.quality_Score,
                defaultPricingScore: ex.pricing_Score,
                defaultTrendScore: ex.trend_Score
            })),
            ...noticeList.map(n => ({ 
                date: n.notice_Release_Date, 
                content: `发布了${faNames[fromDays2000(n.rpt_Period).format('MMDD')] || date2str(n.rpt_Period)}预告`, 
                id: n.id, 
                type: '预',
                price_Close: n.price_Close,
                defaultQualityScore: n.quality_Score,
                defaultPricingScore: n.pricing_Score,
                defaultTrendScore: n.trend_Score
            })),
        ].filter(item => item.date);
        return reports;
    }, [faList, noticeList, expressList]);
    const basicOption = useMemo(() => getBasicOptions(padding, 365*2, 90), [padding]);
    const updateOption = useMemo(() => {
        const comments = commentList.map(c => {
            let type;
            switch (c.status_Action) {
            case 1:
                type = 'C';
                break;
            case 2:
                type = 'W';
                break;
            case 3:
                type = 'S';
                break;
            case 4:
                if (c.short_Wishlist === 1) {
                    type = 'S';
                } else {
                    type = 'P';
                }
                break;
            default:
                type = reports.some(r => r.id === c.stock_Report_Quarterly_ID || r.date === c.date) ? '评' : '逻';
                break;
            }
            return { 
                date: c.date, 
                content: c.content, 
                type
            };
        });
        const signals = events
        .map(e => {
            const label = SignalPriority.map(key => {
                if (e[key]) {
                    return EventNames[key];
                } else {
                    return '';
                }
            })
            .filter(Boolean)
            .join(',');
            for (const key of SignalPriority) {
                if (e[key]) {
                    return { 
                        date: e.date_First, 
                        content: label, 
                        type: SignalLabels[key],
                        price_Close: e.price_Close,
                        defaultQualityScore: e.quality_Score,
                        defaultPricingScore: e.pricing_Score,
                        defaultTrendScore: e.trend_Score_Or_Default
                    };
                }
            }
            return null;
        })
        .filter(Boolean);
        return getUpdateOptions({
            data: chartData,
            reports,
            comments,
            signals,
            riskRewardLine: riskRewardData
        });
    }, [chartData, reports, commentList, events, riskRewardData]);
    
    function handleEditComment(e) {
        let date;
        if (e?.componentType === "series") {
            const idx = e?.dataIndex;
            const data = chartData[idx];
            if (!data) {
                return;
            }
            date = data[0];
        } else if (e?.componentType === "markPoint") {
            const dateStr = e.data.coord[0];
            const d = toDate(moment(dateStr));
            if (d) {
                date = d;
            }
        }
        if (date) {
            const commentId = commentList?.find(c => c.date === date)?.id;
            const report = reports?.find(r => r.date === date);
            const event = events?.find(e => e.date_First === date);
            onShowCommentDialog({
                commentId,
                reportId: report?.id,
                date,
                price_Close: report?.price_Close || event?.price_Close || val(chartData.find(item => item[0] === date)?.[2]),
                defaultPricingScore: report?.defaultPricingScore || event?.pricing_Score,
                defaultQualityScore: report?.defaultQualityScore || event?.quality_Score,
                defaultTrendScore: report?.defaultTrendScore || event?.trend_Score_Or_Default
            });
        }
    }
    return <Paper elevation={3} className={classes.paper}>
        <ECharts
            className={classes.chart}
            option={basicOption}
            updateOption={updateOption}
            onClick={handleEditComment}
        />
    </Paper>
}

/**
 * @param {{
 *   data: import('../api/stock').StockKChartItem[],
 *   reports: ReportItem[],
 *   comments: CommentItem[],
 *   signals: SignalItem[],
 *   riskRewardLine: import('../api/stock').StockRiskRewardChartItem[]
 * }} param0
 */
function getUpdateOptions({
    data = [],
    reports = [],
    comments = [],
    signals = [],
    riskRewardLine = []
}) {
    const categoryData = data.map(d => date2str(d[0]));
    const valueData = data.map((item, i) => {
        if (i === 0) return [item[1], item[2], item[3], item[4], true];
        const prev = data[i-1];
        if (item[1] === prev[1] && item[2] === prev[2] && item[3] === prev[3] && item[4] === prev[4]) {
            return [prev[2],prev[2],prev[2],prev[2], false];
        }
        return [item[1], item[2], item[3], item[4], true];
    });
    const occupiedSet = new Set();
    const commentData = comments.map(({ date, content, type }) => {
        /** @type {[ date: string, value: string, content: string, type: string, visible: boolean ]} */
        const r = [date2str(date), data.find(d => d[0] === date)?.[4], content, type, !occupiedSet.has(date)];
        return r;
    }).filter(Boolean);
    comments.forEach(c => occupiedSet.add(c.date));
    const reportData = reports.map(({ date, content, id, type }) => {
        /** @type {[ date: string, value: string, content: string, id: number, type: string, visible: boolean]} */
        const r = [date2str(date), data.find(d => d[0] === date)?.[4], content, id, type, !occupiedSet.has(date)];
        return r;
    });
    reports.forEach(r => occupiedSet.add(r.date));
    const signalData = signals.map(({ date, content, type }) => {
        /** @type {[ date: string, value: string, content: string, type: string, visible: boolean ]} */
        const r = [date2str(date), data.find(d => d[0] === date)?.[4], content, type, !occupiedSet.has(date)];
        return r;
    }).filter(Boolean);
    const riskLineData = riskRewardLine.map(row => [date2str(row[0]), Number(row[1])]);
    const rewardLineData = riskRewardLine.map(row => [date2str(row[0]), Number(row[2])]);
    return {
        xAxis: {
            data: categoryData,
        },
        series: [
            {
                name: '日K',
                data: valueData,
            },
            {
                name: '财报',
                data: reportData,
                markPoint: {
                    data: reportData.map(([date, value, /*content*/, /*id*/, type, visible], i) => {
                        if (!visible) {
                            return null;
                        }
                        return {
                            coord: [date, value],
                            name: `report-${i}`,
                            value: type,
                            itemStyle: {
                                color: '#2979ff'
                            }
                        };
                    }).filter(Boolean),
                }
            },
            {
                name: '风险线',
                type: 'line',
                data: riskLineData,
            },
            {
                name: '回报线',
                type: 'line',
                data: rewardLineData
            },
            {
                name: '信号',
                data: signalData,
                markPoint: {
                    data: signalData.map(([date, value, /*content*/, type, visible], i) => {
                        if (!visible) {
                            return null;
                        }
                        return {
                            coord: [date, value],
                            name: `signal-${i}`,
                            value: type,
                            itemStyle: {
                                color: '#651fff'
                            }
                        }
                    }).filter(Boolean),
                }
            },
            {
                name: '评论',
                data: commentData,
                markPoint: {
                    data: commentData.map(([date, value, /*content*/, type, visible], i) => {
                        if (!visible) {
                            return null;
                        }
                        return {
                            coord: [date, value],
                            name: `comment-${i}`,
                            value: type,
                            itemStyle: {
                                color: '#b22a00'
                            }
                        }
                    }).filter(Boolean),
                }
            },
        ]
    };
}

function getBasicOptions(padding, initDataLength, initDateRange) {
    const upColor = '#b23c17';
    const upBorderColor = '#b23c17';
    const downColor = '#357a38';
    const downBorderColor = '#357a38';
    const textColor = '#C7C7C7';

    const categoryData = [];
    const valueData = [];
    const reportData = [];
    const commentData = [];
    const signalData = [];
    return {
        backgroundColor: '#424242',
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            },
            renderMode: 'html',
            formatter: function(series, ...more) {
                const d = series[0].data;
                // console.log(d);
                const tradeDay = d[5];
                let l;
                if (tradeDay) {
                    l = `日期：${series[0]?.axisValue}<br/>开盘：${d[1]}<br/>收盘：${d[2]}<br/>最低：${d[3]}<br/>最高：${d[4]}`;
                } else {
                    l = `日期：${series[0]?.axisValue}<br/>非交易日`;
                }
                for (let i = 3; i < series.length; i++) {
                    const s = series[i];
                    l += `<div style="max-width:500px;word-break: break-all;white-space:normal">${s.seriesName}：${toHtml(s.data[2], 'span')}</div>`;
                }
                return l;
            }
        },
        // legend: {
        //     textStyle: {
        //         color: textColor
        //     },
        //     right: padding,
        //     top: 0,
        //     data: [{
        //         name: '财报',
        //         icon: 'pin',
        //     }, {
        //         name: '评论',
        //         icon: 'pin',
        //     }, {
        //         name: '信号',
        //         icon: 'pin',
        //     }],
        // },
        grid: {
            left: padding,
            right: padding,
            bottom: padding + 50,
            top: padding + 20,
            containLabel: true
        },
        textStyle: {
            color: textColor
        },
        xAxis: {
            type: 'category',
            data: categoryData,
            scale: true,
            boundaryGap: false,
            axisLine: {
                onZero: false,
                lineStyle: {
                    color: textColor
                }
            },
            axisLabel: {
                textStyle: {
                    color: textColor,
                }
            },
            splitLine: {show: false},
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
        },
        yAxis: {
            scale: true,
            axisLine: {
                show: false,
                lineStyle: {
                    color: textColor,
                }
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                textStyle: {
                    color: textColor,
                }
            },
        },
        dataZoom: [
            {
                show: true,
                type: 'slider',
                bottom: 10,
                height: 40,
                start: Math.max(0, 100 - Math.floor(100 * initDateRange / initDataLength)),
                end: 100,
                textStyle: {
                    color: textColor
                },
                borderColor: 'rgba(255,255,255,0.5)',
                fillerColor: 'rgba(255,255,255,0.25)',
                dataBackground: {
                    areaStyle: {
                        color: 'white'
                    },
                    lineStyle: {
                        color: 'white'
                    }
                }
            }
        ],
        series: [
            {
                name: '日K',
                type: 'candlestick',
                data: valueData,
                zlevel: 1,
                itemStyle: {
                    color: upColor,
                    color0: downColor,
                    borderColor: upBorderColor,
                    borderColor0: downBorderColor,
                }
            },
            {
                name: '风险线',
                type: 'line',
                data: [],
                symbol: 'none',
                lineStyle: {
                    color: downColor,
                    type: 'solid'
                }
            },
            {
                name: '回报线',
                type: 'line',
                data: [],
                symbol: 'none',
                lineStyle: {
                    color: upColor,
                    type: 'solid'
                }
            },
            {
                name: '财报',
                type: 'scatter',
                symbol: 'none',
                data: reportData,
                itemStyle: {
                    color: '#2979ff',
                },
                markPoint: {
                    data: reportData.map(([date, value], i) => ({
                        coord: [date, value],
                        name: `report-${i}`,
                        value: '报',
                        itemStyle: {
                            color: '#2979ff'
                        }
                    })),
                }
            },
            {
                name: '评论',
                type: 'scatter',
                symbol: 'none',
                data: commentData,
                itemStyle: {
                    color: '#b22a00',
                },
                markPoint: {
                    data: commentData.map(([date, value], i) => {
                        const offset = reportData.find(r => r[0] === date) ? 1 : 0;
                        return {
                            coord: [date, value],
                            name: `comment-${i}`,
                            value: '评',
                            symbolOffset: [0, `${-80*offset}%`],
                            itemStyle: {
                                color: '#b22a00'
                            }
                        }
                    }),
                }
            },
            {
                name: '信号',
                type: 'scatter',
                symbol: 'none',
                data: signalData,
                itemStyle: {
                    color: '#651fff',
                },
                markPoint: {
                    data: signalData.map(([date, value], i) => {
                        let offset = reportData.find(r => r[0] === date) ? 1 : 0;
                        offset += commentData.find(r => r[0] === date) ? 1 : 0;
                        return {
                            coord: [date, value],
                            name: `comment-${i}`,
                            value: '信',
                            symbolOffset: [0, `${-80*offset}%`],
                            itemStyle: {
                                color: '#651fff'
                            }
                        }
                    }),
                }
            }
        ]
    };
}

export default memo(StockChart);

