import { Button, ButtonGroup, Card, CircularProgress, Fab, IconButton, makeStyles, Paper } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { getStockUpdatableList, useStockInfo } from '../api/stock';
import { FixedTable, FullHeightVirtualScrollTable } from '../Table';
import AddIcon from '@material-ui/icons/Add';
import { useGlobal } from '../lib/useGlobal';
import { BoolLabels, BusinessStabilityLabels, CashflowLabels, CeilingSourceFlags, ClientsLabels, CompanyCeilingLabels, CompanyGrowthLabels, CompanyStabilityLabels, CompetitiveAdvantageLabels, CyclePositionLabels, DemandStageLabels, FieldNames, FTWidthLabels, IndustrialChainPositionImportanceLabels, IndustryCeilingLabels, ManagementLabels, MedTermTrendLabels, PTWidthLabels, ReturnLabels, SecurityImportanceLabels, ShortWishlistLabels, SupplyStageLabels, TrendStabilityLabels, TrendStageLabels, TrendStrengthLabels, TrendSustainabilityLabels, TrendWidthLabels } from '../lib/enums';
import { useApiState } from '../api/common';
import useErrorMessage from '../lib/useErrorMessage';
import { bn, bns } from '../lib/bn';
import { getUsers } from '../api/user';
import { getEnums } from '../api/enum';
import bigDecimal from 'js-big-decimal';
import { Checkbox } from '@material-ui/core';
import { useCoCallback } from '../lib/useCo';
import createExcel from '../Table/excel';
import { useSnackbar } from 'notistack';
import { AddRounded, GetAppRounded } from '@material-ui/icons';
import ImportStockInfoDialog from './ImportStockInfoDialog';
import { Link } from 'react-router-dom';


/** 
 * @typedef {{
 *   code: string,
 *   name: string,
 *   industrial_Chain: string,
 *   section: string,
 *   product: string,
 *   sw1: string,
 *   sw2: string,
 *   sw3: string,
 *   cluster_Layer_Value: string,
 *   coverer: string,
 *   business_Stability: string,
 *   industry_Ceiling: string,
 *   demand_Stage: string,
 *   supply_Stage: string,
 *   penetration_Rate: string,
 *   cr_N_Value: string,
 *   cr_N: string,
 *   industrial_Chain_Position_Importance: string,
 *   cashflow: string,
 *   return: string,
 *   company_Stability: string,
 *   cycle_Causation: string[],
 *   cycle_Position: string,
 *   company_Ceiling: string,
 *   ceiling_Source: string[],
 *   company_Growth: string,
 *   expected_CAGR_Bottomline: string,
 *   expected_CAGR_Topline: string,
 *   clients: string,
 *   security_Importance: string,
 *   competitive_Advantage: string,
 *   management: string,
 *   trend_Width: string,
 *   trend_Exists: string,
 *   logic: string,
 *   med_Term_Trend: string,
 *   short_Wishlist: string,
 *   ft_Width: string,
 *   pt_Width: string,
 *   trend_Sustainability: string,
 *   trend_Stability: string,
 *   trend_Strength: string,
 *   trend_Stage: string,
 *   alert_Before_Next_FS: string,
 *   read_Next_FS: string,
 *   none?: undefined,
 * }} StockInfoRow 
 */

/**
 * @param {bigDecimal} n 
 */
function ns(n) {
    return n ? n.getValue() : "";
}

/**
 * @param {import('../api/stock').StockUpdatableInfo} r 
 * @param {{[userId: number]: string}} users
 * @param {import('../api/enum').Enums} enums
 * @returns { StockInfoRow }
 */
 function renderRow(r, users, enums) {
    const cat = {
        industrial_Chain: r.industrial_Chain_ID === 0 ? '-' : enums.industrialChainList.find(i => i.industrial_Chain_ID === r.industrial_Chain_ID)?.industrial_Chain_Name || '',
        section: r.section_ID === 0 ? '-' : enums.sectionList.find(i => i.section_ID === r.section_ID)?.section_Name || '',
        product: r.product_ID === 0 ? '-' : enums.productList.find(i => i.product_ID === r.product_ID)?.product_Name || '',
        sw1: r.sw1_ID === 0 ? '-' : enums.sw1List.find(i => i.sw1_ID === r.sw1_ID)?.sw1_Name || '',
        sw2: r.sw2_ID === 0 ? '-' : enums.sw2List.find(i => i.sw2_ID === r.sw2_ID)?.sw2_Name || '',
        sw3: r.sw3_ID === 0 ? '-' : enums.sw3List.find(i => i.sw3_ID === r.sw3_ID)?.sw3_Name || '',
    };
    const cluster_Layer_Key = ['', 'industrial_Chain', 'section', '', '', 'product', 'sw1', 'sw2', 'sw3'][r.cluster_Layer || 0];
    return {
        code: r.code,
        name: r.name,
        coverer: r?.coverer?.map(id => users[id]).filter(Boolean).join(','),
        ...cat,
        cluster_Layer_Value: cat[cluster_Layer_Key],
        business_Stability: BusinessStabilityLabels[r.business_Stability],
        industry_Ceiling: IndustryCeilingLabels[r.industry_Ceiling],
        demand_Stage: DemandStageLabels[r.demand_Stage],
        supply_Stage: SupplyStageLabels[r.supply_Stage],
        penetration_Rate: ns(r.penetration_Rate),
        cr_N_Value: ns(r.cr_N_Value),
        cr_N: `${r.cr_N}`,
        industrial_Chain_Position_Importance: IndustrialChainPositionImportanceLabels[r.industrial_Chain_Position_Importance],
        cashflow: CashflowLabels[r.cashflow],
        'return': ReturnLabels[r['return']],
        company_Stability: CompanyStabilityLabels[r.company_Stability],
        cycle_Causation: enums.cycleCausationFlagList
            .filter(item => (item.cycle_Causation_Flag & r.cycle_Causation))
            .map(item => item.cycle_Causation_Name),
        cycle_Position: CyclePositionLabels[r.cycle_Position],
        company_Ceiling: CompanyCeilingLabels[r.company_Ceiling],
        ceiling_Source: CeilingSourceFlags.filter(flag => r.ceiling_Source & flag.id)
            .map(flag => flag.name),
        company_Growth: CompanyGrowthLabels[r.company_Growth],
        expected_CAGR_Bottomline: ns(r.expected_CAGR_Bottomline),
        expected_CAGR_Topline: ns(r.expected_CAGR_Topline),
        clients: ClientsLabels[r.clients],
        security_Importance: SecurityImportanceLabels[r.security_Importance],
        competitive_Advantage: CompetitiveAdvantageLabels[r.competitive_Advantage],
        management: ManagementLabels[r.management],
        trend_Width: TrendWidthLabels[r.trend_Width],
        trend_Exists: BoolLabels[r.trend_Exists],
        logic: BoolLabels[r.logic],
        med_Term_Trend: MedTermTrendLabels[r.med_Term_Trend],
        short_Wishlist: ShortWishlistLabels[r.short_Wishlist],
        ft_Width: FTWidthLabels[r.ft_Width],
        pt_Width: PTWidthLabels[r.pt_Width],
        trend_Sustainability: TrendSustainabilityLabels[r.trend_Sustainability],
        trend_Stability: TrendStabilityLabels[r.trend_Stability],
        trend_Strength: TrendStrengthLabels[r.trend_Strength],
        trend_Stage: TrendStageLabels[r.trend_Stage],
        alert_Before_Next_FS: BoolLabels[r.alert_Before_Next_FS],
        read_Next_FS: BoolLabels[r.read_Next_FS],
    }
}

/** @type {import('../Table').ColumnOption<StockInfoRow>[]} */
const columns = [
    // === 基本信息 === //
    {
        field: 'name',
        label: 'Name',
        width: 150,
        model: 'string',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.name}</Link>
    },
    {
        field: 'code',
        label: FieldNames.code,
        width: 150,
        model: 'string',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code}</Link>
    },
    {
        field: 'section',
        label: '板块',
        width: 100,
        model: 'enum',
    },
    {
        field: 'industrial_Chain',
        label: '产业链',
        width: 100,
        model: 'enum',
    },
    {
        field: 'product',
        label: '产品和服务',
        width: 100,
        model: 'string',
    },
    {
        field: 'sw1',
        label: 'SW1',
        width: 100,
        model: 'string',
    },
    {
        field: 'sw2',
        label: 'SW2',
        width: 100,
        model: 'string',
    },
    {
        field: 'sw3',
        label: 'SW3',
        width: 100,
        model: 'string',
    },
    {
        field: 'cluster_Layer_Value',
        label: '归层的内容',
        width: 100,
        model: 'string',
    },
    {
        field: 'coverer',
        label: FieldNames.coverer,
        width: 100,
        model: 'enum'
    },
    // === 行业信息 === //
    {
        field: 'business_Stability',
        label: FieldNames.business_Stability,
        width: 100,
        model: 'enum',
    },
    {
        field: 'industry_Ceiling',
        label: FieldNames.industry_Ceiling,
        width: 100,
        model: 'enum'
    }, 
    {
        field: 'demand_Stage',
        label: FieldNames.demand_Stage,
        width: 100,
        model: 'enum'
    },
    {
        field: 'supply_Stage',
        label: FieldNames.supply_Stage,
        width: 100,
        model: 'enum'
    },
    {
        field: 'penetration_Rate',
        label: FieldNames.penetration_Rate,
        width: 100,
        model: 'number',
        render: row => row.penetration_Rate ? bns(row.penetration_Rate, 2) : "",
    },
    {
        field: 'cr_N_Value',
        label: FieldNames.cr_N_Value,
        width: 100,
        model: 'number',
        render: row => row.cr_N_Value ? bns(row.cr_N_Value, 2) : "",
    },
    {
        field: 'cr_N',
        label: FieldNames.cr_N,
        width: 50,
        model: 'enum'
    },
    {
        field: 'industrial_Chain_Position_Importance',
        label: FieldNames.industrial_Chain_Position_Importance,
        width: 100,
        model: 'enum',
    },
    // === 个股信息 === //
    {
        field: 'cashflow',
        label: FieldNames.cashflow,
        width: 100,
        model: 'enum'
    },
    {
        field: 'return',
        label: FieldNames['return'],
        width: 100,
        model: 'enum'
    },
    {
        field: 'company_Stability',
        label: FieldNames.company_Stability,
        width: 100,
        model: 'enum'
    },
    {
        field: 'cycle_Causation',
        label: FieldNames.cycle_Causation,
        width: 150,
        model: 'enum',
        render: row => row.cycle_Causation.join(",")
    },
    {
        field: 'cycle_Position',
        label: FieldNames.cycle_Position,
        width: 100,
        model: 'enum'
    },
    {
        field: 'company_Ceiling',
        label: FieldNames.company_Ceiling,
        width: 100,
        model: 'enum'
    },
    {
        field: 'ceiling_Source',
        label: FieldNames.ceiling_Source,
        width: 150,
        model: 'enum',
        render: row => row.ceiling_Source.join(",")
    },
    {
        field: 'company_Growth',
        label: FieldNames.company_Growth,
        width: 100,
        model: 'enum'
    },
    {
        field: 'expected_CAGR_Bottomline',
        label: FieldNames.expected_CAGR_Bottomline,
        width: 100,
        model: 'number',
        render: row => row.expected_CAGR_Bottomline ? bns(row.expected_CAGR_Bottomline, 2) : "",
    },
    {
        field: 'expected_CAGR_Topline',
        label: FieldNames.expected_CAGR_Topline,
        width: 100,
        model: 'number',
        render: row => row.expected_CAGR_Bottomline ? bns(row.expected_CAGR_Bottomline, 2) : "",
    },
    {
        field: 'clients',
        label: FieldNames.clients,
        width: 100,
        model: 'enum'
    },
    {
        field: 'security_Importance',
        label: FieldNames.security_Importance,
        width: 100,
        model: 'enum'
    },
    {
        field: 'competitive_Advantage',
        label: FieldNames.competitive_Advantage,
        width: 100,
        model: 'enum'
    },
    {
        field: 'management',
        label: FieldNames.management,
        width: 100,
        model: 'enum'
    },
    // === 行情信息 === //
    {
        field: 'trend_Width',
        label: FieldNames.trend_Width,
        width: 100,
        model: 'enum'
    },
    {
        field: 'trend_Exists',
        label: FieldNames.trend_Exists,
        width: 100,
        model: 'enum'
    },
    {
        field: 'logic',
        label: FieldNames.logic,
        width: 100,
        model: 'enum'
    },
    {
        field: 'med_Term_Trend',
        label: FieldNames.med_Term_Trend,
        width: 100,
        model: 'enum'
    },
    {
        field: 'short_Wishlist',
        label: FieldNames.short_Wishlist,
        width: 100,
        model: 'enum'
    },
    {
        field: 'ft_Width',
        label: FieldNames.ft_Width,
        width: 100,
        model: 'enum'
    },
    {
        field: 'pt_Width',
        label: FieldNames.pt_Width,
        width: 100,
        model: 'enum'
    },
    {
        field: 'trend_Sustainability',
        label: FieldNames.trend_Sustainability,
        width: 100,
        model: 'enum'
    },
    {
        field: 'trend_Stability',
        label: FieldNames.trend_Stability,
        width: 100,
        model: 'enum'
    },
    {
        field: 'trend_Strength',
        label: FieldNames.trend_Strength,
        width: 100,
        model: 'enum'
    },
    {
        field: 'trend_Stage',
        label: FieldNames.trend_Stage,
        width: 100,
        model: 'enum'
    },
    {
        field: 'alert_Before_Next_FS',
        label: FieldNames.alert_Before_Next_FS,
        width: 100,
        model: 'enum'
    },
    {
        field: 'read_Next_FS',
        label: FieldNames.read_Next_FS,
        width: 100,
        model: 'enum'
    },
    {
        field: 'none',
        label: null,
        model: 'none',
        sortable: false
    }
];

/** 
 * @type {import('../Table').SorterAndFilters<StockInfoRow>} */
const initSorterAndFilters = null;

/** @type {import('../Table').Fields<StockInfoRow>[]} */
const BasicVisibleColumns = [
    'code', 'name', 'coverer'
];

/** @type {import('../Table').Fields<StockInfoRow>[]} */
const CategoryVisibleColumns = [
    'section', 'industrial_Chain', 'sw1', 'sw2', 'sw3', 'product', 'cluster_Layer_Value', 
];

/** @type {import('../Table').Fields<StockInfoRow>[]} */
const IndustryVisibleColumns = [
    'business_Stability', 'industry_Ceiling', 'demand_Stage', 
    'supply_Stage', 'penetration_Rate', 'cr_N_Value', 
    'cr_N', 'industrial_Chain_Position_Importance'
];

/** @type {import('../Table').Fields<StockInfoRow>[]} */
const StockVisibleColumns = [
    'cashflow', 'return', 'company_Stability', 'cycle_Causation', 
    'cycle_Position', 'company_Ceiling', 'ceiling_Source', 
    'company_Growth', 'expected_CAGR_Bottomline', 'expected_CAGR_Topline', 
    'clients', 'security_Importance', 'competitive_Advantage', 'management',
];

/** @type {import('../Table').Fields<StockInfoRow>[]} */
const TrendVisibleColumns = [
    'trend_Width', 'trend_Exists', 'logic', 'med_Term_Trend', 
    'short_Wishlist', 'ft_Width', 'pt_Width', 'trend_Sustainability', 
    'trend_Stability', 'trend_Strength', 'trend_Stage', 
    'alert_Before_Next_FS', 'read_Next_FS',
];

/** @type {import('../Table').FullHeightVirtualScrollTableComponent<StockInfoRow>} */
// @ts-ignore
const Table = FullHeightVirtualScrollTable;

export default function StockInfoTable({  }) {
    const [categoryCols, setCategoryCols] = useState(true);
    const [industryCols, setIndustryCols] = useState(true);
    const [stockCols, setStockCols] = useState(true);
    const [trendCols, setTrendCols] = useState(true);
    const visibleColumns = useMemo(() => {
        /** @type {import('../Table').Fields<StockInfoRow>[]} */
        const r = [
            ...BasicVisibleColumns, 
            ...categoryCols ? CategoryVisibleColumns : [],
            ...industryCols ? IndustryVisibleColumns : [],
            ...stockCols ? StockVisibleColumns : [],
            ...trendCols ? TrendVisibleColumns : [],
            'none'
        ];
        return r;
    }, [categoryCols, industryCols, stockCols, trendCols]);
    const [users, usersLoading, usersError] = useApiState(getUsers);
    const [enums, enumsLoading, enumsError] = useApiState(getEnums);
    const [reload, setReload] = useState(0);
    const [stocks=[], stocksLoading, stocksError] = useApiState(getStockUpdatableListReloadable, reload);
    const data = useMemo(() => {
        if (!stocks || !users || !enums) return [];
        /** @type {{[userId: number]: string}} */
        const userMap = {};
        users.forEach(u => userMap[u.user_ID] = u.display_Name);
        return stocks.map(s => renderRow(s, userMap, enums));
    }, [stocks, users, enums]);
    const [sorterAndFilters, setSorterAndFilters] = useState(initSorterAndFilters);
    useErrorMessage(usersError || enumsError || stocksError);
    const [exportInProgress, setExportInProgress] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [showImport, setShowImport] = useState(false);
    const exportExcel = useCoCallback(function*(){
        try {
            setExportInProgress(true);
            const filename = `个股信息表`;
            yield createExcel(data, columns, visibleColumns.slice(0, visibleColumns.length-1), sorterAndFilters, {
                code: 'string',
                name: 'string',
                industrial_Chain: 'string',
                section: 'string',
                sw1: 'string',
                sw2: 'string',
                sw3: 'string',
                product: 'string',
                cluster_Layer_Value: 'string',
                coverer: 'string',
                business_Stability: 'string',
                industry_Ceiling: 'string',
                demand_Stage: 'string',
                supply_Stage: 'string',
                penetration_Rate: 'number',
                cr_N_Value: 'number',
                cr_N: 'number',
                industrial_Chain_Position_Importance: 'string',
                cashflow: 'string',
                return: 'string',
                company_Stability: 'string',
                cycle_Causation: 'string',
                cycle_Position: 'string',
                company_Ceiling: 'string',
                ceiling_Source: 'string',
                company_Growth: 'string',
                expected_CAGR_Bottomline: 'number',
                expected_CAGR_Topline: 'number',
                clients: 'string',
                security_Importance: 'string',
                competitive_Advantage: 'string',
                management: 'string',
                trend_Width: 'string',
                trend_Exists: 'string',
                logic: 'string',
                med_Term_Trend: 'string',
                short_Wishlist: 'string',
                ft_Width: 'string',
                pt_Width: 'string',
                trend_Sustainability: 'string',
                trend_Stability: 'string',
                trend_Strength: 'string',
                trend_Stage: 'string',
                alert_Before_Next_FS: 'string',
                read_Next_FS: 'string',
                none: 'string',
            }, filename, "个股信息表");
        } catch (e) {
            console.warn(e);
            enqueueSnackbar('生成Excel文件出错');
        } finally {
            setExportInProgress(false);
        }
    }, [data, columns, visibleColumns, sorterAndFilters]);
    
    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Paper style={{ padding: 16, paddingTop: 8, paddingBottom: 8, marginBottom: 10 }}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{width: 200}}>
                    <Checkbox checked={categoryCols} onChange={e => setCategoryCols(e.target.checked)} color="primary" /> 分类信息
                </div>
                <div style={{width: 200}}>
                    <Checkbox checked={industryCols} onChange={e => setIndustryCols(e.target.checked)} color="primary" /> 行业信息
                </div>
                <div style={{width: 200}}>
                    <Checkbox checked={stockCols} onChange={e => setStockCols(e.target.checked)} color="primary" /> 个股信息
                </div>
                <div style={{width: 200}}>
                    <Checkbox checked={trendCols} onChange={e => setTrendCols(e.target.checked)} color="primary" /> 行情信息
                </div>
            </div>
        </Paper>
        <div style={{ flex: 1, position: 'relative' }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, display: 'flex' }}>
                <Table
                    data={data}
                    columns={columns}
                    sorterAndFilters={sorterAndFilters}
                    onSorterAndFiltersChange={setSorterAndFilters}
                    variant="full"
                    visibleColumnOrder={visibleColumns}
                />
            </div>
        </div>
        <div style={{height:60}}>
            <Fab 
                size="small" 
                color="primary" 
                style={{ position: 'fixed', bottom: 10, left: 20 }} 
                onClick={() => setShowImport(true)}
            >
                <AddRounded />
            </Fab>
            <Fab
                size="small" 
                color="primary" 
                style={{ position: 'fixed', right: 20, bottom: 10 }}
                disabled={exportInProgress}
                onClick={exportExcel}
            >
                { exportInProgress ? <CircularProgress color="inherit" size={20} /> : <GetAppRounded /> }
            </Fab>
        </div>
        <ImportStockInfoDialog
            cycleCausationFlagList={enums?.cycleCausationFlagList}
            open={showImport} 
            onClose={()=>setShowImport(false)}
            onReload={()=>setReload(r => r+1)}
        />
    </div>
}

function getStockUpdatableListReloadable(ignored) {
    return getStockUpdatableList();
}
