import { get } from "./common";
import BigDecimal from 'js-big-decimal';
import { bn } from "../lib/bn";

/**
 * @typedef {{
 *   id: number,
 *   code: string,
 *   aum_Mn: BigDecimal
 * }} Fund
 */ 

/**
 * @returns { Promise<Fund[]> }
 */
export async function getAllFunds() {
    const list = await get('/api/fund');
    return list.map(item => ({
        ...item,
        aum_Mn: bn(item.aum_Mn)
    }));
}