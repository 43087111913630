import { makeStyles, Typography } from '@material-ui/core';
import { green, red, grey, yellow } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import SmallCheckbox from '../components/SmallCheckbox';
import React, { memo, useMemo, useState } from 'react';
import bigDecimal from 'js-big-decimal';
import { date2quarter, date2semi, date2str, quarterFromDays2000, today } from '../lib/date';
import { ClusterLayerLabels, FieldNames, ShortWishlistLabels, StatusLabels } from '../lib/enums';
import { bns, div } from '../lib/bn';
import { useApiState } from '../api/common';
import { getUsersByIds } from '../api/user';
import CreateRounded from '@material-ui/icons/CreateRounded';
import RequirePermission from '../components/RequirePermission';
import StockIsEditable from './StockIsEditable';
import CovererDialog from '../components/CovererDialog';
import { getShortable } from '../api/shortable';
import SmallRadioBox from '../components/SmallRadioBox';


const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    col: {
        marginRight: theme.spacing(1)
    },
    mainRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    firstCol: {
        flex: 20.5,
        marginRight: theme.spacing(2),
    },
    firstRow:{
        display: 'flex',
        paddingRight: theme.spacing(1),
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    rise: {
        lineHeight: 1.0,
        color: red[600],
    },
    fall: {
        lineHeight: 1.0,
        color: green[600]
    },
    price: {
        fontSize: 24,
        fontWeight: 500
    },
    priceAlt: {
        fontSize: 16
    },
    priceInfo: {
        fontSize: 12,
        opacity: 0.5,
        lineHeight: 1.2,
    },
    infoCol: {
        fontSize: 12,
        lineHeight: 1.6,
        
    },
    checkCol: {
        fontSize: 12,
        paddingLeft: theme.spacing(1),
    },
    checkLabel: {

    },
    reportCol: {
        flex:3.5,
        fontSize: 12,
        lineHeight: 1.6,
        paddingRight: theme.spacing(1),
    },
    reportLabel: {
        fontWeight: 'bold',
        color: grey[800],
        paddingRight: 2,
        paddingLeft: 2,
        backgroundColor: grey[200],
        borderRadius: 2,
        marginRight: theme.spacing(0.2),
    },
    selectCol:{
        flex:2,
        paddingRight: theme.spacing(1),
        fontSize: 12
    },
    selector: {
    },
    statusCol:{
        flex:2,
        display:'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'space-between',
    },
    statusLabel:{
        display: 'flex',
        padding: '0 8px',
        height: 36,
        backgroundColor: grey[800],
        borderStyle: "solid",
        borderRadius:8,
        borderWidth:1,
        borderColor: yellow[500],
        fontSize: 18,
        fontWeight: 600,
        color: yellow[500],
        alignItems: "center",
        justifyContent: 'center',
    },
    respondUser: {
        marginTop: theme.spacing(0.5),
        textAlign: 'right',
        fontSize: 12,
    },
    labelRow: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        '& > *': {
            marginLeft: theme.spacing(0.5),
        },
    },
}));

/**
 * @param  {...number} ids 
 */
function getUsersByIdList(...ids) {
    return getUsersByIds(ids);
}

/**
 * @param {{
 *   edit: boolean,
 *   stock: import('../api/stock').StockInfo,
 *   enums: import('../api/enum').Enums,
 *   reports: { 
 *     noticeList: import('../api/stock').StockReportQuarterlyNotice[], 
 *     expressList: import('../api/stock').StockReportQuarterlyExpress[], 
 *     faList: import('../api/stock').StockReportQuarterlyFA[] 
 *   },
 *   style?: React.CSSProperties,
 *   setStockFields: (fields?: import('./types').SetStockFields) => void,
 *   onReloadStock: () => void,
 * }} param0 
 */
function TitleBar({ edit, style = null, stock, enums, reports, setStockFields, onReloadStock }) {
    const classes = useStyles();

    const [coverers] = useApiState(getUsersByIdList, ...stock.coverer);

    const [checkedDaFenYouXiao, setCheckedDaFenYouXiao] = useState(true);
    const handleChangeDaFenYouXiao = (event) => {
        setCheckedDaFenYouXiao(event.target.checked);
    };

    const [shortable] = useApiState(getShortable, stock.code);
    const shortableMsg = shortable ? `$${bns(div(shortable.total_USD, '1000000'),1)}mn/${bns(shortable.weighted_Rate)}` : ['否', '是', '否'][stock.shortable_Or_Default];

    const [showCoverer, setShowCoverer] = useState(false);

    const prevReport = useMemo(() => {
        let type = null;
        let date = 0;
        let period;
        const t = today();
        if (reports?.faList) {
            reports.faList.forEach(r => {
                if (r.fa_Release_Date < t && r.fa_Release_Date > date) {
                    date = r.fa_Release_Date;
                    type = '报';
                    period = stock.quarter_Or_Semi ? 
                        date2quarter(r.rpt_Period) : 
                        date2semi(r.rpt_Period);
                }
            });
        }
        if (reports?.expressList) {
            reports.expressList.forEach(r => {
                if (r.express_Release_Date > date) {
                    date = r.express_Release_Date;
                    type = '快';
                    period = date2quarter(r.rpt_Period);
                }
            });
        }
        if (reports?.noticeList) {
            reports.noticeList.forEach(r => {
                if (r.notice_Release_Date > date) {
                    date = r.notice_Release_Date;
                    type = '预';
                    period = date2quarter(r.rpt_Period);
                }
            });
        }
        if (!period) {
            return '-';
        }
        return `${period}${type} ${date2str(date)}`;
    }, [stock, reports]);

    const nextReport = useMemo(() => {
        let type = null;
        let date = Number.MAX_SAFE_INTEGER;
        let period;
        const t = today();
        if (reports?.faList) {
            reports.faList.forEach(r => {
                if (r.fa_Expected_Release_Date >= t && r.fa_Expected_Release_Date < date) {
                    date = r.fa_Expected_Release_Date;
                    type = '报';
                    period = r.rpt_Period;
                }
            });
        }
        if (!period) {
            return '-';
        }
        return `${quarterFromDays2000(period)}${type} ${date2str(date)}`;
    }, [reports]);

    const clusterEnums = useMemo(() => [
        [], 
        enums.industrialChainList.map(ic => ({ id: ic.industrial_Chain_ID, name: ic.industrial_Chain_Name })),
        enums.sectionList.map(s => ({ id: s.section_ID, name: s.section_Name })),
        [],
        [],
        enums.productList.map(p => ({ id: p.product_ID, name: p.product_Name })),
        enums.sw1List.map(e => ({ id: e.sw1_ID, name: e.sw1_Name })),
        enums.sw2List.map(e => ({ id: e.sw2_ID, name: e.sw2_Name })),
        enums.sw3List.map(e => ({ id: e.sw3_ID, name: e.sw3_Name })),
    ], [enums]);

    let status = stock.status_Or_Default;
    if (status === 4/* pass */ && stock.short_Wishlist_Or_Default === 1/*YES*/) {
        status = 3;/* short */
    }

    return (
        <div className={classes.mainRow} style={style}>
            {/* col 1 */}
            <div className={classes.firstCol}>
                <div className={classes.firstRow}>
                    <div className={classes.col}>
                        <Typography variant="h3">{stock.name}</Typography>
                        <Typography variant="h3">{stock.code}</Typography>
                    </div>
                    <div className={classes.col}>
                        <div className={stock.price_Chg?.compareTo(new bigDecimal(0)) >= 0 ? classes.rise : classes.fall}>
                            <div>
                                <span className={classes.price}>
                                    {bns(stock.price_Close) }
                                </span>
                            </div>
                            <div>
                                <span className={classes.priceAlt}>
                                    &nbsp;{bns(stock.price_Chg)}&nbsp;({bns(stock.price_Chg_Pct?.multiply(new bigDecimal(100)))}%)
                                </span>
                            </div>
                        </div>
                        
                        <div className={classes.priceInfo}>
                            <span>
                                {stock.exchange}&nbsp;{stock.currency}&nbsp;{date2str(stock.date)}&nbsp;闭市
                            </span>
                        </div>
                    </div>

                    <div className={classes.infoCol} style={{}}>
                        <div>
                            <span>总分:&nbsp;</span>
                            <span>{bns(stock.total_Score, 1)}</span>
                        </div>
                        <div className={classes.infoCol}>
                            <span>市值(本):&nbsp;</span>
                            <span>{bns(stock.marketValue?.divide(new bigDecimal(100), 2))}亿</span>
                        </div>
                        <div>
                            <span>{ClusterLayerLabels[stock.cluster_Layer_Or_Default]}:&nbsp;</span>
                            <span>{clusterEnums[stock.cluster_Layer_Or_Default]?.find(item => item.id === stock.cluster_Layer_Value)?.name || '-'}</span>
                        </div>
                    </div>

                    <div className={classes.infoCol}>
                        <div>
                            <span>{FieldNames.quality_Score}:&nbsp;</span>
                            <span>{bns(stock.quality_Score, 1)}</span>
                        </div>
                        <div>
                            <span>PE-T0:&nbsp;</span>
                            <span>{bns(stock.pe_T0)}</span>
                        </div>
                        <div>
                            <span>风险线:&nbsp;</span>
                            <span>{bns(stock.risk_Line_Or_Default_Value)}</span>
                        </div>
                    </div>

                    <div className={classes.infoCol}>
                        <div>
                            <span>{FieldNames.pricing_Score}:&nbsp;</span>
                            <span>{bns(stock.pricing_Score, 1)}</span>
                        </div>
                        <div>
                            <span>PB-TTM:&nbsp;</span>
                            <span>{bns(stock.pb_TTM)}</span>
                        </div>
                        <div>
                            <span>回报线:&nbsp;</span>
                            <span>{bns(stock.reward_Line_Or_Default_Value)}</span>
                        </div>
                    </div>

                    <div className={classes.infoCol}>
                        <div>
                            <span>{FieldNames.trend_Score}:&nbsp;</span>
                            <span>{bns(stock.trend_Score_Or_Default, 1)}</span>
                        </div>
                        <div>
                            <span>可期-中:&nbsp;</span>
                            <span>{bns(stock.expected_CAGR)}</span>
                        </div>
                        <div>
                            <span>回报风险比:&nbsp;</span>
                            <span>{bns(stock.reward_Risk_Ratio)}</span>
                        </div>
                    </div>

                    <div className={classes.infoCol}>
                        <div>
                            <span>Ft:&nbsp;</span>
                            <span>{bns(stock.ft_Score)}</span>
                        </div>
                        <div>
                            <span>Pt:&nbsp;</span>
                            <span>{bns(stock.pt_Score)}</span>
                        </div>
                    </div>

                    <div className={classes.infoCol}>
                        <div>
                            <span>可空:&nbsp;{shortableMsg}</span>
                        </div>
                        <div style={{display:'flex'}}>
                            <div style={{height: 27, whiteSpace: 'nowrap', lineHeight: '27px'}}>{FieldNames.short_Wishlist}: {ShortWishlistLabels[stock.short_Wishlist_Or_Default]}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.reportCol}>
                <Tooltip title="上个业绩报告">
                    <div>
                        <span className={classes.reportLabel}>P</span>
                        <span> {prevReport}</span>
                    </div>
                </Tooltip>
                <Tooltip title="下个业绩报告">
                    <div>
                        <span className={classes.reportLabel}>N</span>
                        <span> {nextReport}</span>
                    </div>
                </Tooltip>
            </div>
            <div className={classes.selectCol}>
                <div className={classes.selector}>
                    <SmallRadioBox color='primary' size='small' disabled={!edit}
                        checked={stock.alert_Before_Next_FS_Or_Default === 1} 
                        onChange={e => setStockFields({ alert_Before_Next_FS: e.target.checked ? 1 : 2 })}/>
                    <span>{FieldNames.alert_Before_Next_FS}</span>
                </div>
                <div className={classes.selector}>
                    <SmallRadioBox color='primary' size='small' disabled={!edit}
                        checked={stock.read_Next_FS_Or_Default === 1} 
                        onChange={e => setStockFields({ read_Next_FS: e.target.checked ? 1 : 2 })}/>
                    <span>{FieldNames.read_Next_FS}</span>
                </div>
                <div className={classes.selector}>
                    <SmallCheckbox
                        checked={checkedDaFenYouXiao}
                        onChange={handleChangeDaFenYouXiao}
                        size="small"
                        disabled={!edit}
                    />
                    <span>打分有效</span>
                </div>
            </div>
            {/* col 4 */}
            <div className={classes.statusCol}>
                <div className={classes.statusLabel}>
                    <span>{StatusLabels[status]}</span>
                </div>
                <div className={classes.respondUser}>
                    <span>
                        负责人: {coverers?.map(c => c.display_Name).join("/\u200b") || '-'}
                        <StockIsEditable stock={stock}>
                            <RequirePermission required="editUsers">
                                <CreateRounded style={{cursor: 'pointer', marginLeft:8, verticalAlign:'middle',fontSize:16}} onClick={() => setShowCoverer(true)} fontSize="small"/>
                            </RequirePermission>
                        </StockIsEditable>
                    </span>
                </div>
            </div>
            <CovererDialog 
                code={stock.code} 
                open={showCoverer} 
                initialCovererIds={coverers?.map(c => c.user_ID)} 
                initialCovererNames={coverers?.map(c => c.display_Name)} 
                onClose={() => setShowCoverer(false)} 
                onReloadStock={onReloadStock}  
            />
        </div>
    );
}

export default memo(TitleBar);
