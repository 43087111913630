import bigDecimal from 'js-big-decimal';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import useAuth from '../api/auth';
import { deleteTicketComment, updateTicketComment } from '../api/ticket';
import { useCoCallback } from '../lib/useCo';

/**
 * @param {{
 *   onComplete: () => void,
 *   onDeleteComplete: () => void,
 * }} param0 
 */
export function useTicketCommentActions({
    onComplete,
    onDeleteComplete
}) {
    const [loginUser] = useAuth();
    const canSave = loginUser.permissions.indexOf('reviewTicket') >= 0;
    const [ saving, setSaving ] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const callbacks = useRef({
        onComplete,
        onDeleteComplete
    });
    useEffect(() => {
        callbacks.current.onComplete = onComplete;
        callbacks.current.onDeleteComplete = onDeleteComplete;
    }, [onComplete, onDeleteComplete])

    const save = useCoCallback(
        function*(isCancelled,
            /** @type {import('../api/ticket').TicketComment} */ comment,
            /** @type {Partial<import('../api/ticket').TicketFill>} */ editFields,
            /** 
             * @type {{
             *   valuation_Price_Multiple_Low: bigDecimal;
             *   valuation_Price_Multiple_High: bigDecimal;
             *   expected_Number: bigDecimal;
             *   valuation_Price_Low: bigDecimal;
             *   valuation_Price_High: bigDecimal;
             *   pricing_Score: string 
             * }} 
             */ defaults
        ){
            try {
                const merged = { ...comment, ...editFields };
                const requiredMissing = [];
                if (!merged.status) { requiredMissing.push('多'); }
                if (merged.logic == 1 && !merged.trend_Width) { requiredMissing.push('广度'); }
                if (merged.logic == 1 && !merged.trend_Sustainability) { requiredMissing.push('持续性'); }
                if (merged.logic == 1 && !merged.trend_Stability) { requiredMissing.push('稳定性'); }
                if (merged.logic == 1 && !merged.trend_Stage) { requiredMissing.push('阶段'); }
                if (merged.logic == 1 && !merged.num_Q) { requiredMissing.push('持续'); }

                if (requiredMissing.length > 0) {
                    throw new Error(`缺少必填字段：${requiredMissing.join("、")}`);
                }
                setSaving(true);
                yield updateTicketComment(comment.id, editFields);
                enqueueSnackbar('保存成功', { variant: 'success' });
                setSaving(false);
                callbacks.current.onComplete();
            } catch (e) {
                setSaving(false);
                console.warn(e);
                enqueueSnackbar(e.message);
            }
        }, 
        []
    );
    const onDelete = useCoCallback(function*(isCancelled, /** @type { number } */ticketCommentId){
        try {
            setSaving(true);
            yield deleteTicketComment(ticketCommentId);
            enqueueSnackbar('删除成功', { variant: 'success' });
            setSaving(false);
            callbacks.current.onDeleteComplete();
        } catch (e) {
            setSaving(false);
            console.warn(e);
            enqueueSnackbar(e.message);
        }
    }, []);
    return { canSave, onSave: save, isSaving: saving, onDelete };
    
}