import React, { memo, useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles, Paper } from "@material-ui/core";
import FieldLabel from '../components/FieldLabel';
import { yellow } from '@material-ui/core/colors';
import { bns, div, min, mul, bnsPercent } from '../lib/bn';
import { KellyConfidenceLabels, LiquidityLabels, PriceVolLabels } from '../lib/enums';
import SelectField from './SelectField';
import { useApiState } from '../api/common';
import { getAllFunds } from '../api/fund';
import { aum_One_Pct_Over_ADV, hard_Cap, kelly_Position_Or_Hard_Cap, liquidity } from '../lib/computed';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        position: 'relative',
    },
    edit: {
        position: 'absolute',
        right: 0,
        top: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        fontSize: 16,
        minWidth: 0,
        padding: 8,
        display: 'none',
        ':hover > &': {
            display: 'inline-flex'
        }
    },
    select: {
        width: '100%',
        fontSize: 12,
    },
    input: {
        width: '100%',
    },
    scoreLabel: {
        textAlign: 'center',
        fontSize: 16,
        color: yellow[400],
    },
    scoreValue: {
        textAlign: 'center',
        fontSize: 24,
        color: yellow[400],
    },
    scoreContainer: {
        marginBottom: -theme.spacing(3),
    },
    grid7col: {
        flexGrow: 0,
        maxWidth: '14.285714286%',
        flexBasis: '14.285714286%'
    }
}));

/**
 * @param {{
 *   edit: boolean,
 *   setEdit: (newEdit: boolean) => void,
 *   stock: import('../api/stock').StockInfo,
 *   setStockFields: (newValue) => void,
 *   external: { [key: string]: string }
 * }} param0 
 */
function OverAllMeasures({ edit, stock, setStockFields, external }) {
    const [funds = []] = useApiState(getAllFunds);
    const fundOptions = useMemo(() => {
        /** @type {{[id:number]: string}} */
        const r = {};
        if (funds) {
            funds.forEach(f => {
                r[f.id] = f.code;
            });
        }
        return r;
    }, [funds]);
    const [selectedFundId, setSelectedFundId] = useState(0);
    useEffect(() => {
        if (funds?.length && !selectedFundId) {
            setSelectedFundId(funds[0].id);
        }
    }, [funds]);
    const selectedFund = funds.find(f => f.id === selectedFundId);
    const computed = useMemo(() => ({
        aum: selectedFund?.aum_Mn,
        hard_Cap: hard_Cap(stock.avg_ADV, selectedFund?.aum_Mn),
        kelly_Position_Or_Hard_Cap: kelly_Position_Or_Hard_Cap(stock.avg_ADV, selectedFund?.aum_Mn, stock.kelly_Position),
        aum_One_Pct_Over_ADV: aum_One_Pct_Over_ADV(stock.avg_ADV, selectedFund?.aum_Mn),
        liquidity: liquidity(stock.avg_ADV, selectedFund?.aum_Mn),
    }), [selectedFund, stock]);
    
    const classes = useStyles();
    return <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={2}>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="凯利概率" dot="green" tooltip="对于股价向目标价格运动的确信度,默认为60,可以自己输入">
                    <SelectField
                        edit={edit}
                        value={ stock.kelly_Confidence }
                        defaultValue={stock.kelly_Confidence_Default}
                        onChange={v => setStockFields({ kelly_Confidence: v })}
                        options={KellyConfidenceLabels}
                    />
                </FieldLabel>
                <FieldLabel label="价格波动" dot="yellow" plain tooltip={<>平均波动:<br/>&lt;=16%,小;<br/>(16%-32%],中;<br/>(32%-48%],大;<br/>48%以上,特别大</>}>
                    {PriceVolLabels[stock.price_Vol]}
                </FieldLabel>
                <FieldLabel label="流动性" dot="yellow" plain tooltip={<>AUM/平均ADV:<br/>&lt;=50/3,好;<br/>(50/3,200/3],正常;<br/>200/3以上,差;</>}>
                    {LiquidityLabels[computed.liquidity]}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="基金-LS/LO" dot="yellow" plain tooltip="选择基金策略">
                    <SelectField
                        edit={true}
                        value={selectedFundId}
                        onChange={setSelectedFundId}
                        options={fundOptions}
                    />
                </FieldLabel>
                <FieldLabel label="10D波动(年)" dot="yellow" plain tooltip="最近10个交易日价格波动率(年化)">
                    {bns(stock.price_Vol_10D)}
                </FieldLabel>
                <FieldLabel label="Adv-10D($Mn)" dot="yellow" plain tooltip="最近10个交易日日均美金交易额">
                    {bns(stock.adv_10D)}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="质量Cap" dot="yellow" plain tooltip={<>质量分*2%<br/>如果质量分&lt;=0,Cap=0.5%</>}>
                    {bns(stock.quality_Cap_Pct, 1)}%
                </FieldLabel>
                <FieldLabel label="6M波动(年)" dot="yellow" plain tooltip="最近6个月价格波动率(年化)">
                    {bns(stock.price_Vol_6M)}
                </FieldLabel>
                <FieldLabel label="Adv-3M($Mn)" dot="yellow" plain tooltip="最近3个月日均美金交易额">
                    {bns(stock.adv_3M)}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="凯利/流动性仓位" dot="yellow" plain tooltip={<>% of AUM,取Min(凯利仓位或1/3平均ADV)</>}>
                    {bns(computed.kelly_Position_Or_Hard_Cap)}%
                </FieldLabel>
                <FieldLabel label="12M波动(年)" dot="yellow" plain tooltip="最近12个月价格波动率(年化)">
                    {bns(stock.price_Vol_1Y)}
                </FieldLabel>
                <FieldLabel label="平均ADV($Mn)" dot="yellow" plain tooltip="(Adv-10D($Mn)+Adv-3M($Mn))/2">
                    {bns(stock.avg_ADV)}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="1%AUM占平均" dot="yellow" plain tooltip="1%的策略AUM占日均交易量的比例">
                    {bnsPercent(computed.aum_One_Pct_Over_ADV)}
                </FieldLabel>
                <FieldLabel label="平均波动" dot="yellow" plain tooltip="(10D波动(年)+6M波动(年)+12M波动(年))/3">
                    {bns(stock.avg_Price_Vol)}
                </FieldLabel>
                <FieldLabel label="策略AUM" dot="yellow" plain tooltip="对应基金策略的AUM总和">
                    {bns(computed.aum) }
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel label="所属行业" plain>
                    {external?.['所属行业']}
                </FieldLabel>
                <FieldLabel label="最赚钱业务" plain>
                    {external?.['最赚钱业务']}
                </FieldLabel>
            </Grid>
            <Grid item className={classes.grid7col}>
                <FieldLabel col={0} row={2}>
                    <div className={classes.scoreLabel}>总分</div>
                    <div className={classes.scoreValue}>{bns(stock.total_Score, 1) || '-'}</div>
                </FieldLabel>
            </Grid>
        </Grid>
    </Paper>    
}

export default memo(OverAllMeasures);
