import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import bigDecimal from "js-big-decimal";
import React, { useEffect, useMemo, useState } from "react";
import { addNewStock } from "../api/stock";
import { val } from "../lib/bn";
import { BoolLabels, ClusterLayerLabels, FieldNames } from "../lib/enums";
import { readSheetFromFile } from "../lib/excel";
import { useCoCallback } from "../lib/useCo";
import { makePinyinKeyword } from "../lib/pinyin";
import { parseExcelDayNum } from "../lib/importer";

/**
 * @typedef {{
 *   code: string,
 *   code_Factset?: string,
 *   code_BBG?: string,
 *   quarterly_Report_Type?: string,
 *   ipo_Date?: string,
 *   exchange?: string,
 *   name?: string,
 *   industrial_Chain?: string,
 *   section?: string,
 *   product?: string,
 *   sw1?: string,
 *   sw2?: string,
 *   sw3?: string,
 *   cluster_Layer?: string,
 *   coverer?: string,
 *   shortable?: string,
 *   keyword?: string,
 *   currency?: string,
 *   introduce?: string,
 *   is_new?: string,
 * }} ImportableStockListItemRow
 */

const exchangeCurrencyMap = {
    'SSE': 'CNY',
    'SZSE': 'CNY',
    'AMEX': 'USD',
    'HKEX': 'HKD',
    'NASDAQ': 'USD',
    'TWSE': 'TWD',
    'NYSE': 'USD',
    'BJEX': 'CNY',
    'OO': 'USD',
};

const requiredFieldsForNew = [
    'code_Factset', 'code_BBG', 'quarterly_Report_Type', 'ipo_Date', 'exchange', 'name', 
];

function requiredString() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseRequiredString (str) {
        if (str) {
            return [true, str];
        } else {
            return [false, `不能为空`];
        }
    };
    return parseRequiredString;
}

function optionalString() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseOptionalString (str) {
        let s = str ? `${str}`.trim() : '';
        if (s === '-') {
            s = '';
        }
        return [true, s];
    };
    return parseOptionalString;
}

/**
 * @param {string[]} labels
 * @param {number[]} validLabelIndexes
 */
function optionalEnum(labels, validLabelIndexes=undefined) {
    const m = {};
    if (validLabelIndexes) {
        validLabelIndexes.forEach(i => m[labels[i]]= `${i}`)
    } else {
        labels.forEach((n,i) => m[n] = `${i}`);
    }
    
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseOptionalEnum(str) {
        if (!str) {
            return [true, ''];
        }
        if (str in m) {
            return [true, m[str]];
        }
        return [false, `的值只能是：${Object.keys(m).join(", ")}`];
    }
    return parseOptionalEnum;
}


/**
 * @param {string[]} labels 
 * @param {RegExp} splitter
 */
 function optionalEnumList(labels, splitter=/\s|[\/,\\]/g) {
    const m = {};
    labels.forEach((n,i) => m[n] = `${i}`);
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseOptionalEnum(str) {
        if (!str) {
            return [true, ''];
        }
        const userNames = str.split(splitter).map(s=>s.trim()).filter(Boolean);
        const userIds = [];
        for (const name of userNames) {
            if (!m[name]) {
                return [false, `包含无效的值：${name}`];
            }
            userIds.push(m[name]);
        }
        return [true, userIds.join(",")];
    }
    return parseOptionalEnum;
}


const yes = ['是', 'y', 'yes'];
const no = ['否', 'n', 'no'];

function bool() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
     function parseBool012(str) {
        if (!str) {
            return [true, ''];
        } else if (str == '-') {
            return [true, '0'];
        } else if (yes.indexOf(str.toLowerCase()) >= 0) {
            return [true, '1'];
        } else if (no.indexOf(str.toLowerCase()) >= 0) {
            return [true, '2'];
        } else {
            return  [false, `的值只能是'是'或'否'`];
        }
    }
    return parseBool012;
}

function optionalBigNumber() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
     function parseOptionalBigNumber(str) {
        if (!str) {
            return [true, ''];
        }
        /** @type {(v:bigDecimal)=>bigDecimal} */
        let negIfQuoted = a => a;
        const m = /^[(（]\s*(.*)\s*[)）]$/.exec(str);
        if (m) {
            str = m[1];
            negIfQuoted = a => a.negate();
        }
        let n = val(str);
        if (!n) {
            return [false, `必须是一个有效的数值`];
        }
        n = negIfQuoted(n);
        return [true, n.getValue()];
    }
    return parseOptionalBigNumber;
}

function optionalDate() {
    /**
     * @param {string} str 
     * @returns {[boolean, string]}
     */
    function parseOptionalDate(str) {
        if (!str) {
            return [true, ''];
        }
        const m = parseExcelDayNum(str);
        if (typeof(m) !== 'number') {
            return [false, `单元格日期格式错误`];
        }
        return [true, `${m}`];
    }
    return parseOptionalDate;
}

/**
 * @param {string[]} existingCodes
 * @param {import("../api/user").User[]} users
 * @param {import("../api/enum").Enums} enums
 */
function makeParser(existingCodes, users, enums) {
    const codeMap = {};
    if (existingCodes) {
        existingCodes.forEach(code => codeMap[code] = true);
    }
    const UserLabels = ['-'];
    if (users) {
        users.forEach(u => UserLabels[u.user_ID] = u.display_Name);
    }
    if (!enums) {
        enums = {
            industrialChainList: [],
            sectionList: [],
            productList: [],
            cycleCausationFlagList: [],
            sw1List: [],
            sw2List: [],
            sw3List: [],
        };
    }
    /** 
     * @type {{ 
     *   [key in keyof ImportableStockListItemRow] : (
     *     value: string,
     *   ) => [boolean, string]
     * }} 
     */
    const importableColFields = {
        code: requiredString(),
        name: optionalString(),
        code_Factset: optionalString(),
        code_BBG: optionalString(),
        quarterly_Report_Type: optionalEnum(['-', '季报', '半年报']),
        ipo_Date: optionalDate(),
        exchange: optionalString(),
        industrial_Chain: optionalString(),
        section: optionalString(),
        product: optionalString(),
        sw1: optionalString(),
        sw2: optionalString(),
        sw3: optionalString(),
        coverer: optionalEnumList(UserLabels),
        cluster_Layer: optionalEnum(ClusterLayerLabels, [0,1,2,3,4,5,8]),
        shortable: optionalEnum(BoolLabels),
        keyword: optionalString(),
        introduce: optionalString(),
    };
    
    const importableColAliases = {};

    for (const key of Object.keys(importableColFields)) {
        if (FieldNames[key]) {
            importableColAliases[FieldNames[key]] = key;
        }
    }

    /**
     * @param {string[][]} aoa 
     * @param {(setter:(v:React.ReactNode[])=>React.ReactNode[])=>void} setWarnings 
     * @param {(setter:(v:React.ReactNode[])=>React.ReactNode[])=>void} setErrors
     * @returns { {name: string, field: keyof ImportableStockListItemRow} [] }
     */
    function validateHeaders(aoa, setWarnings, setErrors) {
        const unrecognizedHeaders = [];
        const header = aoa[0];
        let codeFound = false;
        let anyOtherFound = false;
        /** @type  { {name: string, field: keyof ImportableStockListItemRow} [] } */
        const cols = [];
        for (let n of header) {
            let name = n;
            if (importableColAliases[n]) {
                n = importableColAliases[n];
            }
            if (!importableColFields[n]) {
                unrecognizedHeaders.push(n);
                cols.push(null);
            } else if (n === 'code') {
                cols.push({ name, field: n });
                codeFound = true;
            } else {
                // @ts-ignore
                cols.push({ name, field: n });
                anyOtherFound = true;
            }
        }
        let r = true;
        if (!codeFound) {
            setErrors(errs => [...errs, "找不到列'Wind Code'"]);
            r = false;
        }
        if (!anyOtherFound) {
            setErrors(errs => [...errs, "找不到任何可识别的数据列"]);
            r = false;
        }
        if (unrecognizedHeaders.length > 0) {
            setWarnings(ws => [...ws, <>以下的列在导入时会被忽略：<br/>{unrecognizedHeaders.join(", ")}</>])
        }
        return r ? cols : null;
    }
    
    /**
     * @param {{name: string, field: keyof ImportableStockListItemRow}[]} cols 
     * @param {string[][]} aoa 
     * @param {(setter:(v:React.ReactNode[])=>React.ReactNode[])=>void} setErrors
     * @returns {Promise<ImportableStockListItemRow[]>}
     */
    async function parseTable(cols, aoa, setErrors) {
        const errors = [];
        let moreErrors = 0;
        /** @type {ImportableStockListItemRow[]} */
        const result = [];
        r: for (let ri = 1; ri < aoa.length; ri++) {
            /** @type {ImportableStockListItemRow} */
            const row = { code: '' };
            const r = aoa[ri];
            for (let ci = 0; ci < r.length; ci++) {
                const col = cols[ci];
                if (!col) continue;
                const { name, field: cn } = col;
                const parser = importableColFields[cn];
                const [success, val] = parser(r[ci]);
                if (!success) {
                    if (errors.length < 5) {
                        errors.push(`第${ri+1}行出错：${name}${val}`);
                    } else {
                        moreErrors ++;
                    }
                    continue r;
                }
                row[cn] = val;
            }
            if (row.exchange) {
                if (exchangeCurrencyMap[row.exchange]) {
                    if (!row.currency) {
                        row.currency = exchangeCurrencyMap[row.exchange];
                    }
                } else {
                    if (errors.length < 5) {
                        errors.push(`第${ri+1}行出错：${FieldNames.exchange}的值只能是：${Object.keys(exchangeCurrencyMap).join(", ")}`);
                    } else {
                        moreErrors ++;
                    }
                    continue r;
                }
            }
            if (!row.keyword) {
                row.keyword = await makePinyinKeyword(row.name);
            }
            row.is_new = codeMap[row.code] ? '' : '1';
            if (row.is_new) {
                for (const f of requiredFieldsForNew) {
                    if (!row[f]) {
                        if (errors.length < 5) {
                            errors.push(`第${ri+1}行出错：新增的股票必须有${FieldNames[f]}字段`);
                        } else {
                            moreErrors ++;
                        }
                        continue r;
                    }
                }
            }
            result.push(row);
        }
        if (errors.length > 0) {
            setErrors(errs => [...errs, <>
                数据表中{errors.length+moreErrors}行数据识别失败，这些行不会被导入：
                {errors.map((e, i) => <span key={i}><br/>{e}</span>)}
                {moreErrors?<>...以及{moreErrors}条更多的问题</>:null}
            </>])
        }
        if (result.length === 0) {
            setErrors(errs => [...errs, "没有可导入的数据"]);
        }
        return result.length === 0 ? null : result;
    }

    return { validateHeaders, parseTable };
}

/** @type {React.ReactNode[]} */
const initReactNodes = [];

/** @type {ImportableStockListItemRow[]} */
const initRows = null;

/**
 * @param {{
 *   codes: string[],
 *   enums: import("../api/enum").Enums,
 *   users: import("../api/user").User[],
 *   open: boolean,
 *   onClose: () => void,
 *   onReload: () => void,
 * }} param0
 */
export default function ImportStockBasicInfoDialog({ codes, users, enums, open, onClose, onReload }) {
    const parser = useMemo(() => makeParser(codes, users, enums), [codes, users, enums]);
    const [imported, setImported] = useState(0);
    const [importedCount, setImportedCount] = useState({ success: 0, error: 0 });
    const [importCount, setImportCount] = useState({ create: 0, update: 0 });
    const [errors, setErrors] = useState(initReactNodes);
    const [warnings, setWarnings] = useState(initReactNodes);
    const [rows, setRows] = useState(initRows);
    useEffect(() => {
        if (open) {
            setImported(0);
            setImportedCount({ success: 0, error: 0 });
            setImportCount({ create: 0, update: 0 });
            setErrors([]);
            setWarnings([]);
            setRows(null);
        }
    }, [open]);
    const onSelect = useCoCallback(function*(_isCancelled, event) {
        setImported(0);
        setImportedCount({ success: 0, error: 0 });
        setImportCount({ create: 0, update: 0 });
        setErrors([]);
        setWarnings([]);
        setRows(null);
        if (!event?.target?.files[0]) return;
        try {
            const sheet = yield readSheetFromFile(event.target.files[0]);
            const cols = parser.validateHeaders(sheet, setWarnings, setErrors);
            if (!cols) {
                return;
            }
            /** @type {ImportableStockListItemRow[]} */
            const rows = yield parser.parseTable(cols, sheet, setErrors);
            if (!rows) {
                return;
            }
            setRows(rows);
            let create = 0, update = 0;
            for (const r of rows) {
                if (r.is_new) {
                    create ++;
                } else {
                    update ++;
                }
            }
            setImportCount({ create, update });
            console.log("data is ready for import, creating: ", rows.filter(r => r.is_new), "updating", rows.filter(r => !r.is_new));
        } catch (e) {
            console.warn(e);
            setErrors(errs => [...errs, "解析文件时发生错误：" + e.message]);
        }
    }, [parser]);
    const onSubmit = useCoCallback(function*(isCancelled) {
        setWarnings([]);
        setErrors([]);
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            try {
                setImported(i+1);
                yield addNewStock({
                    Code: row.code,
                    Code_Factset: row.code_Factset,
                    Code_BBG: row.code_BBG,
                    Name: row.name,
                    IPO_Date: row.ipo_Date == "" ? undefined : Number(row.ipo_Date),
                    Exchange: row.exchange,
                    Currency: row.currency,
                    Quarterly_Report_Type: Number(row.quarterly_Report_Type) || 0,
                    Keyword: row.keyword,
                    Industrial_Chain: row.industrial_Chain || undefined,
                    Section: row.section || undefined,
                    Product: row.product || undefined,
                    SW1: row.sw1 || undefined,
                    SW2: row.sw2 || undefined,
                    SW3: row.sw3 || undefined,
                    Coverer: row.coverer ? row.coverer.split(",").map(Number).filter(Boolean) : [],
                    Shortable: Number(row.shortable) || 0,
                    Cluster_Layer: Number(row.cluster_Layer) || 0,
                    Introduce: row.introduce,
                });
                setImportedCount(c => ({ success: c.success + 1, error: c.error }));
                setImported(i+2);
            } catch (e) {
                setImportedCount(c => ({ success: c.success, error: c.error + 1 }));
                setImported(i+2);
                setErrors(errs => {
                    const old = errs.length < 5 ? errs : errs.slice(1);
                    return [...old, `导入第${i+1}条数据出错：${e.message}`];
                });
            }
        }
        onReload();
    }, [rows, open]);
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>导入数据</DialogTitle>
            <DialogContent>
                <div style={{fontSize: 16, width: 300}}>
                    从此页面导出的表格可以直接作为模板导入，留空的单元格不会被修改。
                </div>
                <div style={{width: 300}}>
                    <div style={{marginTop: 16, marginBottom: 16}}>
                        <input disabled={Boolean(imported)} id="importReportFile" type="file" style={{fontSize: 16}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={onSelect} />
                    </div>
                </div>
                { (!imported && rows ) ? <Alert severity="success">成功识别{rows.length}条可导入数据，其中{importCount.create}条新增；{importCount.update}条更新</Alert> : null }
                { (imported && imported < rows?.length) ? (
                    <Alert severity="warning">
                        如果导入过程被中断，已经导入的数据仍然会存在。
                    </Alert>
                ) : null }
                { (imported && imported <= rows?.length) ? (<Alert severity="info">
                    正在导入第{imported}/{rows?.length}条数据
                    <br/>成功：{importedCount.success}
                    <br/>失败：{importedCount.error}
                </Alert>) : null}
                { (imported && imported > rows?.length && importedCount.success === 0 ) ? (
                    <Alert severity="error">导入完成，所有{importedCount.success}条数据均导入失败</Alert>
                ) : null}
                { (imported && imported > rows?.length && importedCount.success > 0 && importedCount.error > 0 ) ? (
                    <Alert severity="warning">导入完成，{importedCount.success}条数据导入成功；{importedCount.error}条数据导入失败</Alert>
                ) : null}
                { (imported && imported > rows?.length && importedCount.success > 0 && importedCount.error === 0 ) ? (
                    <Alert severity="success">导入完成，{importedCount.success}条数据导入成功</Alert>
                ) : null}
                { (imported && imported > rows?.length && importCount.create > 0 ) ? (
                    <Alert severity="info">新增的股票须刷新页面后才能搜索到</Alert>
                ) : null}
                { errors.map((m, i) => (
                        <Alert key={i} severity="error">{m}</Alert>
                ))}
                { warnings.map((m, i) => (
                        <Alert key={i} severity="warning">{m}</Alert>
                ))}
            </DialogContent>
            <DialogActions style={{position: 'sticky', left: 0, right: 0, bottom: 0, background: '#424242', zIndex: 1 }}>                
                { !imported ? (
                    <Button onClick={onClose}>
                        取消
                    </Button>
                ) : null}
                { !imported ? (
                    <Button disabled={Boolean(imported || !rows)} onClick={onSubmit} color="primary">
                        开始导入
                    </Button>
                ) : null}
                { (imported && imported <= rows?.length) ? (
                    <Button onClick={onClose} color="secondary">
                        中断导入
                    </Button>
                ) : null}
                { (imported && imported > rows?.length) ? (
                    <Button onClick={onClose}>
                        关闭
                    </Button>
                ) : null}
            </DialogActions>
        </Dialog>
    );
}