import { Fab, Paper, Tab, Tabs } from '@material-ui/core';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { FullHeightVirtualScrollTable } from '../../Table';
import { FieldNames } from '../../lib/enums';
import { useApiState } from '../../api/common';
import useErrorMessage from '../../lib/useErrorMessage';
import { useSnackbar } from 'notistack';
import { AddRounded, CheckRounded, CloseRounded } from '@material-ui/icons';
import { bns } from '../../lib/bn';
import { getShortableCNTable, getShortableCNTableVersions, invalidShortableCNTable, validShortableCNTable } from '../../api/shortable-cn';
import { urlParent } from '../../lib/url';
import { useCoCallback } from '../../lib/useCo';


/** 
 * @typedef {{
 *   code: string,
 *   type: string,
 *   gics: string,
 *   total_Shares: string,
 *   total_USD: string,
 *   weighted_Rate: string,
 *   baml_Rate: string,
 *   baml_USD: string,
 *   baml_Shares: string,
 *   citi_Rate: string,
 *   citi_USD: string,
 *   citi_Shares: string,
 *   clsa_Rate: string,
 *   clsa_USD: string,
 *   clsa_Shares: string,
 *   cs_Rate: string,
 *   cs_USD: string,
 *   cs_Shares: string,
 *   hsbc_Rate: string,
 *   hsbc_USD: string,
 *   hsbc_Shares: string,
 *   jpm_Rate: string,
 *   jpm_USD: string,
 *   jpm_Shares: string,
 *   ubs_Rate: string,
 *   ubs_USD: string,
 *   ubs_Shares: string,
 *   cicc_Rate: string,
 *   cicc_USD: string,
 *   cicc_Shares: string,
 * }} ShortableCNRenderedRow 
 */

const TypeLabels = ['-', 'CN', 'C2', 'C1'];

/**
 * @param {import('../../api/shortable-cn').ShortableCN} r
 * @returns { ShortableCNRenderedRow }
 */
function renderRow(r) {
    return {
        code: r.code,
        type: TypeLabels[r.type],
        gics: r.gics,
        total_Shares: bns(r.total_Shares),
        total_USD: bns(r.total_USD),
        weighted_Rate: bns(r.weighted_Rate),
        baml_Rate: bns(r.baml_Rate),
        baml_USD: bns(r.baml_USD),
        baml_Shares: bns(r.baml_Shares),
        citi_Rate: bns(r.citi_Rate),
        citi_USD: bns(r.citi_USD),
        citi_Shares: bns(r.citi_Shares),
        clsa_Rate: bns(r.clsa_Rate),
        clsa_USD: bns(r.clsa_USD),
        clsa_Shares: bns(r.clsa_Shares),
        cs_Rate: bns(r.cs_Rate),
        cs_USD: bns(r.cs_USD),
        cs_Shares: bns(r.cs_Shares),
        hsbc_Rate: bns(r.hsbc_Rate),
        hsbc_USD: bns(r.hsbc_USD),
        hsbc_Shares: bns(r.hsbc_Shares),
        jpm_Rate: bns(r.jpm_Rate),
        jpm_USD: bns(r.jpm_USD),
        jpm_Shares: bns(r.jpm_Shares),
        ubs_Rate: bns(r.ubs_Rate),
        ubs_USD: bns(r.ubs_USD),
        ubs_Shares: bns(r.ubs_Shares),
        cicc_Rate: bns(r.cicc_Rate),
        cicc_USD: bns(r.cicc_USD),
        cicc_Shares: bns(r.cicc_Shares),
    };
}

/** @type {import('../../Table').ColumnOption<ShortableCNRenderedRow>[]} */
const columns = [
    // === 基本信息 === //
    {
        field: 'code',
        label: FieldNames.code,
        width: 150,
        model: 'string',
        render: row => <Link to={`/stock/${row.code}`} target="_blank">{row.code}</Link>
    },
    {
        field: 'type',
        label: '类型',
        width: 100,
        model: 'enum',
    },
    {
        field: 'gics',
        label: 'GICS',
        width: 100,
        model: 'string'
    },
    { field: 'total_Shares', label: 'Total Shares', width: 100, model: 'number' },
    { field: 'total_USD', label: 'Total USD', width: 100, model: 'number' },
    { field: 'weighted_Rate', label: 'Weighted Rate', width: 100, model: 'number' },
    { field: 'baml_Rate', label: 'BAML Rate', width: 100, model: 'number' },
    { field: 'baml_USD', label: 'BAML USD', width: 100, model: 'number' },
    { field: 'baml_Shares', label: 'BAML Shares', width: 100, model: 'number' },
    { field: 'citi_Rate', label: 'CITI Rate', width: 100, model: 'number' },
    { field: 'citi_USD', label: 'CITI USD', width: 100, model: 'number' },
    { field: 'citi_Shares', label: 'CITI Shares', width: 100, model: 'number' },
    { field: 'clsa_Rate', label: 'CLSA Rate', width: 100, model: 'number' },
    { field: 'clsa_USD', label: 'CLSA USD', width: 100, model: 'number' },
    { field: 'clsa_Shares', label: 'CLSA Shares', width: 100, model: 'number' },
    { field: 'cs_Rate', label: 'CS Rate', width: 100, model: 'number' },
    { field: 'cs_USD', label: 'CS USD', width: 100, model: 'number' },
    { field: 'cs_Shares', label: 'CS Shares', width: 100, model: 'number' },
    { field: 'hsbc_Rate', label: 'HSBC Rate', width: 100, model: 'number' },
    { field: 'hsbc_USD', label: 'HSBC USD', width: 100, model: 'number' },
    { field: 'hsbc_Shares', label: 'HSBC Shares', width: 100, model: 'number' },
    { field: 'jpm_Rate', label: 'JPM Rate', width: 100, model: 'number' },
    { field: 'jpm_USD', label: 'JPM USD', width: 100, model: 'number' },
    { field: 'jpm_Shares', label: 'JPM Shares', width: 100, model: 'number' },
    { field: 'ubs_Rate', label: 'UBS Rate', width: 100, model: 'number' },
    { field: 'ubs_USD', label: 'UBS USD', width: 100, model: 'number' },
    { field: 'ubs_Shares', label: 'UBS Shares', width: 100, model: 'number' },
    { field: 'cicc_Rate', label: 'CICC Rate', width: 100, model: 'number' },
    { field: 'cicc_USD', label: 'CICC USD', width: 100, model: 'number' },
    { field: 'cicc_Shares', label: 'CICC Shares', width: 100, model: 'number' },
];

/** 
 * @type {import('../../Table').SorterAndFilters<ShortableCNRenderedRow>} */
const initSorterAndFilters = null;

/** @type {import('../../Table').Fields<ShortableCNRenderedRow>[]} */
const visibleColumns = columns.map(col => col.field);

/** @type {import('../../Table').FullHeightVirtualScrollTableComponent<ShortableCNRenderedRow>} */
// @ts-ignore
const Table = FullHeightVirtualScrollTable;

export default function ShortableCNTable({  }) {
    const version = /** @type {{version:string}} */(useParams())?.version;
    const history = useHistory();
    const [reload, setReload] = useState(0);
    const [versions] = useApiState(getShortableCNTableVersionsReloadable, reload);
    const { url } = useRouteMatch();
    let noVersionUrl = version ? urlParent(url) : url;

    useEffect(() => {
        if (version && versions && Number(version) !== versions.uploaded) {
            history.replace(noVersionUrl);
        }
    }, [version, versions]);

    const [table, loading, error] = useApiState(getShortableCNTableReloadable, version, reload);
    const data = useMemo(() => (table||[]).map(renderRow), [table]);
    useErrorMessage(error);
    
    const [submitting, setSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const onMakeValid = useCoCallback(function*(isCancelled, version){
        try {
            setSubmitting(true);
            yield validShortableCNTable(version);
            setSubmitting(false);
            enqueueSnackbar('上传数据已生效');
            history.replace(noVersionUrl);
            setReload(r => r+1);
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, []);

    const onMakeInvalid = useCoCallback(function*(isCancelled, version){
        try {
            setSubmitting(true);
            yield invalidShortableCNTable(version);
            setSubmitting(false);
            enqueueSnackbar('上传数据已取消');
            history.replace(noVersionUrl);
            setReload(r => r+1);
        } catch (e) {
            setSubmitting(false);
            enqueueSnackbar(e.message);
        }
    }, []);

    const [sorterAndFilters, setSorterAndFilters] = useState(initSorterAndFilters);
    
    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Paper>
            <Tabs value={version ? 'uploaded' : 'valid' } indicatorColor="primary">
                <Tab value='valid' label="已导入" onClick={() => history.push(noVersionUrl)} />
                {versions?.uploaded ? (
                    <Tab value='uploaded' label="等待导入" onClick={() => history.push(`${noVersionUrl}/${versions?.uploaded}`)} />
                ):null}
            </Tabs>
        </Paper>
        <div style={{ flex: 1, position: 'relative' }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, display: 'flex' }}>
                <Table
                    data={data}
                    columns={columns}
                    sorterAndFilters={sorterAndFilters}
                    onSorterAndFiltersChange={setSorterAndFilters}
                    variant="full"
                    visibleColumnOrder={visibleColumns}
                />
            </div>
        </div>
        <div style={{height:60}}>
            <Link to={`${url}/add`}>
                <Fab
                    size="small" 
                    color="primary" 
                    style={{ position: 'fixed', bottom: 10, left: 20 }}
                >
                    <AddRounded />
                </Fab>
            </Link>
            { version ? (
                <Fab
                    size="small" 
                    style={{ position: 'fixed', bottom: 10, right: 80 }} 
                    onClick={() => onMakeInvalid(version)}
                    disabled={submitting}
                >
                    <CloseRounded />
                </Fab>
            ) : null }
            { version ? (
                <Fab
                    size="small" 
                    color="primary" 
                    style={{ position: 'fixed', bottom: 10, right: 20 }} 
                    onClick={() => onMakeValid(version)}
                    disabled={submitting}
                >
                    <CheckRounded />
                </Fab>
            ) : null }
        </div>
    </div>
}

function getShortableCNTableReloadable(version, ignored) {
    return getShortableCNTable(version);
}

function getShortableCNTableVersionsReloadable(ignored) {
    return getShortableCNTableVersions();
}