import { Button, Checkbox, CircularProgress, Divider, ListItemText, makeStyles, Menu, MenuItem, Paper, Select, Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '../api/auth';
import { useApiState, withReload } from '../api/common';
import { getNextTradeDayPriceCloseForCodes } from '../api/stock';
import { getTicketCommentsOfTickets, getTicketFillsOfTickets, getTickets, updateTicketFill } from '../api/ticket';
import { getUsers, getUsersByIds } from '../api/user';
import CovererDialog from '../components/CovererDialog';
import { bns, div, mul, sub } from '../lib/bn';
import { date2quarter, date2quarterOrSemi, dayNumOfToday, dayNumToQuarterNum, fromDays2000, quarterFromDayNum, quarterNumToDayNum, toDate } from '../lib/date';
import { ShortableLabels, StatusLabels, StatusOptions } from '../lib/enums';
import { useCoCallback } from '../lib/useCo';
import { useApiCollectionFetcher } from '../lib/useCollector';
import useErrorMessage from '../lib/useErrorMessage';
import useQuery from '../lib/useQuery';
import { FullHeightVirtualScrollTable } from '../Table';

const StatusShortLabels = ['', 'C', 'W', 'S', 'P', ''];

/** 
 * @typedef {{
 *   theme: string,
 *   labels: string,
 *   fullName: string,
 *   introduce: string,
 *   deductedChg: string,
 *   revChg: string,
 *   profitChg: string,
 *   coverer: string,
 *   shortable: string,
 *   status: string,
 *   priceCloseChg: string,
 *   comment?: string,
 *   origin: import('../api/ticket').Ticket,
 * }} TicketTableItem 
 */

/**
 * @param {import('../api/ticket').Ticket} r
 * @param {import('../api/user').User[]} users
 * @param {import('../api/ticket').TicketFill[]} ticketFillList
 * @param {import('../api/stock').CodeDateAndPriceClose[]} nextTradeDayPrices
 * @returns { TicketTableItem }
 */
 function renderRow(r, users, ticketFillList, nextTradeDayPrices) {
    const q = date2quarterOrSemi(r.quarter_Or_Semi, r.rpt_Period);
    const t = ['', '预', '快', '财'][r.type];
    let labels = '';
    if (r.is_Core) labels += '核';
    if (r.is_Quality) labels += '质';
    if (r.is_Fast) labels += '快';
    if (!r.is_Read_Next) labels += '后';
    if (r.is_Hold) labels += 'H';
    if (r.is_Call) labels += 'C';
    if (r.is_Watch) labels += 'W';
    
    let shortable;
    if (r.shortable_Total_USD) {
        const mn = div(r.shortable_Total_USD, "1000000");
        shortable = `$${bns(mn, 1)}mn/${bns(r.shortable_Weighted_Rate)}`;
    } else {
        shortable = ShortableLabels[r.shortable];
    }
    let priceCloseChg = '';
    const nextPrice = nextTradeDayPrices?.find(item => item.code === r.code)?.price_Close;
    if (nextPrice) {
        priceCloseChg = bns(mul(
            div(
                sub(nextPrice, r.price_Close),
                r.price_Close
            ),
            100
        )) + "%"
    }

    return {
        theme: `${q}${t}`,
        labels,
        fullName: `${r.code}/${r.name}`,
        introduce: r.introduce,
        deductedChg: '',
        revChg: '',
        profitChg: '',
        coverer: ticketFillList?.filter(f => f.ticket_ID == r.id && users?.find(u => u.user_ID === f.user_ID)).map(f => users?.find(u => u.user_ID === f.user_ID)?.display_Name||'').join(','),
        shortable,
        status: ticketFillList?.filter(f => f.ticket_ID == r.id && users?.find(u => u.user_ID === f.user_ID)).map(f => StatusLabels[f.status]).join(","),
        priceCloseChg, 
        origin: r,
    };
}

/** @type {import('../Table').SorterAndFilters<TicketTableItem>} */
const initSorterAndFilters = null;

/** @type {import('../Table').Fields<TicketTableItem>[]} */
const visibleColumns = ['theme', 'labels', 'fullName', 'deductedChg', 'revChg', 'profitChg', 'priceCloseChg', 'coverer', 'shortable', 'status', 'comment'];

/** @type {import('../Table').FullHeightVirtualScrollTableComponent<TicketTableItem>} */
// @ts-ignore
const Table = FullHeightVirtualScrollTable;

const today = dayNumOfToday();
const todayMoment = fromDays2000(today);

const getTicketsReloadable = withReload(getTickets);

/**
 * @param {import('../api/ticket').Ticket[]} tickets 
 */
function getNextTradeDayPriceCloseForTickets(tickets) {
    return getNextTradeDayPriceCloseForCodes(
        tickets.map(t=>({ code: t.code, date: t.report_Date }))
    );
}


export default function TicketList({ }) {

    const [auth] = useAuth();

    const [date, setDate] = useQuery('d', ({ dayNum: today, type: /** @type {'date'|'quarter'} */('date') }),
        s => {
            if (Number.isSafeInteger(Number(s))) {
                const n = Number(s);
                if (n === 0) {
                    return null;
                } else if (n > 0) {
                    return { dayNum: n, type: /** @type {'date'|'quarter'} */('date') };
                } else {
                    return { dayNum: -n, type: /** @type {'date'|'quarter'} */('quarter')};
                }
            }
            return null;
        },
        m => {
            if (!m) return '';
            if (m.type === 'date') {
                return `${m.dayNum}`;
            } else {
                return `${-m.dayNum}`;
            }
        }
    );
    const [selectedCoverer, setSelectedCoverer] = useQuery('u', /** @type { number[] } */([]), s => s.split(',').filter(Boolean).map(Number), ids => ids.map(String).join(","));
    const [all, setAll] = useQuery('a', '');
    const [hasFill, setHasFill] = useQuery('f', false, Boolean, v => v ? '1' : '');

    const cny_gte = all ? null : 2000;
    const hkd_gte = all ? null : 2000;
    const usd_gte = all ? null : 300;
    const [users, usersLoading, usersError] = useApiState(getUsers); 

    const [reload, setReload] = useState(0);
    const [tickets, loading, error] = useApiState(
        getTicketsReloadable,
        date.type === 'date' ? date.dayNum : null,
        selectedCoverer, 
        date.type === 'quarter' ? date.dayNum : null, 
        cny_gte, hkd_gte, usd_gte,
        hasFill,
        reload
    );
    const [ticketFills,,,reloadTicketFills] = useApiCollectionFetcher(tickets, t=>t.id, getTicketFillsOfTickets); 
    const [ticketComments] = useApiCollectionFetcher(tickets, t=>t.id, getTicketCommentsOfTickets);
    const [priceCloses] = useApiState(getNextTradeDayPriceCloseForTickets, tickets);
    
    const [sorterAndFilters, setSorterAndFilters] = useState(initSorterAndFilters);
    
    const data = useMemo(() => {
        return tickets?.map(t => renderRow(t, users, ticketFills, priceCloses)) || [];
    }, [tickets, users, ticketFills, priceCloses]);

    const { enqueueSnackbar } = useSnackbar();

    const setTicketToPass = useCoCallback(function*(isCancelled, /** @type{number} */ ticketId, /** @type{number} */ userId) {
        try {
            yield updateTicketFill(ticketId, userId, { status: 4 });
            enqueueSnackbar('保存成功', { variant: 'success' });
            reloadTicketFills();
        } catch (e) {
            console.warn(e);
            enqueueSnackbar(e.message);
        }
    }, []);

    const history = useHistory();

    const viewTicketFill = useCallback((/** @type{number} */ ticketId, /** @type{number} */ userId) => {
        history.push(`/user-table/ticket/${ticketId}/fill-user/${userId}`);
    }, []);

    const viewTicketComment = useCallback((/** @type{number} */ ticketCommentId) => {
        history.push(`/user-table/ticket/comment/${ticketCommentId}`);
    }, []);

    const classes = useStyles();

    const canSelectCoverer = auth.permissions.indexOf('editUsers') >= 0;

    const [selectCoverer, setSelectCoverer] = useState({
        open: false,
        code: '',
        initialCovererIds: /**@type{number[]} */([]),
        initialCovererNames: /**@type{string[]} */([]),
    });

    const [statusMenu, setStatusMenu] = useState({
        open: false,
        anchorEl: /** @type {HTMLElement} */(null),
        ticket: /** @type {import('../api/ticket').Ticket} */(null),
    });

    const columns = useMemo(() => {
        /** @type {import('../Table').ColumnOption<TicketTableItem>[]} */
        const columns = [
            {
                field: 'theme',
                model: 'none',
                label: '主题',
                width: 80,
            }, 
            {
                field: 'labels',
                model: 'none',
                label: '优先标签',
                width: 80,
            },
            {
                field: 'fullName',
                model: 'none',
                label: '证券',
                render: r => {
                    if (r.introduce) {
                        return <Tooltip title={r.introduce}>
                            <Link to={`/stock/${r.origin.code}`} target="_blank">{r.fullName}</Link>
                        </Tooltip>
                    }
                    return r.fullName
                },
                width: 160,
            },
            {
                field: 'deductedChg',
                model: 'none',
                label: '季报扣非变动%',
            },
            {
                field: 'revChg',
                model: 'none',
                label: '季报营收变动%'
            },
            {
                field: 'profitChg',
                model: 'none',
                label: '预告利润变动%'
            },
            {
                field: 'priceCloseChg',
                model: 'none',
                label: '次日涨跌幅',
            },
            {
                field: 'coverer',
                model: 'none',
                label: 'Analyst',
                cellClassName: canSelectCoverer ? classes.clickableCell : '',
                render: canSelectCoverer ?
                    (row) => (
                        <div 
                            className={classes.clickableContent}
                            onClick={() => {
                                setSelectCoverer({
                                    open: true,
                                    code: row.origin.code,
                                    initialCovererIds: row.origin.coverer,
                                    initialCovererNames: row.origin.coverer.map(id => users?.find(u => u.user_ID === id)?.display_Name)
                                })
                            }}
                        >
                            {
                                row.origin.coverer
                                    .map(id => users?.find(u => u.user_ID === id))
                                    .filter(Boolean)
                                    .map(u => u.display_Name)
                                    .join(",")
                            }
                        </div>
                    )
                    :
                    (row) => (
                        <div>
                            {
                                row.origin.coverer
                                    .map(id => users?.find(u => u.user_ID === id))
                                    .filter(Boolean)
                                    .map(u => u.display_Name)
                                    .join(",")
                            }
                        </div>
                    )
            },
            {
                field: 'shortable',
                model: 'none',
                label: '可空'
            },
            {
                field: 'status',
                model: 'none',
                label: '评论状态',
                cellClassName: classes.clickableCell,
                render: (row) => {
                    const coverers = row.origin.coverer?.filter(id => users?.find(u => u.user_ID === id));
                    if (!coverers.length) {
                        return null;
                    }
                    const r = [];
                    for (const coverer of coverers) {
                        const user = users.find(u => u.user_ID === coverer)
                        if (r.length) {
                            r.push(<span key={`s-${r.length}`}>,</span>)
                        }
                        const s = StatusShortLabels[ticketFills.find(f => f.ticket_ID == row.origin.id && f.user_ID === coverer)?.status ?? 0];
                        if (!s) continue;
                        r.push(
                            <span key={`f-${coverer}`}>
                                {s}-{user?.display_Name}
                            </span>
                        )
                    }
                    return (
                        <div 
                            className={classes.clickableContent}
                            onClick={(e) => {
                                setStatusMenu({
                                    open: true,
                                    ticket: row.origin,
                                    anchorEl: /** @type {HTMLDivElement} */(e.target),
                                })
                            }}
                        >
                            {r.length ? r : '-'}
                        </div>
                    );
                }
            },
            {
                field: 'comment',
                model: 'none',
                label: '保存状态',
                cellClassName: classes.clickableCell,
                render: (row) => {
                    const comment = ticketComments.find(comment => comment.ticket_ID === row.origin.id);
                    if (!comment) return '';
                    return (
                        <div 
                            className={classes.clickableContent}
                            onClick={(e) => {
                                viewTicketComment(comment.id);
                            }}
                        >
                            <span>
                                {StatusLabels[comment.status]}
                            </span>
                        </div>
                    );
                }
            }
        ];
        return columns;
    }, [ticketFills, ticketComments, users, classes, canSelectCoverer]);

    useErrorMessage(error || usersError);

    
    

    if (loading || usersLoading) {
        return <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
            <CircularProgress color="primary" />
        </div>;
    }
    return <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Paper style={{padding:20,  marginBottom: 10}}>
            <DateOrQuarterPicker
                value={date}
                onChange={setDate}
            />
            {/* <DatePicker
                style={{marginRight: 16, width: 200}}
                autoOk
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="none"
                value={date}
                maxDate={todayMoment}
                onChange={setDate}
                PopoverProps={popupoverProps}
            /> */}
            <Select
                style={{marginRight: 16, width:200}}
                value={selectedCoverer}
                multiple
                onChange={e => {
                    setSelectedCoverer(e.target.value);
                }}
                displayEmpty
                renderValue={v => {
                    if (!v || !v.length) {
                        return '全部用户'
                    }
                    return v.map(userId => users?.find(user => user.user_ID === userId)?.display_Name).join(" / ")
                }}
            >
                { users?.map(user => (
                        <MenuItem key={user.user_ID} value={user.user_ID}>
                            <Checkbox checked={selectedCoverer.indexOf(user.user_ID) >= 0} />
                            <ListItemText primary={user.display_Name} />
                        </MenuItem>
                ))}
            </Select>
            <Select
                style={{marginRight: 16, width:200}}
                value={all ? 1 : 0}
                onChange={e => {
                    setAll(e.target.value ? '1' : '');
                }}
            >
                <MenuItem value={0}>
                    大市值
                </MenuItem>
                <MenuItem value={1}>
                    全部市值
                </MenuItem>
            </Select>
            <div style={{display:'inline-block', marginRight: 16, width:200}}>
                <Checkbox checked={hasFill} onChange={e => setHasFill(e.target.checked)} />
                <span>已刷</span>
            </div>
            
        </Paper>
        <div style={{ flex: 1, position: 'relative', marginBottom: 20 }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, right: 0, bottom: 0, display: 'flex' }}>
                <Table
                    data={data}
                    columns={columns}
                    sorterAndFilters={sorterAndFilters}
                    onSorterAndFiltersChange={setSorterAndFilters}
                    variant="full"
                    visibleColumnOrder={visibleColumns}
                />
            </div>
        </div>
        <CovererDialog
            {...selectCoverer}
            onClose={()=>setSelectCoverer(s => ({ ...s, open: false }))}
            onReloadStock={()=>{setReload(r => r+1)}}
        />
        <StatusMenu
            {...statusMenu}
            onClose={()=>setStatusMenu(s => ({ ...s, open: false }))}
            ticketFills={ticketFills}
            userId={auth?.userId}
            users={users}
            onSetTicketStatusToPass={setTicketToPass}
            onViewTicketDetail={viewTicketFill}
            onViewTicketComment={viewTicketComment}
        />
    </div>;
}

const useStyles = makeStyles(theme => ({
    clickable: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    clickableCell: {
        position: 'relative',
        padding: 0,
    },
    clickableContent: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            background: grey[700]
        }
    },
    commentStatus: {
        textDecoration: 'underline'
    }
}));

/**
 * @param {{
 *   open: boolean,
 *   onClose: () => void,
 *   ticket: import('../api/ticket').Ticket,
 *   ticketFills: import('../api/ticket').TicketFill[],
 *   userId: number,
 *   users: import('../api/user').User[],
 *   anchorEl: HTMLElement,
 *   onSetTicketStatusToPass: (ticketId: number, userId: number) => void,
 *   onViewTicketDetail: (ticketId: number, userId: number) => void,
 *   onViewTicketComment: (ticketCommentId: number) => void,
 * }} param0 
 */
function StatusMenu({ open, onClose, ticket, ticketFills, userId, users, anchorEl, onSetTicketStatusToPass, onViewTicketDetail, onViewTicketComment }) {
    if (!open) return null;
    const fills = ticket.coverer.map(userId => {
        const existing = ticketFills.find(f => f.ticket_ID === ticket.id && f.user_ID === userId);
        return existing || {
            ticket_ID: ticket.id,
            user_ID: userId,
            status: 0,
        };
    });
    
    const myFill = fills?.filter(f => f.ticket_ID === ticket?.id && f.user_ID === userId)[0];
    const otherFills = fills?.filter(f => f.ticket_ID === ticket?.id && f.user_ID !== userId && users?.find(u => u.user_ID === f.user_ID)) || [];
    return (
        <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
        >   
            { myFill ? (
                <MenuItem
                    onClick={() => { 
                        onViewTicketDetail(myFill.ticket_ID, myFill.user_ID);
                        onClose();
                    }}
                >
                    刷
                </MenuItem>
            ) : null}
            { myFill ? (
                <MenuItem
                    onClick={() => {
                        onSetTicketStatusToPass(myFill.ticket_ID, myFill.user_ID);
                        onClose();
                    }}
                >
                    Pass
                </MenuItem>
            ) : null}
            { otherFills.map(f => (
                <MenuItem
                    disabled={!f.status}
                    key={`${f.ticket_ID}-${f.user_ID}`}
                    onClick={() => {
                        onViewTicketDetail(f.ticket_ID, f.user_ID);
                        onClose();
                    }}
                >
                    查看{users?.find(u => u.user_ID === f.user_ID)?.display_Name}
                </MenuItem>
            ))}
        </Menu>
    )
}

/** @typedef {{ type: 'date' | 'quarter', dayNum: number }} DateOrQuarter */

const DateOrQuarterPickerContext = React.createContext({
    value: /** @type {DateOrQuarter} */(null),
    onChange: /** @type {(value: DateOrQuarter) => void} */(null)
});

const currentYear = toDate(moment().set({ month: 1, date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }));
const firstQuarterOfCurrentYear = dayNumToQuarterNum(currentYear);
const currentQuarter = dayNumToQuarterNum(dayNumOfToday());

// const currentQuarter = dayNumToQuarterNum(dayNumOfToday());
const rptPeriodOptions = /** @type { number[]} */([]);
const firstQuarter = currentQuarter - 7; //(currentQuarter - firstQuarterOfCurrentYear) > 1 ? firstQuarterOfCurrentYear : firstQuarterOfCurrentYear - 4;
const lastQuarter = currentQuarter + 1; //(currentQuarter - firstQuarterOfCurrentYear) > 1 ? firstQuarterOfCurrentYear + 8 : firstQuarterOfCurrentYear + 4;

for (let q = firstQuarter; q < lastQuarter; q++) {
    rptPeriodOptions.push(quarterNumToDayNum(q));
}

const DatePickerWrapper = React.forwardRef(({ children, ...props }, ref) => {
    const { value, onChange } = useContext(DateOrQuarterPickerContext);
    const selected = value?.type === 'quarter' ? value.dayNum : null;
    return <div ref={ref} {...props}>
        <div style={{margin:16,marginBottom:0}}>按日期</div>
        {children}
        <div style={{marginBottom: 8}}>
            <Divider/>
            <div style={{margin:16, marginBottom: 8}}>按季度</div>
            <div style={{width:310, display: 'flex', flexWrap: 'wrap', flexDirection: 'row', marginLeft: 8, marginRight: 8}}>
                { rptPeriodOptions.map(rptPeriod => (
                    <div key={rptPeriod} style={{width:'25%', flex: 1, padding: 8 }}>
                        <Button
                            color={ selected === rptPeriod ? 'primary' : 'default' }
                            variant={ selected === rptPeriod ? "contained" : "outlined" }
                            onClick={() => {
                                onChange({ dayNum: rptPeriod, type: 'quarter' });
                            }}
                            style={{width:'100%'}}
                        >
                            { quarterFromDayNum(rptPeriod) }
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    </div>
});

const PopoverProps = {
    PaperProps: {
        component: DatePickerWrapper
    }
};

const useDateOrQuarterPickerStyles = makeStyles({
    datePicker: {
        '& .MuiInput-input::placeholder': {
            opacity: 1
        }
    }
});

/**
 * 
 * @param {{
 *   value: DateOrQuarter,
 *   onChange: (value: DateOrQuarter) => void
 * }} param0 
 */
function DateOrQuarterPicker({
    value, onChange
}) {
    const [open, setOpen] = useState(false);
    const classes = useDateOrQuarterPickerStyles();
    const contextValue = useMemo(() => ({ 
        value, 
        onChange: (val) => {
            onChange(val);
            setOpen(false);
        }
    }), [value, onChange]);

    const date = value && value.type === 'date' ? fromDays2000(value.dayNum) : null;

    return (
        <DateOrQuarterPickerContext.Provider value={contextValue}>
            <DatePicker
                open={open}
                onOpen={()=>setOpen(true)}
                onClose={()=>setOpen(false)}
                style={{marginRight: 16, width: 200}}
                className={classes.datePicker}
                disableToolbar
                variant="inline"
                format="YYYY-MM-DD"
                margin="none"
                value={date}
                maxDate={todayMoment}
                onChange={(date) => {
                    onChange({ dayNum: toDate(date), type: 'date' });
                    setOpen(false);
                }}
                renderDay={(date, selectedDate, dayInCurrentMonth, dayComponent) => {
                    if (value.type === 'quarter') {
                        return React.cloneElement(dayComponent, { current: false, selected: false });
                    }
                    return dayComponent;
                }}
                PopoverProps={PopoverProps}
                placeholder={value.type === 'quarter' ? quarterFromDayNum(value.dayNum) : ''}
            />
        </DateOrQuarterPickerContext.Provider>
    );
}

