/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

/**
 * @param {{
 *   value: { id: number, name: string }[],
 *   onChange: (value: { id: number, name: string }[]) => void,
 *   label: string,
 *   variant?: import('@material-ui/core').TextFieldProps['variant'],
 *   options: { id: number, name: string }[],
 * } & Partial<Omit<import('@material-ui/lab/Autocomplete').AutocompleteProps< { id: number, name: string }, true, false, false>, 'value' | 'onChange' | 'options'>> } param0 
 */
export default function SearchableSelectMulti({ value, onChange, label, variant = undefined, options, ...props }) {
    return (
        <Autocomplete
            multiple
            value={value}
            onChange={(event, newValue) => {
                onChange(newValue)
            }}
            options={options}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(opt, val) => opt.id === val.id}
            renderInput={(params) => (
                <TextField {...params} label={label} variant={variant} />
            )}
            {...props}
        />
    );
}
