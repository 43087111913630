import { DialogContent, DialogActions, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { timeout } from '../../api/common';
import { addReport } from '../../api/stock';
import { dateToDayNum, dayNumOfToday, dayNumToDate, dayNumToQuarterNum } from '../../lib/date';
import { aoa2json, readSheetFromFile } from '../../lib/excel';
import { useCoCallback } from '../../lib/useCo';
import fullTemplateUrl from './full-template.xlsx';
import simpleTemplateUrl from './simple-template.xlsx';

const today = dayNumOfToday();

/**
 * @param {(string|string[])[]} sub
 * @param {string[]} sup
 */
function isSubset(sub, sup) {
    return sub.every(v => {
        if (Array.isArray(v)) {
            return v.some(v => sup.indexOf(v) >= 0);
        }
        return sup.indexOf(v) >= 0;
    });
}

/** 
 * @returns {{
 *   lines: any[],
 *   warnings: string[],
 *   errors: string[],
 *   count: { notice: number, express: number, fa: number }
 * }} 
 */
function parseSimple(simple) {
    const lines = [];
    const errors = [];
    const warnings = [];
    const counts = [, 0, 0, 0];
    simple.forEach((r, i) => {
        const code = r['代码'] || r['证券代码'];
        const type = r['类型'];
        const date = r['公告日期'];
        const quarter = r['业绩期'];
        if (!code) {
            errors.push(`第${i+2}行出错：代码或证券代码不能为空`);
            return;
        }
        if (!type) {
            errors.push(`第${i+2}行出错：类型不能为空`);
            return;
        }
        const t = Number(type);
        if (t !== 1 && t !== 2 && t !== 3) {
            errors.push(`第${i+2}行出错：类型只能是1,2,3(分别对应预，快，财)`);
            return;
        }
        counts[t] += 1;
        if (!date) {
            errors.push(`第${i+2}行出错：公告日期不能为空`);
            return;
        }
        const d = dateToDayNum(date);
        if (!d) {
            errors.push(`第${i+2}行出错：公告日期不是一个有效的日期`);
            return;
        }
        if (d < today - 365 || d > today + 365) {
            warnings.push(`第${i+2}行可能出错：公告日期距今天太远，可能不是一个正确的日期（${dayNumToDate(d).format('YYYY-MM-DD')}）`);
        }
        if (!quarter) {
            errors.push(`第${i+2}行出错：业绩期不是一个有效的日期`);
            return;
        }
        const q = dateToDayNum(quarter)
        const mmdd = dayNumToDate(q).format('MMDD');
        switch(mmdd) {
            case '0331':
            case '0630':
            case '0930':
            case '1231':
                break;
            default:
                errors.push(`第${i+2}行出错：业绩期不是一个有效的季度截止日期`);
                return;
        }
        lines.push({
            row: i+2,
            report: {
                code: code, type: t, rpt_Period: q, release_Date: d
            }
        });
    });
    return {
        lines, warnings, errors, count: { notice: counts[1], express: counts[2], fa: counts[3] },
    };
}

const dateFields = ['Rpt_Period', 'Notice_Release_Date', 'Notice_Release_1st_Date', 'Express_Release_Date', 'FA_Release_Date', 'FA_Expected_Release_Date'];
const numberFields = ["Price_Close", "PT_Score", "FT_Score", "Trend_Score", "Pricing_Score", "Quality_Score", "Notice_Accm_NP_Dside_Pct", "Notice_Accm_NP_Uside_Pct", "Notice_Accm_NP_Dside", "Notice_Accm_NP_Uside", "Notice_Q_NP_Dside_Pct", "Notice_Q_NP_Uside_Pct", "Notice_Q_NP_Dside", "Notice_Q_NP_Uside", "Express_Accm_Rev", "Express_Accm_Rev_YOY", "Express_Accm_OP_Profit", "Express_Accm_OP_Profit_YOY", "Express_Accm_Profit_Parent_Comp", "Express_Accm_Profit_Parent_Comp_YOY", "Express_Accm_Profit_Parent_Comp_Last_Year", "Express_Accm_OP_Profit_Last_Year", "Express_Q_Rev", "Express_Q_Rev_YOY", "Express_Q_OP_Profit", "Express_Q_OP_Profit_YOY", "Express_Q_Profit_Parent_Comp", "Express_Q_Profit_Parent_Comp_YOY", "FA_Accm_Rev", "FA_Accm_Rev_YOY", "FA_Accm_OP_Profit", "FA_Accm_OP_Profit_YOY", "FA_Accm_Profit_Parent_Comp", "FA_Accm_Profit_Parent_Comp_YOY", "FA_Accm_Profit_Parent_Comp_Deducted", "FA_Accm_Profit_Parent_Comp_Deducted_YOY", "FA_Q_Rev", "FA_Q_Rev_YOY", "FA_Q_OP_Profit", "FA_Q_OP_Profit_YOY", "FA_Q_Profit_Parent_Comp", "FA_Q_Profit_Parent_Comp_YOY", "FA_Q_Profit_Parent_Comp_Deducted", "FA_Q_Profit_Parent_Comp_Deducted_YOY"];
const nFields = ["Code", "Rpt_Period", "Price_Close", "PT_Score", "FT_Score", "Trend_Score", "Pricing_Score", "Quality_Score", "Notice_Release_Date", "Notice_Release_1st_Date", "Notice_Abstract", "Notice_Reason", "Notice_Accm_NP_Dside_Pct", "Notice_Accm_NP_Uside_Pct", "Notice_Accm_NP_Dside", "Notice_Accm_NP_Uside", "Notice_Q_Type", "Notice_Q_NP_Dside_Pct", "Notice_Q_NP_Uside_Pct", "Notice_Q_NP_Dside", "Notice_Q_NP_Uside"];
const eFields = ["Code", "Rpt_Period", "Price_Close", "PT_Score", "FT_Score", "Trend_Score", "Pricing_Score", "Quality_Score", "Express_Release_Date", "Express_Accm_Rev", "Express_Accm_Rev_YOY", "Express_Accm_OP_Profit", "Express_Accm_OP_Profit_YOY", "Express_Accm_Profit_Parent_Comp", "Express_Accm_Profit_Parent_Comp_YOY", "Express_Accm_Profit_Parent_Comp_Last_Year", "Express_Accm_OP_Profit_Last_Year", "Express_Q_Rev", "Express_Q_Rev_YOY", "Express_Q_OP_Profit", "Express_Q_OP_Profit_YOY", "Express_Q_Profit_Parent_Comp", "Express_Q_Profit_Parent_Comp_YOY"];
const fFields = ["Code", "Rpt_Period", "Price_Close", "PT_Score", "FT_Score", "Trend_Score", "Pricing_Score", "Quality_Score", "FA_Expected_Release_Date", "FA_Release_Date", "FA_Accm_Rev", "FA_Accm_Rev_YOY", "FA_Accm_OP_Profit", "FA_Accm_OP_Profit_YOY", "FA_Accm_Profit_Parent_Comp", "FA_Accm_Profit_Parent_Comp_YOY", "FA_Accm_Profit_Parent_Comp_Deducted", "FA_Accm_Profit_Parent_Comp_Deducted_YOY", "FA_Q_Rev", "FA_Q_Rev_YOY", "FA_Q_OP_Profit", "FA_Q_OP_Profit_YOY", "FA_Q_Profit_Parent_Comp", "FA_Q_Profit_Parent_Comp_YOY", "FA_Q_Profit_Parent_Comp_Deducted", "FA_Q_Profit_Parent_Comp_Deducted_YOY"];
const fieldMaps = {
    Notice_Release_Date: 'Release_Date',
    Express_Release_Date: 'Release_Date',
    FA_Release_Date: 'Release_Date',
};

function parseFull(full) {
    const lines = [];
    let nc = 0;
    let ec = 0;
    let fc = 0;
    const errors = [];
    const warnings = [];
    full.forEach((r, i) => {
        const l = {...r};
        if (!l['Code']) {
            errors.push(`第${i+2}行出错：Code不能为空`);
            return;
        }
        for (const k of dateFields) {
            const v = r[k];
            if (v) {
                const d = dateToDayNum(v);
                if (!d) {
                    errors.push(`第${i+3}行出错：${k}不是一个有效的日期`);
                    return;
                }
                if (d < today - 365 || d > today + 365) {
                    warnings.push(`第${i+3}行可能出错：${k}距今天太远，可能不是一个正确的日期（${dayNumToDate(d).format('YYYY-MM-DD')}）`);
                }
                l[k] = d;
            }
        }
        const mmdd = dayNumToDate(l['Rpt_Period']).format('MMDD');
        switch(mmdd) {
            case '0331':
            case '0630':
            case '0930':
            case '1231':
                break;
            default:
                errors.push(`第${i+3}行出错：Rpt_Period不是一个有效的季度截止日期`);
                return;
        }
        for (const k of numberFields) {
            const v = r[k];
            if (v) {
                if (!isFinite(Number(v))) {
                    errors.push(`第${i+3}行出错：${k}不是一个有效的数值`);
                    return;
                }
            }
        }
        let anyrow = false;
        if (l['Notice_Release_Date'] || l['Notice_Release_1st_Date']) {
            const n = { type: 1 };
            for (const k of nFields) {
                n[lowercase(fieldMaps[k] || k)] = l[k];
            }
            lines.push({ row: i+3, report: n });
            nc++;
            anyrow = true;
        }
        if (l['Express_Release_Date']) {
            const e = { type: 2 };
            for (const k of eFields) {
                e[lowercase(fieldMaps[k] || k)] = l[k];
            }
            lines.push({ row: i+3, report: e });
            ec++;
            anyrow = true;
        }
        if (l['FA_Release_Date'] || l['FA_Expected_Release_Date']) {
            const f = { type: 3 };
            for (const k of fFields) {
                f[lowercase(fieldMaps[k] || k)] = l[k];
            }
            lines.push({ row: i+3, report: f });
            fc++;
            anyrow = true;
        }
        if (!anyrow) {
            errors.push(`第${i+3}行出错：预报，快报和财报均无法识别`);
            return;
        }
    });
    return {
        lines,
        count: { notice: nc, express: ec, fa: fc },
        warnings,
        errors,
    };
}

const simpleHeader = ["公告日期", ["代码", "证券代码"], "类型", "业绩期"];
const fullHeader = ["Code", "Rpt_Period", "Price_Close", "PT_Score", "FT_Score", "Trend_Score", "Pricing_Score", "Quality_Score", "Notice_Release_Date", "Notice_Release_1st_Date", "Notice_Abstract", "Notice_Reason", "Notice_Accm_NP_Dside_Pct", "Notice_Accm_NP_Uside_Pct", "Notice_Accm_NP_Dside", "Notice_Accm_NP_Uside", "Notice_Q_Type", "Notice_Q_NP_Dside_Pct", "Notice_Q_NP_Uside_Pct", "Notice_Q_NP_Dside", "Notice_Q_NP_Uside", "Express_Release_Date", "Express_Accm_Rev", "Express_Accm_Rev_YOY", "Express_Accm_OP_Profit", "Express_Accm_OP_Profit_YOY", "Express_Accm_Profit_Parent_Comp", "Express_Accm_Profit_Parent_Comp_YOY", "Express_Accm_Profit_Parent_Comp_Last_Year", "Express_Accm_OP_Profit_Last_Year", "Express_Q_Rev", "Express_Q_Rev_YOY", "Express_Q_OP_Profit", "Express_Q_OP_Profit_YOY", "Express_Q_Profit_Parent_Comp", "Express_Q_Profit_Parent_Comp_YOY", "FA_Expected_Release_Date", "FA_Release_Date", "FA_Accm_Rev", "FA_Accm_Rev_YOY", "FA_Accm_OP_Profit", "FA_Accm_OP_Profit_YOY", "FA_Accm_Profit_Parent_Comp", "FA_Accm_Profit_Parent_Comp_YOY", "FA_Accm_Profit_Parent_Comp_Deducted", "FA_Accm_Profit_Parent_Comp_Deducted_YOY", "FA_Q_Rev", "FA_Q_Rev_YOY", "FA_Q_OP_Profit", "FA_Q_OP_Profit_YOY", "FA_Q_Profit_Parent_Comp", "FA_Q_Profit_Parent_Comp_YOY", "FA_Q_Profit_Parent_Comp_Deducted", "FA_Q_Profit_Parent_Comp_Deducted_YOY"];

/**
* @param {{
*   state: import(".").AddReportDialogState,
*   onReloadReport: () => void
* }} param0 
*/
export default function ImportReport({ state, onReloadReport }) {
    const { code, onClose } = state;
    const [submitting, setSubmitting] = useState(false);
    
    const [type, setType] = useState(0);
    const [lines, setLines] = useState([]);
    const [errors, setErrors] = useState([]);
    const [warnings, setWarnings] = useState([]);
    const [counts, setCounts] = useState(null);

    const [importIndex, setImportIndex] = useState(0);
    const [importError, setImportError] = useState([]);
    const [importCount, setImportCount] = useState({ success: 0, error: 0 });

    const onSubmit = useCoCallback(function*(){
        setSubmitting(true);
        let affectCurrentStock = false;
        for (let i = 0; i < lines.length; i++) {
            const r = lines[i];
            if (r.report.code === code) {
                affectCurrentStock = true;
            }
            try {
                setImportIndex(i);
                console.log("import", r.report);
                yield addReport(r.report);
                // yield timeout(500);
                setImportCount(c => ({ success: c.success + 1, error: c.error }));
            } catch (e) {
                setImportError(es => {
                    if (es.length >= 5) {
                        return [...es.slice(1), { row: r.row, message: e.message }];
                    }
                    return [...es, { row: r.row, message: e.message }];
                });
                setImportCount(c => ({ success: c.success, error: c.error + 1 }));
            }
        }
        if (affectCurrentStock) {
            onReloadReport();
        }
    }, [code, lines]);

    const onSelect = useCoCallback(function*(isCancelled, event) {
        try {
            const file = event.target.files[0];
            if (!file) {
                setType(0);
                setLines([]);
                setErrors([]);
                setWarnings([]);
                setCounts(null);
                return;
            }
            /** @type {string[][]} */
            const sheet = yield readSheetFromFile(file);
            if (isSubset(simpleHeader, sheet[0])) {
                const simple = aoa2json(sheet, 0);
                const data = parseSimple(simple);
                setType(1);
                setLines(data.lines);
                setErrors(data.errors);
                setWarnings(data.warnings);
                setCounts(data.count);
            } else if (isSubset(fullHeader, sheet[1])) {
                const full = aoa2json(sheet, 1);
                const data = parseFull(full);
                setType(2);
                setLines(data.lines);
                setErrors(data.errors);
                setWarnings(data.warnings);
                setCounts(data.count);
            } else {
                setType(3);
                setLines([]);
                setErrors([]);
                setWarnings([]);
                setCounts(null);
            }
        } catch (e) {
            console.warn(e);
            setType(3);
            setLines([]);
            setErrors([]);
            setWarnings([]);
            setCounts(null);
        }
    }, []);

    return <>
        <DialogContent>
            <div style={{fontSize: 16, width: 300}}>
                下载&nbsp;
                <a href={fullTemplateUrl} style={{color: 'white'}}>完整财报模板</a>
                &nbsp;或&nbsp;
                <a href={simpleTemplateUrl} style={{color: 'white'}}>简要财报模板</a>
                &nbsp;，修改内容后在此处上传。
            </div>
            <div style={{marginTop: 16, marginBottom: 16}}>
                <input disabled={submitting} id="importReportFile" type="file" style={{fontSize: 16}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={onSelect} />
            </div>
            {!submitting ? <div>
                { type === 3 ? <Alert severity="error">无法识别表头</Alert> : null}
                { lines.length > 0 ? <Alert severity="success">
                    成功识别{lines.length}条数据，可以导入
                    {counts ? <span><br/>其中共有{counts.notice}条预报；{counts.express}条快报；{counts.fa}条财报</span> : null}
                </Alert> : null}
                { warnings.length > 0 ? 
                    <Alert severity="warning">
                        其中{warnings.length}条可能有问题，需要注意：
                        { warnings.slice(0, 5).map((w, i) => <span key={i}><br/>{w}</span>)}
                        { warnings.length > 5 ? <span><br/>...以及{warnings.length-5}条更多的问题</span> : null }
                    </Alert>
                :null}
                { errors.length > 0 ?
                    <Alert severity="error">
                        数据表中{errors.length}行数据识别失败，这些行不会被导入：
                        { errors.slice(0, 5).map((w, i) => <span key={i}><br/>{w}</span>)}
                        { errors.length > 5 ? <span><br/>...以及{errors.length-5}条更多的问题</span> : null }
                    </Alert>
                :null}
            </div> : <div>
                <Alert severity="warning">
                    如果导入过程被中断，已经导入的数据仍然会存在。
                </Alert>
                { importIndex < lines.length - 1 ? (
                    <Alert severity="info">
                        正在导入第{importIndex+1}/{lines.length}条数据
                        <br/>成功：{importCount.success}
                        <br/>失败：{importCount.error}
                    </Alert>
                ) : ( importError.length > 0 ? (
                    <Alert severity="warning">导入完成，但有{importError.length}条数据导入失败</Alert>
                ) : (
                    <Alert severity="success">导入完成，{lines.length}条数据导入成功</Alert>
                ))}
                { importError.map((e, i) => (
                    <Alert severity="error">第{e.row}行的数据导入出错：{e.message}</Alert>
                )) }
            </div>}
            
        </DialogContent>
        <DialogActions>
            
            {!submitting ? <>
                <Button onClick={onClose}>
                    取消
                </Button>
                <Button onClick={onSubmit} disabled={!type || lines.length === 0} color="primary">
                    开始导入
                </Button>
            </> : null}
            {submitting && importIndex < lines.length - 1 ? (
                <Button onClick={onClose} color="secondary">
                    中断导入
                </Button>
            ) : null}
            {submitting && importIndex >= lines.length - 1 ? (
                <Button onClick={onClose}>
                    关闭
                </Button>
            ) : null}

            
        </DialogActions>
    </>
}

function lowercase(name) {
    return name.replace(/^[A-Z]+/, function(content) {
        return content.toLowerCase();
    });
}