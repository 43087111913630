import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import useAuth from '../api/auth';
import TicketCommentView from './TicketCommentView';
import TicketList from './TicketList';
import TicketView from './TicketView';

export default function Ticket({}) {
    const [auth] = useAuth();
    let defaultRoute;
    if (auth.permissions.indexOf('editNonCoveredStock') >= 0) {
        defaultRoute = '/user-table/ticket/list';
    } else {
        defaultRoute = '/user-table/ticket/list?u=' + auth.userId;
    }
    return (
        <Switch>
            <Route path="/user-table/ticket/list" component={TicketList} />
            <Route path="/user-table/ticket/:ticketId/fill-user/:userId" component={TicketView} />
            <Route path="/user-table/ticket/comment/:ticketCommentId" render={props => <TicketCommentView ticketCommentId={Number(props.match.params.ticketCommentId)} />} />
            <Redirect to={defaultRoute} />
        </Switch>
    )
}
