import React from 'react';
import Tabs from './components/Tabs';
import StockBasicInfoTable from './StockBasicInfoTable';
import StockDaily from './StockDaily';
import StockInfoTable from './StockInfoTable';
import UserTable from './UserTable';
import RelationCNHKTableRouter from './UserTable/RelationCNHK';
import ShortableCNTableRouter from './UserTable/ShortableCNTable';
import ShortableHKTableRouter from './UserTable/ShortableHKTable';

/** @type { import('./components/Tabs').TabDef[] } */
const tabs = [
    {
        label: '池子管理',
        path: '/manage/stocklist',
        component: StockBasicInfoTable,
        permission: 'editStockList'
    },
    {
        label: '个股标签管理',
        path: '/manage/stocklabel',
        component: StockInfoTable,
        permission: 'editCoveredStock'
    },
    {
        label: '用户管理',
        path: '/manage/user',
        component: UserTable,
        permission: 'editUsers'
    },
    {
        label: 'AH对照表',
        path: '/manage/relation-cn-hk',
        component: RelationCNHKTableRouter,
        permission: 'editStockList'
    },
    {
        label: 'A股可空库存',
        path: '/manage/shortable-cn',
        component: ShortableCNTableRouter,
        permission: 'editStockList'
    },
    {
        label: 'H股可空',
        path: '/manage/shortable-hk',
        component: ShortableHKTableRouter,
        permission: 'editStockList'
    },
    {
        label: '日更数据',
        path: '/manage/daily',
        component: StockDaily,
        permission: 'editStockList'
    },
];

export default function Manage() {
    return <div style={{display: 'flex', flex: 1, flexDirection: 'column', paddingLeft: 20, paddingRight: 20}}>
        <Tabs tabs={tabs} />
    </div>
}