import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import StockReportUploadUSTable from './StockReportUploadUSTable';
import StockReportUploadUSTableImport from './StockReportUploadUSTableImport';

export default function StockReportUploadUSTableRouter() {
    return <Switch>
        <Route path="/user-table/stock-report-upload-us" exact component={StockReportUploadUSTable} />
        <Route path="/user-table/stock-report-upload-us/add" exact component={StockReportUploadUSTableImport} />
        <Route path="/user-table/stock-report-upload-us/:version" exact component={StockReportUploadUSTable} />
        <Redirect to="/user-table/stock-report-upload-us" />
    </Switch>
}