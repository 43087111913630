import { Button, CircularProgress, LinearProgress, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { updateDateAggr } from '../api/aggr';
import { timeout, useApiState } from '../api/common';
import { commitRelationCNHKTable, createRelationCNHKTable, uploadRelationCNHKTableRow } from '../api/relation-cn-hk';
import { getStockCodesAndExchangeWithExchanges, getStockCodesWithExchanges, getStockSearchList } from '../api/stock';
import { uploadDailyData } from '../api/stock-daily-import';
import { bn } from '../lib/bn';
import { fromDays2000, toDate } from '../lib/date';
import { readSheetFromFile } from '../lib/excel';
import { makeParser } from '../lib/importer';
import { sorted } from '../lib/sort';
import { urlParent, urlResolve } from '../lib/url';
import { useCoCallback } from '../lib/useCo';
import { StockDailyColNames } from './common';

/** 
 * @typedef {{
 *     code: string,
 *     code_BBG: string,
 *     date: string,
 *     price_Close: string,
 *     price_Open: string,
 *     price_Hi: string,
 *     price_Lo: string,
 *     price_Chg: string,
 *     price_52w_Hi: string,
 *     price_52w_Lo: string,
 *     last_RSI: string,
 *     macd_DIFF: string,
 *     macd_DEA: string,
 *     marketValue_USD: string,
 *     ev: string,
 *     book_Value: string,
 *     price_Vol_10D: string,
 *     price_Vol_6M: string,
 *     price_Vol_1Y: string,
 *     adv_1M: string,
 *     adv_10D: string,
 *     adv_3M: string,
 *     num_Shares_T0_10K: string,
 *     eps_Consensus_T0: string,
 *     eps_Consensus_T1: string,
 *     eps_YOY_Consensus_T0: string,
 *     eps_YOY_Consensus_T1: string,
 *     eps_PE_Consensus_T0: string,
 *     eps_PE_Consensus_T1: string,
 *     bps_Consensus_T0: string,
 *     bps_Consensus_T1: string,
 *     bps_YOY_Consensus_T0: string,
 *     bps_YOY_Consensus_T1: string,
 *     bps_PB_Consensus_T0: string,
 *     bps_PB_Consensus_T1: string,
 *     ebitda_Consensus_T0: string,
 *     ebitda_Consensus_T1: string,
 *     ebitda_YOY_Consensus_T0: string,
 *     ebitda_YOY_Consensus_T1: string,
 *     ebitda_EVX_Consensus_T0: string,
 *     ebitda_EVX_Consensus_T1: string,
 *     rev_Consensus_T0: string,
 *     rev_Consensus_T1: string,
 *     rev_YOY_Consensus_T0: string,
 *     rev_YOY_Consensus_T1: string,
 *     rev_PS_Consensus_T0: string,
 *     rev_PS_Consensus_T1: string,
 * }} ParsedRow
 */

const headerMap = /** @type {{[key:string]: string}} */({});

Object.entries(StockDailyColNames).forEach(([key, value]) => {
    headerMap[value] = key;
});

const buildParser = (
    /** @type {{ code: string, code_BBG:string, exchange: string }[]} */stocks
) => makeParser(builder => {
    return {
        code: str => {
            if (!str) {
                return [true, ''];
            }
            const s = stocks.find(s => s.code === str);
            if (s) {
                return [true, str];
            } else {
                return [false, '不存在的Wind股票代码: ' + str];
            }
        },
        code_BBG: str => {
            if (!str) {
                return [true, ''];
            }
            const s = stocks.find(s => s.code_BBG === str);
            if (s) {
                return [true, str];
            } else {
                return [false, '不存在的BBG股票代码: ' + str];
            }
        },
        name: builder.any(),
        date: builder.requiredDayNum(),
        price_Close: builder.required(builder.bigNumber()),
        price_Open: builder.required(builder.bigNumber()),
        price_Hi: builder.required(builder.bigNumber()),
        price_Lo: builder.required(builder.bigNumber()),
        price_Chg: builder.required(builder.bigNumber()),
        price_52w_Hi: builder.required(builder.bigNumber()),
        price_52w_Lo: builder.required(builder.bigNumber()),
        last_RSI: (() => {
            const inner = builder.required(builder.bigNumber());
            return (/** @type{string} */str) => {
                if (str === "#N/A N/A") {//针对RSI的特殊处理，允许这种形式缺少数据，将其识别为0
                    return [true, '0'];
                }
                return inner(str);
            }
        })(),
        macd_DIFF: builder.required(builder.bigNumber()),
        macd_DEA: builder.required(builder.bigNumber()),
        marketValue_USD: builder.required(builder.bigNumber()),
        adv_1M: builder.bigNumber(),

        ev: builder.bigNumber(),
        book_Value: builder.bigNumber(),
        price_Vol_10D: builder.bigNumber(),
        price_Vol_6M: builder.bigNumber(),
        price_Vol_1Y: builder.bigNumber(),
        adv_10D: builder.bigNumber(),
        adv_3M: builder.bigNumber(),
        num_Shares_T0_10K: builder.bigNumber(),
        eps_Consensus_T0: builder.bigNumber(),
        eps_Consensus_T1: builder.bigNumber(),
        eps_YOY_Consensus_T0: builder.bigNumber(),
        eps_YOY_Consensus_T1: builder.bigNumber(),
        eps_PE_Consensus_T0: builder.bigNumber(),
        eps_PE_Consensus_T1: builder.bigNumber(),
        bps_Consensus_T0: builder.bigNumber(),
        bps_Consensus_T1: builder.bigNumber(),
        bps_YOY_Consensus_T0: builder.bigNumber(),
        bps_YOY_Consensus_T1: builder.bigNumber(),
        bps_PB_Consensus_T0: builder.bigNumber(),
        bps_PB_Consensus_T1: builder.bigNumber(),
        ebitda_Consensus_T0: builder.bigNumber(),
        ebitda_Consensus_T1: builder.bigNumber(),
        ebitda_YOY_Consensus_T0: builder.bigNumber(),
        ebitda_YOY_Consensus_T1: builder.bigNumber(),
        ebitda_EVX_Consensus_T0: builder.bigNumber(),
        ebitda_EVX_Consensus_T1: builder.bigNumber(),
        rev_Consensus_T0: builder.bigNumber(),
        rev_Consensus_T1: builder.bigNumber(),
        rev_YOY_Consensus_T0: builder.bigNumber(),
        rev_YOY_Consensus_T1: builder.bigNumber(),
        rev_PS_Consensus_T0: builder.bigNumber(),
        rev_PS_Consensus_T1: builder.bigNumber(),
    };
}, /** @type { ParsedRow } */(null),
    row => {
        if (!row.code && !row.code_BBG) {
            return { message: `${StockDailyColNames.code}与${StockDailyColNames.code_BBG}至少得存在一个` };
        }
        if (row.code && row.code_BBG) {
            const s1 = stocks.find(s => s.code === row.code);
            const s2 = stocks.find(s => s.code_BBG === row.code_BBG);
            if (s1?.code != s2?.code) {
                return { message: `${StockDailyColNames.code}与${StockDailyColNames.code_BBG}不对应`};
            }
        }
        return null;
    }
);

export default function StockDailyImport() {
    const [stocks, stocksLoading, stocksError] = useApiState(getStockCodesAndExchangeWithExchanges);
    const parser = useMemo(() => {
        if (!stocks) {
            return null;
        }
        return buildParser(stocks);
    }, [stocks]);
    const [warnings, setWarnings] = useState(/** @type {import('../lib/importer').ErrorMessage[]} */([]));
    const [errors, setErrors] = useState(/** @type {import('../lib/importer').ErrorMessage[]} */([]));
    const [parseErrors, setParseErrors] = useState(/** @type {string[]} */([]));
    const [data, setData] = useState(/** @type { { code: string, rows: ParsedRow[] }[] } */(null));
    const [rows, setRows] = useState(0);
    const [originalRows, setOriginalRows] = useState(0);
    const [[minDate, maxDate], setDateRange] = useState([0, 0]);
    const [importSuccessCount, setImportSuccessCount] = useState(0);
    const [importErrorCount, setImportErrorCount] = useState(0);
    const [importErrors, setImportErrors] = useState(/** @type {string[]} */([]));
    const [fileSelectKey, setFileSelectKey] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [importResult, setImportResult] = useState(0);
    const history = useHistory();
    const { url } = useRouteMatch();
    const parent = urlParent(url);

    function reset() {
        setWarnings([]);
        setErrors([]);
        setParseErrors([]);
        setData(null);
        setOriginalRows(0);
        setRows(0);
        setImportSuccessCount(0);
        setImportErrorCount(0);
        setImportErrors([]);
        setFileSelectKey(k => k + 1);
        setSubmitting(false);
        setImportResult(0);
    }

    const onSelect = useCoCallback(function*(_isCancelled, event) {
        try {
            setWarnings([]);
            setErrors([]);
            setParseErrors([]);
            setData(null);
            const sheet = /** @type {string[][]} */(yield readSheetFromFile(event.target.files[0]));
            console.log("sheet", sheet);
            const parseResult = parser(sheet, 1, 0, headerMap, []);

            console.log(parseResult);

            const codeSet = /** @type {Set<string>} */(new Set());
            const resultMap = /** @type {{[code:string]: ParsedRow[] }} */({});
            
            let minDate = null;
            let maxDate = null;

            for (const row of parseResult.data) {
                codeSet.add(row.code);
                if (!resultMap[row.code]) {
                    resultMap[row.code] = [];
                }
                resultMap[row.code].push(row);

                if (minDate === null || minDate > Number(row.date)) {
                    minDate = Number(row.date);
                }
                if (maxDate === null || maxDate < Number(row.date)) {
                    maxDate = Number(row.date);
                }
            }

            const errors = [...parseResult.errors];

            const data = sorted(Array.from(codeSet)).map(code => ({
                code: code,
                rows: sorted(resultMap[code], (a, b) => Number(a.date) - Number(b.date))
            }));

            const filtered = data.filter(s => {
                for (let d = 0; d < s.rows.length - 1; d++) {
                    if (Number(s.rows[d+1].date) !== Number(s.rows[d].date) + 1) {
                        errors.push({
                            ref: s.code,
                            message: `该股票的数据跳过了日期${fromDays2000(Number(s.rows[d].date) + 1).format('YYYY-MM-DD')}，日更数据必须按顺序导入。`,
                        });
                        return false;
                    }
                }
                return true;
            });

            console.log(filtered);
            
            setData(filtered);
            setWarnings(parseResult.warnings);
            setErrors(errors);
            setParseErrors(parseResult.parseErrors);
            setRows(filtered.reduce((n, s) => n + s.rows.length, 0));
            setOriginalRows(sheet.length - 1);
            setDateRange([minDate, maxDate]);
        } catch (e) {
            console.warn(e);
            setErrors([{ message: e.message }]);
        }
    }, [parser]);

    const onSubmit = useCoCallback(function*(isCancelled, /** @type {{ code: string, rows: ParsedRow[] }[]} */ data, minDate, maxDate){
        try {
            setSubmitting(true);
            let anyError = false;
            let anySuccess = false;

            for (const stock of data) {
                let successCount = 0;
                let currentDate;
                try {
                    for (const row of stock.rows) {
                        currentDate = Number(row.date);
                        yield uploadDailyData({
                            ...row,
                            date: Number(row.date)
                        });
                        anySuccess = true;
                        successCount += 1;
                        setImportSuccessCount(c => c + 1);
                    }
                } catch (e) {
                    console.warn(e);
                    setImportErrorCount(c => c + stock.rows.length - successCount);
                    setImportErrors(errs => [...errs, `导入${fromDays2000(currentDate).format('YYYY-MM-DD')}的${stock.code}时出现错误：${e.message}`]);
                    anyError = true;
                }
            }
            if (anySuccess) {
                for (let d = minDate; d <= maxDate; d++) {
                    try {
                        yield updateDateAggr(d);
                        setImportSuccessCount(c => c + 1);
                    } catch (e) {
                        console.warn(e);
                        setImportErrorCount(c => c + 1);
                        setImportErrors(errs => [...errs, `更新${fromDays2000(d).format('YYYY-MM-DD')}的综合信息时出现错误：${e.message}`]);
                        anyError = true;
                    }
                }
            } else {
                setImportErrorCount(c => c + maxDate - minDate + 1);
            }
            if (!anyError) {
                setImportResult(1);
            } else if (anySuccess) {
                setImportResult(2);
            } else {
                setImportResult(3);
            }
            setSubmitting(false);
        } catch (e) {
            console.warn(e);
            setImportErrors(es => [...es, e.message]);
            setImportResult(2);
            setSubmitting(false);
        }
    }, []);
    if (stocksLoading) {
        return (
            <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
                <CircularProgress color="primary" />
            </div>
        );
    }
    if (stocksError) {
        return (
            <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
                加载股票列表失败，请刷新重试
            </div>
        );
    }
    return (
        <Paper style={{flex:1, marginBottom: 20, padding: 20}}>
            <div style={{marginBottom: 16}}>
                <input key={fileSelectKey} disabled={data?.length > 0} type="file" style={{fontSize: 16}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv" onChange={onSelect} />
            </div>
            <div>
                { parseErrors?.length ? ( parseErrors.map((e, i) => (
                    <Alert severity="error" key={i}>
                        { e }
                    </Alert>
                ))) : null}
                { data ? (<div>
                    { rows === 0 && originalRows === 0 ? (
                        <Alert severity="error">
                            导入的表格中没有数据
                        </Alert>
                    ) : null}
                    { rows === 0 && originalRows !== 0 ? (
                        <Alert severity="error">
                            原始数据量：{ originalRows }<br/>
                            没有可导入的数据，错误原因如下：
                            {errors?.length ? errors.map((e,i) => (
                                <span key={i}>
                                    <br/>{ e.ref }：{ e.message }
                                </span>
                            )) : null}
                        </Alert>
                    ) : null}
                    { rows !== 0 && rows < originalRows ? (
                        <Alert severity="warning">
                            原始数据量：{ originalRows }<br/>
                            可导入数据量：{ rows }<br/>
                            股票数量：{ data?.length }<br/>
                            日期范围：{ minDate ? fromDays2000(minDate).format('YYYY-MM-DD') : '' } ~ { maxDate ? fromDays2000(maxDate).format('YYYY-MM-DD') : '' }<br/>
                            以下数据解析出错：
                            {errors?.length ? errors.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e.ref }：{ e.message }
                                    </span>
                                </span>
                            )) : null}
                        </Alert>
                    ) : null}
                    { rows !== 0 && rows === originalRows ? (
                        <Alert severity="success">
                            原始数据量：{ originalRows }<br/>
                            可导入数据量：{ rows }<br/>
                            股票数量：{ data?.length }<br/>
                            日期范围：{ minDate ? fromDays2000(minDate).format('YYYY-MM-DD') : '' } ~ { maxDate ? fromDays2000(maxDate).format('YYYY-MM-DD') : '' }<br/>
                        </Alert>
                    ) : null}
                    { warnings.length ? (
                        <Alert severity="warning">
                            以下数据可能有问题，但不影响导入：
                            {warnings.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e.ref }：{ e.message }
                                    </span>
                                </span>
                            ))}
                        </Alert>
                    ) : null }
                </div>) : null}
            </div>
            { rows > 0 ? (<div style={{marginTop: 20}}>
                <div>
                    <LinearProgress
                        style={{marginTop: 16, marginBottom: 16}}
                        color="primary"
                        variant="determinate"
                        value={(importSuccessCount + importErrorCount) * 100 / (rows + maxDate - minDate + 1)}
                    />
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div style={{flex:1}}>
                        导入进度：{importSuccessCount + importErrorCount}/{rows + maxDate - minDate + 1}
                    </div>
                    <Button disabled={submitting} variant="contained" onClick={reset}>
                        重置
                    </Button>
                    <Button
                        disabled={submitting || importResult > 0 }
                        style={{marginLeft: 16}} 
                        variant="contained"
                        color="primary" 
                        onClick={()=>onSubmit(data, minDate, maxDate)}
                    >
                        开始导入
                    </Button>
                </div>
                <div>
                    {importResult === 1 ? (
                        <Alert severity="success">导入完成</Alert>
                    ):null}
                    {importResult === 2 ? (
                        <Alert severity="warning">部分数据导入成功，下方是详细错误信息：</Alert>
                    ):null}
                    {importResult === 2 ? (
                        <Alert severity="error">导入失败，下方是详细错误信息：</Alert>
                    ):null}
                </div>
                <div>
                    { importErrors.length > 0 ? (
                        <Alert severity="error">
                            导入数据过程中发生以下错误：
                            {importErrors.map((e,i) => (
                                <span key={i}>
                                    <br/>
                                    <span style={{marginLeft: 16}}>
                                        { e }
                                    </span>
                                </span>
                            ))}
                        </Alert>
                    ) : null}
                </div>
            </div>) : null}
        </Paper>
    )
}