
import React from 'react';
import Tabs from './components/Tabs';
import Ticket from './Ticket';
import StockReportUploadCNTableRouter from './UserTable/StockReportUploadCN';
import StockReportUploadHKTableRouter from './UserTable/StockReportUploadHK';
import StockReportUploadUSTableRouter from './UserTable/StockReportUploadUS';

/** @type { import('./components/Tabs').TabDef[] } */
const tabs = [
    {
        label: '当日业绩',
        path: '/user-table/ticket',
        component: Ticket,
        permission: 'editCoveredStock'
    },
    {
        label: 'A股当日财报',
        path: '/user-table/stock-report-upload-cn',
        component: StockReportUploadCNTableRouter,
        permission: 'importStock'
    },
    {
        label: '港股当日财报',
        path: '/user-table/stock-report-upload-hk',
        component: StockReportUploadHKTableRouter,
        permission: 'importStock'
    },
    {
        label: '美股当日财报',
        path: '/user-table/stock-report-upload-us',
        component: StockReportUploadUSTableRouter,
        permission: 'importStock'
    },
];

export default function TicketTabs() {
    return (
        <div style={{display: 'flex', flex: 1, flexDirection: 'column', paddingLeft: 20, paddingRight: 20 }}>
            <Tabs tabs={tabs} />
        </div>
    );
}