import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { updateStockInfo, tryUpdateStockInfo, getStockReportQuarterly, updateStockStatus, updateReportFTType, getStockInfo, getStockExternalInfo, getStockKChartList, getStockRiskRewardList } from '../api/stock';
import { getEnums } from '../api/enum';
import { useCoCallback, useCoEffect } from '../lib/useCo';
import PricingMeasures from './PricingMeasures';
import FATable from './FATable';
import TrendMeasures from './TrendMeasures';
import QualityMeasures from './QualityMeasures';
import TitleBar from './TitleBar';
import OverAllMeasures from './OverAllMeasures';
import { useSnackbar } from 'notistack';
import StockChart from './StockChart';
import { useApiState } from '../api/common';
import useErrorMessage from '../lib/useErrorMessage';
import { getAllCommentsForStock, getCommentsByIds } from '../api/comment';
import { CircularProgress, Fab, useTheme, Zoom } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { AddRounded, CloseRounded, EditRounded, SaveRounded } from '@material-ui/icons';
import { getEvents } from '../api/event';
import { CommentDialog } from '../components/CommentDialog';
import BigDecimal from 'js-big-decimal';
import { bns, val } from '../lib/bn';
import Alert, { useAlertState } from '../components/Alert';
import AddReportDialog, { useAddReportDialogState } from './AddReportDialog';
import { useStockIsEditable } from './StockIsEditable';
import { today } from '../lib/date';

function bigDecimalFieldsToString(data) {
    data = {...data};
    Object.keys(data).forEach(key => {
        const v = data[key];
        if (v instanceof BigDecimal) {
            data[key] = bns(v);
        } else if (v === null) {
            data[key] = "";
        }
    });
    return data;
}

/**
 * 
 * @param {import('../api/stock').StockInfo} stock 
 * @param {string} code 
 * @param {any} data 
 */
async function tryUpdateStockInfoIfEdited(stock, code, data) {
    if (!data) {
        return stock;
    }
    return tryUpdateStockInfo(code, bigDecimalFieldsToString(data));
}

/**
 * @param {string} code
 * @param {unknown} _reload
 * @returns { Promise<{
 *   noticeList: (import('../api/stock').StockReportQuarterlyNotice & { haveEffectiveStatus?: boolean })[];
 *   expressList: (import('../api/stock').StockReportQuarterlyExpress & { haveEffectiveStatus?: boolean })[];
 *   faList: (import('../api/stock').StockReportQuarterlyFA & { haveEffectiveStatus?: boolean })[];
 * }> }
 */
async function getStockReportQuarterlyWithHaveEffect(code, _reload) {
    /** 
     * @type {{
     *   noticeList: (import('../api/stock').StockReportQuarterlyNotice & { haveEffectiveStatus?: boolean })[];
     *   expressList: (import('../api/stock').StockReportQuarterlyExpress & { haveEffectiveStatus?: boolean })[];
     *   faList: (import('../api/stock').StockReportQuarterlyFA & { haveEffectiveStatus?: boolean })[];
     * }}
     */
    const reports = await getStockReportQuarterly(code);
    const all = [
        ...reports.faList,
        ...reports.noticeList,
        ...reports.expressList,
    ];
    all.sort((a, b) => {
        // @ts-ignore
        const da = a.express_Release_Date || a.notice_Release_Date || a.fa_Release_Date || a.rpt_Period;
        // @ts-ignore
        const db = b.express_Release_Date || b.notice_Release_Date || b.fa_Release_Date || b.rpt_Period;
        return db - da;
    });
    let lastItem;
    for (const item of all) {
        if (item.status_Action) {
            item.haveEffectiveStatus = true;
            return reports;
        }
        if (item.last) {
            lastItem = item;
        } else {
            break;
        }
    }
    if (lastItem) lastItem.haveEffectiveStatus = true;
    return reports;
}

/** @type {import('../api/stock').StockKChartItem[]} */
const emptyChartData = [];

/** @type {import('../api/stock').StockRiskRewardChartItem[]} */
const emptyRiskRewardData = [];

/** @type { import('../api/comment').Comment[] } */
const emptyComments = [];

/** @type { import('../api/enum').Enums } */
const nullEnums = null;

/** 
 * @type {import('../components/CommentDialog').CommentDialogState} 
 */
const nullShowCommentDialog = null;

/** @type { import('./types').SetStockFields } */
const nullStockEditFields = null;

/** @type { import('../api/stock').StockInfo } */
const nullStockInfo = null;

/**
 * @param {{ code: string }} param0 
 */
export default function SingleStock({ code }) {
    const theme = useTheme();
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };
    const [external] = useApiState(getStockExternalInfo, code);
    const { enqueueSnackbar } = useSnackbar();
    const [stockLoading, setStockLoading] = useState(true);
    const [stock, setStock] = useState(nullStockInfo);
    const [reloadStock, setReloadStock] = useState(0);
    useCoEffect(function*(){
        try {
            const stock = yield getStockInfo(code, 0);
            setStock(stock);
            setStockLoading(false);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    }, [code, reloadStock]);
    const [enums, setEnums] = useState(nullEnums);
    const onReloadStock = useCallback(() => setReloadStock(r => r + 1), []);
    
    useCoEffect(function*(){
        try {
            const enums = yield getEnums();
            setEnums(enums);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    }, []);
    const [reloadReport, setReloadReport] = useState(0);
    const [reports, reportsLoading, reportsError] = useApiState(getStockReportQuarterlyWithHaveEffect, code, reloadReport);
    const onReloadReport = useCallback(() => setReloadReport(r => r + 1), []);
    
    const [comments, setComments] = useState(emptyComments);
    useCoEffect(function*(){
        try {
            const comments = yield getAllCommentsForStock(code);
            setComments(comments);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    }, [code]);

    const onReloadComment = useCoCallback(function*(isCancelled, /** @type { number } */commentId) {
        try {
            const comments = yield getCommentsByIds([commentId]);
            const comment = comments[0];
            if (comment) {
                setComments(comments => {
                    const n = [...comments];
                    const idx = n.findIndex(c => c.id === commentId);
                    if (idx >= 0) {
                        n[idx] = comment;
                    } else {
                        n.push(comment);
                    }
                    return n;
                });
            } else {
                setComments(comments => {
                    const n = [...comments];
                    const idx = n.findIndex(c => c.id === commentId);
                    if (idx >= 0) {
                        n.splice(idx, 1);
                    }
                    return n;
                });
            }
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    }, []);
    
    const loading = stockLoading || reportsLoading;
    useErrorMessage(reportsError);

    const [stockEditFields, setStockEditFields] = useState(nullStockEditFields);
    const setStockFields = useCallback((/** @type { import('./types').SetStockFields } */fields) => {
        setStockEditFields(f => ({ ...f, ...fields }));
    }, []);

    useEffect(() => {
        /** @type {OnBeforeUnloadEventHandler} */
        const listener = (e) => {
            if (stockEditFields && Object.keys(stockEditFields).length > 0)  {
                e.preventDefault();
                e.returnValue = true;
                return "修改的个股信息尚未保存，现在退出会丢失已修改的信息，确认退出吗？";
            }
        };
        window.addEventListener('beforeunload', listener);
        return () => window.removeEventListener('beforeunload', listener);
    }, [stockEditFields]);

    useEffect(() => {
        setStockEditFields(null);
    }, [code]);
    const [affectedFields] = useApiState(tryUpdateStockInfoIfEdited, stock, code, stockEditFields);
    const cachedStock = useMemo(() => ({ ...stock, ...affectedFields, ...stockEditFields }), [stock, affectedFields, stockEditFields]);
    const setEdit = useCallback((isEdit) => {
        if (isEdit) {
            setStockEditFields({});
        } else {
            setStockEditFields(null);
        }
    }, []);
    const [events,] = useApiState(getEvents, code, null, null, 1, 1, 1, 1, 1, 1, 1, 1, 1);
    const [saving, setSaving] = useState(false);
    const [saveStockDialogState, openSaveStockDialog, closeSaveStockDialog] = useAlertState();
    const saveStockFields = useCoCallback(function*(_isCancelled, fields, /** @type { import('../api/stock').StockInfo } */stock, warningConfirmed){
        try {
            if (Object.keys(fields).length === 0) {
                throw new Error('没有修改任何字段');
            }
            if (!warningConfirmed) {
                if (fields.trend_Exists || stock.trend_Exists_Or_Default == 1) {
                    const notFilled = [];
                    if (!fields.trend_Sustainability && !stock.trend_Sustainability) {
                        notFilled.push("行情持续性");
                    }
                    if (!fields.trend_Stability && !stock.trend_Stability) {
                        notFilled.push("行情稳定度");
                    }
                    if (!fields.trend_Stage && !stock.trend_Stage) {
                        notFilled.push("行情阶段");
                    }
                    if (!fields.trend_Strength && !stock.trend_Strength) {
                        notFilled.push("行情强度");
                    }
                    if (notFilled.length) {
                        openSaveStockDialog({
                            open: true,
                            content: `当前股票有行情，您应该为${notFilled.join("、")}设置非空的值`,
                            okText: '忽略并继续保存',
                            cancelText: '返回修改'
                        });
                        return;
                    }
                }
            }
            setSaving(true);
            const r = yield updateStockInfo(code, bigDecimalFieldsToString(fields));
            enqueueSnackbar("数据已更新", { variant: 'success' });
            setStock(r);
            setEdit(false);
            setSaving(false);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, { variant: 'error' });
            setSaving(false);
        }
    }, [code]);
    const updateStockFieldsDirect = useCoCallback(function*(isCancelled,/** @type {import('./types').SetStockFields} */ fields){
        try {
            if (Object.keys(fields).length === 0) {
                throw new Error('没有修改任何字段');
            }
            setSaving(true);
            const r = yield updateStockInfo(code, bigDecimalFieldsToString(fields));
            enqueueSnackbar("数据已更新", { variant: 'success' });
            setStock(r);
            setSaving(false);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, { variant: 'error' });
            setSaving(false);
        }
    }, [code]);

    const onShortWishlistUpdate = useCoCallback(function*(isCancelled, /** @type {string} */code, /** @type {number} */newShortWishlist){
        try {
            setSaving(true);
            const r = yield updateStockInfo(code, { short_Wishlist: `${newShortWishlist}` });
            enqueueSnackbar("数据已更新", { variant: 'success' });
            setStock(r);
            setSaving(false);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, { variant: 'error' });
            setSaving(false);
        }
    }, [code])

    const onChangeStockStatus = useCoCallback(function*(isCancelled, status, reportId, commentId, date){
        try {
            const [msg, r] = yield updateStockStatus(code, status, date, reportId, commentId);
            enqueueSnackbar(msg, { variant: 'success' });
            setStock(r);
            if (reportId) {
                setReloadReport(r => r + 1);
            }
            if (commentId) {
                onReloadComment(commentId);
            }
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    }, [code]);

    const onChangeFTType = useCoCallback(function*(_isCancelled, type, reportId) {
        try {
            const r = yield updateReportFTType(reportId, type);
            enqueueSnackbar("Ft已更新", { variant: 'success' });
            setStock(r);
            setReloadReport(r => r + 1);
        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    }, []);

    const edit = Boolean(stockEditFields);

    const [showCommentDialog, setShowCommentDialog] = useState(nullShowCommentDialog);

    const maxCommentDate = useMemo(() => Math.max(...comments.map(c => c.date)), [comments]) ;

    const addReportDialogState = useAddReportDialogState();
    const onAddReport = (type) => addReportDialogState.onShow(stock.code, stock.quarter_Or_Semi === 2 ? 'semi' : 'quarter');
    const stockIsEditable = useStockIsEditable(stock);

    const [chartData, setChartData] = useState(emptyChartData);
    const [riskRewardData, setRiskRewardData] = useState(emptyRiskRewardData);
    useCoEffect(function*() {
        try {
            const end = today();
            const start = end - 365 * 2;
            /** @type {[import('../api/stock').StockKChartItem[], import('../api/stock').StockRiskRewardChartItem[]] } */
            const [ data, rr = [] ] = yield Promise.all([
                getStockKChartList(code, start, end),
                getStockRiskRewardList(code, start, end)
            ]);
            /** @type {{[date: number]: import('../api/stock').StockRiskRewardChartItem }} */
            const rrMap = {};
            rr.forEach(r => rrMap[r[0]] = r);
            /** @type {import('../api/stock').StockRiskRewardChartItem[]} */
            const rrArr = [];
            /** @type {string} */
            let risk = null;
            /** @type {string} */
            let reward = null;
            for (let i = start; i <= end; i++) {
                if (rrMap[i]) {
                    risk = rrMap[i][1];
                    reward = rrMap[i][2];
                }
                if (risk !== null || reward !== null) {
                    rrArr.push([i, risk, reward]);
                }
            }
            setChartData(data);
            setRiskRewardData(rrArr);
        } catch (e) {
            enqueueSnackbar(e.message);
        }
    }, [code]);

    if (loading || !enums || !stock) {
        return <div style={{flex:1,display:'flex',alignItems: 'center', justifyContent:'center'}}>
            <CircularProgress color="primary" />
        </div>;
    }

    let commentDialog = null;
    if (showCommentDialog) {
        const c = comments.find(c => c.id === showCommentDialog.commentId);
        if (c || stockIsEditable) {
            commentDialog = (
                <CommentDialog
                    stock={stock}
                    show
                    onClose={() => setShowCommentDialog(null)}
                    code={stock.code}
                    comment={c}
                    onReloadComment={onReloadComment}
                    onReloadReport={onReloadReport}
                    onReloadStock={onReloadStock}
                    onChangeStockStatus={onChangeStockStatus}
                    reportId={showCommentDialog.reportId}
                    date={showCommentDialog.date}
                    onChangeCommentDialog={setShowCommentDialog}
                />
            );
        }
    }
    return (
        <div style={{padding: 20}}>
            <TitleBar edit={edit} stock={cachedStock} enums={enums} onReloadStock={onReloadStock} setStockFields={setStockFields} reports={reports}/>
            <OverAllMeasures edit={edit} setEdit={setEdit} stock={cachedStock} setStockFields={setStockFields} external={external}/>
            <QualityMeasures edit={edit} stock={cachedStock} enums={enums} setStockFields={setStockFields} onChangeEnums={setEnums} />
            <TrendMeasures edit={edit} stock={cachedStock} setStockFields={setStockFields} noticeList={reports?.noticeList} expressList={reports.expressList} faList={reports?.faList} />
            <PricingMeasures edit={edit} stock={cachedStock} setStockFields={setStockFields} />
            <FATable
                stock={stock}
                chartData={chartData}
                quarterOrSemi={stock.quarter_Or_Semi}
                reports={reports}
                comments={comments}
                events={events}
                onChangeStockStatus={onChangeStockStatus}
                onChangeFTType={onChangeFTType}
                onShowCommentDialog={setShowCommentDialog}
            />
            <StockChart
                chartData={chartData}
                riskRewardData={riskRewardData}
                faList={reports?.faList} 
                noticeList={reports?.noticeList} 
                expressList={reports?.expressList}
                commentList={comments}
                events={events}
                onShowCommentDialog={setShowCommentDialog}
            />
            { commentDialog }
            { stockIsEditable && <>
                <Fab 
                    size="small" 
                    color="primary" 
                    style={{ position: 'fixed', bottom: 20, left: 20 }} 
                    onClick={() => onAddReport()}
                >
                    <AddRounded />
                </Fab>
                <Zoom
                    in={edit}
                    timeout={transitionDuration}
                    style={{transitionDelay: `${edit ? transitionDuration.exit : 0}ms`}}
                    unmountOnExit
                >
                    <Fab 
                        size="small" 
                        style={{ position: 'fixed', bottom: 20, right: 70, backgroundColor:grey[400] }} 
                        onClick={() => setEdit(false)}
                        disabled={saving}
                    >
                        <CloseRounded />
                    </Fab>
                </Zoom>
                <Zoom
                    in={edit}
                    timeout={transitionDuration}
                    style={{transitionDelay: `${edit ? transitionDuration.exit : 0}ms`}}
                    unmountOnExit
                >
                    <Fab 
                        size="small" 
                        color="primary" 
                        style={{ position: 'fixed', bottom: 20, right: 20 }} 
                        onClick={() => saveStockFields(stockEditFields, stock)}
                        disabled={saving}
                    >
                        { saving ? <CircularProgress color="inherit" size={20} /> : <SaveRounded /> }
                    </Fab>
                </Zoom>
                <Zoom
                    in={!edit}
                    timeout={transitionDuration}
                    style={{transitionDelay: `${!edit ? transitionDuration.exit : 0}ms`}}
                    unmountOnExit
                >
                    <Fab 
                        size="small" 
                        color="primary" 
                        style={{ position: 'fixed', bottom: 20, right: 20 }} 
                        onClick={() => setEdit(true)}
                    >
                        <EditRounded />
                    </Fab>
                </Zoom>
            </> }
            <div style={{height:64}}/>
            <Alert 
                state={saveStockDialogState} 
                onCancel={closeSaveStockDialog} 
                onOk={() => { closeSaveStockDialog(); saveStockFields(stockEditFields, stock, true); }}
            />
            <AddReportDialog state={addReportDialogState} onReloadReport={onReloadReport} />
        </div>
    );
}