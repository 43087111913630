import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions({
    limit: 100,
    stringify: item => item.name
});

export default function SearchableSelect({ value, onChange, label, variant = undefined, options, ...props }) {
    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                onChange(newValue)
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (filtered.length === 0) {
                    filtered.push({
                        name: '找不到匹配的项',
                        disabled: true,
                    });
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                return option.name;
            }}
            getOptionDisabled={(option) => option.disabled}
            renderOption={(option) => option.name}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label={label} variant={variant} />
            )}
            {...props}
        />
  );

}