import { Button, Card, CardActions, CardContent, CardMedia, CircularProgress, makeStyles, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { changePassword } from './api/user';

const useStyles = makeStyles(theme => ({
    flex: {
        flex: 1
    },
    root: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    card: {
        width: 320,
        display: 'flex',
        flexDirection: 'column'
    },
    box: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    }
}));

export default function ChangePassword() {
    const classes = useStyles();

    const [opwd, setopwd] = useState('');
    const [npwd, setnpwd] = useState('');
    const [ncpwd, setncpwd] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    async function onSubmit(opwd, npwd, ncpwd) {
        try {
            setLoading(true);
            if (!opwd) {
                throw new Error('必须输入旧密码');
            }
            if (!npwd) {
                throw new Error('必须输入新密码');
            }
            if (!ncpwd) {
                throw new Error('必须输入确认新密码');
            }
            if (npwd != ncpwd) {
                throw new Error('两次输入的新密码必须相同');
            }
            await changePassword(opwd, npwd);
            enqueueSnackbar('密码修改成功', { variant: 'success' });
            setopwd('');
            setnpwd('');
            setncpwd('');
            setLoading(false);
        } catch (e) {
            setLoading(false);
            enqueueSnackbar(e.message);
        }
    }

    return (
        <div className={classes.root}>
            <Card className={classes.card} elevation={5}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        修改密码
                    </Typography>
                    <div className={classes.box}>
                        <TextField label="旧密码" fullWidth type="password" value={opwd} onChange={e => setopwd(e.target.value)} />
                    </div>
                    <div className={classes.box}>
                        <TextField label="新密码" fullWidth type="password" value={npwd} onChange={e => setnpwd(e.target.value)} />
                    </div>
                    <div className={classes.box}>
                        <TextField label="确认新密码" fullWidth type="password" value={ncpwd} onChange={e => setncpwd(e.target.value)} />
                    </div>
                </CardContent>
                <div className={classes.flex} />
                <CardActions>
                    <div className={classes.flex} />
                    <Button variant="contained" disabled={loading}  color="primary" onClick={() => onSubmit(opwd, npwd, ncpwd)}>
                        {loading ? <CircularProgress size="1.53rem" color="inherit" /> : '修改密码'}
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
}