/** @typedef { import("./bn").DecimalConvertable } DecimalConvertable */

import bigDecimal from "js-big-decimal";
import { div, gt, lte, min, mul, sub, val } from "./bn";

/**
 * @param {DecimalConvertable} avg_ADV 
 * @param {DecimalConvertable} aum 
 */
export function hard_Cap(avg_ADV, aum) {
    return div(div(avg_ADV, 3), aum);
}

/**
 * @param {DecimalConvertable} avg_ADV 
 * @param {DecimalConvertable} aum 
 * @param {DecimalConvertable} kelly_Position
 */
export function kelly_Position_Or_Hard_Cap(avg_ADV, aum, kelly_Position) {
    const hc = hard_Cap(avg_ADV, aum);
    return hc === null ? null : min(kelly_Position, hc);
}

/**
 * @param {DecimalConvertable} avg_ADV 
 * @param {DecimalConvertable} aum 
 */
export function aum_One_Pct_Over_ADV(avg_ADV, aum) {
    return div(mul(aum, '0.01'), avg_ADV);
}

const liquidityGood = div(50, 3);
const liquidityNormal = div(200, 3);

/**
 * 流动性，好:2/一般:3/差:4;
 * @param {DecimalConvertable} avg_ADV 
 * @param {DecimalConvertable} aum 
 * @returns { 0 | 2 | 3 | 4 }
 */
export function liquidity(avg_ADV, aum) {
    const n = div(aum, avg_ADV);
    if (n === null) {
        return 0;
    } else if (lte(n, liquidityGood)) {
        return 2;
    } else if (lte(n, liquidityNormal)) {
        return 3;
    } else {
        return 4;
    }
}

/**
 * 
 * @param {DecimalConvertable} price_Close 
 * @param {0|1|2} shortable 
 * @param {DecimalConvertable} risk 
 * @param {DecimalConvertable} reward 
 */
export function pricing_Score(price_Close, shortable, risk, reward) {
    const Risk_Line = val(risk);
    const Reward_Line = val(reward);
    const risk_pct = div(
        mul(
            sub(price_Close, Risk_Line),
            100
        ),
        price_Close
    );
    const reward_pct = div(
        mul(
            sub(Reward_Line, price_Close),
            100
        ),
        price_Close
    );
    const reward_risk_ratio = div(reward_pct, risk_pct);
    if (lte(risk_pct, 0)) {
        return val("1.5");
    } else if (lte(reward_pct, 0)) {
        if (shortable === 1) {
            return val("-1.5");
        } else {
            return val(0);
        }
    } else if (gt(reward_risk_ratio, "2.5")) {
        return val("1.5");
    } else if (gt(reward_risk_ratio, "1.5")) {
        return val(1);
    } else if (gt(reward_risk_ratio, "1.25")) {
        return val("0.5");
    } else if (shortable === 1) {
        if (lte(reward_risk_ratio, 0)) {
            return val(0);
        } else if (lte(reward_risk_ratio, "0.4")) {
            return val("-1.5");
        } else if (lte(reward_risk_ratio, "0.66")) {
            return val("-1");
        } else if (lte(reward_risk_ratio, "0.8")) {
            return val("-0.5");
        } else {
            return val(0);
        }
    } else {
        return val(0);
    }
}